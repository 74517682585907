<section class="uiuxSectionWrapper">
    <div class="heroSection">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="heroContent">
              <div>
                <h1>DevOps Consulting</h1>
                <h5>Code, Deploy, Thrive</h5>
                <p>
                    In the fast-paced world of software development, efficient and streamlined processes are crucial. At Techware Lab, we offer DevOps consulting services, optimizing the delivery pipeline from development to deployment.
                </p>
                <button (click)="requestQuote()">Get Started</button>
              </div>
  
              <div class="pinkBgDiv"></div>
              <div class="yellowBgDiv"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="heroImgWrapper">
              <img src="../../assets/images/devops-hero-Image.png" />
            </div>
            <div class="heroImageBgWrapper">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M48.4,-55.6C62.1,-46.3,71.9,-30.4,74.1,-13.7C76.3,3,70.9,20.4,62.6,37.4C54.3,54.3,43,70.8,28,75.8C13,80.8,-5.8,74.2,-21.1,65.4C-36.4,56.6,-48.1,45.6,-58.3,31.8C-68.4,18,-77.1,1.4,-74.7,-13.4C-72.3,-28.2,-59,-41.3,-44.7,-50.5C-30.5,-59.7,-15.2,-64.9,1.1,-66.2C17.4,-67.5,34.8,-64.9,48.4,-55.6Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
            <div class="heroImageWrapper2">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M65.2,-21.4C72.9,2.3,59.4,32.7,36.8,48.7C14.2,64.6,-17.4,66,-40.1,50.6C-62.8,35.2,-76.5,2.9,-68.4,-21.6C-60.2,-46.1,-30.1,-62.8,-0.7,-62.5C28.8,-62.3,57.6,-45.2,65.2,-21.4Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="serviceSection">
      <div class="container">
        <h2>Our DevOps Services</h2>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../assets/images/devops-devops-image.png" />
            <h3>DevOps Assessment & Strategy</h3>
            <p>
                Understand where you stand. Our team conducts a thorough assessment of your current practices and crafts a tailor-made strategy to elevate your DevOps game.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/devops-infrastructure-image.png" />
            <h3>Infrastructure Automation</h3>
            <p>
                Manual processes can hinder speed and efficiency. We help automate infrastructure deployment, ensuring consistency and rapid delivery.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/devops-continuous-image.png" />
            <h3>Continuous Integration & Continuous Deployment (CI/CD)</h3>
            <p>
                Accelerate your development cycle. Our experts set up and optimize CI/CD pipelines, ensuring faster
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../assets/images/devops-monitoring-image.png" />
            <h3>Monitoring & Log Management</h3>
            <p>
                Stay informed and proactive. We implement sophisticated monitoring and log management tools, giving you a real-time overview of your applications and infrastructure.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/devops-microservice-imaeg.png" />
            <h3>Microservices & Containerization</h3>
            <p>
                Make your applications agile and scalable. Our team helps in transitioning to microservices and leveraging container platforms like Docker and Kubernetes.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/devops-cloud-image.png" />
            <h3>Cloud Automation & Optimization</h3>
            <p>
                The cloud is the future. We assist in automating cloud deployments and optimizing cloud infrastructure costs.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="featureSection">
      <div class="blueBgDiv"></div>
      <div class="pinkBgDiv"></div>
      <div class="yellowBgDiv"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <img src="../../assets/images/devops-feature-image.png" />
          </div>
          <div class="col-lg-6">
            <h2>Why Choose Techware Lab for DevOps Consulting?</h2>
            <div>
              <h6>Experienced Professionals</h6>
              <p>
                Our DevOps consultants have years of experience in optimizing development lifecycles and have worked with diverse industries.
              </p>
            </div>
            <div>
              <h6>Tailored Solutions</h6>
              <p>
                One size doesn’t fit all. We offer solutions specifically designed to meet the unique requirements of your business.
              </p>
            </div>
            <div>
              <h6>Cutting-Edge Tools</h6>
              <p>
                We employ the latest and greatest in DevOps toolchains to ensure you stay ahead.
              </p>
            </div>
            <div>
              <h6>Transparent Communication</h6>
              <p>
                Clarity is critical. Our team ensures you’re kept in the loop, understanding every step we take and its impact.
              </p>
            </div>
            <div>
              <h6>Cost-Effective</h6>
              <p>
                Achieve operational excellence without breaking the bank. Our solutions are designed to provide maximum ROI.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="ctaWrapper">
      <div class="container">
        <h1>Embark on a DevOps Transformation</h1>
        <p>
            With Techware Lab, transition seamlessly into a world where development and operations go hand in hand. Let’s collaborate to create faster, more efficient, and more resilient software delivery processes. Get in touch with us today!
        </p>
  
        <button routerLink="/contact">Book A Free Consultation</button>
      </div>
    </div>
  </section>
  