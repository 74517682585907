 <section class="career-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="circle-wrapper">
            <h1>
              TRide: Self-Drive Car  <br/>
              Rental Software 
            </h1>
            <h2 class="cmn-sub-heading">
              Upgrade to a central hub for your vehicle rental service. Eliminate complicated rental processes through an all-in-one rental platform and raise revenue instantly.
            </h2>
            <a (click)="requestQuote()" target="_blank">Request a demo</a>
          </div>
        </div>
        <div class="col-md-5">
          <img
            src="/assets/images/tride-img-1.png"
            class="img-fluid"
            alt="tride"
          />
        </div>
      </div>
    </div>
</section>

<section class="whatistaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-5" data-aos="fade-right">
        <img
          src="/assets/images/tride-img-2.png"
          class="img-fluid"
          alt="tride"
        />
      </div>
      <div class="col-md-7">
        <div class="content">
          <h1 class="cmn-heading">What Is TRide?</h1>
          <p>
            Looking for a car rental system for your business? Techware Lab is proud to present TRide, an advanced application for managing vehicle rentals. This simple and innovative app enables you to manage bookings efficiently and respond to customer inquiries quickly.
          </p>
          <p>
            TRide is an on-demand automated car rental management software suite and an end-to-end solution for rental businesses to raise profitability. Designed for all rental companies from small to large, TRide offers a highly customizable rental booking system.
          </p>
          <p>
            Give your customers everything they need to browse and book rental cars through a responsive platform that offers full visibility into your services, customer interactions, and more.
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
        <div class="taxi-box">
          <img
            src="/assets/images/tride-icon-1.png"
            class="img-fluid"
            alt="tride"
          />
          <h2>
            Empower Clients With a Responsive Platform
          </h2>
          <p>
            City Ride features a quick login setup and a content management system for the efficient creation of site content. Users may receive notifications after each successful booking. Best of all, the entire source code is customizable, allowing you to add your own touches.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
        <div class="taxi-box">
          <img
            src="/assets/images/tride-icon-2.png"
            class="img-fluid"
            alt="tride"
          />
          <h2>
            Gain More Control Over Your Business
          </h2>
          <p>
            City Ride’s integrated administrator panel gives businesses the ability to track bookings, pickups, and drop-offs each day. Each user can see the status of car bookings in real-time for easy car management and convenient delivery capabilities.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
        <div class="taxi-box">
          <img
            src="/assets/images/tride-icon-3.png"
            class="img-fluid"
            alt="tride"
          />
          <h2>
            Access All the Information You Need in One Place
          </h2>
          <p>
            Keep a useful database of all the information your business needs to operate, including thousands of car model profiles and dealership locations. Users can calculate relevant tariffs, availability, and the statistics of individual vehicles in real-time. 
          </p>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="ttaxi-features-wrapper">
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-4">
        <h1 class="cmn-heading">TRide Features</h1>
        <img
          src="/assets/images/tride-img-3.png"
          class="img-fluid"
          alt="tride"
        />
      </div>
      <div class="col-md-8">
        <ul class="list-1">
          <li>
            <span>1</span>
            <h6>Real-Time Availability Tracking</h6>
          </li>
          <li>
            <span>2</span>
            <h6>Rapid Login System</h6>
          </li>
          <li>
            <span>3</span>
            <h6>Flexible Rental Options</h6>
          </li>
          <li>
            <span>4</span>
            <h6>Simple Content Management System</h6>
          </li>
          <li>
            <span>5</span>
            <h6>Tariff Calculator</h6>
          </li>
          <li>
            <span>6</span>
            <h6>PayPal Integration</h6>
          </li>
          <li>
            <span>7</span>
            <h6>1,000+ Vehicle Database</h6>
          </li>
          <li>
            <span>8</span>
            <h6>Multiple Dealership Locations</h6>
          </li>
          <li>
            <span>9</span>
            <h6>Notifications for Successful Bookings</h6>
          </li>
          <li>
            <span>10</span>
            <h6>Administration Panel</h6>
          </li>
          <li>
            <span>11</span>
            <h6>Multiple Languages</h6>
          </li>
          <li>
            <span>12</span>
            <h6>Full Customization</h6>
          </li>
        </ul>
        <button routerLink="/contact">Let’s get started</button>
      </div>
    </div>
  </div>
</section>

<section class="abouttaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-7 order-md-0 order-1">
        <div class="content">
          <h1 class="cmn-heading">About Techware Lab</h1>
          <p>
            Techware Lab provides top design capabilities and software solutions to create industry-specific apps and elevate your business online. Choose the experts in web design and mobile app development to bring your car rental business or car rental app development to the modern world. TRide makes self-drive car rental and car delivery a painless process so users can get to where they need to faster. 
          </p>
        </div>
      </div>
      <div class="col-md-5 order-md-1 order-0" data-aos="fade-left">
        <img
          src="/assets/images/tride-img-4.png"
          class="img-fluid"
          alt="tride"
        />
      </div>
    </div>
  </div>
</section>

<section class="footer-banner-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">
          Make Your Ride Smooth With Our Simplified App
        </h1>
        <h2 class="cmn-sub-heading">
          Choose the self-drive car rental platform that will upgrade your business for the technological age. Our team at TRide uses the latest technology to deliver a value-added mobile solution to your clients that will put you on the map.  
        </h2>
        <button routerLink="/contact">Let’s get started</button>
      </div>
    </div>
  </div>
</section>


<section class="faq-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Frequently Asked Questions</h1>
    <mat-accordion>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How can I improve my car rental business?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          With TRide, you can increase service efficiency, visibility, and improve the customer experience for your car rental business. This online car booking software saves a lot of resources and time, ensuring profit stays in your business. Simplify all customer touchpoints with a powerful platform and user-friendly support.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            What is a car rental management system?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          TRide self-drive car software helps to easily book and pay for a fleet, cab, or taxi by using a wallet and payment gateway. Taxi users can also track their driver's live status, manage bills, payments, booking, and more.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Will car rental software be beneficial for taxi business owners?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Yes. Whether you want to streamline operations or simplify the booking process, car rental software lets users book directly from your system. Self-driving car software simplifies vehicle rental management operations and enhances your profit.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How much does it cost to develop an online car rental software?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Techware’s app development technology allows us to develop car rental software starting at $5,000. Pricing for your car rental platform will depend on what features you require, the number of vehicles it covers, and the number of users. Looking for more information about pricing for a taxi or car rental app? Book a discovery call today by emailing hello@techwarelab.com.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>

<section class="relates-solutions-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Related Solutions</h1>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <div class="related-box" routerLink="/legal-case-management-software">
            <img src="/assets/images/new-related-img1.png" class="img-fluid" alt="Images">
        </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/doctor-appointment-app">
              <img src="/assets/images/new-related-img2.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-management-system">
              <img src="/assets/images/new-related-img3.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-dispatch-software">
              <img src="/assets/images/new-related-img4.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/grocery-app-development">
              <img src="/assets/images/new-related-img5.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/online-food-ordering-system">
              <img src="/assets/images/new-related-img6.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/movie-ticketing-software">
              <img src="/assets/images/new-related-img8.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/beauty-salon-software">
              <img src="/assets/images/new-related-img9.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/automotive-appointment-software">
              <img src="/assets/images/new-related-img10.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/matrimony-app-development">
              <img src="/assets/images/new-related-img11.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/payroll-management-system">
              <img src="/assets/images/new-related-img12.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>