<section class="header-banner">
    <div class="container">
        <div class="text-content">
            <h1>eCommerce Design and Web <br> Solutions for Start-Ups and <br>Enterprises</h1>  
            <h6>Take your company to the next level with a custom website design and development that <br> increases customer engagement and retention in all areas of eCommerce.</h6>
            <a (click)="requestQuote()" target="_blank">Request a demo</a>
        </div>
    </div>
</section>
<section class="techware-wrapper">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col-md-5 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/ecommerce-img-1.png" class="img-fluid" alt="ecommerce">
            </div>
            <div class="col-md-7">
                <div class="help-box mt-4 mt-md-0">
                    <h1 class="cmn-heading">
                        Techware Lab is a One-stop <br/>
                        Shop for Your eCommerce <br>
                        Website Development Needs
                    </h1>
                    <p>
                        Our expert team of front-end, back-end, and full-stack developers leverage the latest technology to create next-level digital experiences for a diverse portfolio of online stores to grow your business.
                    </p>
                    <button routerLink="/contact">Get a quote</button>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <section class="testimonial-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h1 class="cmn-heading">Who We Work With</h1>
          <p>See other companies who have partnered with Techware Lab for <br> eCommerce web development services.</p>
        </div>
        <div class="col-md-4"></div>
      </div>
      <div id="demo" class="carousel slide mt-5" data-ride="carousel">
        <!-- The slideshow -->
        <!-- <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-img.png"
                      alt="Profile"
                    />
                  </div>
                  <h3>Donnette Copestake</h3>
                  <h4>IT Director, Wyelands Bank</h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “Techware Lab and I worked together on a number of exciting innovation and R&D projects. Nixon was my lead technical consultant. He is a knowledgeable IT consultant who is highly collaborative in his approach. He is always quick to respond to questions / issues offering solutions to complex problems and often thinks ‘out of the box’. Nixon is intelligent, diligent and thorough in his approach and maintains a sense of humour. He was a highly valued member of my team, often going above and beyond what is expected of him. I would welcome him back to my team and strongly recommend him to future employers.”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img src="/assets/images/AbdulSattar.jpg" alt="Profile" />
                  </div>
                  <h3>AbdulSattar Zahid</h3>
                  <h4>
                    Alibaba Product Growth,<br />
                    Dubai
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “In a nutshell, I consider them to be a member of our team,
                    who just don't sit with us. I have always found the Techware
                    team very compliant. I have never gotten the feeling that
                    getting to a goal is important to me and to them we are just
                    another client, but I have always gotten the feeling that we
                    are one team and I value that over all the disagreements and
                    what not. As a testament to this feedback, what started from
                    one project has transformed our partnership in a very long one
                    and we hope to continue that. ”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-image-3.jpg"
                      alt="Profile"
                    />
                  </div>
                  <h3>Danette Copestake</h3>
                  <h4>
                    IT Director,<br />
                    Wyelands Bank
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    "Techware Lab and I worked together on a number of exciting
                    innovation and R&D projects. Nixon was my lead technical
                    consultant. He is a knowledgeable IT consultant who is highly
                    collaborative in his approach. He is always quick to respond
                    to questions / issues offering solutions to complex problems
                    and often thinks ‘out of the box’. Nixon is intelligent,
                    diligent and thorough in his approach and maintains a sense of
                    humour. He was a highly valued member of my team, often going
                    above and beyond what is expected of him. I would welcome him
                    back to my team and strongly recommend him to future
                    employers."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <a class="carousel-control-prev" href="#demo" data-slide="prev"> </a>
          <a class="carousel-control-next" href="#demo" data-slide="next"> </a>
        </div>
      </div>
    </div>
</section> -->

<section class="brands-wrapper">
    <div class="container">
      <div class="row mt-5 mb-4 mt-md-0">
        <div class="col-md-12 text-center">
          <h1 class="cmn-heading">Who We Work With</h1>
          <h2 class="cmn-sub-heading">
            See other companies who have partnered with Techware Lab for website
            design<br />
            and development services.
          </h2>
        </div>
      </div>
  
      <owl-carousel-o [options]="customOptionsOne">
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-one.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-two.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-three.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-four.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-eigt.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-nine.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-logo-five.png"
              class="img-fluid brand-img-one"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-logo-four.png"
              class="img-fluid brand-img-two"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-three.png"
              class="img-fluid brand-img-three"
              alt="Images"
            />
          </div>
        </ng-template>
  
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-five.png"
              class="img-fluid brand-img-four"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-six.png"
              class="img-fluid brand-img-five"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-sev.png"
              class="img-fluid brand-img-six"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-ten.png"
              class="img-fluid brand-img-seven"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-elv.png"
              class="img-fluid brand-img-eigt"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-tw.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </section>







<section class="design-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Ecommerce Web Solution Services</h1>
                <h2 class="cmn-sub-heading ">Launching your e-commerce platform should be an all-inclusive, silo-free experience for both you and your future shoppers.</h2>
           
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/ecommerce-img-2.png" class="img-fluid side-img" alt="ecommerce">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-5 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Our Ecommerce Web Solution Services
                                         </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        Launching your e-commerce platform should be an all-inclusive, silo-free experience for both you and your future shoppers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Customizable Website Design
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        Get a polished online marketplace tailored to your business goals and objectives. Our e-commerce solutions offer appealing UX/UI interface navigation and are SEO optimized.
                                       </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            CMS (Content Management Software) Integration
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            Easily make your business profitable. Our web development features are compatible with renowned CMS like OpenCart, Shopify, Magento, and WooCommerce—no technical coding skills required.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Marketing Suite & API Integration
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            Drive revenue to your eCommerce website with our in-house marketing suite features. Build funnels that drive traffic and welcome immediate sales, and integrate payment options such as Quickbooks, Google Analytics, and Paypal to complete your sales funnel.
                                        </p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<section class="approach-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Our Approach</h1>
                <h2 class="cmn-sub-heading ">We offer assistance at every stage of your eCommerce design and web <br> development with our agile five-step approach.</h2>
            </div>
        </div>
        <div class="row mb-md-5 mt-md-4">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box blue">
                    <img src="/assets/images/ecommerce-icon-1.png" alt="enterprise Icon">
                    <h3> Market Analysis  </h3>
                    <p>We start by conducting market research to determine the needs of your ideal customer.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="approach-box red">
                    <img src="/assets/images/ecommerce-icon-2.png" alt="enterprise Icon">
                    <h3>Design Strategy</h3>
                    <p>Based on this research, we build wireframes for your eCommerce platform that cater to your users’ need and business objectives.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box yellow">
                    <img src="/assets/images/ecommerce-icon-3.png" alt="enterprise Icon">
                    <h3>Website Development</h3>
                    <p>Then our developers use the latest trends and technologies to configure the code, interface design,and internal hardware for the  website to match the latest trends in eCommerce.</p>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box blue">
                    <img src="/assets/images/ecommerce-icon-4.png" alt="enterprise Icon">
                    <h3>UX/UI Testing</h3>
                    <p>We test your online store’s usability and quality assurance. The website development is internally tested to ensure that all pages and features effectively communicate with users.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box red">
                    <img src="/assets/images/ecommerce-icon-4.png" alt="enterprise Icon">
                    <h3>Launch and Ongoing Support</h3>
                    <p>We deliver the final project to you and assist with any website deployment or support you need. </p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="software-wrapper">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col-md-7">
                <div class="help-box">
                    <h1 class="cmn-heading">
                        An Immersive Multi-Platform
                    </h1>
                    <p>Optimize for usability and conversions on all devices.</p>
                    <p> We develop a competitive browser-based experience compatible for all iOS and Android devices. </p>
                </div>
            </div>
            <div class="col-md-5 mt-3 mt-md-0" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/ecommerce-img-3.png" class="img-fluid" alt="ecommerce">
            </div>
            
        </div>
    </div>
</section>






<section class="mobile-app-wrapper">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Features</h1>
                <p>Our innovative features allow our customers to stay ahead of their online competition. </p>
            </div>
        </div>
        <div class="row side">
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/ecommerce-ind-one.png" class="img-fluid img-ind-one" alt="Images">
                    <h5 class="cmn-sub-heading">Cyber Security </h5>
                    
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/ecommerce-ind-two.png" class="img-fluid img-ind-two" alt="Images">
                    <h5 class="cmn-sub-heading">SEO</h5>
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/ecommerce-ind-three.png" class="img-fluid img-ind-three" alt="Images">
                    <h5 class="cmn-sub-heading">Email Marketing</h5>
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/ecommerce-ind-four.png" class="img-fluid img-ind-four" alt="Images">
                    <h5 class="cmn-sub-heading">Data-Driven Marketing</h5>
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/ecommerce-ind-five.png" class="img-fluid img-ind-five" alt="Images">
                    <h5 class="cmn-sub-heading">AR/VR Add-Ons</h5>
                    
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/ecommerce-ind-six.png" class="img-fluid img-ind-six" alt="Images">
                    <h5 class="cmn-sub-heading">B2B and B2C <br> Websites </h5>
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/ecommerce-ind-seven.png" class="img-fluid img-ind-seven" alt="Images">
                    <h5 class="cmn-sub-heading">Third-Party <br> Integrations</h5>
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/ecommerce-ind-eight.png" class="img-fluid img-ind-eight" alt="Images">
                    <h5 class="cmn-sub-heading">Guided Navigation </h5>
                </div>
            </div>
            
        </div>
    </div>
</section>


<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Build a Profitable eCommerce Store With Our Proven Team</h1>
                <h5 class="cmn-sub-heading">Increase your online sales with a well-integrated and progressive website that meets the needs of your ideal shoppers. Our developers champion the latest technology solutions and will take you through our five-step go-to-market approach, so you can achieve your biggest outcomes.</h5>
                <button routerLink="/contact">Get a quote now</button>
            </div>
        </div>
    </div>
</section>








