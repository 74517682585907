import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-benefits-of-using-firebase',
  templateUrl: './benefits-of-using-firebase.component.html',
  styleUrls: ['./benefits-of-using-firebase.component.scss']
})
export class BenefitsOfUsingFirebaseComponent implements OnInit {

  public fbLink:string=environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;

  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Key benefits of using Firebase for mobile app development"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "While several mobile development platforms are available, Firebase stands out because of its benefits. Here are key benefits of using Firebase for mobile app development.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "While several mobile development platforms are available, Firebase stands out because of its benefits. Here are key benefits of using Firebase for mobile app development.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-18.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Key benefits of using Firebase for mobile app development",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "While several mobile development platforms are available, Firebase stands out because of its benefits. Here are key benefits of using Firebase for mobile app development.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/benefits-of-using-firebase-for-mobile-app-development",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-18.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Key benefits of using Firebase for mobile app development",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "While several mobile development platforms are available, Firebase stands out because of its benefits. Here are key benefits of using Firebase for mobile app development.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Key benefits of using Firebase for mobile app development",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `benefits-of-using-firebase-for-mobile-app-development`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u',`https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');
  
      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');
      
      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }

}
