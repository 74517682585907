import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "About Techware Lab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "At Techware Lab, we’re passionate about driving growth with innovative technologies and an agile approach. We’re all about starting small and achieving more. ",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "At Techware Lab, we’re passionate about driving growth with innovative technologies and an agile approach. We’re all about starting small and achieving more. ",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og-about.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "About Techware Lab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "At Techware Lab, we’re passionate about driving growth with innovative technologies and an agile approach. We’re all about starting small and achieving more. ",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/about",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og-about.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "About Techware Lab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "At Techware Lab, we’re passionate about driving growth with innovative technologies and an agile approach. We’re all about starting small and achieving more. ",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "About Techware Lab",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 



}
