import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-the-importance-of-inclusive-website-design',
  templateUrl: './the-importance-of-inclusive-website-design.component.html',
  styleUrls: ['./the-importance-of-inclusive-website-design.component.scss'],
})
export class TheImportanceOfInclusiveWebsiteDesignComponent implements OnInit {
  public fbLink: string = environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;

  constructor(
    private title: Title,
    private meta: Meta,
    private seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      'Responsive and Accessible: The Importance of Inclusive Website Design | Techware Lab'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        "In this blog, we'll uncover the magic behind inclusive website design and why it's not just a nice-to-have but an absolute necessity.",
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        "In this blog, we'll uncover the magic behind inclusive website design and why it's not just a nice-to-have but an absolute necessity.",
    });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://techwarelab.com/assets/images/vue.js-vs-react.jpg',
    });
    this.meta.updateTag({
      property: 'og:title',
      content: 'Responsive and Accessible: The Importance of Inclusive Website Design | Techware Lab',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        "In this blog, we'll uncover the magic behind inclusive website design and why it's not just a nice-to-have but an absolute necessity.",
    });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://techwarelab.com/vuejs-vs-react-a-comprehensive-comparison',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://techwarelab.com/assets/images/vue.js-vs-react.jpg',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Responsive and Accessible: The Importance of Inclusive Website Design | Techware Lab',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        "In this blog, we'll uncover the magic behind inclusive website design and why it's not just a nice-to-have but an absolute necessity.",
    });
    this.meta.updateTag({
      name: 'twitter:domain',
      content: 'Techware Lab | Responsive and Accessible: The Importance of Inclusive Website Design',
    });
  }
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `the-importance-of-inclusive-website-design`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u', `https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }
}
