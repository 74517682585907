import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-benefits-of-it-staff-augmentation-services',
  templateUrl: './benefits-of-it-staff-augmentation-services.component.html',
  styleUrls: ['./benefits-of-it-staff-augmentation-services.component.scss']
})
export class BenefitsOfItStaffAugmentationServicesComponent implements OnInit {

  public fbLink:string=environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;

  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "5 Benefits of IT Staff Augmentation Services | Techware Lab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "IT staff augmentation allows companies to add skilled professionals to their existing workforce. Read about the benefits of IT staff augmentation services and see how Techware Lab can help you with IT staff augmentation. ",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "IT staff augmentation allows companies to add skilled professionals to their existing workforce. Read about the benefits of IT staff augmentation services and see how Techware Lab can help you with IT staff augmentation. ",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-11.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "5 Benefits of IT Staff Augmentation Services | Techware Lab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "IT staff augmentation allows companies to add skilled professionals to their existing workforce. Read about the benefits of IT staff augmentation services and see how Techware Lab can help you with IT staff augmentation. ",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/benefits-of-it-staff-augmentation-services",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-11.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "5 Benefits of IT Staff Augmentation Services | Techware Lab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "IT staff augmentation allows companies to add skilled professionals to their existing workforce. Read about the benefits of IT staff augmentation services and see how Techware Lab can help you with IT staff augmentation. ",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Techware Lab | Software & Mobile App Development Tailored To Your Business Needs",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `benefits-of-it-staff-augmentation-services`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u',`https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');
  
      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }

}
