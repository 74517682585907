import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Meta, Title } from "@angular/platform-browser";;
import { SeoService } from '../seo.service';


@Component({
    selector: 'app-mobile-app-development',
    templateUrl: 'mobile-app-development.component.html',
    styleUrls: ['./mobile-app-development.component.scss']

})

export class MobileAppDevelopmentComponent implements OnInit {


    customOptionsOne: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
          0: {
            items: 1
          },
          400: {
            items: 2
          },
          740: {
            items: 2
          },
          940: {
            items: 4
          }
        },
        nav: true
      }
      constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }


      ngOnInit(): void {
        this.createLinkForCanonicalURL();
    
        this.title.setTitle(
          "Top Custom Mobile App Development Company| TechwareLab"
        );
        this.meta.updateTag({
          name: "description",
          content:
            "Boost brand awareness, and revenue with our leading mobile app development services. Create an outstanding app experience with our innovative app development services.",
        });
        this.meta.updateTag({
          property: "og:description",
          content:
            "Boost brand awareness, and revenue with our leading mobile app development services. Create an outstanding app experience with our innovative app development services.",
        });
        this.meta.updateTag({
          property: "og:image",
          content:
            "https://techwarelab.com/assets/images/ser-2.png",
        });
        this.meta.updateTag({
          property: "og:title",
          content:
            "Top Custom Mobile App Development Company| TechwareLab",
        });
        this.meta.updateTag({
          property: "og:description",
          content:
            "Boost brand awareness, and revenue with our leading mobile app development services. Create an outstanding app experience with our innovative app development services.",
        });
        this.meta.updateTag({
          property: "og:url",
          content:
            "https://techwarelab.com/mobile-app-development",
        });
        this.meta.updateTag({
          name: "twitter:image",
          content:
            "https://techwarelab.com/assets/images/ser-2.png",
        });
        this.meta.updateTag({
          name: "twitter:title",
          content:
            "Top Custom Mobile App Development Company| TechwareLab",
        });
        this.meta.updateTag({
          name: "twitter:description",
          content:
            "Boost brand awareness, and revenue with our leading mobile app development services. Create an outstanding app experience with our innovative app development services.",
        });
        this.meta.updateTag({
          name: "twitter:domain",
          content:
            "Top Custom Mobile App Development Company| TechwareLab",
        });
      }
    
      createLinkForCanonicalURL() {
        this.seoService.createLinkForCanonicalURL();
      }
}