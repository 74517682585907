import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-ui-ux-design-trends',
  templateUrl: './ui-ux-design-trends.component.html',
  styleUrls: ['./ui-ux-design-trends.component.scss']
})
export class UiUxDesignTrendsComponent implements OnInit {

  public fbLink:string=environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;

  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "What are Mobile App UI/UX Design Trends in 2022 | Techware Lab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Create your own mobile app using the latest UI/UX design. Read the article and view a list of mobile UI/UX design trends for 2022.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Create your own mobile app using the latest UI/UX design. Read the article and view a list of mobile UI/UX design trends for 2022.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-15.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "What are Mobile App UI/UX Design Trends in 2022 | Techware Lab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Create your own mobile app using the latest UI/UX design. Read the article and view a list of mobile UI/UX design trends for 2022.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/ui-ux-design-trends-2022",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-15.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "What are Mobile App UI/UX Design Trends in 2022 | Techware Lab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Create your own mobile app using the latest UI/UX design. Read the article and view a list of mobile UI/UX design trends for 2022.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "What are Mobile App UI/UX Design Trends in 2022 | Techware Lab",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `ui-ux-design-trends-2022`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u',`https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');
  
      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');
      
      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }

}
