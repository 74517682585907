import { Component, OnInit } from '@angular/core';
import { SeoService } from '../seo.service';
import { environment } from 'src/environments/environment';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-ui-ux-service',
  templateUrl: './ui-ux-service.component.html',
  styleUrls: ['./ui-ux-service.component.scss']
})
export class UiUxServiceComponent implements OnInit {

  public fbLink:string=environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;

  constructor(private seoService: SeoService, private model: MatDialog) { }

  createLinkForCanonicalURL(){
    this.seoService.createLinkForCanonicalURL();
  }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();
  }
  requestQuote() {
            
    this.model.open(RequestQuoteComponent);

  }

}
