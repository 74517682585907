<section class="header-banner">
    <div class="container">
        <div class="text-content">
            <h1>Mobile App Development</h1>  
            <h5>Mobile App Development for the Modern Business
            </h5>  
            <h6>
                Fast, efficient and experiential mobile application development for<br/> diverse and modern enterprises on the rise.
               
            </h6>
            <button routerLink="/portfolio">Explore our Mobile App portfolio</button>
        </div>
    </div>
</section>




<section class="techware-wrapper">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col-md-5 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/design-mon.webp" class="img-fluid" alt="Cutting edge technologies">
            </div>
            <div class="col-md-7">
                <div class="help-box mt-4 mt-md-0">
                    <h1 class="cmn-heading">
                        We’re Techware Lab  —<br/>
                        Your Trusted Mobile App Designers</h1>
                    <p>
                        Techware Lab is your one-stop solution that will transform your business operations and increase your customers’ experiences with your products and services. Our team of development professionals are skilled in designing immersive and transferable software solutions for various industries.
                    </p>
               
                </div>
            </div>
        </div>
    </div>
</section>




<section class="brands-wrapper">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Who We Work With </h1>
                <h2 class="cmn-sub-heading">See other companies who have partnered with Techware Lab for website design<br/> and development services.</h2>
            </div>
        </div>

        <owl-carousel-o  [options]="customOptionsOne">
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-one.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-two.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-three.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-four.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-eigt.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-nine.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-logo-five.png" class="img-fluid brand-img-one" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-logo-four.png" class="img-fluid brand-img-two" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-three.png" class="img-fluid brand-img-three" alt="Images">
                    
                </div>
            </ng-template>
       
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-five.png" class="img-fluid brand-img-four" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-six.png" class="img-fluid brand-img-five" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-sev.png" class="img-fluid brand-img-six" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-ten.png" class="img-fluid brand-img-seven" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-elv.png" class="img-fluid brand-img-eigt" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-tw.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
           
        </owl-carousel-o>
    </div>
</section>



<section class="design-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Web Design & Development Services</h1>
                <h2 class="cmn-sub-heading ">Everything you need to take your mobile app from concept to completion is right here.</h2>
           
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/web-design.webp" class="img-fluid side-img" alt="Mobile app development company">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-5 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Mobile App Strategy Development
                                         </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        We help you build a custom development strategy from conception to implementation for both iOS and Android app marketplaces.                                       </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            User Experience and Interface Design                                         </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        Our developers, designers, and product engineers provide mobile app design recommendations that will help you achieve outstanding customer engagement and retention. It’s all about providing an innovative, memorable, and enjoyable mobile experience for your audience.                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Mobile Application Testing
                                                                                </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        We prioritize cyber security and top performance in the app development process. Our AI automated testing process will ensure that your application is free from hiccups and impossible for hackers to intrude.                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Application Support and Maintenance</button>
                                    </h5>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        Our multidisciplinary and flexible team of front-end, back-end, and full-stack developers provide technical application support around the clock to ensure developmental success at every stage of your mobile app development.
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<section class="approach-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Our Approach</h1>
                <h2 class="cmn-sub-heading">We offer assistance at every stage of your mobile app development to ensure <br/>you have support from start to finish.</h2>
            </div>
        </div>
        <div class="row mb-md-5 mt-md-4">
            <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box blue">
                    <img src="/assets/images/app-img-1.png" alt="Service Icon">
                    <h3>Research and Discovery 
                    </h3>
                    <p>Our team will conduct the appropriate research about your business to gauge an overall vision and direction for your website development plan</p>
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="250">
                <div class="approach-box red">
                    <img src="/assets/images/app-img-2.png" alt="Service Icon">
                    <h3>Process Development 
                    </h3>
                    <p>We enter planning mode and decipher a compatible development process that aligns with your business values and modern design trends.</p>
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box yellow">
                    <img src="/assets/images/approach-3.png" alt="Service Icon">
                    <h3>Delivery 
                    </h3>
                    <p>Our experts will implement your website development plan, doubling down on structure, design, and customer deliverables—then we test it all.</p>
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="50">
                <div class="approach-box blue">
                    <img src="/assets/images/approach-4.png" alt="Service Icon">
                    <h3>Ongoing Support</h3>
                    <p>Even when the job is done, we are not. After discovering the results of your website development process, we work with you to reiterate and strategize for greater results.</p>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="software-wrapper">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col-md-7">
                <div class="help-box">
                    <h1 class="cmn-heading">
                        Adaptable App Software <br/> Development for Both iOS and<br/> Android Devices</h1>
                    <p>
                        We develop a competitive browser-based experience compatible for all iOS and Android devices.                   
                     </p>
               
                </div>
            </div>
            <div class="col-md-5 mt-3 mt-md-0" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/software-img.webp" class="img-fluid" alt="Cutting edge technologies">
            </div>
            
        </div>
    </div>
</section>


<section class="mobile-app-wrapper">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-left">
                <h1 class="cmn-heading">Mobile Apps for All Industries </h1>
            </div>
        </div>
        <div class="row side">
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-one.png" class="img-fluid img-ind-one" alt="Images">
                    <p>Public Sector</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-two.png" class="img-fluid img-ind-two" alt="Images">
                    <p>Healthcare</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-three.png" class="img-fluid img-ind-three" alt="Images">
                    <p>Enterprise</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-four.png" class="img-fluid img-ind-four" alt="Images">
                    <p>Banking & Finance </p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-five.png" class="img-fluid img-ind-five" alt="Images">
                    <p>Hospitality </p>
                </div>
            </div>
            <div class="col-md-2 ">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-six.png" class="img-fluid img-ind-six" alt="Images">
                    <p>Retail & Ecommerce </p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-sev.png" class="img-fluid img-ind-sev" alt="Images">
                    <p>Travel & Tourism</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-eigt.png" class="img-fluid img-ind-eigt" alt="Images">
                    <p>Food & Beverage</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-nine.png" class="img-fluid img-ind-nine" alt="Images">
                    <p>Transport & Logistic</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-ten.png" class="img-fluid img-ind-ten" alt="Images">
                    <p>On-Demand Delivery</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-elv.png" class="img-fluid img-ind-elv" alt="Images">
                    <p>Social &  Communication</p>
                </div>
            </div>
            <div class="col-md-2 ">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-tw.png" class="img-fluid img-ind-tw" alt="Images">
                    <p>Education & Elearning</p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="techware-innovation-wrapper ">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-left">
                <h1 class="cmn-heading">We Prioritize Technology and Innovation</h1>
            </div>
        </div>
        <div class="row side">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="team-box text-center">
                    <img src="/assets/images/inn-1.png" class="img-one" alt="Techware-team">
                    <h3>Artificial Intelligence</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="team-box text-center">
                    <img src="/assets/images/inn-2.png" class="img-two" alt="Techware-team">
                    <h3>Virtual Reality</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box text-center">
                    <img src="/assets/images/inn-3.png" class="img-three" alt="Techware-team">
                    <h3>Blockchain</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box text-center">
                    <img src="/assets/images/inn-4.png" class="img-four" alt="Techware-team">
                    <h3>Big Data</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box text-center">
                    <img src="/assets/images/inn-5.png" class="img-five" alt="Techware-team">
                    <h3>Data Science</h3>
                </div>
            </div>
            <div class="col-md-4 " data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box text-center">
                    <img src="/assets/images/inn-6.png" class="img-six" alt="Techware-team">
                    <h3>Augmented Reality</h3>
                </div>
            </div>
        </div>
    </div>
</section> 


<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Build a Mobile App That’s Designe for <br/> the Modern User Experience
                </h1>
                <p>Introducing a mobile app to your enterprise’s service offerings will multiply your business opportunities in today’s <br/> digital world. Techware Lab ensures you offer a cohesive, immersive, and scalable mobile <br/> experience to your users.</p>
                <h5 class="cmn-sub-heading">Are you ready to build your next mobile app? Get in touch with our team of <br/> mobile app development specialists to get started.</h5>
                <button routerLink="/contact">Get a free quote</button>
            </div>
        </div>
    </div>
</section>








