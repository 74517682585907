import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Portfolio - Techware Lab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "At Techware Lab, we develop a diverse range of digital products for all industries. Find out some of our works which we have delivered to our clients.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "At Techware Lab, we develop a diverse range of digital products for all industries. Find out some of our works which we have delivered to our clients.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Portfolio - Techware Lab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "At Techware Lab, we develop a diverse range of digital products for all industries. Find out some of our works which we have delivered to our clients.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/portfolio",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Portfolio - Techware Lab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "At Techware Lab, we develop a diverse range of digital products for all industries. Find out some of our works which we have delivered to our clients.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Portfolio - Techware Lab",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }


}
