<section class="about-section">
    <div class="container">
      <div class="row d-flex justify-content-center align-items-center about-row">
        <div class="col-md-6 about-div">
          <div class="about-img" data-aos="fade-right">
            <img [src]="imageUrl" class="img-fluid" alt="About Image"/>
          </div>
        </div>
        <div class="col-md-6 about-content about-right">
          <h3>{{ title }}</h3>
          <p *ngFor="let shortDesc of description">{{ shortDesc }}</p>
        </div>
      </div>
    </div>
</section>
  