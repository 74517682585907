import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';

const GET_CAREERS = gql`
    query GetAllCareersWeb {
    getAllCareersWeb {
        message
        status
        data {
            id
            experience
            location
            description
            status
            CategoryId
            VacancyId
            Category {
                id
                categoryName
                createdAt
                updatedAt
            }
            Vacancy {
                id
                vacancyTitle
                status
                CategoryId
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
    }
}`;

const GET_CATEGORY = gql`
    query GetCategoriesWeb {
        getCategoriesWeb {
            message
            status
            data {
                id
                categoryName
                createdAt
                updatedAt
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class CareerService {

    constructor(private apollo: Apollo) { }

    getCareers(): Observable<any> {
        return this.apollo
            .watchQuery<any>(
                {
                    query: GET_CAREERS, fetchPolicy: 'network-only',
                    variables: {}
                }
            )
            .valueChanges.pipe(map((result) => result.data.getAllCareersWeb))
    }
    getCategory(): Observable<any> {
        return this.apollo
            .watchQuery<any>(
                {
                    query: GET_CATEGORY, fetchPolicy: 'network-only',
                    variables: {}
                }
            )
            .valueChanges.pipe(map((result) => result.data.getCategoriesWeb))
    }
}