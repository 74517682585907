<section class="header-banner">
    <div class="container">
        <div class="text-content">
            <h1>Become a Techware Lab Partner <br/>
                and Grow Your Business</h1>  
            <h5>Grow your business faster than ever by partnering with a  <br/>
                proven technology partner like Techware Lab.
                </h5>  
            <div class="para">
                <h6 class="paragraph">Access the resources you need to scale up</h6>
                <h6 class="paragraph">Receive the support you need every step of the way</h6>
                <h6 class="paragraph">Streamline marketing, product development, training, and more</h6>
            </div>  
            <button routerLink="/contact">Become a partner</button>

        </div>
    </div>
</section>


<section class="techware-team-wrapper ">
    <div class="container">
        <div class="row mt-5 mt-md-0">
            <div class="col-md-12">
                <h1 class="cmn-heading">Receive Guided Support From the  <br/> Techware Lab Team</h1>
                <h2 class="cmn-sub-heading">Becoming a Techware Lab partner gives you access to an exclusive club that’ll help you grow your business.</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="team-box yellow">
                    <img src="/assets/images/team-one.png" alt="Techware-team" class="team-img-one">
                    <h3>Dedicated Account Manager</h3>
                    <p>All partners are assigned a dedicated account manager that’s hyper-focused on helping you achieve your outcomes.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="team-box blue">
                    <img src="/assets/images/team-two.png" alt="Techware-team" class="team-img-two">
                    <h3>Leverage Our Network</h3>
                    <p>Tap into our vast network of creatives, developers, designers, and more. Networking and making connections is vital to growing your business.
                    </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box red">
                    <img src="/assets/images/team-three.png" alt="Techware-team" class="team-img-three">
                    <h3>Keep Your Business Growing</h3>
                    <p>Grow your business with Techware Lab, a leading business in the software solutions industry. We ensure your business stays focused on value creation.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section> 


<section class="partner-content-wrapper">
    <div class="container">
        <div class="row pb-5">
            <div class="col-md-12 mb-5">
                <h1 class="cmn-heading">How Does Our Partner Program Work?</h1>
                <h2 class="cmn-sub-heading">Joining our partner program is incredibly easy and rewarding.</h2>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-md-6" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/content-img-one.webp" class="img-fluid" alt="End to end software development">
            </div>
            <div class="col-md-6">
                <div class="about-box">
                    <h2 class="blue">Join the Program
                    </h2>
                    <p>
                        All partners are assigned a dedicated account manager that’s hyper-focused on helping you achieve your outcomes.
                    </p>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-md-6 order-md-1 order-0 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/content-img-two.webp" class="img-fluid" alt="Cutting edge technologies">
            </div>
            <div class="col-md-6 order-md-0 order-1">
                <div class="about-box">
                    <h2 class="blue">Receive a Dedicated Account Manager
                    </h2>
                <p>
                    Your account manager will help you stay organized and on track to reach your goals.</p>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-md-6" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/content-img-three.webp" class="img-fluid" alt="Partnership for the goals">
            </div>
            <div class="col-md-6">
                <div class="about-box">
                    <h2 class="blue">Access the Resources You Need
                    </h2>
                    <p> 
                        Receive all the resources you need, from marketing, product development, training, to certifications.                </p>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-md-6 order-md-1 order-0 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/content-img-four.webp" class="img-fluid" alt="Successful business partnership">
            </div>
            <div class="col-md-6 order-md-0 order-1">
                <div class="about-box">
                    <h2 class="blue">Create Tangible Value
                    </h2>
                    <p>
                        Stay focused on creating value every step of the way with ongoing support from a team with decades of technical expertise.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section> 


<section class="faq-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Frequently Asked Questions</h1>
        <mat-accordion>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                    How does the Techware Lab partnership program work? 
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                Get in contact with us to become a partner. Partners receive active support for ongoing projects, access to referrals, industry connections, and other perks that are geared towards rapid growth.

              </p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    What do I have to do once I’m in a partnership? 
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    Our partner program works to support your outcomes. Whether you are leveraging our team for ongoing project support or to generate referral commissions—we work around your needs.    
                </p>              
                </mat-expansion-panel>
             
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Why should I join your partnership program?                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    Techware Lab prides itself on providing complete end-to-end development, support, and IT services. Our in-house team has everything you need to tackle complex marketing, development, and IT projects.    
                </p>             
             </mat-expansion-panel>
        </mat-accordion>
    </div>
</section>