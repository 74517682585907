import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-results-section',
  templateUrl: './results-section.component.html',
  styleUrls: ['./results-section.component.scss']
})
export class ResultsSectionComponent {
  @Input() title: string = '';
  @Input() description: string[] = [];
  @Input() imageUrl: string = '';
}
