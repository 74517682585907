import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from 'src/app/request-quote/request-quote.component';
import { SeoService } from 'src/app/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-social-media-optimization',
  templateUrl: './social-media-optimization.component.html',
  styleUrls: ['./social-media-optimization.component.scss']
})
export class SocialMediaOptimizationComponent implements OnInit {


  public fbLink:string=environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;
  navUrl: string;
  constructor(private seoService: SeoService, private model: MatDialog) { }

  createLinkForCanonicalURL(){
    this.seoService.createLinkForCanonicalURL();
  }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();
  }
  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

}
