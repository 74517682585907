<section class="career-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="circle-wrapper">
            <h1>
              TSalon: Take Your Beauty <br/>
              Salon Business to Mobile
            </h1>
            <h2 class="cmn-sub-heading">
              Give your salon business a makeover with the TSalon app. Upgrade your salon experience with expert software design solutions to highlight what makes your business the best. 
            </h2>
            <a (click)="requestQuote()" target="_blank">Request a demo</a>
          </div>
        </div>
        <div class="col-md-5">
          <img
            src="/assets/images/tsalon-img-1.png"
            class="img-fluid"
            alt="tsalon"
          />
        </div>
      </div>
    </div>
</section>

<section class="whatistaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-5" data-aos="fade-right">
        <img
          src="/assets/images/tsalon-img-2.png"
          class="img-fluid"
          alt="tsalon"
        />
      </div>
      <div class="col-md-7">
        <div class="content">
          <h1 class="cmn-heading">What Is TSalon?</h1>
          <p>
            The TSalon application offered by Techware Lab is an innovative approach to streamlining the workflows of hair salons, beauty spas, hairdressers, massage parlors, and beauty salons. Expert web design and a personalized online platform outfit the software suite to your needs.
          </p>
          <p>
            TSalon beauty salon scheduling software gives salon owners the ability to host a complete list of all offered services and allows customers to book within seconds from their mobile devices. Our salon scheduling app is perfect for salon owners, employees, and renters. TSalon fully supports both Android and iOS.
          </p>
          <p>
            Enhance the way you market your business. With TSalon, you can leverage personalized marketing to increase bookings, share promotions, and more.
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
        <div class="taxi-box">
          <img
            src="/assets/images/tsalon-icon-1.png"
            class="img-fluid"
            alt="tsalon"
          />
          <h2>
            Discover New Salon Experiences
          </h2>
          <p>
            The TSalon on-demand beauty salon app equips users with modern tools for finding appointments with the right beauty professionals. Select your date, choose your salon, and book the service appointment with just a few taps.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
        <div class="taxi-box">
          <img
            src="/assets/images/tsalon-icon-2.png"
            class="img-fluid"
            alt="tsalon"
          />
          <h2>
            Let More People Know of Your Services
          </h2>
          <p>
            TSalon allows salon owners to enhance their beauty business through online registration and presence. Advertise your offers, services, and promotions to potential customers and build a loyal client base today.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
        <div class="taxi-box">
          <img
            src="/assets/images/tsalon-icon-3.png"
            class="img-fluid"
            alt="tsalon"
          />
          <h2>
            Enhance Management With an Elegant UI
          </h2>
          <p>
            Salon administrators can use TSalon to manage settings and internal operations for an entire salon. The user-friendly interface ensures that everyone can find, advertise, and manage professional services effectively with just a touch.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="ttaxi-features-wrapper">
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-5">
        <h1 class="cmn-heading">TSalon Features</h1>
        <img
          src="/assets/images/tsalon-img-3.png"
          class="img-fluid"
          alt="tsalon"
        />
      </div>
      <div class="col-md-6">
        <ul class="list-1">
          <li>
            <span>1</span>
            <h6>Simple and Elegant Design</h6>
          </li>
          <li>
            <span>2</span>
            <h6>User Management</h6>
          </li>
          <li>
            <span>3</span>
            <h6>Security Key for Bulk Data Protection</h6>
          </li>
          <li>
            <span>4</span>
            <h6>Online Salon Booking</h6>
          </li>
          <li>
            <span>5</span>
            <h6>Store Locator</h6>
          </li>
          <li>
            <span>6</span>
            <h6>Google API Based Searching</h6>
          </li>
          <li>
            <span>7</span>
            <h6>Cash Payment Option</h6>
          </li>
          <li>
            <span>8</span>
            <h6>Filter By Services, Prices and offers, Gender 
              and Location</h6>
          </li>
        </ul>
        <button routerLink="/contact">Let’s get started</button>
      </div>
    </div>
  </div>
</section>

<section class="abouttaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-7 order-md-0 order-1">
        <div class="content">
          <h1 class="cmn-heading">About Techware Lab</h1>
          <p>
            Techware Lab provides top design capabilities and software solutions to create industry-specific apps and elevate your salon business. Choose our experts in web design to enhance your online presence and beauty management solutions. TSalon makes running a salon and growing your client base an optimized process for ultimate business success. 
          </p>
        </div>
      </div>
      <div class="col-md-5 order-md-1 order-0" data-aos="fade-left">
        <img
          src="/assets/images/tsalon-img-4.png"
          class="img-fluid"
          alt="tsalon"
        />
      </div>
    </div>
  </div>
</section>

<section class="footer-banner-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">
          Choose Technology That’s Transforming the Beauty Industry
        </h1>
        <h2 class="cmn-sub-heading">
          Experience how TSalon makes booking and managing salon appointments a breeze. Our mobile app development and technologies take the fuss out of lengthy beauty regimes and revolutionize how we search for salon services on a daily basis. Whoever said beauty is pain has never tried TSalon. 
        </h2>
        <button routerLink="/contact">Let’s get started</button>
      </div>
    </div>
  </div>
</section>


<section class="faq-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Frequently Asked Questions</h1>
    <mat-accordion>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How do I create a beauty salon app?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          A beauty salon scheduling app is ideal for standalone salons or a chain of salon venues. You will need to work with a specialized software development company to build your beauty salon app. Here at Techware, we build the best beauty salon apps to attract users, highlight key services, and grow your business. Contact us to get your free quote now.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How can a salon booking system help me manage my beauty business?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          The TSalon spa and salon management system simplifies booking, rebooking, and check ins for your services. TSalon allows your customers to book your services from anywhere, anytime, at their own convenience. This means less time spent on administrative tasks and more time providing services.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How can a beauty salon app solve my business’s problems?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          The TSalon beauty and salon appointment app lets your customers book appointments on the go. They can quickly discover what services you offer, when you’re available, and receive reminders for upcoming appointments. TSalon helps you fill cancellations and ensure open slots are booked using a solution built with the user experience in mind.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How much does it cost to develop a salon appointment mobile app like Glamsquad?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Pricing for a basic salon app that is fully customizable and fits the needs of your business will start around $5,000. Have questions about our software development services? Get in touch with us by emailing hello@techwarelab.com.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>

<section class="relates-solutions-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Related Solutions</h1>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <div class="related-box" routerLink="/legal-case-management-software">
            <img src="/assets/images/new-related-img1.png" class="img-fluid" alt="Images">
        </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/doctor-appointment-app">
              <img src="/assets/images/new-related-img2.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-management-system">
              <img src="/assets/images/new-related-img3.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-dispatch-software">
              <img src="/assets/images/new-related-img4.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/grocery-app-development">
              <img src="/assets/images/new-related-img5.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/online-food-ordering-system">
              <img src="/assets/images/new-related-img6.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/car-rental-app-development">
              <img src="/assets/images/new-related-img7.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/movie-ticketing-software">
              <img src="/assets/images/new-related-img8.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/automotive-appointment-software">
              <img src="/assets/images/new-related-img10.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/matrimony-app-development">
              <img src="/assets/images/new-related-img11.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/payroll-management-system">
              <img src="/assets/images/new-related-img12.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>