import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SeoService } from '../seo.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-automotive-appointment-software',
  templateUrl: './automotive-appointment-software.component.html',
  styleUrls: ['./automotive-appointment-software.component.scss']
})
export class AutomotiveAppointmentSoftwareComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  constructor(private model: MatDialog,private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Automotive Appointment Scheduling | Car Service Booking Software"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Automotive appointment scheduling software or car service or repair app helps mechanics offer a seamless customer experience. Save time and money with a centralized app for mechanics.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Automotive appointment scheduling software or car service or repair app helps mechanics offer a seamless customer experience. Save time and money with a centralized app for mechanics.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Automotive Appointment Scheduling | Car Service Booking Software",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Automotive appointment scheduling software or car service or repair app helps mechanics offer a seamless customer experience. Save time and money with a centralized app for mechanics.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Automotive Appointment Scheduling | Car Service Booking Software",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Automotive appointment scheduling software or car service or repair app helps mechanics offer a seamless customer experience. Save time and money with a centralized app for mechanics.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Automotive Appointment Scheduling | Car Service Booking Software",
    });
  }

  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

}
