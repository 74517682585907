import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatDialog } from '@angular/material/dialog';
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WebService } from "../../providers/web.service";
import { HelperService } from "../../providers/helper.service";
@Component({
  selector: 'app-software-development',
  templateUrl: './software-development.component.html',
  styleUrls: ['./software-development.component.scss']
})
export class SoftwareDevelopmentComponent implements OnInit {

  softwareDevForm:any;
  softwareDevSubmit:boolean = false
  loader:boolean = false

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }

  customOptions2: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }

  customOptions3: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  customOptions4: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  customOptions5: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  constructor(private model: MatDialog, private title: Title, private meta: Meta, private seoService: SeoService, private service: WebService, private helper:HelperService) { }

  ngOnInit(): void {

    this.softwareDevForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
      ]),
      phone: new FormControl('', [Validators.required]),
      requirement: new FormControl('', [Validators.required]),
      reason: new FormControl('Software Development'),
    });

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Enterprise Software Development Services | Software Development Company"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Transform your business and support growth by partnering with a boutique software development company in India, UAE, and USA that’s all-in on your growth story.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Transform your business and support growth by partnering with a boutique software development company in India, UAE, and USA that’s all-in on your growth story.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        " https://www.techwarelab.com/gitex-landing-page",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Enterprise Software Development Services | Software Development Company",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Transform your business and support growth by partnering with a boutique software development company in India, UAE, and USA that’s all-in on your growth story.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "  https://www.techwarelab.com/gitex-landing-page"
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Enterprise Software Development Services | Software Development Company",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Transform your business and support growth by partnering with a boutique software development company in India, UAE, and USA that’s all-in on your growth story.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Enterprise Software Development Services | Software Development Company",
    });
  }

  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "start",});

  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 

  softwareSubmit(){
    this.softwareDevSubmit = true;
    if (this.softwareDevForm.valid) {
      this.loader = true;
      let userObj = this.softwareDevForm.value;
      this.service.post_data("softwareFormData", "", userObj).subscribe(
        (response) => {
          if (response && response.status && response.status == 200) {
            this.helper.showAlert(1, "Success");
          } else {
            this.helper.showAlert(4, "Something went wrong");
          }
          this.softwareDevForm.reset({
            name: "",
            email: "",
            phone: "",
            requirement: ""
          });
          this.softwareDevSubmit = false;
          this.loader = false;
        },
        (error) => {
          this.helper.showAlert(4, "Something went wrong");
          this.softwareDevSubmit = false;
          this.loader = false;
        }
      );
    }

  }
}
