<section class="uiuxSectionWrapper">
    <div class="heroSection">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="heroContent">
              <div>
                <h1>Custom Software Development Service</h1>
                <h5>Your Vision, Coded to Perfection</h5>
                <p>
                    We pride ourselves on our ability to bring your vision to life with our custom software development services. We understand that every business is unique, and in the digital age, a one-size-fits-all solution just doesn’t cut it.
                </p>
                <button (click)="requestQuote()">Get Started</button>
              </div>
  
              <div class="pinkBgDiv"></div>
              <div class="yellowBgDiv"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="heroImgWrapper">
              <img src="../../assets/images/csdHero-Image.png" />
            </div>
            <div class="heroImageBgWrapper">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M48.4,-55.6C62.1,-46.3,71.9,-30.4,74.1,-13.7C76.3,3,70.9,20.4,62.6,37.4C54.3,54.3,43,70.8,28,75.8C13,80.8,-5.8,74.2,-21.1,65.4C-36.4,56.6,-48.1,45.6,-58.3,31.8C-68.4,18,-77.1,1.4,-74.7,-13.4C-72.3,-28.2,-59,-41.3,-44.7,-50.5C-30.5,-59.7,-15.2,-64.9,1.1,-66.2C17.4,-67.5,34.8,-64.9,48.4,-55.6Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
            <div class="heroImageWrapper2">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M65.2,-21.4C72.9,2.3,59.4,32.7,36.8,48.7C14.2,64.6,-17.4,66,-40.1,50.6C-62.8,35.2,-76.5,2.9,-68.4,-21.6C-60.2,-46.1,-30.1,-62.8,-0.7,-62.5C28.8,-62.3,57.6,-45.2,65.2,-21.4Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="featureSection">
        <div class="blueBgDiv"></div>
        <div class="pinkBgDiv"></div>
        <div class="yellowBgDiv"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <img src="../../assets/images/csdFeature-Image.png" />
            </div>
            <div class="col-lg-6">
              <h2>Why Choose Techware Lab for Custom Software Development?</h2>
              <div>
                <h6>Tailored Solutions</h6>
                <p>
                    Every business has its nuances, and our team of skilled developers dives deep into your business requirements to ensure the software aligns perfectly with your goals.
                </p>
              </div>
              <div>
                <h6>Cutting-Edge Technology</h6>
                <p>
                    We harness the latest technologies and methodologies to deliver robust, scalable, and futuristic solutions.
                </p>
              </div>
              <div>
                <h6>Full-Cycle Development</h6>
                <p>
                    From ideation and planning to deployment and support, Techware Lab is with you every step, ensuring smooth project flow and timely delivery.
                </p>
              </div>
              <div>
                <h6>Transparent Communication</h6>
                <p>
                    Our team believes in maintaining transparent communication throughout the project, ensuring you’re always informed and in control.
                </p>
              </div>
              <div>
                <h6>Data Security</h6>
                <p>
                    Protecting your data is our topmost priority. We employ state-of-the-art security measures to ensure your software and data are always safe.
                </p>
              </div>
              <div>
                <h6>Cost-Effective Solutions</h6>
                <p>
                    We strive to provide the best value for your investment, ensuring you get high-quality solutions without breaking the bank.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    <section class="technologies-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="cmn-heading">Our Custom Software Development Process</h1>
            
            <div class="row mt-5">
              
              
              <div class="col-md-6 mt-4">
                <div class="accordion" id="accordionExample">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                         
                          aria-controls="collapseOne"
                        >
                        Requirement Analysis
                        </button>
                      </h5>
                    </div>
  
                    <div
                      id="collapseOne"
                      class="collapse "
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p>Our initial discussions focus on understanding your business, goals, and specific needs.</p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                         
                          aria-controls="collapseTwo"
                        >
                        Design & Prototyping
                        </button>
                      </h5>
                    </div>
  
                    <div
                      id="collapseTwo"
                      class="collapse "
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p>We create an interactive prototype to give you a tangible feel of your software and refine it based on your feedback.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                         
                          aria-controls="collapseThree"
                        >
                        Development & Testing
                        </button>
                      </h5>
                    </div>
  
                    <div
                      id="collapseThree"
                      class="collapse "
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p> Our developers build the software while constantly testing it to ensure top-notch quality and performance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                         
                          aria-controls="collapseFour"
                        >
                        Deployment
                        </button>
                      </h5>
                    </div>
  
                    <div
                      id="collapseFour"
                      class="collapse "
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p> Once the software is refined and ready, we assist in deploying it to your preferred platform.</p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFive">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                         
                          aria-controls="collapseFive"
                        >
                        Maintenance & Support
                        </button>
                      </h5>
                    </div>
  
                    <div
                      id="collapseFive"
                      class="collapse "
                      aria-labelledby="headingFive"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p>Techware Lab offers consistent support and care to ensure your software stays updated and efficient.</p>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-5">
                  <img
                    src="../../assets/images/csdAccod-Image.png"
                    class="img-fluid"
                    alt="Mobile app development company"
                  />
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  
   
  
    <div class="serviceSection">
      <div class="container">
        <h2>Industries We Serve</h2>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../assets/images/csdHealthCare.png" />
            <h3>Healthcare</h3>
            
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/csdFinance.png" />
            <h3>Finance & Banking</h3>
           
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/csdRetail.png" />
            <h3>Retail & E-commerce</h3>
            
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../assets/images/csdEducation.png" />
            <h3>Education</h3>
           
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/csdRealestate.png" />
            <h3>Real Estate</h3>
           
          </div>
          
        </div>
      </div>
    </div>
  
    
  
    <div class="ctaWrapper">
      <div class="container">
        <h1>
          Ready for a tech upgrade?

        </h1>
        <p>Our custom software solutions are tailored for your success. Let's build success together.
        </p>
  
        <button routerLink="/contact">CONTACT US</button>
      </div>
    </div>
  </section>
  