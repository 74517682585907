<section class="techware-banner">
    <div class="banner-content">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1>Software innovation<br> tailored to your business needs</h1>
                    <h2 class="cmn-sub-heading">Gaining a competitive advantage in today’s fast-paced digital world can be challenging. Capture the full business value<br> of tech by partnering with a boutique software development firm that’s all-in on your growth story.</h2>
                    <button routerLink="/contact">Let’s get started</button>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="welcome-set">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">A personalized approach <br> that brings your business <br> vision to life</h1>
                <h2 class="cmn-sub-heading">Unlock the potential of cross-platform tech to cut costs, accelerate growth, and extend your reach. We use an agile<br> methodology to keep all aspects of a project collaborative and flexible.</h2>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="feature-box">
                    <h3 class="red">Work smarter<br> and harder</h3>
                    <p>Test, build, and scale faster with a talented team of software experts. Create stand-out digital experiences that work for your business.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="feature-box">
                    <h3 class="blue">Transform tech <br> into profit </h3>
                    <p>Techware Lab closes the gap between emerging technologies and profit. Our goal is to turn innovation into maximum-value outcomes. </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="feature-box">
                    <h3 class="yellow">Get to market <br> faster
                    </h3>
                    <p>We work tirelessly to create market-ready solutions tailored to your exact business needs. Move fast, deliver value, and disrupt the competition with Techware Lab. </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="specialize-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-5" data-aos="fade-right">
                <img src="/assets/images/specialize-img.webp" class="img-fluid" alt="Best software development company">
            </div>
            <div class="col-md-7">
                <div class="content-box">
                    <h1 class="cmn-heading">Specialized For <br> Your success </h1>
                    <p>We’re built differently. Techware Lab offers hands-on, specialized solutions for businesses that don’t have time or money to waste. We believe in helping you make the most of cutting-edge digital technologies through our agile approach
                        to software development. Our first and only priority is your success. That means we work around the clock to deliver a solution that works for you.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-right">
                <h1 class="cmn-heading">Our Services</h1>
                <h2 class="cmn-sub-heading">We offer a diverse range of solutions all designed to find a new<br> competitive edge for your business.</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="service-box yellow">
                    <img src="/assets/images/ser-1.png" alt="Service Icon">
                    <h3>Web App <br> Development</h3>
                    <p>Enrich customer relationships and grow operations exponentially with a fully-customized web application that understands your business needs and goals. </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="service-box blue">
                    <img src="/assets/images/ser-2.png" alt="Service Icon">
                    <h3>Mobile <br> Development
                    </h3>
                    <p>Put your business in the hands of customers with an outstanding mobile experience. Boost brand awareness, customer loyalty, and revenue with our leading mobile development services.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="service-box red">
                    <img src="/assets/images/ser-3.png" alt="Service Icon">
                    <h3>Digital <br> Marketing
                    </h3>
                    <p>The right marketing can turn big ideas into success stories. Attract and win new customers with a compelling digital strategy designed by a professional team of marketing specialists.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="service-box blue">
                    <img src="/assets/images/ser-4.png" alt="Service Icon">
                    <h3>Business <br> Consulting
                    </h3>
                    <p>Technology is complex. Get the most out of digital innovation by following a clearly-defined roadmap for change. Modernize your software and implement new systems without the risk.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="service-box red">
                    <img src="/assets/images/ser-it-staff.png" alt="Service Icon">
                    <h3>IT Staff <br> Augmentation</h3>
                    <p>Reliable, and cost-effective method of increasing the size and productivity of your teams with minimal effort. Techware Lab can help you strike the right balance between progress and cost.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="service-box yellow">
                    <img src="/assets/images/ser-6.png" alt="Service Icon">
                    <h3>Ecommerce <br> Development
                    </h3>
                    <p>Got something to sell? Maximize profits with a sleek and effortless ecommerce portal tailored to your exact business needs. Deliver an exceptional customer experience across any device.</p>
                </div>
            </div>
        </div>
        <div class="text-right">
            <button routerLink="/services"> View More</button>
        </div>
    </div>
</section>

<section class="industries-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Industries </h1>
                <h2 class="cmn-sub-heading">We design and develop agile enterprise solutions across industries.</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-7">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="industry-one" role="tabpanel" aria-labelledby="industry-tab-one">
                        <img src="/assets/images/industries-icon-1.webp" class="img-fluid industry-image animate__animated animate__fadeInLeft" alt="App Development Industries">
                    </div>
                    <div class="tab-pane fade" id="industry-two" role="tabpanel" aria-labelledby="industry-tab-two">
                        <img src="/assets/images/industries-icon-2.webp" class="img-fluid industry-image animate__animated animate__fadeInLeft" alt="App Development Industries">
                    </div>
                    <div class="tab-pane fade" id="industry-three" role="tabpanel" aria-labelledby="industry-tab-three">
                        <img src="/assets/images/industries-icon-3.webp" class="img-fluid industry-image animate__animated animate__fadeInLeft" alt="App Development Industries">
                    </div>
                    <div class="tab-pane fade" id="industry-four" role="tabpanel" aria-labelledby="industry-tab-four">
                        <img src="/assets/images/industries-icon-4.webp" class="img-fluid industry-image animate__animated animate__fadeInLeft" alt="App Development Industries">
                    </div>
                    <div class="tab-pane fade" id="industry-five" role="tabpanel" aria-labelledby="industry-tab-five">
                        <img src="/assets/images/industries-icon-5.webp" class="img-fluid industry-image animate__animated animate__fadeInLeft" alt="App Development Industries">
                    </div>
                    <div class="tab-pane fade" id="industry-six" role="tabpanel" aria-labelledby="industry-tab-six">
                        <img src="/assets/images/industries-icon-6.webp" class="img-fluid industry-image animate__animated animate__fadeInLeft" alt="App Development Industries">
                    </div>
                    <div class="tab-pane fade" id="industry-seven" role="tabpanel" aria-labelledby="industry-tab-seven">
                        <img src="/assets/images/industries-icon-7.webp" class="img-fluid industry-image animate__animated animate__fadeInLeft" alt="App Development Industries">
                    </div>
                    <div class="tab-pane fade" id="industry-eight" role="tabpanel" aria-labelledby="industry-tab-eight">
                        <img src="/assets/images/industries-icon-8.webp" class="img-fluid industry-image animate__animated animate__fadeInLeft" alt="App Development Industries">
                    </div>
                    <div class="tab-pane fade" id="industry-nine" role="tabpanel" aria-labelledby="industry-tab-nine">
                        <img src="/assets/images/industries-icon-9.png" class="img-fluid industry-image animate__animated animate__fadeInLeft" alt="App Development Industries">
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="nav flex-column h-100 justify-content-center nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link active" id="industry-tab-one" data-toggle="pill" href="#industry-one" role="tab" aria-controls="industry-one" aria-selected="true">Mobility</a>
                    <a class="nav-link" id="industry-tab-two" data-toggle="pill" href="#industry-two" role="tab" aria-controls="industry-two" aria-selected="false">Healthcare</a>
                    <a class="nav-link" id="industry-tab-three" data-toggle="pill" href="#industry-three" role="tab" aria-controls="industry-three" aria-selected="false">Beauty and Wellness</a>
                    <a class="nav-link" id="industry-tab-four" data-toggle="pill" href="#industry-four" role="tab" aria-controls="industry-four" aria-selected="false">Hospitality</a>
                    <a class="nav-link" id="industry-tab-four" data-toggle="pill" href="#industry-five" role="tab" aria-controls="industry-five" aria-selected="false">Finance and Legal</a>
                    <a class="nav-link" id="industry-tab-four" data-toggle="pill" href="#industry-six" role="tab" aria-controls="industry-six" aria-selected="false">Retail</a>
                    <a class="nav-link" id="industry-tab-four" data-toggle="pill" href="#industry-seven" role="tab" aria-controls="industry-seven" aria-selected="false">Entertainment</a>
                    <a class="nav-link" id="industry-tab-four" data-toggle="pill" href="#industry-eight" role="tab" aria-controls="industry-eight" aria-selected="false">Ecommerce</a>
                    <a class="nav-link" id="industry-tab-four" data-toggle="pill" href="#industry-nine" role="tab" aria-controls="industry-nine" aria-selected="false">Education</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="projects-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <h1 class="cmn-heading">Our Products</h1>
                <h2 class="cmn-sub-heading">Your success is our success. That means we don’t stop<br> until you achieve the outcomes you want.</h2>
            </div>
            <div class="col-md-4 mt-md-0 mt-5">
                <!-- Left and right controls -->
                <a class="carousel-control-prev" href="#projects" data-slide="prev">
                </a>
                <a class="carousel-control-next" href="#projects" data-slide="next">
                </a>
            </div>
        </div>
        <div id="projects" class="carousel slide mt-5" data-ride="carousel">
            <!-- The slideshow -->
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="slider-box">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="/assets/images/product-1.webp" class="img-fluid project-image" alt="Taxi booking software">
                            </div>
                            <div class="col-md-8">
                                <div class="project-content">
                                    <img src="/assets/images/t-taxi.png" class="project-logo" alt="Logo">
                                    <p>Build your taxi business online with this advanced cab booking software. Our intuitive mobile tools let you integrate an easy-to-use app that streamlines running a taxi business, simplifies the booking process, and
                                        more.
                                    </p>
                                    <div class="text-right">
                                        <button routerLink="/products" class="view-more-btn">View More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="slider-box">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="/assets/images/product-3.png" class="img-fluid project-image" alt="Taxi booking software">
                            </div>
                            <div class="col-md-8">
                                <div class="project-content">
                                    <img src="/assets/images/t-court.png" class="project-logo" alt="Logo">
                                    <p>Go Court has everything you need to build a technology-driven law firm. Grow your business with powerful functionality that lets you connect with clients online, filter searches based on specializations, in-app payment
                                        integration, and more.</p>
                                    <div class="text-right">
                                        <button routerLink="/products" class="view-more-btn">View More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="slider-box">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="/assets/images/product-2.png" class="img-fluid project-image" alt="Taxi booking software">
                            </div>
                            <div class="col-md-8">
                                <div class="project-content">
                                    <img src="/assets/images/t-doc.png" class="project-logo" alt="Logo">
                                    <p>Build strong physician-patient relationships with an online app that focuses on creating positive patient experiences. Book My Doc improves the booking process by making it easy for patients to find you, book an appointment,
                                        make changes, and receive updates about their appointments.</p>
                                    <div class="text-right">
                                        <button routerLink="/products" class="view-more-btn">View More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technologies-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Technologies</h1>
                <h2 class="cmn-sub-heading">We have extensive knowledge in making web and mobile applications.<br>Our execution handles with latest trends in technologies in order to make the software in highly efficient manner.</h2>
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/technology-image-1.png" class="img-fluid" alt="Mobile app development company">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-4">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Backend technologies
        </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>Node.js</li>
                                            <li>PHP</li>
                                            <li>.NET</li>
                                            <li>Python</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          Frontend technologies
        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>Angular</li>
                                            <li>ReactJS</li>
                                            <li>Vue.js</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Database technologies
        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>MSSQL</li>
                                            <li>MySQL</li>
                                            <li>MongoDB</li>
                                            <li>Postgres</li>
                                            <li>Firestore</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Mobile technologies
        </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>SWIFT</li>
                                            <li>Kotlin</li>
                                            <li>Java</li>
                                            <li>Flutter</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
</section>


<section class="testimonial-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <h1 class="cmn-heading">What do our clients have <br> to say about us? </h1>
                <h2 class="cmn-sub-heading">We believe in delivering tangible value. Don’t believe us?<br> Hear from our network of industry leaders. </h2>
            </div>
            <div class="col-md-4">

            </div>
        </div>
        <div id="demo" class="carousel slide mt-5" data-ride="carousel">
            <!-- The slideshow -->
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="test-circle">
                                <div class="test-profile">
                                    <img src="/assets/images/testimonial-image-1.jpg" alt="Profile">
                                </div>
                                <h3>Josef Myers</h3>
                                <h4>That Level, CEO, USA</h4>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="test-content">
                                <p>“I've been outsourcing some tasks to offshore developers for over 10 years now, and I've had the best experience with Techware by far. They follow the development and design requirements perfectly, and most importantly
                                    they communicate any questions or concerns early in the project, which saves everyone a lot of time. For every phase of the project management, development, and deployment, they work quickly and effectively. So whenever
                                    I send them a project, I know there's no need to worry about them completing correctly and on time.”</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="test-circle">
                                <div class="test-profile">
                                    <img src="/assets/images/AbdulSattar.jpg" alt="Profile">
                                </div>
                                <h3>AbdulSattar Zahid</h3>
                                <h4>Alibaba Product Growth,<br> Dubai</h4>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="test-content">
                                <p>“In a nutshell, I consider them to be a member of our team, who just don't sit with us. I have always found the Techware team very compliant. I have never gotten the feeling that getting to a goal is important to me and
                                    to them we are just another client, but I have always gotten the feeling that we are one team and I value that over all the disagreements and what not. As a testament to this feedback, what started from one project
                                    has transformed our partnership in a very long one and we hope to continue that. ”</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="test-circle">
                                <div class="test-profile">
                                    <img src="/assets/images/testimonial-image-3.jpg" alt="Profile">
                                </div>
                                <h3>Danette Copestake</h3>
                                <h4>IT Director,<br> Wyelands Bank</h4>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="test-content">
                                <p>"Techware Lab and I worked together on a number of exciting innovation and R&D projects. Nixon was my lead technical consultant. He is a knowledgeable IT consultant who is highly collaborative in his approach. He is always quick to respond to questions / issues offering solutions to complex problems and often thinks ‘out of the box’. Nixon is intelligent, diligent and thorough in his approach and maintains a sense of humour. He was a highly valued member of my team, often going above and beyond what is expected of him.  I would welcome him back to my team and strongly recommend him to future employers."
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <a class="carousel-control-prev" href="#demo" data-slide="prev">
                </a>
                <a class="carousel-control-next" href="#demo" data-slide="next">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Ready to work with us?</h1>
                <h2 class="cmn-sub-heading">We’re always looking for our next challenge. Bring your big idea to market with our team of designers,<br> developers, and business strategists.</h2>
                <button routerLink="/contact"> Let's get started</button>
            </div>
        </div>
    </div>
</section>