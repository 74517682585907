<app-heading-section 
    [title]="darazData.heading.title"
    [description]="darazData.heading.description"
    [buttonText]="darazData.heading.buttonText"
    [imageUrl]="darazData.heading.imageUrl">
</app-heading-section>

<app-about-section 
    [title]="darazData.about.title"
    [description]="darazData.about.description"
    [imageUrl]="darazData.about.imageUrl">
</app-about-section>

<app-story-section
    [title]="darazData.story.title"
    [description]="darazData.story.description"
    [imageUrl]="darazData.story.imageUrl"
    [buttonText]="darazData.story.buttonText"
    [viewMoreContent]="darazData.story.viewMoreContent"
    >
</app-story-section>

<app-plain-banner 
    [title]="darazData.plainBanner.title"
    [buttonText]="darazData.plainBanner.buttonText">
</app-plain-banner>

<app-results-section 
    [title]="darazData.results.title"
    [description]="darazData.results.description"
    [imageUrl]="darazData.results.imageUrl">
</app-results-section>

<app-form-section></app-form-section>


