<section class="career-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="circle-wrapper">
          <h1>
            Build your career with<br />
            Techware Lab
          </h1>
          <h2 class="cmn-sub-heading">
            A great workplace combines phenomenal people with challenging
            problems
          </h2>
          <!-- <a href="https://techwarelab.zohorecruit.in/jobs/Careers" target="_blank">View current opportunities</a> -->
          <button class="btn_opp" (click)="joinTeamQuote()">
            Join our team
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="our-mission-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-5" data-aos="fade-right">
        <img
          src="/assets/images/our-mission.png"
          class="img-fluid"
          alt="Specialized Image"
        />
      </div>
      <div class="col-md-7">
        <div class="content-box">
          <h1 class="cmn-heading">Our Mission</h1>
          <p>
            Our goal is to help create and deliver business and technology
            solutions that fit our clients’ needs and drive results. With a
            perfect blend of technology, innovation, and expertise, we deliver
            cutting-edge software solutions.
          </p>
          <div class="bottom-content">
            <h2>Help businesses harness the</h2>
            <h3>power of digital innovation</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="our-culture-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Our Culture</h1>
    <p>
      At Techware Lab, we’re passionate about driving growth with innovative
      technologies and an agile approach. We’re all about starting small,
      scaling fast, and achieving more. We are also proud of our community of
      passionate individuals who believe in the power of software and technology
      to achieve business excellence.
    </p>
    <div class="row mt-5">
      <div class="col-md-5" data-aos="fade-right">
        <img
          src="/assets/images/our-culture.png"
          class="img-fluid"
          alt="Specialized Image"
        />
      </div>
      <div class="col-md-7">
        <div class="content-box">
          <h2>
            Together we learn, work,<br />
            innovate and develop solution
          </h2>
          <div class="bottom-content">
            <h3>
              as the leading Boutique <br />
              Software Company
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="our-values-wrapper">
  <div class="container">
    <h1 class="cmn-heading d-md-none d-block">Our Values</h1>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <div class="value-box red">
              <h2>01</h2>
              <h3>Learning = Innovating</h3>
            </div>
          </div>
          <div class="col-md-6">
            <div class="value-box blue">
              <h2>02</h2>
              <h3>
                Passion for <br />
                innovation
              </h3>
            </div>
          </div>
          <div class="col-md-6">
            <div class="value-box yellow">
              <h2>03</h2>
              <h3>
                Quality and <br />
                execution
              </h3>
            </div>
          </div>
          <div class="col-md-12">
            <div class="value-box red">
              <h2>04</h2>
              <h3>Teamwork and collaboration</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-4">
            <div class="value-box red h-90">
              <h2>05</h2>
              <h3>
                Open <br />
                door <br />
                policy
              </h3>
            </div>
          </div>
          <div class="col-md-8">
            <h1 class="cmn-heading d-md-block d-none">Our Values</h1>
            <div class="value-box blue">
              <h2>06</h2>
              <h3>
                Empower <br />
                ourselves to fix <br />
                problems
              </h3>
            </div>
          </div>
          <div class="col-md-12">
            <div class="value-box yellow">
              <h2>07</h2>
              <h3>
                Our client&apos;s success <br />
                is our success
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="opportunities-main-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="head-set">
          <h1 class="cmn-heading circle">“Be Part of Something Great”</h1>
          <a
            href="https://techwarelab.zohorecruit.in/jobs/Careers"
            class="apply-btn"
            target="_blank"
            >Apply Now</a
          >
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12 mt-5">
        <h1 class="cmn-heading">Our Opportunities</h1>
        <p class="resp-paragraph">
          We&apos;re growing like crazy. So we always need more talented people to
          join our team.
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6 pr-3 pr-md-5">
        <img
          src="/assets/images/opportunities.png"
          class="img-fluid"
          alt="Image"
        />
      </div>
      <!-- test code -->
      <div class="col-md-6 mt-md-0 mt-5">
        <h4>Current Job Openings</h4>
        <mat-accordion>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <!-- IT Services -->
                Flutter Developer
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- <ul *ngFor="let career of careerList?.data"> -->
            <!-- <li *ngIf="career?.CategoryId === 1"> -->
            <ul>
              <li>
                <a>
                  <h3>Experience-3-6 years
                  </h3>
                  <!-- <h2>{{ career?.Vacancy?.vacancyTitle }}</h2> -->
                  <!-- <h3>Exp - {{ career?.experience }}</h3> -->
                  <!-- <h3>{{ career?.location }}</h3> -->
                </a>
              </li>
            </ul>
            <div>
              <span class="jobLocation">Infopark, Kochi</span>
              <p>Drop your resume here: <b><a style="
                color:black;
                text-decoration: none;
              " href=mailto:“id-careers@techwarelab.com”>id-careers&#64;techwarelab.com</a></b></p>
              
            </div>
          </mat-expansion-panel>
          
          <mat-expansion-panel aria-expanded="" >
            <mat-expansion-panel-header class="BDEcustomCss">
              <mat-panel-title>
                <!-- IT Services -->
                Angular Developer
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- <ul *ngFor="let career of careerList?.data"> -->
            <!-- <li *ngIf="career?.CategoryId === 1"> -->
            <ul>
              <li>
                <a>
                  <h3>Experience-3-6 years
                  </h3>
                  <!-- <h2>{{ career?.Vacancy?.vacancyTitle }}</h2> -->
                  <!-- <h3>Exp - {{ career?.experience }}</h3> -->
                  <!-- <h3>{{ career?.location }}</h3> -->
                </a>
              </li>
            </ul>
            <div>
              <span class="jobLocation">Infopark, Kochi</span>
              <p>Drop your resume here: <b><a style="
                color:black;
                text-decoration: none;
              " href=mailto:“id-careers@techwarelab.com”>id-careers&#64;techwarelab.com</a></b></p>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <!-- IT Services -->
                Dot-Net Developer
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- <ul *ngFor="let career of careerList?.data"> -->
            <!-- <li *ngIf="career?.CategoryId === 1"> -->
            <ul>
              <li>
                <a>
                  <h3>Experience-4-7 years

                  </h3>
                  <!-- <h2>{{ career?.Vacancy?.vacancyTitle }}</h2> -->
                  <!-- <h3>Exp - {{ career?.experience }}</h3> -->
                  <!-- <h3>{{ career?.location }}</h3> -->
                </a>
              </li>
            </ul>
            <div>
              <span class="jobLocation">Infopark, Kochi</span>
              <p>Drop your resume here: <b><a style="
                color:black;
                text-decoration: none;
              " href=mailto:“id-careers@techwarelab.com”>id-careers&#64;techwarelab.com</a></b></p>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <!-- IT Services -->
                UI UX Designer Lead 
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- <ul *ngFor="let career of careerList?.data"> -->
            <!-- <li *ngIf="career?.CategoryId === 1"> -->
            <ul>
              <li>
                <a>
                  <h3>
                    Experience-8 plus years

                  </h3>
                  <!-- <h2>{{ career?.Vacancy?.vacancyTitle }}</h2> -->
                  <!-- <h3>Exp - {{ career?.experience }}</h3> -->
                  <!-- <h3>{{ career?.location }}</h3> -->
                </a>
              </li>
            </ul>
            <div>
              <span class="jobLocation">Infopark, Kochi</span>
              <p>Drop your resume here: <b><a style="
                color:black;
                text-decoration: none;
              " href=mailto:“id-careers@techwarelab.com” >id-careers&#64;techwarelab.com</a></b></p>
            </div>
          </mat-expansion-panel>
          <!-- <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title> -->
                <!-- IT Services -->
                <!-- Flutter Developer   
              </mat-panel-title>
            </mat-expansion-panel-header> -->
            <!-- <ul *ngFor="let career of careerList?.data"> -->
            <!-- <li *ngIf="career?.CategoryId === 1"> -->
            <!-- <ul>
              <li>
                <a>
                  <h3>Exp-2-3 years

                  </h3> -->
                  <!-- <h2>{{ career?.Vacancy?.vacancyTitle }}</h2> -->
                  <!-- <h3>Exp - {{ career?.experience }}</h3> -->
                  <!-- <h3>{{ career?.location }}</h3> -->
                <!-- </a>
              </li>
            </ul>
            <div>
              <span class="jobLocation">Infopark, Kochi</span>
              <p>Drop your resume here: <b>careers@techwarelab.com</b></p>
            </div>
          </mat-expansion-panel> -->
          <!-- <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title> -->
                <!-- IT Services -->
                <!-- Junior IT Project Coordinator
              </mat-panel-title>
            </mat-expansion-panel-header> -->
            <!-- <ul *ngFor="let career of careerList?.data"> -->
            <!-- <li *ngIf="career?.CategoryId === 1"> -->
            <!-- <ul>
              <li>
                <a>
                  <h3>Exp - 1-3 years of relevant experience</h3> -->
                  <!-- <h2>{{ career?.Vacancy?.vacancyTitle }}</h2> -->
                  <!-- <h3>Exp - {{ career?.experience }}</h3> -->
                  <!-- <h3>{{ career?.location }}</h3> -->
                <!-- </a>
              </li>
            </ul>
            <div>
              <span class="jobLocation">Infopark, Kochi</span>
              <p>Drop your resume here: <b>careers@techwarelab.com</b></p>
            </div>
          </mat-expansion-panel> -->
          <!-- <mat-expansion-panel expanded>
            <mat-expansion-panel-header class="BDEcustomCss">
              <mat-panel-title>
                Business Development Executive - IT Sales & Marketing
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul *ngFor="let career of careerList?.data">
              <li *ngIf="career?.CategoryId === 2">
                <a> -->
                  <!-- <h2>{{ career?.Vacancy?.vacancyTitle }}</h2> -->
                  <!-- <h3>Exp - {{ career?.experience }}</h3> -->
                  <!-- <h3>{{ career?.location }}</h3> -->
                <!-- </a>
              </li>
            </ul>
            <div>
              <span class="jobLocation">Infopark, Kochi</span>
              <p>Drop your resume here: <b>careers@techwarelab.com</b></p>
            </div>
          </mat-expansion-panel> -->
          <!-- <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title> Software Tester </mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
              <li>
                <a>
                  <h3>Exp - 1.5 - 2 years of relevant experience</h3> -->
                  <!-- <h2>{{ career?.Vacancy?.vacancyTitle }}</h2> -->
                  <!-- <h3>Exp - {{ career?.experience }}</h3> -->
                  <!-- <h3>{{ career?.location }}</h3> -->
                <!-- </a>
              </li>
            </ul>
            <div>
              <span class="jobLocation">Infopark, Kochi</span>
              <p>Drop your resume here: <b>careers@techwarelab.com</b></p>
            </div>
          </mat-expansion-panel> -->
          <!-- Other panels go here -->
        </mat-accordion>
        <h6 style="
          color:red;
          padding-top: 15px;
          font-weight: 800;
        ">Prefer Immediate joiners</h6>
        <button class="btn_opp" (click)="joinTeamQuote()">Join our team</button>
      </div>
      <!-- test code -->
    </div>
  </div>
</section>

<section class="testimonial-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <h1 class="cmn-heading">The Voices Of Our People</h1>
      </div>
      <div class="col-md-4"></div>
    </div>
    <div id="demo" class="carousel slide mt-5" data-ride="carousel">
      <!-- The slideshow -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row">
            <div class="col-md-3">
              <div class="test-circle">
                <div class="test-profile">
                  <img src="/assets/images/adarsh.jpeg" alt="Profile" />
                </div>
                <h3>Adarsh</h3>
                <h4>Technical Lead</h4>
              </div>
            </div>
            <div class="col-md-9">
              <div class="test-content">
                <p>
                  “I’m very proud of being one of the oldest employees at
                  Techware Lab. It has provided me with a good platform for
                  career growth since hard work and talent are highly
                  appreciated. As a Technical Lead, I thoroughly enjoy working
                  with my team members. I look forward to being a part of this
                  company for a long time to come.”
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-md-3">
              <div class="test-circle">
                <div class="test-profile">
                  <img src="/assets/images/vaisakh.jpg" alt="Profile" />
                </div>
                <h3>Vaisak</h3>
                <h4>Full Stack Developer</h4>
              </div>
            </div>
            <div class="col-md-9">
              <div class="test-content">
                <p>
                  "It's a great place to work! What I enjoy most is the fact
                  that I'm constantly learning new skills, both technical and
                  personal. At Techware Lab, you will be valued and you will get
                  a chance to upskill and increase your knowledge. It will be up
                  to you to grab that chance and make the most of it.
                  Additionally, this is also the place where I can completely
                  maintain my work-life balance".
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-md-3">
              <div class="test-circle">
                <div class="test-profile">
                  <img src="/assets/images/zuha.jpeg" alt="Profile" />
                </div>
                <h3>Zuha</h3>
                <h4>.NET Developer</h4>
              </div>
            </div>
            <div class="col-md-9">
              <div class="test-content">
                <p>
                  "I am very grateful for the opportunity Techware Lab has given
                  me to discover my hidden skills and gain experience in the
                  industry. I am fortunate to have been surrounded by great
                  individuals who not only care about their jobs but are
                  generous in sharing knowledge for the purpose of making the
                  company better. Together we work as a team to make Techware
                  Lab a great company with strong core values and a strong focus
                  on client satisfaction."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right">
        <a class="carousel-control-prev" href="#demo" data-slide="prev"> </a>
        <a class="carousel-control-next" href="#demo" data-slide="next"> </a>
      </div>
    </div>
  </div>
</section>

<section class="footer-banner-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">
          Don’t find a suitable position that matches your skill set, but still
          interested? <br />
          Get in touch with us!
        </h1>

        <a
          href="https://techwarelab.zohorecruit.in/jobs/Careers"
          target="_blank"
          >Apply</a
        >
      </div>
    </div>
  </div>
</section>
