import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { Meta, Title } from "@angular/platform-browser";;
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-enterprise-development',
  templateUrl: './enterprise-development.component.html',
  styleUrls: ['./enterprise-development.component.scss']
})
export class EnterpriseDevelopmentComponent implements OnInit {

  customOptionsOne: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  

  constructor(private model: MatDialog, private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Enterprise Mobility Development Services | TechwareLab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "We provides enterprise mobility solutions to encourage business with scalability and security. We give efficient mobility solutions to keep driving your business to incredible heights.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "We provides enterprise mobility solutions to encourage business with scalability and security. We give efficient mobility solutions to keep driving your business to incredible heights.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/ser-4.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Enterprise Mobility Development Services | TechwareLab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "We provides enterprise mobility solutions to encourage business with scalability and security. We give efficient mobility solutions to keep driving your business to incredible heights.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/enterprise-development",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/ser-2.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Enterprise Mobility Development Services | TechwareLab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "We provides enterprise mobility solutions to encourage business with scalability and security. We give efficient mobility solutions to keep driving your business to incredible heights.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Enterprise Mobility Development Services | TechwareLab",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

}
