import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { Meta, Title } from "@angular/platform-browser";;
import { SeoService } from '../seo.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-ecommerce-web-solutions',
  templateUrl: './ecommerce-web-solutions.component.html',
  styleUrls: ['./ecommerce-web-solutions.component.scss']
})
export class EcommerceWebSolutionsComponent implements OnInit {

  constructor(private model: MatDialog, private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "#1 Top Ecommerce Web Development Services Company | TechwareLab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Create the best eCommerce web development experiences with Techwarelab eCommerce web solutions, we deliver an exceptional customer experience across any device.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Create the best eCommerce web development experiences with Techwarelab eCommerce web solutions, we deliver an exceptional customer experience across any device.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/ser-6.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "#1 Top Ecommerce Web Development Services Company | TechwareLab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Create the best eCommerce web development experiences with Techwarelab eCommerce web solutions, we deliver an exceptional customer experience across any device.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/ecommerce-web-solutions",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/ser-6.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "#1 Top Ecommerce Web Development Services Company | TechwareLab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Create the best eCommerce web development experiences with Techwarelab eCommerce web solutions, we deliver an exceptional customer experience across any device.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "#1 Top Ecommerce Web Development Services Company | TechwareLab",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

  customOptionsOne: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

}
