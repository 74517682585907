import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-why-business-needs-chatbot',
  templateUrl: './why-business-needs-chatbot.component.html',
  styleUrls: ['./why-business-needs-chatbot.component.scss']
})
export class WhyBusinessNeedsChatbotComponent implements OnInit {

  public fbLink:string=environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;
  
  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Top 6 Reasons Why your Business Needs a Chatbot - Techware Lab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Many businesses can easily add chatbots to various departments to improve the customer experience and increase their business. Read what you need to know about chatbots and why your business needs a chatbot.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Many businesses can easily add chatbots to various departments to improve the customer experience and increase their business. Read what you need to know about chatbots and why your business needs a chatbot.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-5.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Top 6 Reasons Why your Business Needs a Chatbot - Techware Lab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Many businesses can easily add chatbots to various departments to improve the customer experience and increase their business. Read what you need to know about chatbots and why your business needs a chatbot.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/why-business-needs-chatbot",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-5.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Top 6 Reasons Why your Business Needs a Chatbot - Techware Lab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Many businesses can easily add chatbots to various departments to improve the customer experience and increase their business. Read what you need to know about chatbots and why your business needs a chatbot.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Top 6 Reasons Why your Business Needs a Chatbot - Techware Lab",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `why-business-needs-chatbot`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u',`https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');
  
      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }

}
