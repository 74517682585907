<nav class="techware-navbar" *ngIf="shouldDisplayNavbar()">
  <div class="container">
    <div class="header-flex">
      <button routerLink="/" class="main-logo">
        <img
          src="/assets/images/logo.svg"
          alt="Mobile app development company India"
        />
      </button>
      <button
        *ngIf="router.url !== '/gitex-technology-week'"
        class="menu-btn d-md-none d-block"
        (click)="toggleClass()"
      >
        <img src="/assets/images/burger.svg " alt="Menu " />
      </button>
      <ul class="d-md-block d-none">
        <li *ngIf="router.url !== '/gitex-technology-week'" routerLink="/" class="navmenu">
          Home
        </li>
        <li *ngIf="router.url !== '/gitex-technology-week'" routerLink="/about" class="navmenu">
          About
        </li>
        <li
          class="open-services"
          *ngIf="router.url !== '/gitex-technology-week'"
          
        >
        <span routerLink="/services" class="navmenu">Services</span>
          
          <div class="megamenu-services-wrapper">
            <div class="container">
              <div class="megamenu-box">
                <div class="row">
                  <div class="col-md-4">
                    <ul>
                      <h4>Design</h4>
                      <li routerLink="/ui-ux-service" (click)="sNavHide()">
                        UI/UX Design & Development
                      </li>
                      <li routerLink="/branding-services" (click)="sNavHide()">
                        Branding and Graphic Design Services 
                      </li>
                    </ul>
                    
                    <ul>
                      <h4>Consulting</h4>
                      <li routerLink="/business-consulting" (click)="sNavHide()">
                        Business Consulting
                      </li>
                      <li routerLink="/devops-consulting" (click)="sNavHide()">
                        DevOps Consulting
                      </li>
                      <li routerLink="/it-staff-augmentation" (click)="sNavHide()">
                        IT Staff Augmentation
                      </li>
                      <li routerLink="/it-consulting" (click)="sNavHide()">
                        IT Consulting
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <h4 routerLink="/dedicated-development-team" (click)="sNavHide()">Dedicated Development Team</h4>
                      </li>
                    </ul>
                    
                  </div>
                  <div class="col-md-4">
                    <ul>
                      <h4>Development</h4>
                      <li routerLink="/custom-software-development-service" (click)="sNavHide()">
                        Custom Software Development
                      </li>
                      <li routerLink="/web-app-development" (click)="sNavHide()">  
                        Web App Development
                      </li>
                      <li routerLink="/ecommerce-web-solutions" (click)="sNavHide()">
                        Ecommerce Development
                      </li>
                      <li routerLink="/mobile-app-development" (click)="sNavHide()">
                        Mobile App Development
                      </li>
                      <li routerLink="/android-app-development" (click)="sNavHide()">
                        Android App Development
                      </li>
                      <li routerLink="/ios-development" (click)="sNavHide()">
                        IOS App Development
                      </li>
                      <li routerLink="/enterprise-development" (click)="sNavHide()">
                        Enterprise Development
                      </li>
                      <li routerLink="/testing-qa-service" (click)="sNavHide()">
                        Testing and QA Services
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4">
                    <ul>
                      <h4>Marketing</h4>
                      <li routerLink="/digital-marketing" (click)="sNavHide()">
                        Digital Marketing
                      </li>
                      <li routerLink="/search-engine-optimization" (click)="sNavHide()">
                        Search Engine Optimization
                      </li>
                      <li routerLink="/performance-marketing-service" (click)="sNavHide()">
                        Performance Marketing
                      </li>
                      <li routerLink="/social-media-optimization" (click)="sNavHide()">
                        Social Media Optimization
                      </li>
                      <li routerLink="/web-analytics" (click)="sNavHide()">
                        Web Analytics
                      </li>
                      <li routerLink="/marketing-automation" (click)="sNavHide()">
                        Marketing Automation
                      </li>
                    </ul>
                    
                  </div>
                  <!-- <div class="col-md-3">
                    <ul>
                      <h4>Consulting</h4>
                      <li routerLink="/business-consulting" (click)="sNavHide()">
                        Business Consulting
                      </li>
                      <li routerLink="/devops-consulting" (click)="sNavHide()">
                        DevOps Consulting
                      </li>
                      <li routerLink="/it-staff-augmentation" (click)="sNavHide()">
                        IT Staff Augmentation
                      </li>
                      <li routerLink="/it-consulting" (click)="sNavHide()">
                        IT Consulting
                      </li>
                    </ul>
                   
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </li>
        <li
          class="open-products"
          *ngIf="router.url !== '/gitex-technology-week'"
          
        >
          <span routerLink="/products" class="navmenu">Products</span> 
          <div class="megamenu-products-wrapper">
            <div class="container">
              <div class="megamenu-box">
                <div class="row">
                  <div class="col-md-4">
                    <ul>
                      <li routerLink="/taxi-dispatch-software" (click)="pNavHide()">
                        <img
                          src="/assets/images/mega-product-1.svg"
                          class="img-fluid"
                          alt="Product Icon"
                        />
                        <div class="details">
                          <h1>TTaxi</h1>
                          <h2>Expect a smooth ride with our feature-rich seamless taxi booking software</h2>
                        </div>
                      </li>
                      <li routerLink="/doctor-appointment-app" (click)="pNavHide()">
                        <img
                          src="/assets/images/mega-product-2.svg"
                          class="img-fluid"
                          alt="Product Icon"
                        />
                        <div class="details">
                          <h1>TDoc</h1>
                          <h2>Build strong physician-patient relationships with simplified app for doctors</h2>
                        </div>
                      </li>
                      <li routerLink="/legal-case-management-software" (click)="pNavHide()">
                        <img
                          src="/assets/images/mega-product-3.svg"
                          class="img-fluid"
                          alt="Product Icon"
                        />
                        <div class="details">
                          <h1>TCourt</h1>
                          <h2>Instant online appointment scheduling software for lawyers and law firms</h2>
                        </div>
                      </li>
                      <li routerLink='car-rental-app-development' (click)="pNavHide()">
                        <img
                          src="/assets/images/mega-product-4.svg"
                          class="img-fluid"
                          alt="Product Icon"
                        />
                        <div class="details">
                          <h1>TRide</h1>
                          <h2>Seamless app that streamlines all aspects of running a car rental business</h2>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4">
                    <ul>
                        <li routerLink="/grocery-app-development" (click)="pNavHide()">
                          <img
                            src="/assets/images/mega-product-5.svg"
                            class="img-fluid"
                            alt="Product Icon"
                          />
                          <div class="details">
                            <h1>TKart</h1>
                            <h2>Premier solution for modern online grocery shopping that’s fast and easy</h2>
                          </div>
                        </li>
                        <li routerLink="/online-food-ordering-system" (click)="pNavHide()">
                          <img
                            src="/assets/images/mega-product-6.svg"
                            class="img-fluid"
                            alt="Product Icon"
                          />
                          <div class="details">
                            <h1>TFood</h1>
                            <h2>Start serving delicious food with an online ordering system for your brand</h2>
                          </div>
                        </li>
                        <li routerLink='/taxi-management-system' (click)="pNavHide()">
                          <img
                            src="/assets/images/mega-product-7.svg"
                            class="img-fluid"
                            alt="Product Icon"
                          />
                          <div class="details">
                            <h1>TCab</h1>
                            <h2>Build your taxi business online with this advanced cab booking software</h2>
                          </div>
                        </li>
                        <li routerLink='/beauty-salon-software' (click)="pNavHide()">
                          <img
                            src="/assets/images/mega-product-8.svg"
                            class="img-fluid"
                            alt="Product Icon"
                          />
                          <div class="details">
                            <h1>TSalon</h1>
                            <h2>Take full control of the booking process and receive appointment updates </h2>
                          </div>
                        </li>
                      </ul>
                  </div>
                  <div class="col-md-4">
                    <ul>
                        <li routerLink="/movie-ticketing-software" (click)="pNavHide()">
                          <img
                            src="/assets/images/mega-product-9.svg"
                            class="img-fluid"
                            alt="Product Icon"
                          />
                          <div class="details">
                            <h1>TTicket</h1>
                            <h2>Take your theatre business to the next level with an online booking system</h2>
                          </div>
                        </li>
                        <li routerLink="/matrimony-app-development" (click)="pNavHide()">
                          <img
                            src="/assets/images/mega-product-10.svg"
                            class="img-fluid"
                            alt="Product Icon"
                          />
                          <div class="details">
                            <h1>TSoulmate</h1>
                            <h2>Dating app for your matrimony agency with powerful search functionality</h2>
                          </div>
                        </li>
                        <li routerLink='/automotive-appointment-software' (click)="pNavHide()">
                          <img
                            src="/assets/images/mega-product-11.svg"
                            class="img-fluid"
                            alt="Product Icon"
                          />
                          <div class="details">
                            <h1>TMechanic</h1>
                            <h2>Ideal online booking solution for auto mechanics looking to stay organized</h2>
                          </div>
                        </li>
                        <li routerLink='/payroll-management-system' (click)="pNavHide()">
                          <img
                            src="/assets/images/mega-product-12.svg"
                            class="img-fluid"
                            alt="Product Icon"
                          />
                          <div class="details">
                            <h1>TOffice</h1>
                            <h2>Transform the way you track the attendance and handle payroll processes</h2>
                          </div>
                        </li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li
          *ngIf="router.url !== '/gitex-technology-week'"
          routerLink="/portfolio" class="navmenu"
        >
          Portfolio
        </li>
        <li
        *ngIf="router.url !== '/gitex-technology-week'"
        routerLink="/become-our-partner" class="navmenu"
      >
         Partner
      </li>
        <!-- <li
          *ngIf="router.url !== '/gitex-technology-week'"
          routerLink="/careers" class="navmenu"
        >
        Careers
        </li> -->


        <li *ngIf="router.url !== '/gitex-technology-week'" routerLink="/blog" class="navmenu">
          Blog
        </li>
        <li
          *ngIf="router.url !== '/gitex-technology-week'"
          routerLink="/contact" class="navmenu"
        >
          Contact Us
        </li>
        <li class="request-quote" (click)="requestQuote()">Request a Quote</li>
      </ul>
    </div>
  </div>
</nav>

<div class="menu-side-wrapper" [class.expand-menu]="classApplied">
  <span class="close-btn" (click)="toggleClass()"
    ><img src="/assets/images/close.png " alt="Close "
  /></span>
  <div class="menu-floater">
    <h1>MENU</h1>
    <ul>
      <li
        *ngIf="router.url !== '/gitex-technology-week'"
        routerLink="/"
        (click)="toggleClass()"
      >
        <span>01</span> Home
      </li>
      <li
        *ngIf="router.url !== '/gitex-technology-week'"
        routerLink="/about"
        (click)="toggleClass()"
      >
        <span>02</span> About Us
      </li>
      <li
        *ngIf="router.url !== '/gitex-technology-week'"
        routerLink="/services"
        (click)="toggleClass()"
      >
        <span>03</span> Services
      </li>
      <li
        *ngIf="router.url !== '/gitex-technology-week'"
        routerLink="/products"
        (click)="toggleClass()"
      >
        <span>04</span> Products
      </li>
      <li
        *ngIf="router.url !== '/gitex-technology-week'"
        routerLink="/portfolio"
        (click)="toggleClass()"
      >
        <span>05</span> Portfolio
      </li>
      <li
      *ngIf="router.url !== '/gitex-technology-week'"
      routerLink="/become-our-partner"
      (click)="toggleClass()"
    >
      <span>06</span> Partner
    </li>
      <li
        *ngIf="router.url !== '/gitex-technology-week'"
        routerLink="/blog"
        (click)="toggleClass()"
      >
        <span>07</span> Blog
      </li>
      <li
        *ngIf="router.url !== '/gitex-technology-week'"
        routerLink="/contact"
        (click)="toggleClass()"
      >
        <span>08</span> Contact Us
      </li>
    </ul>
  </div>
</div>
