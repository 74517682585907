import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SeoService } from '../seo.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-movie-ticketing-software',
  templateUrl: './movie-ticketing-software.component.html',
  styleUrls: ['./movie-ticketing-software.component.scss']
})
export class MovieTicketingSoftwareComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  constructor(private model: MatDialog,private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Online Movie Ticket Booking | Cinema Ticketing Software Development"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Skip the movie queue with our cinema booking software in India, UAE and USA. Our online movie ticket booking software makes it easy for your customers to find the best movie tickets online.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Skip the movie queue with our cinema booking software in India, UAE and USA. Our online movie ticket booking software makes it easy for your customers to find the best movie tickets online.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Online Movie Ticket Booking | Cinema Ticketing Software Development",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Skip the movie queue with our cinema booking software in India, UAE and USA. Our online movie ticket booking software makes it easy for your customers to find the best movie tickets online.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/movie-ticketing-software",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Online Movie Ticket Booking | Cinema Ticketing Software Development",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Skip the movie queue with our cinema booking software in India, UAE and USA. Our online movie ticket booking software makes it easy for your customers to find the best movie tickets online.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Online Movie Ticket Booking | Cinema Ticketing Software Development",
    });
  }

  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

}
