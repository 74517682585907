import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../seo.service';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { MatDialog } from '@angular/material/dialog'

@Component({
    selector: 'app-staff-augmentation',
    templateUrl: 'staff-augmentation.component.html',
    styleUrls: ['./staff-augmentation.component.scss']

})

export class StaffAugmentationComponent implements OnInit {
    constructor(private model: MatDialog,private title: Title, private meta: Meta, private seoService: SeoService) { }

    ngOnInit() { 
        this.createLinkForCanonicalURL();
        this.title.setTitle(
            "Staff Augmentation Services Company | TechwareLab "
          );
          this.meta.updateTag({
            name: "description",
            content:
              "Techware Lab can help you take your business to the next level with the help of our expert staff augmentation team. Our extensive reach across various technologies gives us access to a massive talent pool of skilled IT professionals.",
          });
          this.meta.updateTag({
            property: "og:description",
            content:
              "Techware Lab can help you take your business to the next level with the help of our expert staff augmentation team. Our extensive reach across various technologies gives us access to a massive talent pool of skilled IT professionals.",
          });
          this.meta.updateTag({
            property: "og:image",
            content:
              "https://techwarelab.com/assets/images/og_logo.png",
          });
          this.meta.updateTag({
            property: "og:title",
            content:
              "Staff Augmentation Services Company | TechwareLab ",
          });
          this.meta.updateTag({
            property: "og:description",
            content:
              "Techware Lab can help you take your business to the next level with the help of our expert staff augmentation team. Our extensive reach across various technologies gives us access to a massive talent pool of skilled IT professionals.",
          });
          this.meta.updateTag({
            property: "og:url",
            content:
              "https://techwarelab.com/it-staff-augmentation",
          });
          this.meta.updateTag({
            name: "twitter:image",
            content:
              "https://techwarelab.com/assets/images/og_logo.png",
          });
          this.meta.updateTag({
            name: "twitter:title",
            content:
              "Staff Augmentation Services Company | TechwareLab ",
          });
          this.meta.updateTag({
            name: "twitter:description",
            content:
              "Techware Lab can help you take your business to the next level with the help of our expert staff augmentation team. Our extensive reach across various technologies gives us access to a massive talent pool of skilled IT professionals.",
          });
          this.meta.updateTag({
            name: "twitter:domain",
            content:
              "Staff Augmentation Services Company | TechwareLab ",
          });
    }

    createLinkForCanonicalURL() {
        this.seoService.createLinkForCanonicalURL();
      } 

    requestQuote() {
      this.model.open(RequestQuoteComponent);
    }
}