import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Meta, Title } from "@angular/platform-browser";;
import { SeoService } from '../seo.service';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-android-app-development',
  templateUrl: './android-app-development.component.html',
  styleUrls: ['./android-app-development.component.scss']
})
export class AndroidAppDevelopmentComponent implements OnInit {

  constructor(private model: MatDialog,private title: Title, private meta: Meta, private seoService: SeoService,) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Android Mobile App Development Company | TechwareLab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "TechwareLab offers custom android application development services and deliver Efficient, Fast, and Affordable Way to ManageYour Android App Development Needs.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "TechwareLab offers custom android application development services and deliver Efficient, Fast, and Affordable Way to ManageYour Android App Development Needs.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/ser-3.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Android Mobile App Development Company | TechwareLab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "TechwareLab offers custom android application development services and deliver Efficient, Fast, and Affordable Way to ManageYour Android App Development Needs.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/android-app-development",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/ser-3.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Android Mobile App Development Company | TechwareLab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "TechwareLab offers custom android application development services and deliver Efficient, Fast, and Affordable Way to ManageYour Android App Development Needs.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Android Mobile App Development Company | TechwareLab",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }
  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

  customOptionsOne: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

}
