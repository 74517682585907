<section class="page-not-found-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <img src="/assets/images/page-not-found.png" class="img-fluid" alt="Page Not Found">
                <h1>Oops! Page not found.</h1>
                <h2>The link might be corrupted.</h2>
                <p>Or the page may have been removed. </p>
                <button routerLink="/">GO BACK HOME</button>
            </div>
        </div>
    </div>
</section>