<section class="blog-details-wrapper" *ngIf="blog">
    <div class="container">
        <div class="row">
            <!-- test code -->
            <div class="col-md-12">
                <nav aria-label="Breadcrumb" class="breadcrumb-blog">
                    <ol>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <a routerLink="/blog">Blog</a>
                        </li>
                        <li>
                            <!-- changes -->
                            <a [routerLink]="'/'+blog?.data?.slug" aria-current="page">
                                {{blog?.data?.title}}
                            </a>
                        </li>
                    </ol>
                </nav>
            </div>
            <!-- test code -->
            <div class="col-md-12">
                <h1 [innerHTML]="blog.data.title"></h1>
                <!-- <h1>Ecommerce App Development Cost in 2022</h1> -->
                <h2 class="cmn-sub-heading">{{ blog?.data?.createdAt | date : "MMMM y" }}</h2>
                <div class="row">
                    <div class="social-share col-1 col-md-1">
                        <ul>
                            <li (click)="share('facebook')"><img src="/assets/images/facebook.png" alt="Facebook"></li>
                            <li (click)="share('linkedin')"><img src="/assets/images/linked-icon.png" alt="LinkedIn">
                            </li>
                            <li (click)="share('twitter')"><img src="/assets/images/twitter.png" alt="Twitter"></li>
                            <li (click)="share('whatsapp')"><img src="/assets/images/whatsapp-icon.png" alt="whatsapp">
                            </li>
                        </ul>
                    </div>
                    <div class="col-11 col-md-11">
                        <p [innerHTML]="blog.data.excerpt"></p>

                        <img [src]="blog.data.featuredImage" class="img-fluid" alt="Ecommerce app development">
                        <!-- <img src="/assets/images/blog-img-17.png" class="img-fluid" alt="Ecommerce app development"> -->
                    </div>
                </div>
                <div [innerHTML]="blog.data.description" class="description-style"></div>

                <div class="row">
                    <div id="disqus_thread "></div>
                    <script>
                        /**
                         *  RECOMMENDED CONFIGURATION VARIABLES: EDIT AND UNCOMMENT THE SECTION BELOW TO INSERT DYNAMIC VALUES FROM YOUR PLATFORM OR CMS.
                         *  LEARN WHY DEFINING THESE VARIABLES IS IMPORTANT: https://disqus.com/admin/universalcode/#configuration-variables    */
                        /*
                        var disqus_config = function () {
                        this.page.url = PAGE_URL;  // Replace PAGE_URL with your page's canonical URL variable
                        this.page.identifier = PAGE_IDENTIFIER; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
                        };
                        */
                        (function () { // DON'T EDIT BELOW THIS LINE
                            var d = document,
                                s = d.createElement('script');
                            s.src = 'https://techwarelab.disqus.com/embed.js';
                            s.setAttribute('data-timestamp', +new Date());
                            (d.head || d.body).appendChild(s);
                        })();
                    </script>
                    <noscript>Please enable JavaScript to view the <a href="https://disqus.com/?ref_noscript ">comments
                            powered by Disqus.</a></noscript>
                </div>
            </div>
            <!-- <div class="col-md-4 "></div> -->
        </div>
    </div>
</section>

<section class="related-blog">
    <div class="container">
        <h2>Related Blog</h2>
        <div class="row">
            <div class="col-md-3">
                <div class="related-box">
                    <img src="/assets/images/blog-img-5.png" class="img-fluid" alt="Chatbots for Business">
                    <h1 routerLink="/why-business-needs-chatbot">Top 6 reasons why your business needs a chatbot</h1>
                </div>
            </div>
            <div class="col-md-3">
                <div class="related-box">
                    <img src="/assets/images/blog-img-1.png" class="img-fluid"
                        alt="Why Techware software solutions  rebranded to Techware Lab">
                    <h1 routerLink="/we-have-rebranded">Techware Lab: Why (and how) we rebranded</h1>
                </div>
            </div>
            <div class="col-md-3">
                <div class="related-box">
                    <img src="/assets/images/blog-img-3.png" class="img-fluid" alt="Blog Image">
                    <h1 routerLink="/benefits-chatbot-for-business">Chatbots for Business: 4 Benefits You Need to Know
                    </h1>
                </div>
            </div>
            <div class="col-md-3">
                <div class="related-box">
                    <img src="/assets/images/blog-img-4.png" class="img-fluid" alt="Mobile App Ideas">
                    <h1 routerLink="/mobile-app-ideas">7 Best Mobile App Ideas to Consider in 2022</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="footer-banner-wrapper ">
    <div class="container ">
        <div class="row ">
            <div class="col-md-12 ">
                <h3 class="cmn-heading ">Ready to work with us?
                </h3>
                <h2 class="cmn-sub-heading ">We’re always looking for our next challenge. Bring your big idea to market
                    with our team of designers,<br> developers, and business strategists.</h2>
                <button routerLink="/contact">Let's get started</button>
            </div>
        </div>
    </div>
</section>