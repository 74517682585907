import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HelperService } from 'src/providers/helper.service';
import { WebService } from 'src/providers/web.service';
import { MessageSuccessComponent } from '../message-success/message-success.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  subscribeForm;
  subscribeFormSubmit:boolean;

  constructor( public router: Router, public service: WebService , public helper: HelperService, private model: MatDialog) { }

  ngOnInit(): void {

    this.subscribeForm = new FormGroup({
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
      ]),
    });
  }
  openDefaultEmailClient() {
    window.location.href = 'mailto:';
}

  shouldDisplayFooter(): any{

    const url = this.router.url;
    return (
      !url.includes('/gitex-technology-week') &&
      !url.includes('/software-development') &&
      !url.includes('/education-software-development') &&
      !url.includes('/education-software-development-one') &&
      !(url === '/custom-app-development' || url.includes('/custom-app-development?')))

  }


  subscribeBox(){
    this.subscribeFormSubmit = true;
    if (this.subscribeForm.valid) {
      const email = this.subscribeForm.value
      this.service.post_data("subscribeBoxMailIdTechlab", "", email).subscribe(
        (response) => {        
          if (response && response.status && response.status == 200) {
            this.model.open(MessageSuccessComponent);
          } else {
            this.helper.showAlert(4, "Already Subscribed");
          }
          this.subscribeForm.reset({
            email: "",
          });
          this.subscribeFormSubmit = false;
        },
        (error) => {
          this.helper.showAlert(4, "Something went wrong");
          this.subscribeFormSubmit = false;
        }
      )

    }
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
