<section class="career-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="circle-wrapper">
            <h1>
              TTicket: Take Your  <br/>
              Movie Theatre to the <br/>
              Next Level
            </h1>
            <h2 class="cmn-sub-heading">
              Never be late to a showing again. TTicket makes online movie ticket booking an easy and reliable process using an intuitive interface built for the modern user experience.
            </h2>
            <a (click)="requestQuote()" target="_blank">Request a demo</a>
          </div>
        </div>
        <div class="col-md-5">
          <img
            src="/assets/images/tticket-img-1.png"
            class="img-fluid"
            alt="tticket"
          />
        </div>
      </div>
    </div>
</section>

<section class="whatistaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-5" data-aos="fade-right">
        <img
          src="/assets/images/tticket-img-2.png"
          class="img-fluid"
          alt="tticket"
        />
      </div>
      <div class="col-md-7">
        <div class="content">
          <h1 class="cmn-heading">What Is TTicket?</h1>
          <p>More moviegoers are saying no to long queues at the theater by booking tickets online in advance. For theater businesses big and small, having an online ticketing app means staying ahead of the game.</p>
          <p>TTicket allows theater businesses value-added ticket booking capabilities and management, seat organization, and new audience outreach. This all-in-one movie ticketing software solution is the secret weapon that elevates theaters to center stage and business growth in the modern world.
          </p>
          <p>Empower your users with a solution built for self-discovery. Users can easily search available shows by venue, city, or event, select from a single screen theater or multiplex, and browse the latest reviews, trailers, and other relevant content—all from one location.
          </p>
          
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-3" data-aos="fade-up-right" data-aos-delay="50">
        <div class="taxi-box">
          <img
            src="/assets/images/tticket-icon-1.png"
            class="img-fluid"
            alt="tticket"
          />
          <h2>
            Find the Perfect Flick For the Night
          </h2>
          <p>
            TTicket is the perfect place to find your next favorite flick. Access a database of upcoming films, new showtimes, and trending movies and choose the right one based on reviews and ratings or proximity to your location.
          </p>
        </div>
      </div>
      <div class="col-md-3" data-aos="fade-up-right" data-aos-delay="150">
        <div class="taxi-box">
          <img
            src="/assets/images/tticket-icon-2.png"
            class="img-fluid"
            alt="tticket"
          />
          <h2>
            Increased Audience Engagement and Participation
          </h2>
          <p>
            The feedback system allows moviegoers to vote on their favorite movies and organize them by genre, format, and language. This interactive feature makes watching a movie a more personalized experience that gets customers coming back. 
          </p>
        </div>
      </div>
      <div class="col-md-3" data-aos="fade-up-right" data-aos-delay="250">
        <div class="taxi-box">
          <img
            src="/assets/images/tticket-icon-3.png"
            class="img-fluid"
            alt="tticket"
          />
          <h2>
            No More Waiting In Line
          </h2>
          <p>
            Book your favorite showing through the TTicket to secure your spot effortlessly. Choose a seat from the availability chart and complete the payment through credit card or PayPal right from the app. 
          </p>
        </div>
      </div>
      <div class="col-md-3" data-aos="fade-up-right" data-aos-delay="250">
        <div class="taxi-box">
          <img
            src="/assets/images/tticket-icon-4.png"
            class="img-fluid"
            alt="tticket"
          />
          <h2>
            The Best Way To Book Tickets
          </h2>
          <p>
            Our cinema ticket booking software solution simplifies theatre management. The app’s administrator dashboard handles the entire site, the seat layouts, and the movies currently showing so moviegoers never miss the next best flick.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="ttaxi-features-wrapper">
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-5">
        <h1 class="cmn-heading">TTicket Features</h1>
        <img
          src="/assets/images/tticket-img-3.png"
          class="img-fluid"
          alt="tticket"
        />
      </div>
      <div class="col-md-6">
        <ul class="list-1">
          <li>
            <span>1</span>
            <h6>User-friendly Design</h6>
          </li>
          <li>
            <span>2</span>
            <h6>Location Based Movie Search</h6>
          </li>
          <li>
            <span>3</span>
            <h6>Top Trending Movies List</h6>
          </li>
          <li>
            <span>4</span>
            <h6>Booking History Details</h6>
          </li>
          <li>
            <span>5</span>
            <h6>Now showing and upcoming movies list</h6>
          </li>
          <li>
            <span>6</span>
            <h6>Users can view the trailers of the currently running movies</h6>
          </li>
          <li>
            <span>7</span>
            <h6>Filter for Advanced Movie Search</h6>
          </li>
          <li>
            <span>8</span>
            <h6>Secured and Integrated Payment Gateway</h6>
          </li>
          <li>
            <span>9</span>
            <h6>Customizable Full Source Code</h6>
          </li>
          <li>
            <span>10</span>
            <h6>Provision for selecting your favorite seat</h6>
          </li>
        </ul>
        <!-- <button routerLink="/contact">Let’s get started</button> -->
      </div>
    </div>
  </div>
</section>

<section class="abouttaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-7 order-md-0 order-1">
        <div class="content">
          <h1 class="cmn-heading">About Techware Lab</h1>
          <p>
            Techware Lab provides best-in-class design capabilities and software solutions that help elevate your business through industry-specific apps, in both iOS and Android. With unparalleled expertise in website design, our dedicated team crafts advanced custom mobile app solutions that will empower your customers through convenient movie booking and value-added theater features for an epic movie-going experience.
          </p>
        </div>
      </div>
      <div class="col-md-5 order-md-1 order-0" data-aos="fade-left">
        <img
          src="/assets/images/tticket-img-4.png"
          class="img-fluid"
          alt="tticket"
        />
      </div>
    </div>
  </div>
</section>

<section class="footer-banner-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">
          Upgrade the Movie-Going Experience With TTicket
        </h1>
        <h2 class="cmn-sub-heading">
          TTicket is a complete entertainment treat for those moviegoers who hate long queues. Let Techware Lab take your theatre business to the next level with brilliant technological solutions and custom web designs to fit your brand.
        </h2>
        <button routerLink="/contact">Leran more today </button>
      </div>
    </div>
  </div>
</section>


<section class="faq-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Frequently Asked Questions</h1>
    <mat-accordion>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            What is an online movie ticket booking system?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          TTicket online movie ticket booking system that allows users to book movie tickets online, while allowing them to search for key details about the movies and theatres. Whether you're looking for movie ticket software for a small independent theatre, a chain, or multiple operators, you can use TTicket. Request your free demo today.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How will TTicket help grow my theatre business?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Today’s users prefer using online self-service booking platforms to save time and ensure the show they want to attend has tickets available. A platform like TTicket gives your users full control over the booking process, helping you fill more seats and grow your business.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How does cinema ticket booking work?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          TTicket offers a single platform for all your movie ticketing requirements. You can easily book movie tickets and search by venue, city, or event. Users can even choose from single screen theaters, multiplexes, and search for the latest movie reviews, trailers, and relevant theaters.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How much does it cost to develop a movie ticketing software like BookMyShow?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Pricing for any web application depends on the features and functionalities you require. Online movie ticketing software typically includes a variety of advanced features and integrations. The cost of a basic booking app will start at $5,000 and increase based on what features are needed, turnaround time, and more.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>

<section class="relates-solutions-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Related Solutions</h1>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <div class="related-box" routerLink="/legal-case-management-software">
            <img src="/assets/images/new-related-img1.png" class="img-fluid" alt="Images">
        </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/doctor-appointment-app">
              <img src="/assets/images/new-related-img2.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-management-system">
              <img src="/assets/images/new-related-img3.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-dispatch-software">
              <img src="/assets/images/new-related-img4.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/grocery-app-development">
              <img src="/assets/images/new-related-img5.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/online-food-ordering-system">
              <img src="/assets/images/new-related-img6.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/car-rental-app-development">
              <img src="/assets/images/new-related-img7.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/beauty-salon-software">
              <img src="/assets/images/new-related-img9.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/automotive-appointment-software">
              <img src="/assets/images/new-related-img10.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/payroll-management-system">
              <img src="/assets/images/new-related-img12.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>
