<section class="career-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="circle-wrapper">
          <h1>
            TKart: Online Grocery <br />
            Delivery Solution <br />
            That’s Fast and Easy
          </h1>
          <h2 class="cmn-sub-heading">
            Add efficiency and convenience to your grocery cart with TKart, the
            premier solution for modern online grocery shopping.
          </h2>
          <a (click)="requestQuote()" target="_blank">Request a demo</a>
        </div>
      </div>
      <div class="col-md-5">
        <img
          src="/assets/images/tkart-img-1.png"
          class="img-fluid"
          alt="ttaxi"
        />
      </div>
    </div>
  </div>
</section>

<section class="whatistaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-5" data-aos="fade-right">
        <img
          src="/assets/images/tkart-img-2.png"
          class="img-fluid"
          alt="ttaxi"
        />
      </div>
      <div class="col-md-7">
        <div class="content">
          <h1 class="cmn-heading">What Is TKart?</h1>
          <p>
            Techware Lab presents TKart, a practical online storefront directed
            towards the grocery shopping market that enables administrators to
            manage their businesses effortlessly. You won’t need any other
            solution with our on-demand grocery delivery app.
          </p>
          <p>
            TKart is an easy to use and intuitive grocery app development
            solution. Users can quickly discover supermarkets around their
            location, browse available products, add products to their cart, and
            checkout and pay. From there, delivery agents will pick up the
            products from the store and deliver them to your doorstep.
          </p>
          <p>
            Our expertly engineered online white label grocery delivery app
            allows customers to drag-and-drop desired products from thousands of
            fresh items into their online cart and checkout through PayPal or
            credit card seamlessly
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
        <div class="taxi-box">
          <img
            src="/assets/images/tkart-icon-1.png"
            class="img-fluid"
            alt="TKart"
          />
          <h2>
            Offer Convenient Doorstep <br />
            Delivery
          </h2>
          <p>
            TKart’s cart function handles checkout and your desired delivery
            location. Customers will love using TKart thanks to its convenient
            features and technologically advanced interface
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
        <div class="taxi-box">
          <img
            src="/assets/images/tkart-icon-2.png"
            class="img-fluid"
            alt="TKart"
          />
          <h2>
            Accept a Wide Variety of<br />
            Payment Options
          </h2>
          <p>
            TKart supports multiple payment options, including PayPal
            integration. Online grocery shopping has never been so easy.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
        <div class="taxi-box">
          <img
            src="/assets/images/tkart-icon-3.png"
            class="img-fluid"
            alt="TKart"
          />
          <h2>
            A Custom, Easy-to-Use App<br />
            Experience
          </h2>
          <p>
            The user-friendly features offer easy navigation that eliminates the
            frustration of navigating real grocery aisles. TKart’s custom
            branding capabilities also allow vendors to manage all parts of
            their business through the platform, from the customers to the
            products and discounts.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="ttaxi-features-wrapper">
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-4">
        <h1 class="cmn-heading">TKart Features</h1>
        <img
          src="/assets/images/tkart-img-3.png"
          class="img-fluid"
          alt="TTaxi"
        />
      </div>
      <div class="col-md-8">
        <ul class="list-1">
          <li>
            <span>1</span>
            <h6>Easy Registration</h6>
          </li>
          <li>
            <span>2</span>
            <h6>User Profile</h6>
          </li>
          <li>
            <span>3</span>
            <h6>Shop By Store, Category, Product Listing, and Search</h6>
          </li>
          <li>
            <span>4</span>
            <h6>Quick Order</h6>
          </li>
          <li>
            <span>5</span>
            <h6>User-Friendly Cart</h6>
          </li>
          <li>
            <span>6</span>
            <h6>Users Can Schedule Delivery Date and Time</h6>
          </li>
          <li>
            <span>7</span>
            <h6>Cash on Delivery</h6>
          </li>
        </ul>
        <button routerLink="/contact">Let’s get started</button>
      </div>
    </div>
  </div>
</section>

<section class="abouttaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-7 order-md-0 order-1">
        <div class="content">
          <h1 class="cmn-heading">About Techware Lab</h1>
          <p>
            Techware Lab provides top web design capabilities and mobile app
            development that upgrade your business through industry-specific
            apps. Its software design team delivers unique technological
            solutions that make online grocery delivery services a breeze for
            customers and vendors. TKart’s one-stop-shop and delivery process
            means easy shopping and even easier navigation across an integrated
            grocery shopping platform.
          </p>
        </div>
      </div>
      <div class="col-md-5 order-md-1 order-0" data-aos="fade-left">
        <img
          src="/assets/images/tkart-img-4.png"
          class="img-fluid"
          alt="ttaxi"
        />
      </div>
    </div>
  </div>
</section>

<section class="footer-banner-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">
          TKart: The Key to Profitable Grocery App Development
        </h1>
        <h2 class="cmn-sub-heading">
          Try TKart today and take advantage of the growing popularization of
          online grocery delivery. There’s no better time to select an Ecommerce
          grocery software for your supermarket or grocery store when you
          partner with Techware Lab. Looking for iOS app development and Android
          app development? TKart is available for both iOS and Android.
        </h2>
        <button routerLink="/contact">Let’s get started</button>
      </div>
    </div>
  </div>
</section>

<section class="faq-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Frequently Asked Questions</h1>
    <mat-accordion>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How can I build a grocery delivery app?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          As a leading grocery delivery app development company, we provide the
          best custom and white label grocery solutions for grocery delivery
          startups, grocery chains, grocery aggregators. Our ready-made
          solutions can also be customized to fit your business model.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How much does it cost to develop an online grocery delivery app?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          The cost of your grocery delivery app will depend on your
          requirements, what features you need, and turnaround time. Grocery app
          development costs typically start at $5,000. Please get in touch with
          our team if you’re interested in learning more.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Will developing a grocery delivery app benefit my grocery business?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Yes. A user-friendly and intuitive grocery app that offers the best
          features is one of the easiest ways to grow and scale your business.
          Online grocery shopping is growing in popularity as consumers look for
          innovative waves to save time. A grocery delivery app ensures your
          customers can quickly access essential products they need - with the
          click of a button.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How long will it take to deliver my grocery delivery app?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          The development time for a grocery delivery solution depends on the
          complexity of the app. If you’re looking for custom and unique
          features, development time will increase. On average, a standard
          grocery delivery solution requires around 1-2 months of development
          time.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>

<section class="relates-solutions-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Related Solutions</h1>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <div class="related-box" routerLink="/legal-case-management-software">
            <img src="/assets/images/new-related-img1.png" class="img-fluid" alt="Images">
        </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/doctor-appointment-app">
              <img src="/assets/images/new-related-img2.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-management-system">
              <img src="/assets/images/new-related-img3.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-dispatch-software">
              <img src="/assets/images/new-related-img4.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/online-food-ordering-system">
              <img src="/assets/images/new-related-img6.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/car-rental-app-development">
              <img src="/assets/images/new-related-img7.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/movie-ticketing-software">
              <img src="/assets/images/new-related-img8.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/beauty-salon-software">
              <img src="/assets/images/new-related-img9.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/automotive-appointment-software">
              <img src="/assets/images/new-related-img10.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/matrimony-app-development">
              <img src="/assets/images/new-related-img11.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/payroll-management-system">
              <img src="/assets/images/new-related-img12.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>
