import { Component, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { WebService } from "../../providers/web.service";
import { HelperService } from "../../providers/helper.service";
import { MessageSuccessComponent } from "../message-success/message-success.component";
import * as countries from "./../contact/countries.json";
import { MatDialog } from '@angular/material/dialog';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';


@Component({
  selector: 'app-gitex-landing-page',
  templateUrl: 'gitex-landing-page.component.html',
  styleUrls: ['./gitex-landing-page.component.scss'],
})
export class GitexLandingPageComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }

  customOptionsTwo: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      740: {
        items: 4
      },
      940: {
        items: 6
      }
    },
    nav: true
  }


  customOptionsOne: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  contactForm;
  countryList: any = countries
  contactSubmit: boolean;
  loader: boolean;

  constructor(private title: Title, private meta: Meta, private seoService: SeoService ,private model: MatDialog,public service: WebService, public helper: HelperService) {}

  ngOnInit(): void {


    this.contactForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
      ]),
      country: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
      reason: new FormControl("",[Validators.required]),
      requirement: new FormControl("", [Validators.required])
    });


    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "GITEX Technology Week 2021 Dubai, UAE | Techware Lab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Gitex Technology Week will take place in Dubai between October 17-21, 2021 and we are attending this mega event as a boutique software development company. Visit our booth for more details.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Gitex Technology Week will take place in Dubai between October 17-21, 2021 and we are attending this mega event as a boutique software development company. Visit our booth for more details.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        " https://www.techwarelab.com/gitex-landing-page",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "GITEX Technology Week 2021 Dubai, UAE | Techware Lab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Gitex Technology Week will take place in Dubai between October 17-21, 2021 and we are attending this mega event as a boutique software development company. Visit our booth for more details.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "  https://www.techwarelab.com/gitex-landing-page"
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "GITEX Technology Week 2021 Dubai, UAE | Techware Lab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Gitex Technology Week will take place in Dubai between October 17-21, 2021 and we are attending this mega event as a boutique software development company. Visit our booth for more details.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "GITEX Technology Week 2021 Dubai, UAE | Techware Lab",
    });

    
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "start",});

  }


  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }

  contactFormSubmit() {
    this.contactSubmit = true;
    if (this.contactForm.valid) {
      this.loader = true;
      let userObj = this.contactForm.value;
      this.service.post_data("requestQuoteContactForm", "", userObj).subscribe(
        (response) => {
          if (response && response.status && response.status == 200) {
            this.model.open(MessageSuccessComponent);
          } else {
            this.helper.showAlert(4, "Something went wrong");
          }
          this.contactForm.reset({
            name: "",
            email: "",
            country: "0",
            phone: "",
            reason: "0",
            requirement: ""
          });
          this.contactSubmit = false;
          this.loader = false;
        },
        (error) => {
          this.helper.showAlert(4, "Something went wrong");
          this.contactSubmit = false;
          this.loader = false;
        }
      );
    }
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 

  moveToTop(){
    window.scroll({  top: 0,  left: 0,   behavior: 'smooth'   });
  }
}


