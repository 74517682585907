import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-mobile-app-ideas',
  templateUrl: './mobile-app-ideas.component.html',
  styleUrls: ['./mobile-app-ideas.component.scss']
})
export class MobileAppIdeasComponent implements OnInit {

  public fbLink:string=environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;

  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "10 Best Mobile App Ideas to Consider in 2022 [Trending App Ideas]"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "What app should you build for your next business? Read on to learn 7 best mobile app ideas you should consider.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "What app should you build for your next business? Read on to learn 7 best mobile app ideas you should consider.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-4.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "10 Best Mobile App Ideas to Consider in 2022 [Trending App Ideas]",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "What app should you build for your next business? Read on to learn 7 best mobile app ideas you should consider.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/mobile-app-ideas",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-4.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "10 Best Mobile App Ideas to Consider in 2022 [Trending App Ideas]",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "What app should you build for your next business? Read on to learn 7 best mobile app ideas you should consider.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "10 Best Mobile App Ideas to Consider in 2022 [Trending App Ideas]",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `mobile-app-ideas`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u',`https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');
  
      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');
      
      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }

}
