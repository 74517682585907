import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-hiring-remote-developers-from-india',
  templateUrl: './hiring-remote-developers-from-india.component.html',
  styleUrls: ['./hiring-remote-developers-from-india.component.scss']
})
export class HiringRemoteDevelopersFromIndiaComponent implements OnInit {

  public fbLink:string=environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;

  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.title.setTitle(
      "Why Hire Remote Software Developers From India?"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "India continues to be a popular location for IT outsourcing. Read on to learn why your company should hire dedicated remote developers from India for the next development project. ",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "India continues to be a popular location for IT outsourcing. Read on to learn why your company should hire dedicated remote developers from India for the next development project. ",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-9.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Why Hire Remote Software Developers From India?",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "India continues to be a popular location for IT outsourcing. Read on to learn why your company should hire dedicated remote developers from India for the next development project.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/hiring-remote-developers-from-india",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-9.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Why Hire Remote Software Developers From India?",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "India continues to be a popular location for IT outsourcing. Read on to learn why your company should hire dedicated remote developers from India for the next development project. ",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Why Hire Remote Software Developers From India?",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `hiring-remote-developers-from-india`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u',`https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');
  
      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');

    }
  }

}
