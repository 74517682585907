<section class="message-success-wrapper">

    <span class="close_btn" (click)="closeChatDialog()"><img src="/assets/images/close-2.png" alt="Close"></span>

    <img src="/assets/images/success-icon.png" class="img-fluid" alt="Check">

    <h1>Thank You</h1>

    <h2>We will contact you soon</h2>

</section>