<section class="career-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="circle-wrapper">
                    <h1>TCab: Drive Your Taxi <br>
                    Firm with TCab
                    </h1>
                    <h2 class="cmn-sub-heading">Drive your taxi business to success with TCab taxi management software, the hub for all your taxicab booking needs. Give your clients a centralized experience that makes it easy for them to view service availability, book taxis, and more.
                    </h2>
                    <a (click)="requestQuote()" target="_blank">Request a demo</a>
                </div>
            </div>
            <div class="col-md-5">
                <img src="/assets/images/tcab-img-1.png" class="img-fluid" alt="ttaxi">
            </div>
        </div>
    </div>
</section>

<section class="whatistaxi-wrapper">
    <div class="container">
        <div class="row pb-4">
            <div class="col-md-5" data-aos="fade-right">
                <img src="/assets/images/tcab-img-2.png" class="img-fluid" alt="ttaxi">
            </div>
            <div class="col-md-7">
                <div class="content">
                    <h1 class="cmn-heading">What Is TCab?</h1>
                    <p>Are you looking for a solution to drive your taxi firm? Techware Lab’s online taxi management application, TCab, delivers an efficient and scalable solution for private taxi hiring companies, allowing you to remotely access and take control of your taxi’s with a single app.</p>
                    <p>
                        TCab is an online taxi cab management system that simplifies the way taxi service providers connect with their clients. Our platform is built on PHP and uses the Codeigniter Framework, meaning it’s capable of keeping up with increasing demand.
</p>
                    <p>Stay in control from start to finish with a powerful admin panel that gives you full visibility into the entire process.</p>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="taxi-box">
                    <img src="/assets/images/tcab-icon-1.png" class="img-fluid" alt="TTaxi">
                    <h2>Gain Full Insight With<br> 
                        the Admin Panel
                        </h2>
                    <p>Administrators can take control in multiple ways with TCab. The app lets businesses manage users and drivers individually, view and filter ride history, organize based on car type, handle promo codes, and even operate in a variety of regions.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="taxi-box">
                    <img src="/assets/images/tcab-icon-2.png" class="img-fluid" alt="TTaxi">
                    <h2>Empower Your Customers <br>
                        When They Book Taxis</h2>
                    <p>Ever need a ride? TCab uses GPS to pick the nearest cabs available and estimate the fare for you. Check the feedback of any drivers in your area to find the perfect ride and even offer your own reviews.
                    </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="taxi-box">
                    <img src="/assets/images/tcab-icon-3.png" class="img-fluid" alt="TTaxi">
                    <h2>Upgrade the Modern 
                        Driver Experience</h2>
                    <p>The dedicated driver app lets clients register and log in to the service. The taxi management system for taxi business lets cabbies manage their own profiles, handle legal documentation, view payment histories, look at the ratings of riders, and check on weekly earnings all in one place.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ttaxi-features-wrapper">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-4">
                <h1 class="cmn-heading">TCab Features</h1>
                <img src="/assets/images/tcab-img-3.png" class="img-fluid" alt="TTaxi">
            </div>
            <div class="col-md-8">
                <ul class="list-1">
                    <li class="only-margin"><span>1</span> <h6>User & Admin Accounts</h6></li>
                    <li><h5>Separate apps for users and drivers, as well as an administrator panel.</h5></li>
                    <li class="only-margin"><span>2</span> <h6>Customized Design</h6></li>
                    <li><h5>Fully customizable app design or new development queries available on request.</h5></li>
                    <li class="only-margin"><span>3</span> <h6>Promotional Codes</h6></li>
                    <li><h5>Support for promotional codes.</h5></li>
                    <li class="only-margin"><span>4</span> <h6>Rapid Support</h6></li>
                    <li><h5>Customer support for both admins and clients with quick ticket reply</h5></li>
                    <li class="only-margin"><span>5</span> <h6>Integration-Friendly</h6></li>
                    <li><h5>On-demand apps for other services like food delivery, tow trucks, beauticians, laundry, massage parlors, cleaning services, grocery stores, auto repair, and maids.</h5></li>
                </ul>
            </div>
        </div>
        <!-- <div class="action-video-wrapper">
            <h1 class="cmn-heading">TCab Features</h1>
            <div class="video-box">
                <img src="/assets/images/video.png" class="img-fluid" alt="Video">
            </div>
        </div> -->
        <div class="row mt-5">
            <div class="col-md-6 order-md-0 order-1">
                <div class="content">
                    <h2 class="cmn-heading">About Techware Lab</h2>
                    <p>Techware Lab provides expert design capabilities, mobile app development and software solutions that upgrade your business through industry-specific apps. The company’s technology-driven approach gives cab drivers and ride-seekers the tools to get where they need to go. Combining an unparalleled dedication to client satisfaction and flexibility, TCab makes accessible and scalable cab ride solutions a reality. You won’t need your old-school cab management software with our uber like taxi solution!</p>
                </div>
            </div>
            <div class="col-md-6 order-md-1 order-0">
                <img src="/assets/images/tcab-img-4.png" class="img-fluid" alt="TTaxi">
            </div>
        </div>
    </div>
</section>

<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Start Offering Hassle-Free Taxi 
                    Booking With TCab
                    </h1>
                <h2 class="cmn-sub-heading">Make comfortable, uninterrupted travel a reality with TCab. Fleet management through this online vehicle management system helps you retain more users and generate greater profits. </h2>
                <button routerLink="/contact">Learn more today</button>
            </div>
        </div>
    </div>
</section>

<section class="faq-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Frequently Asked Questions</h1>
        <mat-accordion>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                    I own a taxi firm. How can I improve my taxicab management business?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>You need the best taxi management system if you want to keep your business organized. This type of software helps you better track and manage everything related to your business, its customers, and more.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    What is taxi management software for taxi business?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>TCab is a taxi management app similar to Uber. It is the best solution for private taxi hiring companies, allowing you to remotely access and take control of your taxi’s with a single app. We design and develop cab management software, including separate apps for users and drivers, as well as an administrator panel that automates your taxi business with less human intervention.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Will the cab management system be beneficial for taxi business owners?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Yes. A well-designed taxi management solution makes it simpler for drivers to know where passengers are located, boosting driver income through a streamlined booking process. TCab allows you to increase the number of cap bookings your business receives.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How much does it cost to develop an online taxi software for taxi firms?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>It’s impossible to provide a direct quote without knowing the specifics of what your taxi booking app needs. Cost is typically determined by what features are required, what functionality the platform needs, what devices it needs to support, and turnaround time. A simple taxi booking app will start around $5,000.</p>
              </mat-expansion-panel>
        </mat-accordion>
    </div>
</section>

<section class="relates-solutions-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Related Solutions</h1>
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/legal-case-management-software">
                    <img src="/assets/images/new-related-img1.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/doctor-appointment-app">
                    <img src="/assets/images/new-related-img2.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/taxi-dispatch-software">
                    <img src="/assets/images/new-related-img4.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/grocery-app-development">
                    <img src="/assets/images/new-related-img5.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/online-food-ordering-system">
                    <img src="/assets/images/new-related-img6.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/car-rental-app-development">
                    <img src="/assets/images/new-related-img7.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/movie-ticketing-software">
                    <img src="/assets/images/new-related-img8.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/beauty-salon-software">
                    <img src="/assets/images/new-related-img9.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/automotive-appointment-software">
                    <img src="/assets/images/new-related-img10.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/matrimony-app-development">
                    <img src="/assets/images/new-related-img11.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/payroll-management-system">
                    <img src="/assets/images/new-related-img12.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>