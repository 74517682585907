import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-taxi-management-system',
  templateUrl: './taxi-management-system.component.html',
  styleUrls: ['./taxi-management-system.component.scss']
})
export class TaxiManagementSystemComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  constructor(private title: Title, private meta: Meta, private seoService: SeoService,private model: MatDialog,  private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) { }
  

  ngOnInit(): void {

    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "I own a taxi firm. How can I improve my taxicab management business?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You need the best taxi management system if you want to keep your business organized. This type of software helps you better track and manage everything related to your business, its customers, and more."
        }
      },{
        "@type": "Question",
        "name": "What is taxi management software for taxi business?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "TCab is a taxi management app similar to Uber. It is the best solution for private taxi hiring companies, allowing you to remotely access and take control of your taxi's with a single app. We design and develop cab management software, including separate apps for users and drivers, as well as an administrator panel that automates your taxi business with less human intervention."
        }
      },{
        "@type": "Question",
        "name": "Will the cab management system be beneficial for taxi business owners?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes. A well-designed taxi management solution makes it simpler for drivers to know where passengers are located, boosting driver income through a streamlined booking process. TCab allows you to increase the number of cap bookings your business receives."
        }
      },{
        "@type": "Question",
        "name": "How much does it cost to develop an online taxi software for taxi firms?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "It's impossible to provide a direct quote without knowing the specifics of what your taxi booking app needs. Cost is typically determined by what features are required, what functionality the platform needs, what devices it needs to support, and turnaround time. A simple taxi booking app will start around $5,000."
        }
      }]
    }
  `;
   
    this._renderer2.appendChild(this._document.body, script);

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Cab Management Software | Taxi Management App for Taxi Business"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Drive your taxi firm to success with the best taxi management software or cab management system, the hub for all your taxicab management needs. Give your clients a centralized app experience for their vehicle booking.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Drive your taxi firm to success with the best taxi management software or cab management system, the hub for all your taxicab management needs. Give your clients a centralized app experience for their vehicle booking.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Cab Management Software | Taxi Management App for Taxi Business",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Drive your taxi firm to success with the best taxi management software or cab management system, the hub for all your taxicab management needs. Give your clients a centralized app experience for their vehicle booking.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/taxi-management-system",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Cab Management Software | Taxi Management App for Taxi Business",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Drive your taxi firm to success with the best taxi management software or cab management system, the hub for all your taxicab management needs. Give your clients a centralized app experience for their vehicle booking.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Cab Management Software | Taxi Management App for Taxi Business",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }

}
