import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Techware Lab | Software & Mobile App Development Tailored To Your Business Needs"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Techware Lab is a boutique software and mobile app development company in India, UAE & USA that offers high-quality, innovative software and apps to entrepreneurs and enterprises.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Techware Lab is a boutique software and mobile app development company in India, UAE & USA that offers high-quality, innovative software and apps to entrepreneurs and enterprises.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Techware Lab | Software & Mobile App Development Tailored To Your Business Needs",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Techware Lab is a boutique software and mobile app development company in India, UAE & USA that offers high-quality, innovative software and apps to entrepreneurs and enterprises.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Techware Lab | Software & Mobile App Development Tailored To Your Business Needs",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Techware Lab is a boutique software and mobile app development company in India, UAE & USA that offers high-quality, innovative software and apps to entrepreneurs and enterprises.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Techware Lab | Software & Mobile App Development Tailored To Your Business Needs",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 

  


}
