import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SeoService } from '../seo.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-matrimony-app-development',
  templateUrl: './matrimony-app-development.component.html',
  styleUrls: ['./matrimony-app-development.component.scss']
})
export class MatrimonyAppDevelopmentComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  constructor(private model: MatDialog,private title: Title, private meta: Meta, private seoService: SeoService) { 
    console.log('ytsduytfus');
    
  }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Online Dating Software | Matrimony App Development Solutions"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Find your perfect match with our online dating app solutions and matrimony app development in India, UAE, and USA. Our dating software helps to bring people together on one easy-to-use dating app system.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Find your perfect match with our online dating app solutions and matrimony app development in India, UAE, and USA. Our dating software helps to bring people together on one easy-to-use dating app system.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Online Dating Software | Matrimony App Development Solutions",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Find your perfect match with our online dating app solutions and matrimony app development in India, UAE, and USA. Our dating software helps to bring people together on one easy-to-use dating app system.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Online Dating Software | Matrimony App Development Solutions",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Find your perfect match with our online dating app solutions and matrimony app development in India, UAE, and USA. Our dating software helps to bring people together on one easy-to-use dating app system.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Online Dating Software | Matrimony App Development Solutions",
    });

  }

  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

}
