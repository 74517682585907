<section class="uiuxSectionWrapper">


    <div class="heroSection">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="heroContent">
              <div>
                <h1>Search Engine Optimization</h1>
                <h5>Elevate Your Online Presence With Expert SEO Strategies</h5>
                <p>
                    We specialize in Search Engine Optimization (SEO) services that not only increase your website’s visibility but also enhance its potential to convert and retain your target audience.
                </p>
                <button (click)="requestQuote()">Get Started</button>
              </div>
  
              <div class="pinkBgDiv"></div>
              <div class="yellowBgDiv"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="heroImgWrapper">
              <img src="../../../assets/images/seoHeroImage.png" />
            </div>
            <div class="heroImageBgWrapper">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M48.4,-55.6C62.1,-46.3,71.9,-30.4,74.1,-13.7C76.3,3,70.9,20.4,62.6,37.4C54.3,54.3,43,70.8,28,75.8C13,80.8,-5.8,74.2,-21.1,65.4C-36.4,56.6,-48.1,45.6,-58.3,31.8C-68.4,18,-77.1,1.4,-74.7,-13.4C-72.3,-28.2,-59,-41.3,-44.7,-50.5C-30.5,-59.7,-15.2,-64.9,1.1,-66.2C17.4,-67.5,34.8,-64.9,48.4,-55.6Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
            <div class="heroImageWrapper2">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M65.2,-21.4C72.9,2.3,59.4,32.7,36.8,48.7C14.2,64.6,-17.4,66,-40.1,50.6C-62.8,35.2,-76.5,2.9,-68.4,-21.6C-60.2,-46.1,-30.1,-62.8,-0.7,-62.5C28.8,-62.3,57.6,-45.2,65.2,-21.4Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="featureSection">
        <div class="blueBgDiv"></div>
        <div class="pinkBgDiv"></div>
        <div class="yellowBgDiv"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <img src="../../../assets/images/seoServiceImage.png" />
            </div>
            <div class="col-lg-6">
              <h2>Why Invest in Techware Lab’s SEO Services?</h2>
              <div>
                <h6>Customized Strategies</h6>
                <p>
                    Your business is unique, and so are our SEO strategies. We tailor our approach to your industry, audience, and goals.
                </p>
              </div>
              <div>
                <h6>Comprehensive Research</h6>
                <p>
                    Using advanced analytics and research methods, we identify the keywords and phrases that will drive targeted traffic to your site.
                </p>
              </div>
              <div>
                <h6>Technical Excellence</h6>
                <p>
                    From on-page optimizations to backend enhancements, we ensure your website is primed for optimal search engine performance.
                </p>
              </div>
              <div>
                <h6>Content Is King</h6>
                <p>
                    Our team creates and optimizes content that speaks to your audience, engages them, and elevates your brand’s authority.
                </p>
              </div>
              <div>
                <h6>Results-Driven</h6>
                <p>
                    We focus on tangible outcomes—improved rankings, increased traffic, and enhanced lead generation contributing to your bottom line.
                </p>
              </div>
              <div>
                <h6>Transparent Reporting</h6>
                <p>
                    Stay informed with regular, detailed reports that track your SEO campaign’s progress and our strategic adjustments
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


    <div class="serviceSection">
      <div class="container">
        <h2>Our SMO Service</h2>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../../assets/images/seoKeywordImage.png" />
            <h3>Keyword Research & Strategy</h3>
            
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/seoSEOImage.png" />
            <h3>On-Page SEO Optimization</h3>
           
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/seoContentImage.png" />
            <h3>Content Creation & Marketing</h3>
            
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../../assets/images/seoTechnicalImage.png" />
            <h3>Technical SEO, Including Site Speed and Mobile-Friendliness</h3>
            
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/seoLinkImage.png" />
            <h3>Link Building Campaigns</h3>
           
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/seoLocalImage.png" />
            <h3>Local SEO and Listings</h3>
            
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../../assets/images/seoECommerseImage.png" />
            <h3>E-commerce SEO</h3>
            
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/seoAnalyticsImage.png" />
            <h3>Analytics and Reporting</h3>
           
          </div>
          
        </div>
      </div>
    </div>
    
    <div class="quoteSection">
      <div class="container">
        <div class="row">
            <div class="col-md-6">
              <img src="../../../assets/images/seoQuoteImage.png" />
            </div>
            <div class="col-md-6">
              <div class="quoteContent">
               <div>
                  <p>Your Journey to the Top Begins Here</p>
                 
               </div>
               <p>Are you ready to dominate search results? Partner with Techware Lab and harness the power of sophisticated SEO tactics designed for your unparalleled online ascent.</p>
             
              </div>
            </div>
        </div>
      </div>
    </div>
  
    <div class="ctaWrapper">
      <div class="container">
        <h1>SEO Excellence, Your Key to Visibility
        </h1>
        <p>
          Reach new heights! Our SEO services guide your ascent to the top.
        </p>
  
        <button routerLink="/contact">Book Your Free Consultation</button>
      </div>
    </div>
  </section>
  