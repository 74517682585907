import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-why-flutter-for-mobile-app-development',
  templateUrl: './why-flutter-for-mobile-app-development.component.html',
  styleUrls: ['./why-flutter-for-mobile-app-development.component.scss']
})
export class WhyFlutterForMobileAppDevelopmentComponent implements OnInit {

  public fbLink:string=environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;

  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.title.setTitle(
      "Why Choose Flutter For Your Mobile App Development?"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Why choose Flutter for mobile app development? Are you actively searching for Flutter developers? We can fill in the gaps in your team with our roster of experienced Flutter developers. ",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Why choose Flutter for mobile app development? Are you actively searching for Flutter developers? We can fill in the gaps in your team with our roster of experienced Flutter developers. ",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-10.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Why Choose Flutter For Your Mobile App Development?",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Why choose Flutter for mobile app development? Are you actively searching for Flutter developers? We can fill in the gaps in your team with our roster of experienced Flutter developers.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/why-flutter-for-mobile-app-development",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-10.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Why Choose Flutter For Your Mobile App Development?",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Why choose Flutter for mobile app development? Are you actively searching for Flutter developers? We can fill in the gaps in your team with our roster of experienced Flutter developers. ",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Why Choose Flutter For Your Mobile App Development?",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 


  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `why-flutter-for-mobile-app-development`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u',`https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');
  
      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');

      
    }
  }

}
