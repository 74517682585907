
<section class="uiuxSectionWrapper">


    <div class="heroSection">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="heroContent">
              <div>
                <h1>Performance Marketing Services</h1>
                <h5>Transforming Clicks into Customers</h5>
                <p>
                    We empower your business to achieve measurable results through data-driven marketing strategies that maximize return on investment and fuel sustainable growth.
                </p>
                <button (click)="requestQuote()">Get A Free Quote</button>
              </div>
  
              <div class="pinkBgDiv"></div>
              <div class="yellowBgDiv"></div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="heroImgWrapper">
              <img src="../../../assets/images/pmoHeroImage.png" />
            </div>
            <div class="heroImageBgWrapper">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M48.4,-55.6C62.1,-46.3,71.9,-30.4,74.1,-13.7C76.3,3,70.9,20.4,62.6,37.4C54.3,54.3,43,70.8,28,75.8C13,80.8,-5.8,74.2,-21.1,65.4C-36.4,56.6,-48.1,45.6,-58.3,31.8C-68.4,18,-77.1,1.4,-74.7,-13.4C-72.3,-28.2,-59,-41.3,-44.7,-50.5C-30.5,-59.7,-15.2,-64.9,1.1,-66.2C17.4,-67.5,34.8,-64.9,48.4,-55.6Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
            <div class="heroImageWrapper2">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M65.2,-21.4C72.9,2.3,59.4,32.7,36.8,48.7C14.2,64.6,-17.4,66,-40.1,50.6C-62.8,35.2,-76.5,2.9,-68.4,-21.6C-60.2,-46.1,-30.1,-62.8,-0.7,-62.5C28.8,-62.3,57.6,-45.2,65.2,-21.4Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="featureSection">
        <div class="blueBgDiv"></div>
        <div class="pinkBgDiv"></div>
        <div class="yellowBgDiv"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <img src="../../../assets/images/pmoFeatureImage.png" />
            </div>
            <div class="col-lg-6">
              <h2>Why Choose Techware Lab for Performance Marketing?</h2>
              <div>
                <h6>ROI-Centric Campaigns</h6>
                <p>
                    Our marketing approach is all about performance. We focus on driving actions that contribute directly to your bottom line, ensuring every dollar of your budget is spent effectively.
                </p>
              </div>
              <div>
                <h6>Data-Driven Insights</h6>
                <p>
                    Armed with real-time analytics, we continually optimize campaigns to improve performance metrics, from click-through rates to conversion ratios
                </p>
              </div>
              <div>
                <h6>Cross-Channel Expertise</h6>
                <p>
                    We navigate through many channels to find the right mix that connects with your audience, using targeted ads, affiliate marketing, search engine marketing, and more.
                </p>
              </div>
              <div>
                <h6>Transparent Reporting</h6>
                <p>
                    With our transparent reporting framework, you’re always in the loop. Track your campaign’s success through clear metrics demonstrating our commitment to your growth.
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </div>


    <div class="serviceSection">
      <div class="container">
        <h2>Our Performance Marketing Services</h2>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../../assets/images/pmoSearchImage.png" />
            <h3>Paid Search Advertising</h3>
            <p>
              that places your brand at the top of search engine results for the highest intent keywords.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/pmoSocialImage.png" />
            <h3>Social Media Advertising</h3>
            <p>
                is leveraging sophisticated targeting to create impactful ads that resonate with your audience on every platform.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/pmoAffliateImage.png" />
            <h3>Affiliate Marketing</h3>
            <p>
                through a vast network of partners, amplifying your reach and driving sales.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../../assets/images/pmoEmailImage.png" />
            <h3>Email Marketing Campaigns</h3>
            <p>
                that nurture leads and convert them into loyal customers.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/pmoConversionImage.png" />
            <h3>Conversion Rate Optimization (CRO)</h3>
            <p>
                to turn your website into a high-converting engine.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/pmoProgrammaticImage.png" />
            <h3>Programmatic Advertising</h3>
            <p>
                uses AI to buy and optimize digital campaigns more efficiently than ever.
            </p>
          </div>
        </div>
      </div>
    </div>
    
    <div class="quoteSection">
      <div class="container">
        <div class="row">
            <div class="col-md-6">
              <img src="../../../assets/images/pmoQuoteImage.png" />
            </div>
            <div class="col-md-6">
              <div class="quoteContent">
               <div>
                  <p>Optimize Your Marketing for Performance</p>
                 
               </div>
               <p>Join forces with Techware Lab and watch your marketing evolve into a revenue-generating powerhouse. Our dedicated team is ready to steer your campaigns to unmatched success with precision and passion.</p>
              
              </div>
            </div>
        </div>
      </div>
    </div>
  
    <div class="ctaWrapper">
      <div class="container">
        <h1>Drive Results, Dominate Markets
        </h1>
        <p>Revolutionize your marketing game! Our performance-driven strategies are your key to success. Ready for a powerful boost?

        </p>
  
        <button routerLink="/contact">
          Book A Free Consultation
          </button>
      </div>
    </div>
  </section>
  