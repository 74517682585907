<section class="header-banner">
    <div class="container">
        <div class="text-content">
            <h1>Business & Information <br> Technology Consulting</h1>  
            <h6>Troubleshoot and solve all of the complex IT challenges holding your business back <br>with deep insights into your industry, technology requirements, and more.</h6>
            <a routerLink="/portfolio" target="_blank">View our latest projects</a>
        </div>
    </div>
</section>
<section class="techware-wrapper">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col-md-5 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/business-consulting-img-1.png" class="img-fluid" alt="business-consulting">
            </div>
            <div class="col-md-7">
                <div class="help-box mt-4 mt-md-0">
                    <h1 class="cmn-heading">
                        Techware Lab Is the IT Expert <br> You Need
                    </h1>
                    <p>
                        You may currently experience challenges in your work culture, existing workflow, or overall company strategy. On top of that, the modern workforce is transforming, and companies like yours are in need of a refresh in order to meet the needs of their customers in the digital world. That’s where we come in.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="testimonial-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h1 class="cmn-heading">Who We Work With</h1>
          <p>See other companies who have partnered with Techware Lab for business and IT consulting services.</p>
        </div>
        <div class="col-md-4"></div>
      </div>
      <div id="demo" class="carousel slide mt-5" data-ride="carousel">
        <!-- The slideshow -->
        <!--<div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-img.png"
                      alt="Profile"
                    />
                  </div>
                  <h3>Donnette Copestake</h3>
                  <h4>IT Director, Wyelands Bank</h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “Techware Lab and I worked together on a number of exciting innovation and R&D projects. Nixon was my lead technical consultant. He is a knowledgeable IT consultant who is highly collaborative in his approach. He is always quick to respond to questions / issues offering solutions to complex problems and often thinks ‘out of the box’. Nixon is intelligent, diligent and thorough in his approach and maintains a sense of humour. He was a highly valued member of my team, often going above and beyond what is expected of him. I would welcome him back to my team and strongly recommend him to future employers.”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img src="/assets/images/AbdulSattar.jpg" alt="Profile" />
                  </div>
                  <h3>AbdulSattar Zahid</h3>
                  <h4>
                    Alibaba Product Growth,<br />
                    Dubai
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “In a nutshell, I consider them to be a member of our team,
                    who just don't sit with us. I have always found the Techware
                    team very compliant. I have never gotten the feeling that
                    getting to a goal is important to me and to them we are just
                    another client, but I have always gotten the feeling that we
                    are one team and I value that over all the disagreements and
                    what not. As a testament to this feedback, what started from
                    one project has transformed our partnership in a very long one
                    and we hope to continue that. ”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-image-3.jpg"
                      alt="Profile"
                    />
                  </div>
                  <h3>Danette Copestake</h3>
                  <h4>
                    IT Director,<br />
                    Wyelands Bank
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    "Techware Lab and I worked together on a number of exciting
                    innovation and R&D projects. Nixon was my lead technical
                    consultant. He is a knowledgeable IT consultant who is highly
                    collaborative in his approach. He is always quick to respond
                    to questions / issues offering solutions to complex problems
                    and often thinks ‘out of the box’. Nixon is intelligent,
                    diligent and thorough in his approach and maintains a sense of
                    humour. He was a highly valued member of my team, often going
                    above and beyond what is expected of him. I would welcome him
                    back to my team and strongly recommend him to future
                    employers."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <a class="carousel-control-prev" href="#demo" data-slide="prev"> </a>
          <a class="carousel-control-next" href="#demo" data-slide="next"> </a>
        </div>
      </div>
    </div>
</section> -->

<section class="brands-wrapper">
    <div class="container">
      <div class="row mt-5 mb-4 mt-md-0">
        <div class="col-md-12 text-center">
          <h1 class="cmn-heading">Who We Work With</h1>
          <h2 class="cmn-sub-heading">
            See other companies who have partnered with Techware Lab for website
            design<br />
            and development services.
          </h2>
        </div>
      </div>
  
      <owl-carousel-o [options]="customOptionsOne">
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-one.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-two.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-three.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-four.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-eigt.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-nine.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-logo-five.png"
              class="img-fluid brand-img-one"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-logo-four.png"
              class="img-fluid brand-img-two"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-three.png"
              class="img-fluid brand-img-three"
              alt="Images"
            />
          </div>
        </ng-template>
  
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-five.png"
              class="img-fluid brand-img-four"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-six.png"
              class="img-fluid brand-img-five"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-sev.png"
              class="img-fluid brand-img-six"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-ten.png"
              class="img-fluid brand-img-seven"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-elv.png"
              class="img-fluid brand-img-eigt"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-tw.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </section>


<section class="design-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Business & IT Consulting Services</h1>
                <h2 class="cmn-sub-heading ">We equip you with the right tools to move the operational dial forward no matter what obstacles you may face due to rapid disruption. Our business consultants help you ebb and flow with the latest technology and marketing trends to stay ahead of the curve.</h2>
           
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/business-consulting-img-2.png" class="img-fluid side-img" alt="digital-marketing">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-5 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Develop a Winning Business Strategy
                                         </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        We work closely with your team to devise a road map of your business goals to clarify your business insights and vision.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Revamp Operational Processes & Achieve Company Success
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        A deep dive into your internal operations and resources is done to help you restructure the foundation of your organization. Our team looks for areas of improvement to boost your company’s effectiveness.
                                       </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Improve Your Cultural Values
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            During these times of transformation, we support all the stakeholders in your company, aiming to keep the culture of your business empowered, positive, and efficient.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            IT Solution Strategy
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            Create a detailed strategy or design solutions with our skilled team that align with your business needs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFive">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            IT Solution Implementation 
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            Get a custom IT solution that best suits your business needs and devise a plan for its installment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingSix">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            Cloud Consulting and Integration
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            Choose on a cloud model and migration strategy to store data appropriately and improve your IT performance infrastructure.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>




<section class="approach-wrapper">
    <div class="container">
        <div class="row ">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Approach to Business & IT Consulting</h1>

            </div>
        </div>
        <div class="row mb-md-5 mt-md-4">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box blue">
                    <img src="/assets/images/business-consulting-icon-1.png" alt="business-consulting Icon">
                    <h3> Business Inspection and Planning  </h3>
                    <p>We conduct in-depth research on your business operations, begin strategizing your IT design, and enter into the beginning stages of the implementation plan.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="approach-box red">
                    <img src="/assets/images/business-consulting-icon-2.png" alt="business-consulting Icon">
                    <h3>Implementation </h3>
                    <p>Then our team will stabilize implementation, and conduct IT solution migration and modernization. </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box yellow">
                    <img src="/assets/images/business-consulting-icon-3.png" alt="business-consulting Icon">
                    <h3>Ongoing Support  </h3>
                    <p>Throughout the whole process our team is committed to managing ongoing IT services and helpdesk support.</p>
                </div>
            </div>
        </div>
    </div>
</section>






<section class="mobile-app-wrapper">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12">
                <h1 class="cmn-heading">Benefits of Investing Into a Culture-Driven & <br> Technological Business Transformation</h1> 
            </div>
        </div>
        <div class="row side">
            <div class="col-md-10">
                <div class="team-box">
                    <ul class="styled-list">
                        <li>Build a new and agile operational workflow process </li>
                        <li>Foster long lasting connectivity between the key stakeholders of your business </li>
                        <li>Stay relevant and repel disruption by equipping your business with modern software and methodologies</li>
                        <li>Redefine culture and leadership standards for a sustainable future</li>
                    </ul>
                    
                </div>
            </div>
        </div>
    </div>
</section>





<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Stay Agile and Connected With the Help Of Our <br> Proven Team</h1>
                <h5 class="cmn-sub-heading">Our business consultants will help you identify any core business challenges you may currently face and implement the right end-to-end software solutions to tackle your service delivery excellence and operational management.</h5>
                <button routerLink="/contact"> Get a free consultation</button>
            </div>
        </div>
    </div>
</section>








