<section class="uiuxSectionWrapper">
  <div class="heroSection">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="heroContent">
            <div>
              <h1>Marketing Automation</h1>
              <h5>Effortless Marketing, Powerful Results</h5>
              <p>
                We harness the transformative power of marketing automation to
                streamline your campaigns, nurture leads, and secure
                conversions. We provide sophisticated tools and strategies that
                elevate your marketing efforts.
              </p>
              <button (click)="requestQuote()">Get Started</button>
            </div>

            <div class="pinkBgDiv"></div>
            <div class="yellowBgDiv"></div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="heroImgWrapper">
            <img src="../../assets/images/marketingHeroImage.png" />
          </div>
          <div class="heroImageBgWrapper">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#E6FBFF"
                d="M48.4,-55.6C62.1,-46.3,71.9,-30.4,74.1,-13.7C76.3,3,70.9,20.4,62.6,37.4C54.3,54.3,43,70.8,28,75.8C13,80.8,-5.8,74.2,-21.1,65.4C-36.4,56.6,-48.1,45.6,-58.3,31.8C-68.4,18,-77.1,1.4,-74.7,-13.4C-72.3,-28.2,-59,-41.3,-44.7,-50.5C-30.5,-59.7,-15.2,-64.9,1.1,-66.2C17.4,-67.5,34.8,-64.9,48.4,-55.6Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
          <div class="heroImageWrapper2">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#E6FBFF"
                d="M65.2,-21.4C72.9,2.3,59.4,32.7,36.8,48.7C14.2,64.6,-17.4,66,-40.1,50.6C-62.8,35.2,-76.5,2.9,-68.4,-21.6C-60.2,-46.1,-30.1,-62.8,-0.7,-62.5C28.8,-62.3,57.6,-45.2,65.2,-21.4Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="technologies-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="cmn-heading">Our Approach to Marketing Automation</h1>
          
          <div class="row mt-5">
            
            
            <div class="col-md-6 mt-4">
              <div class="accordion" id="accordionExample">
                <div class="card">
                  <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                       
                        aria-controls="collapseOne"
                      >
                      Personalized Customer Journeys
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseOne"
                    class="collapse "
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      <p> We map out and automate customer interactions that feel personal and timely, guiding your prospects through a custom-tailored sales funnel.</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingTwo">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                       
                        aria-controls="collapseTwo"
                      >
                      Data-Driven Decisions
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseTwo"
                    class="collapse "
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      <p>Our automation solutions thrive on data. By analyzing customer behavior, we create campaigns that react in real time, delivering the right message at the right moment.</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingThree">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                       
                        aria-controls="collapseThree"
                      >
                      Effortless Lead Management
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseThree"
                    class="collapse "
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      <p> We help you categorize and score leads automatically, ensuring that your team focuses on the most promising prospects.</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingFour">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                       
                        aria-controls="collapseFour"
                      >
                      Seamless Integration
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseFour"
                    class="collapse "
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      <p> Our marketing automation services integrate seamlessly into your existing CRM and sales platforms, creating a cohesive ecosystem that drives growth.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingFive">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFive"
                       
                        aria-controls="collapseFive"
                      >
                      Multichannel Campaign Orchestration
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseFive"
                    class="collapse "
                    aria-labelledby="headingFive"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      <p>From email to social media, SMS, and beyond, our automation strategies encompass all channels for a unified brand experience.
                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
                <img
                  src="/assets/images/technology-image-1.png"
                  class="img-fluid"
                  alt="Mobile app development company"
                />
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="featureSection">
    <div class="blueBgDiv"></div>
    <div class="pinkBgDiv"></div>
    <div class="yellowBgDiv"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <img src="../../assets/images/marketingFeatureImage.png" />
        </div>
        <div class="col-lg-6">
          <h2>Why Choose Techware Lab for Marketing Automation?</h2>
          <div>
            <h6>Expert Implementation</h6>
            <p>
              Our team of experts ensures your marketing automation setup is
              flawless, paving the way for campaigns that perform without a
              hitch.
            </p>
          </div>
          <div>
            <h6>Custom Automation Workflows</h6>
            <p>
              We design automation workflows that reflect your business
              processes and align with your marketing goals, delivering relevant
              content that converts.
            </p>
          </div>
          <div>
            <h6>Robust Analytics and Reporting</h6>
            <p>
              With advanced reporting tools, you’ll gain insightful metrics on
              campaign performance, customer engagement, and conversion rates to
              refine your strategy continually.
            </p>
          </div>
          <div>
            <h6>Ongoing Support and Optimization</h6>
            <p>
              Our commitment to your success is unwavering. We provide
              continuous support and optimization services to keep your
              automated campaigns at the forefront of innovation.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="serviceSection">
    <div class="container">
      <h2>Our Marketing Automation Services</h2>
      <div class="row">
        <div class="col-lg-4">
          <img src="../../assets/images/marketingEmailImage.png" />
          <h3>Email Marketing Automation</h3>
          <p>to send targeted, behavior-triggered emails.</p>
        </div>
        <div class="col-lg-4">
          <img src="../../assets/images/marketingLeadImage.png" />
          <h3>Lead Nurturing Campaigns</h3>
          <p>
            that move prospects through the sales funnel. Customer Segmentation
            for hyper-targeted messaging.
          </p>
        </div>
        <div class="col-lg-4">
          <img src="../../assets/images/marketingCustomerImage.png" />
          <h3>Customer Segmentation</h3>
          <p>for hyper-targeted messaging.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <img src="../../assets/images/marketingCampaignImage.png" />
          <h3>Campaign Analysis and Reporting</h3>
          <p>for in-depth performance insights.</p>
        </div>
        <div class="col-lg-4">
          <img src="../../assets/images/marketingLifecycleImage.png" />
          <h3>Lifecycle Marketing</h3>
          <p>to engage customers at every stage</p>
        </div>
      </div>
    </div>
  </div>

  <div class="quoteSection">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <img src="../../assets/images/marketingQuoteImage.png" />
        </div>
        <div class="col-md-6">
          <div class="quoteContent">
            <div>
              <p>Elevate Your Marketing Efficiency</p>
            </div>
            <p>
              With Techware Lab’s marketing automation services, your business
              can achieve more with less, leveraging technology to nurture
              customer relationships and drive sales. Experience the power of
              automation and let your marketing work smarter, not harder.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ctaWrapper">
    <div class="container">
      <h1>
        Strategize, Automate, Succeed

      </h1>
      <p>Let automation take the lead! Experience the magic of automation for precise, targeted marketing.</p>

      <button routerLink="/contact">Book A Free Consultation</button>
    </div>
  </div>
</section>
