<section class="staff-augmentation-banner">
    <div class="container">
        <div class="text-content">
            <h1>Achieve Your Outcomes <br/>
                With Flexible IT Contract Staffing</h1>  
            <h5>Complete your IT projects on time and on budget. Our clients get everything <br/>
                they need to achieve their outcomes.                
                </h5>  
            <div class="para">
                <h6 class="paragraph">Save time with our flexible scheduling</h6>
                <h6 class="paragraph">Reduce your spend when compared to other expensive IT staffing options</h6>
                <h6 class="paragraph">Improve your collaboration potential with our flexible teams</h6>
            </div>  
            <button (click)="requestQuote()" target="_blank">Get in touch now</button>
        </div>
    </div>
</section>

<section class="help-banner">
    <div class="container">
        <div class="row mb-5">
            <div class="col-md-5  order-md-1 order-0 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/tech-img-1.webp" class="img-fluid" alt="Cutting edge technologies">
            </div>
            <div class="col-md-7 order-md-0 order-1">
                <div class="help-box mt-4 mt-md-0">
                    <h1 class="cmn-heading">Techware Lab Helps <br/>
                        Businesses Get IT Work Done</h1>
                    <h2 class="cmn-sub-heading">Businesses need IT work, but getting it done can be stressful<br/>
                        and expensive.
                    </h2>
                <p>
                    Finding an independent contractor can be very time-consuming. Hiring full-time IT staff 
                    can be a waste of resources if they won’t have consistent work. Do yourself a favor and 
                    work with Techware Lab to complete your IT projects.
                </p>
                <p>
                    Take your business to the next level with an experienced IT staffing company like Techware
                    Lab. With decades of experience providing IT staff augmentation, you won’t be disappointed.
                </p>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="partner-content-wrapper">
    <div class="container">
        
        <div class="row mb-5">
            <div class="col-md-6">
                <img src="/assets/images/team-img-1.webp" class="img-fluid team-img-one" alt="End to end software development">
            </div>
            <div class="col-md-6">
                <div class="about-box">
                    <h2 class="blue">Complete Transparency
                    </h2>
                    <p>
                        We believe transparency is one of the most important things 
                        a business can have. We are completely transparent with both
                        our pricing and work. 
                    </p>
                </div>
            </div>
        </div>
        <span class="dot">
            <img src="/assets/images/dot-2.webp" alt="dot" class="img-fluid dot-img">
        </span>
        <div class="row mb-5">
            <div class="col-md-7 order-md-1 order-0 text-right">
                <img src="/assets/images/team-img-2.webp" class="img-fluid team-img-two" alt="Cutting edge technologies">
            </div>
            <div class="col-md-5 order-md-0 order-1">
                <div class="about-box">
                    <h2 class="blue">Efficient Work
                    </h2>
                     <p>
                    Our experienced staff knows how to develop for a variety 
                    of popular platformsand languages. We will work efficiently
                    to ensure your satisfaction.
                    </p>
                                    
                </div>
            </div>
        </div>
        <span class="dot-two">
            <img src="/assets/images/dot-1.webp" alt="dot" class="img-fluid dot-img-two">
        </span>
        <div class="row mb-5">
            <div class="col-md-6">
                <img src="/assets/images/team-img-3.webp" class="img-fluid team-img-three" alt="Partnership for the goals">
            </div>
            <div class="col-md-6">
                <div class="about-box">
                    <h2 class="blue">Flexible & Collaborative
                    </h2>
                    <p> 
                        You need an IT staff to support your team rather than get in the way. 
                        We offer flexible staffing that works around your needs, which is why 
                        we take a collaborative approach.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section> 




<section class="techware-service-wrapper ">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Our Services</h1>
                <h2 class="cmn-sub-heading">Use our flexible IT staff augmentation services to fill in the gaps in your teams.<br/> Here are just a few of the services we offer.</h2>
            </div>
        </div>
        <div class="row side">
            <div class="col-md-2" data-aos="fade-up-right" data-aos-delay="50"></div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="team-box yellow">
                    <img src="/assets/images/serve-one.png" class="img-one" alt="Techware-team">
                    <h3>Dedicated Resources</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="team-box blue">
                    <img src="/assets/images/serve-two.png" class="img-two" alt="Techware-team">
                    <h3>Managed Solutions</h3>
                </div>
            </div>
            <div class="col-md-2" data-aos="fade-up-right" data-aos-delay="50"></div>
            <div class="col-md-2" data-aos="fade-up-right" data-aos-delay="50"></div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box red">
                    <img src="/assets/images/serve-three.png" class="img-four" alt="Techware-team">
                    <h3>Contractual Hiring</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box red">
                    <img src="/assets/images/serve-four.png" class="img-five" alt="Techware-team">
                    <h3>Temporary Staffing</h3>
                </div>
            </div>
            <!-- <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box red">
                    <img src="/assets/images/serve-five.png" class="img-five" alt="Techware-team">
                    <h3>Expertise IT Professionals</h3>
                </div>
            </div>
            <div class="col-md-4 " data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box red">
                    <img src="/assets/images/serve-six.png" class="img-six" alt="Techware-team">
                    <h3>Monitoring & Controlling</h3>
                </div>
            </div> -->
        </div>
    </div>
</section> 




<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Give Your Teams the <br/>
                    Technical Support They Need
                </h1>
                <h2>Save time, money, effort, and support your teams with our flexible IT Staffing Services.</h2>
                <button>Get in touch now</button>
            </div>
        </div>
    </div>
</section>




<section class="faq-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Frequently Asked Questions</h1>
        <mat-accordion>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                    Why choose Techware Lab’s IT contract staffing services?                 
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                IT contract staffing offers a number of benefits. The biggest one being that you don’t have to hire anyone full-time. Hiring full-time employees can be expensive, especially if you only need flexible work. Plus, with our IT contracting services, there’s no need to navigate the hiring process.
              </p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How does staff augmentation work?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    You hire us to do a project and our experienced employees will complete it. You can ask any and all questions, ask for updates during the project, and set up meetings. We take a collaborative approach and work to fill in the gaps in your teams.    
                </p>              
                </mat-expansion-panel>
             
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    What does the pricing look like?     </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    Pricing varies depending on each project’s needs. We offer competitive pricing and high quality results. 
    
                </p>             
             </mat-expansion-panel>
             <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    What if I’m not satisfied? 
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    We prioritize client satisfaction. If you ever have any concerns, comments, or questions—our team is here to help.    
                </p>              
                </mat-expansion-panel>
        </mat-accordion>
    </div>
</section> 
