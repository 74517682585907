import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from 'src/app/request-quote/request-quote.component';

@Component({
  selector: 'app-heading-section',
  templateUrl: './heading-section.component.html',
  styleUrls: ['./heading-section.component.scss']
})
export class HeadingSectionComponent {
  constructor(private model: MatDialog) { }

  @Input() title: string = '';
  @Input() description: string[] = [];
  @Input() buttonText: string = '';
  @Input() imageUrl: string = '';

  requestQuote() {
            
    this.model.open(RequestQuoteComponent);

  }
}




  



 
  
  


