<app-heading-section 
   [title]="mangosuiteData.heading.title"
   [description]="mangosuiteData.heading.description"
   [buttonText]="mangosuiteData.heading.buttonText"
   [imageUrl]="mangosuiteData.heading.imageUrl">
</app-heading-section>

<app-about-section 
   [title]="mangosuiteData.about.title"
   [description]="mangosuiteData.about.description"
   [imageUrl]="mangosuiteData.about.imageUrl">
</app-about-section>

<app-story-section
   [title]="mangosuiteData.story.title"
   [description]="mangosuiteData.story.description"
   [imageUrl]="mangosuiteData.story.imageUrl"
   [buttonText]="mangosuiteData.story.buttonText"
   [viewMoreContent]="mangosuiteData.story.viewMoreContent"
   >
</app-story-section>

<app-plain-banner 
   [title]="mangosuiteData.plainBanner.title"
   [buttonText]="mangosuiteData.plainBanner.buttonText">
</app-plain-banner>

<app-results-section 
   [title]="mangosuiteData.results.title"
   [description]="mangosuiteData.results.description"
   [imageUrl]="mangosuiteData.results.imageUrl">
</app-results-section>

<app-form-section></app-form-section>


