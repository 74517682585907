<section class="gitex-navbar">
    <div class="container">
        <div class="wrapper">
            <img src="/assets/images/logo.svg" class="img-fluid" alt="Logo">
            <button class="request-quote-btn" (click)="requestQuote()">Request a Quote</button>
        </div>
    </div>
</section>
<section class="gitex-banner">
   <div class="gitex-inner">
    <div class="banner-content">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-7">
                    <div class="text-content">
                        <h1>Techware Lab will<br/>
                            be at <span>GITEX 2021</span></h1>  
                            <h5><b>Software innovation tailored to your business needs</b></h5>  
                            <p>We are all geared up and excited to be a part of the biggest technology event in the<br/> Middle East. Visit our booth from October 17 to 21 for a free consultation!</p>
                            <h5><b>We look forward to assisting you with the digital transformation<br/>
                                of your startup or business! </b></h5>
                                <div class="para">
                                    <h6 class="paragraph">When: October 17-21</h6>
                                    <h6 class="paragraph">Where: CC2-18, Kerala IT Pavilion, Concourse 2 (Dubai World Trade Centre)</h6>
                                    <h6 class="paragraph">Why: Get a free consultation to see how we can help you</h6>
                                </div>
                                <ul class="d-flex">
                                    <li><img class="img-one" src="/assets/images/gitex-global.png" alt="gitex-global-img"></li>
                                    <li><img class="img-two" src="/assets/images/cross.png" alt="cross-img"></li>
                                    <li><img class="img-three" src="/assets/images/ai-everything.png" alt="ai-img"></li>
                                </ul>
                    </div>
                </div>
                <div class="col-12 col-md-5 mt-md-6 mt-0" #gitexForm>
                    <div class="banner-form">
                        <div class="demo-form">
                            <div class="top-text pr-md-2">
                                <h5>Let's connect and meet in Dubai</h5>
                                  <h6>  We have a limited number of free <br/>
                                    consultations so book a session with us now! </h6>
                            </div>
                            <div class="quote-wrapper">
                                <form [formGroup]="contactForm" (ngSubmit)="contactFormSubmit()">
                                    <div class="form-group">
                                        <input type="text" formControlName="name" class="form-control" placeholder="Name">
                                        <div class="s_error" *ngIf="!contactForm.controls['name'].valid && (contactForm.controls['name'].dirty || contactForm.controls['name'].touched || contactSubmit)">
                                            <div class="s_validation" *ngIf="contactForm.controls['name'].hasError('required')">
                                                Provide a Name
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="email" formControlName="email" class="form-control" placeholder="Email">
                                        
                                        <div class="s_error" *ngIf="!contactForm.controls['email'].valid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched || contactSubmit)">
                                            <div class="s_validation" *ngIf="contactForm.controls['email'].hasError('required')">Provide an Email</div>
                                            <div class="s_validation" *ngIf="contactForm.controls['email'].hasError('pattern')">Provide a Valid Email Id</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-11">
                                <select class="form-control" formControlName="country">
                                    <option disabled value="0" [selected]="true">Select Country</option>
                                    <option *ngFor="let contry of countryList.default">
                                    {{contry.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-1 star"> * </div>
                        </div>
                        <div class="s_error" *ngIf="!contactForm.controls['country'].valid && (contactForm.controls['country'].dirty || contactForm.controls['country'].touched || contactSubmit)">
                            <div class="s_validation" *ngIf="contactForm.controls['country'].hasError('required')">
                                Choose Country
                            </div>
                        </div>
                    </div>
                                    <div class="form-group">
                                        <input type="Number" formControlName="phone" class="form-control" placeholder="Phone">
                                        
                                        <div class="s_error" *ngIf="!contactForm.controls['phone'].valid && (contactForm.controls['phone'].dirty || contactForm.controls['phone'].touched || contactSubmit)">
                                            <div class="s_validation" *ngIf="contactForm.controls['phone'].hasError('required')">
                                                Provide a Phone number
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <select class="form-control" formControlName="reason">
                                            <option disabled value="0" [selected]="true">What are you looking for?</option>
                                            <option value="App Development">App Development</option>
                                            <option value="Enterprise Software Development">Enterprise Software Development</option>
                                            <option value="Consulting">Consulting</option>
                                            <option value="Marketing">Marketing</option>
                                            <option value="Dedicated Teams">Dedicated Teams</option>
                                            <option value="Dedicated Teams">Other</option>
                                        </select>
                                        <div class="s_error" *ngIf="!contactForm.controls['reason'].valid && (contactForm.controls['reason'].dirty || contactForm.controls['reason'].touched || contactSubmit)">
                                            <div class="s_validation" *ngIf="contactForm.controls['reason'].hasError('required')">
                                                Choose an Option From Above
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <textarea rows="5" placeholder="Please describe your requirements " formControlName="requirement" class="form-control"></textarea>
                                        
                                        <div class="s_error" *ngIf="!contactForm.controls['requirement'].valid && (contactForm.controls['requirement'].dirty || contactForm.controls['requirement'].touched || contactSubmit)">
                                            <div class="s_validation" *ngIf="contactForm.controls['requirement'].hasError('required')">
                                                Provide Your Requirements
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button>Let's talk</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </div>
</section>

<section class="why-gitex-wrapper">
    <div class="gitex-left mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-12 col-md-4" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/gitex-2020-bt.png" class="img-fluid" alt="why-gitex-img">
                </div>
                <div class="col-12 col-md-8">
                    <div class="gitex-content">
                    
                        <h1 class="cmn-heading">Why visit GITEX?</h1>
                        <h5><b>Are you ready for the biggest tech show of the year?</b></h5>
                        <p>
                            GITEX is one of the biggest regional technology events with over 3000+ tech enterprises from 100+ countries and credibility with 41 years of legacy. The theme for the GITEX 2021 event is centered on “Creating a bolder digital future together”.

                        </p>
                        <p>
                            This year, the event will bring together global industry leaders, start-ups and game-changers to share and exchange powerful insights into how technologies such as AI, big data, 5G, cloud, cybersecurity, blockchain and quantum computing are shaping the future. Book your seats for the technology event of the year.
                        </p>
                        <p>
                            Are you searching for the best software development firm to develop unique solutions and understand your unique business requirements? Then come visit Techware Lab at CC2-18, Kerala IT Pavilion, Concourse 2 from 17 to 21 October, 2021.
                        </p>
                        <button (click)="scrollToElement(gitexForm)">Let’s Connect</button>
                        <!-- <a routerLink="./" fragment="gitexForm">Go there</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="our-expertise">
    <div class="container">
        <div class="col-12-md">
            <div class="expertise-text text-center">
                <h1 class="cmn-heading">Our Expertise</h1>
                <h5>
                    Techware Lab offers hands-on, specialized solutions for businesses with a <br/>
                        personalized approach that brings your business vision to life. 
                </h5>
            </div>
        </div>
        <div class="col-md-12">
            <div class="points mt-md-4 mb-md-4">
               <ul class="d-flex">
                   <li><h3>2,400 Clients <span class="slash"></span> </h3></li><hr>
                   <li><h3>400+ Projects <span class="slash"></span></h3></li><hr>
                   <li><h3>50+ Employees<span class="slash"></span></h3></li><hr>
                   <li><h3>25 Countries</h3></li>
               </ul>
            </div>
        </div>
    </div>
</section>





<section class="service-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Services offerings</h1>

        <h5>We offer a diverse range of services designed to find a new <br/>
            competitive edge for your business. </h5>

        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="service-box">
                    <img src="/assets/images/ser-1.png" alt="Service Icon"  class="img-fluid img-ser-one">
                    <p>Web App Development</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="service-box">
                    <img src="/assets/images/ser-2.png" class="img-fluid img-ser-two" alt="Service Icon">
                    <p>Mobile Development</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="service-box">
                    <img src="/assets/images/ser-6.png" class="img-fluid img-ser-three" alt="Service Icon">
                    <p>Ecommerce Development</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="service-box">
                    <img src="assets/images/ser-4.png" class="img-fluid img-ser-four" alt="Service Icon">
                    <p>Business Consulting</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="service-box">
                    <img src="/assets/images/ser-5.png" class="img-fluid img-ser-five" alt="Service">
                    <p>Proof-of-Concept and Prototyping</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="service-box">
                    <img src="/assets/images/ser-3.png" class="img-fluid img-ser-six" alt="Service">                   
                     <p>Digital Marketing</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="service-box">
                    <img src="/assets/images/buisness.png" class="img-fluid img-ser-sevn" alt="Service Icon">
                    <p>Business Consulting</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="service-box">
                    <img src="/assets/images/artificial-img.png" class="img-fluid img-ser-eigt" alt="Service Icon">
                    <p>Artificial Intelligence</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="service-box">
                    <img src="/assets/images/cyber-img.png" class="img-fluid img-ser-nine" alt="Service Icon">
                    <p>Cybersecurity</p>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>


<section class="two-box">
   <div class="container">
    <div class="row no-gutters">
        <div class="col-12 col-md-6">
            <div class="box-one">
                <h3>Staff Augmentation</h3>
                <h5>Hire dedicated IT specialists that will <br/>solve your problems in a short time.</h5>
                <div class="arrow-img">
                    <img src="/assets/images/pink-arrow.png" (click)="scrollToElement(gitexForm)" class="img-fluid" alt="arrow-img">
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="box-two">
                <h3>Partner With Us</h3>
                <h5>Partner with us to develop exclusive <br/>digital solutions.</h5>
                <div class="arrow-img">

                    <img src="/assets/images/pink-arrow.png" (click)="scrollToElement(gitexForm)" class="img-fluid" alt="arrow-img">

                </div>

            </div>
        </div>
    </div>
   </div>
</section>


<section class="industries-wrapper">
    <div class="container">
        <h1 class="cmn-heading"> Industries We Serve</h1>
        <owl-carousel-o [options]="customOptionsTwo">
            <ng-template carouselSlide>
                <div class="industry-box">
                    <img src="/assets/images/ind-one.png" class="img-fluid img-ind-one" alt="Images">
                    <p>Public Sector</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="industry-box">
                    <img src="/assets/images/ind-two.png" class="img-fluid img-ind-two" alt="Images">
                    <p>Healthcare</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="industry-box">
                    <img src="/assets/images/ind-three.png" class="img-fluid img-ind-three" alt="Images">
                    <p>Enterprise</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="industry-box">
                    <img src="/assets/images/ind-four.png" class="img-fluid img-ind-four" alt="Images">
                    <p>Banking & Finance </p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="industry-box">
                    <img src="/assets/images/ind-five.png" class="img-fluid img-ind-five" alt="Images">
                    <p>Hospitality </p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="industry-box">
                    <img src="/assets/images/ind-six.png" class="img-fluid img-ind-six" alt="Images">
                    <p>Retail & Ecommerce </p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="industry-box">
                    <img src="/assets/images/ind-sev.png" class="img-fluid img-ind-sev" alt="Images">
                    <p>Travel & Tourism</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="industry-box">
                    <img src="/assets/images/ind-eigt.png" class="img-fluid img-ind-eigt" alt="Images">
                    <p>Food & Beverage</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="industry-box">
                    <img src="/assets/images/ind-nine.png" class="img-fluid img-ind-nine" alt="Images">
                    <p>Transport & Logistic</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="industry-box">
                    <img src="/assets/images/ind-ten.png" class="img-fluid img-ind-ten" alt="Images">
                    <p>On-Demand Delivery</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="industry-box">
                    <img src="/assets/images/ind-elv.png" class="img-fluid img-ind-elv" alt="Images">
                    <p>Social &  Communication</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="industry-box">
                    <img src="/assets/images/ind-tw.png" class="img-fluid img-ind-tw" alt="Images">
                    <p>Education & Elearning</p>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>


<section class="buiseness-solutions-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Customized Business Solutions</h1>
        <h5 >We design, develop, and scale a diverse range of digital products across all industries. 
           <br/> Take a look at our feature-rich, white-label applications below.</h5>
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="related-box">
                    <img src="/assets/images/solution-img-one.png" class="img-fluid project-image-one" alt="Taxi booking software">
                    <p>Taxi Booking</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/doctor-appointment-app">
                    <img src="/assets/images/solution-img-two.png" class="img-fluid project-image-three" alt="Taxi booking software">
                    <p>Doctor Booking</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/taxi-management-system">
                    <img src="/assets/images/solution-img-three.png" class="img-fluid project-image-two" alt="Taxi booking software">
                    <p>Lawyers Booking</p>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/taxi-dispatch-software">
                    <img src="/assets/images/product-4.webp" class="img-fluid project-image-four" alt="Taxi booking software">
                    <p>Taxi Dispatch Software</p>                
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box">
                    <img src="/assets/images/product-5.webp" class="img-fluid project-image-five" alt="Taxi booking software">
                    <p>Grocery Delivery</p>                
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box">
                    <img src="/assets/images/product-6.webp" class="img-fluid project-image-six" alt="Taxi booking software">
                    <p>Food Delivery</p>                
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box">
                    <img src="/assets/images/product-7.png" class="img-fluid project-image-sev" alt="Taxi booking software">
                    <p>Car Rental</p>                
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box">
                    <img src="/assets/images/product-8.png" class="img-fluid project-image-eigt" alt="Taxi booking software">
                    <p>Beauty Salon Solution</p>                
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box">
                    <img src="/assets/images/product-9.png" class="img-fluid project-image-nine" alt="Taxi booking software">
                    <p>Movie Ticket Booking</p>                
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box">
                    <img src="/assets/images/product-10.webp" class="img-fluid project-image-ten" alt="Taxi booking software">
                    <p>Booking App for Mechanics</p>                
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box">
                    <img src="/assets/images/product-11.png" class="img-fluid project-image-elv" alt="Taxi booking software">
                    <p>Online Match Making</p>                
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box">
                    <img src="/assets/images/product-12.png" class="img-fluid project-image-tw" alt="Taxi booking software">
                    <p>Employee Attendance and Payroll App</p>                
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>




<section class="brands-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Trusted By <br/>
            Leading Brands</h1>

        <owl-carousel-o  [options]="customOptionsOne">
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-one.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-two.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-three.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-four.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-eigt.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-nine.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-logo-five.png" class="img-fluid brand-img-one" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-logo-four.png" class="img-fluid brand-img-two" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-three.png" class="img-fluid brand-img-three" alt="Images">
                    
                </div>
            </ng-template>
       
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-five.png" class="img-fluid brand-img-four" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-six.png" class="img-fluid brand-img-five" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-sev.png" class="img-fluid brand-img-six" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-ten.png" class="img-fluid brand-img-seven" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-elv.png" class="img-fluid brand-img-eigt" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-tw.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            
           
        </owl-carousel-o>
      
    </div>
</section>

<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Bring your big idea to market with our team of designers, developers, and business strategists.
                </h1>
                <h2>Book your FREE 30 minutes consultation with us and get your 
                    project moving!</h2>
                <button (click)="scrollToElement(gitexForm)">Talk to our Experts</button>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid px-0">
    <div class="copyright mt-3 py-3 text-center">
        <p class="m-0">©   2021 Techware Lab. All rights reserved.</p>
    </div>
</div>











