import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { WebService } from "../../providers/web.service";
import { HelperService } from "../../providers/helper.service";
import { MessageSuccessComponent } from "../message-success/message-success.component";
import * as countries from "./../contact/countries.json";

declare var $: any;

@Component({
  selector: 'app-education-popup',
  templateUrl: './education-popup.component.html',
  styleUrls: ['./education-popup.component.scss']
})
export class EducationPopupComponent implements OnInit {
  submitForm;
  countryList: any = new Array();
  contactSubmit: boolean;
  loader: boolean;
  constructor(private model: MatDialog, private dialogRef: MatDialogRef<EducationPopupComponent>, public service: WebService, public helper: HelperService) {
    this.contactSubmit = false;
    this.countryList = countries;
    this.loader = false;
  }

  ngOnInit(): void {
    this.submitForm=new FormGroup({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
      ]),
      country: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
      message: new FormControl("", [Validators.required]),
      interest: new FormControl("Education ERP(Ads)", [Validators.required])
    });
  }
  submitFormSubmit() {
    this.contactSubmit = true;
    if (this.submitForm.valid) {
      this.loader = true;
      let userObj = this.submitForm.value;
      this.service.post_data("educationFormData", "", userObj).subscribe(
        (response) => {
          if (response && response.status && response.status == 200) {
            this.model.open(MessageSuccessComponent);
          } else {
            this.helper.showAlert(4, "Something went wrong");
          }
          this.submitForm.reset({
            name: "",
            email: "",
            country: "0",
            phone: "",
            message: ""
          });
          this.contactSubmit = false;
          this.loader = false;
        },
        (error) => {
          this.helper.showAlert(4, "Something went wrong");
          this.contactSubmit = false;
          this.loader = false;
        }
        
      );
    }
  }

  closeChatDialog() {
    this.dialogRef.close();
  }

}
