import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plain-banner',
  templateUrl: './plain-banner.component.html',
  styleUrls: ['./plain-banner.component.scss']
})
export class PlainBannerComponent {
  @Input() title: string = '';
  @Input() buttonText: string = '';
}
