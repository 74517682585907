<section class="header-banner">
    <div class="container">
        <div class="text-content">
            <h1>Website Development</h1>  
            <h5>Full-Stack Web Development for Enterprises</h5>  
            <h6>
                We build more than just websites. We build websites that are<br/>
                 designed to create an exceptional user experience.
            </h6>
            <button routerLink="/products">View our latest projects</button>
        </div>
    </div>
</section>



<section class="techware-wrapper">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col-md-5 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/tech-img.webp" class="img-fluid" alt="Cutting edge technologies">
            </div>
            <div class="col-md-7">
                <div class="help-box mt-4 mt-md-0">
                    <h1 class="cmn-heading">
                        We’re Techware Lab.We Craft  <br/>Affordable and Engaging User <br/> Experiences Online
                        </h1>
                    <p>
                        For years we’ve built high-performance websites and website applications with trending technologies and frameworks. Our team of designers and developers have diligently served organizations looking to achieve digital transformation and excellence.
                    </p>
               
                </div>
            </div>
        </div>
    </div>
</section>



<section class="brands-wrapper">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Who We Work With </h1>
                <h2 class="cmn-sub-heading">See other companies who have partnered with Techware Lab for website design<br/> and development services.</h2>
            </div>
        </div>

        <owl-carousel-o  [options]="customOptionsOne">
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-one.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-two.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-three.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-four.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-eigt.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-nine.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-logo-five.png" class="img-fluid brand-img-one" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-logo-four.png" class="img-fluid brand-img-two" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-three.png" class="img-fluid brand-img-three" alt="Images">
                    
                </div>
            </ng-template>
       
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-five.png" class="img-fluid brand-img-four" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-six.png" class="img-fluid brand-img-five" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-sev.png" class="img-fluid brand-img-six" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-ten.png" class="img-fluid brand-img-seven" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-elv.png" class="img-fluid brand-img-eigt" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-tw.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
           
        </owl-carousel-o>
    </div>
</section>




<section class="technology-wrapper">
    <div class="container">
        <div class="row mb-5">
            <div class="col-md-5 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/technology-img.webp" class="img-fluid" alt="Cutting edge technologies">
            </div>
            <div class="col-md-7">
                <div class="text-box mt-4 mt-md-0">
                    <h1 class="cmn-heading">
                        Design & Development for<br/> All Digital Devices                        </h1>
                    <p>
                        We ensure that all of our clients deliver seamless web experiences compatible with all digital devices and software. 
                    </p>
               
                </div>
            </div>
        </div>
    </div>
</section>


<section class="device-wrapper ">
    <div class="container">
        <div class="row mb-4">
            <div class="col-md-3">
                <div class="device-box">
                    <img src="/assets/images/device-1.png" class="img-one" alt="Techware-team">
                    <h5>Mobile</h5>
                </div>
            </div>
           
            <div class="col-md-3">
                <div class="device-box ">
                    <img src="/assets/images/device-2.png" class="img-two" alt="Techware-team">
                    <h5>Tablet</h5>
                </div>
            </div>
           
           
            <div class="col-md-3">
                <div class="device-box ">
                    <img src="/assets/images/device-3.png" class="img-three" alt="Techware-team">
                    <h5>Desktop</h5>
                </div>
            </div>
           
           
            <div class="col-md-3">
                <div class="device-box-last">
                    <img src="/assets/images/device-4.png" class="img-four" alt="Techware-team">
                    <h5>Laptop</h5>
                </div>
            </div>
        </div>
    </div>
</section> 



<section class="design-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Web Design & Development Services</h1>
                <h2 class="cmn-sub-heading ">Increase the performance of your website with our wide spectrum of service offerings built to help you top your sales goals and lead generation initiatives.</h2>
           
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/design-img.webp" class="img-fluid side-img" alt="Mobile app development company">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-5 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Web Design 
                                         </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        Get a beautiful and interactive website that will offer a seamless customer experience while making you stand out against the competition.
                                       </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Web Development Services                                        
                                         </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        We build the website using programming languages like HTML, CSS, and JavaScript. Our developers ensure the website is functional, easy to navigate, and works across different devices.                                       </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Website maintenance 
                                                                                </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        Our service will also offer ongoing maintenance and support for the website. This may include security updates, bug fixes, and regular backups.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            UX/UI Design                                         </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        Have design recommendations that will help your enterprise achieve record-breaking customer engagement and retention, as well as improve team performance.                                    </div>
                                </div>
                            </div>
                            <!-- <div class="card">
                                <div class="card-header" id="headingFive">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Digital Marketing 
                                                                                </button>
                                    </h5>
                                </div>
                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                    <div class="card-body">
                                        Develop a killer online digital marketing strategy and align it with your organizational goals when you work with our experts.
                                    </div>
                                </div>
                            </div> -->
                            <div class="card">
                                <div class="card-header" id="headingSix">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            Web Automation and Responsive Testing 
                                                                                                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                    <div class="card-body">
                                        Automation and responsive testing is conducted by our team to analyze your website’s existing performance and provide recommendations to pave a design route for your website’s optimization strategy.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingSeven">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            SaaS Product Development
                                                                                </button>
                                    </h5>
                                </div>
                                <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                    <div class="card-body">
                                        We help you create a solid SaaS development plan for your digital products and help you develop a quality and consistent product flow.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>





<section class="approach-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Our Approach</h1>
                <h2 class="cmn-sub-heading">We offer assistance at every stage of your website development process.</h2>
            </div>
        </div>
        <div class="row mb-md-5">
            <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box blue">
                    <img src="/assets/images/approach-1.png" alt="Service Icon">
                    <h3>Research and Discovery 
                    </h3>
                    <p>Our team will conduct the appropriate research about your business to gauge an overall vision and direction for your website development plan</p>
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="250">
                <div class="approach-box red">
                    <img src="/assets/images/approach-2.png" alt="Service Icon">
                    <h3>Process Development 
                    </h3>
                    <p>We enter planning mode and decipher a compatible development process that aligns with your business values and modern design trends.</p>
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box red">
                    <img src="/assets/images/approach-3.png" alt="Service Icon">
                    <h3>Delivery 
                    </h3>
                    <p>Our experts will implement your website development plan, doubling down on structure, design, and customer deliverables—then we test it all.</p>
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="50">
                <div class="approach-box yellow">
                    <img src="/assets/images/approach-4.png" alt="Service Icon">
                    <h3>Ongoing Support</h3>
                    <p>Even when the job is done, we are not. After discovering the results of your website development process, we work with you to reiterate and strategize for greater results.</p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="framework-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Website Development Frameworks <br/> We Support</h1>
        <owl-carousel-o [options]="customOptionsTwo">
            <ng-template carouselSlide>
                <div class="frame-box">
                    <img src="/assets/images/frame-logo-1.png" class="img-fluid img-frame-one" alt="Images">
                  
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="frame-box">
                    <img src="/assets/images/shopify.png" class="img-fluid img-frame-two" alt="Images">
                 
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="frame-box">
                    <img src="/assets/images/bootstrap.png" class="img-fluid img-frame-three" alt="Images">
                   
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="frame-box">
                    <img src="/assets/images/Angular.png" class="img-fluid img-frame-four" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="frame-box">
                    <img src="/assets/images/magento.png" class="img-fluid img-frame-five" alt="Images">
                </div>
            </ng-template>
            
        </owl-carousel-o>
    </div>
</section>




<section class="technologies-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Technologies</h1>
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/technology-image-1.png" class="img-fluid" alt="Mobile app development company">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-5 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                             Backend technologies
                                         </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>Node.js</li>
                                            <li>PHP</li>
                                            <li>.NET</li>
                                            <li>Python</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Frontend technologies
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>Angular</li>
                                            <li>ReactJS</li>
                                            <li>Vue.js</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Database technologies
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>MSSQL</li>
                                            <li>MySQL</li>
                                            <li>MongoDB</li>
                                            <li>Postgres</li>
                                            <li>Firestore</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Mobile technologies
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>SWIFT</li>
                                            <li>Kotlin</li>
                                            <li>Java</li>
                                            <li>Flutter</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
</section>




<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Achieve Your Outcomes With Turnkey<br/> 
                    Web Development</h1>
                <h2 class="cmn-sub-heading">We’re always looking for our next challenge. Bring your big idea to market with our team of<br/> designers, developers, and business strategists.</h2>
                <button routerLink="/contact"> Let's get started</button>
            </div>
        </div>
    </div>
</section>

