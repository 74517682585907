import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Custom Software & Mobile App Solutions | Techware Lab "
    );
    this.meta.updateTag({
      name: "description",
      content:
        "At Techware Lab, we design, develop, and scale a diverse range of digital products and custom software solutions across all industries. Take a look at our feature-rich, white label applications.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "At Techware Lab, we design, develop, and scale a diverse range of digital products and custom software solutions across all industries. Take a look at our feature-rich, white label applications.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Custom Software & Mobile App Solutions | Techware Lab ",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "At Techware Lab, we design, develop, and scale a diverse range of digital products and custom software solutions across all industries. Take a look at our feature-rich, white label applications.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/products",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Custom Software & Mobile App Solutions | Techware Lab ",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "At Techware Lab, we design, develop, and scale a diverse range of digital products and custom software solutions across all industries. Take a look at our feature-rich, white label applications.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Custom Software & Mobile App Solutions | Techware Lab ",
    });
  }


  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 


}
