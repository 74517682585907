import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef,MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { WebService } from "../../providers/web.service";
import { HelperService } from "../../providers/helper.service";
import { MessageSuccessComponent } from "../message-success/message-success.component";


declare var $: any;

@Component({
  selector: 'app-join-our-team-quote',
  templateUrl: './join-our-team-quote.component.html',
  styleUrls: ['./join-our-team-quote.component.scss']
})
export class JoinOurTeamQuoteComponent implements OnInit {
  contactForm:FormGroup;
  countryList: any = new Array();
  contactSubmit: boolean;
  loader: boolean;
  imageData:File;
  formData2:FormData;
  file: any;
  fileName: string;
  @ViewChild('joinForm') joinForm: any

  constructor(private model: MatDialog,private dialogRef: MatDialogRef<JoinOurTeamQuoteComponent>,public service: WebService, public helper: HelperService) { 
    this.contactSubmit = false;
    this.loader = false;
  }

  ngOnInit(): void {
    this.formData2 = new FormData(this.joinForm)
    this.contactForm = new FormGroup({
      reason: new FormControl("",[Validators.required]),
      name: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}$"),
      ]),
      
      highestQualification: new FormControl("", [Validators.required]),
      totalExperience: new FormControl("", [Validators.required]),
      currentSalary: new FormControl("", [Validators.required]),
      expectedSalary: new FormControl("", [Validators.required]),
      noticePeriod: new FormControl("", [Validators.required]),
      requirement: new FormControl(""),
      file: new FormControl("", [Validators.required]),
      areaIcon: new FormControl("")

    });
  }

  onFileChange(event:any){
    this.changeListener(event)
    if (!event.target.files || !event.target.files[0]) {
      return false;
    }
    this.imageData = event.target.files[0];
  }
  public changeListener(event){
    this.file = event.target.files[0];
    this.fileName = event.target.files[0].name;
    this.contactForm.get('areaIcon').setValue(this.fileName)
  }
  clearBtn(){
    this.contactForm.reset({
      reason: "",
      name: "",
      phone: "",
      email: "",
      highestQualification: "",
      totalExperience: "",
      currentSalary: "",
      expectedSalary: "",
      noticePeriod: "",
      requirement: "",
      file: "",
    });
  }

  contactFormSubmit() {
    this.contactSubmit = true    
    if (this.contactForm.valid) {
      this.loader = true;
      let userObj = this.contactForm.value;
      const formData = new FormData();
      for( const key in userObj){
        formData.append(key,userObj[key])
      }
      console.log(this.imageData);
      if(this.imageData) {
        formData.append("file",this.imageData, this.imageData.name)
      }      
      
      this.service.post_data("joinOurTeamQuoteContactForm", "", formData).subscribe(
        (response) => {
          if (response && response.status && response.status == 200) {
            this.model.open(MessageSuccessComponent);
            this.closeChatDialog()
          } else {
            this.helper.showAlert(4, "Something went wrong");
          }
          this.contactForm.reset({
            reason: "",
            name: "",
            phone: "",
            email: "",
            highestQualification: "",
            totalExperience: "",
            currentSalary: "",
            expectedSalary: "",
            noticePeriod: "",
            requirement: "",
            file: "",
          });
          this.contactSubmit = false;
          this.loader = false;
        },
        (error) => {
          this.helper.showAlert(4, "Something went wrong");
          this.contactSubmit = false;
          this.loader = false;
        }
      );
    }
  }

  closeChatDialog() {
    this.dialogRef.close();
  }

}
