import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from 'src/providers/helper.service';
import { WebService } from 'src/providers/web.service';
import { SeoService } from '../seo.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageSuccessComponent } from '../message-success/message-success.component';
import { BlogDetailsService } from 'src/providers/blog-details.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  /** variables */
  blogList: any[] = new Array();
  search: any;
  pIndex: number = 0;
  pSize: number = 10;
  // dataSize: number[] = [10, 20, 100, 200];
  isLoading: boolean = false;
  throttle = 0;
  distance = 2;
  page = 1;
  totalPages: any;
  subscribeForm;
  subscribeFormSubmit: boolean;

  constructor(
    private title: Title,
    private meta: Meta,
    private model: MatDialog,
    private seoService: SeoService,
    public service: WebService,
    public helper: HelperService,
    private blogService: BlogDetailsService
  ) {}

  ngOnInit(): void {
    this.fetchBlogList();
    this.subscribeForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
      ]),
    });

    this.createLinkForCanonicalURL();

    this.title.setTitle('Blog | Techware Lab');
    // this.meta.updateTag({
    //   name: "description",
    //   content:
    //     "Keep yourself updated with our blogs that offer the latest news, technologies, updates related to enterprise mobility solutions, IoT, web development, and more.",
    // });
    // this.meta.updateTag({
    //   property: "og:description",
    //   content:
    //     "Keep yourself updated with our blogs that offer the latest news, technologies, updates related to enterprise mobility solutions, IoT, web development, and more.",
    // });
    // this.meta.updateTag({
    //   property: "og:image",
    //   content:
    //     "https://techwarelab.com/assets/images/og_logo.png",
    // });
    // this.meta.updateTag({
    //   property: "og:title",
    //   content:
    //     "Latest Software, IoT & Mobile App Blogs - Techware Lab",
    // });
    // this.meta.updateTag({
    //   property: "og:description",
    //   content:
    //     "Keep yourself updated with our blogs that offer the latest news, technologies, updates related to enterprise mobility solutions, IoT, web development, and more.",
    // });
    // this.meta.updateTag({
    //   property: "og:url",
    //   content:
    //     "https://techwarelab.com/blog",
    // });
    // this.meta.updateTag({
    //   name: "twitter:image",
    //   content:
    //     "https://techwarelab.com/assets/images/og_logo.png",
    // });
    // this.meta.updateTag({
    //   name: "twitter:title",
    //   content:
    //     "Latest Software, IoT & Mobile App Blogs - Techware Lab",
    // });
    // this.meta.updateTag({
    //   name: "twitter:description",
    //   content:
    //     "Keep yourself updated with our blogs that offer the latest news, technologies, updates related to enterprise mobility solutions, IoT, web development, and more.",
    // });
    // this.meta.updateTag({
    //   name: "twitter:domain",
    //   content:
    //     "Latest Software, IoT & Mobile App Blogs - Techware Lab",
    // });
  }
  fetchBlogList() {
    this.isLoading = true;
    this.blogService.getBlogs(this.pIndex, this.pSize, this.search).subscribe(
      (data) => {
        this.totalPages = data?.page?.totalPages || 0;
        if (this.pIndex == 0) {
          console.log('in pIndex 0');

          this.blogList = data?.data;
        } else {
          console.log('in pIndex not in 0');
          this.blogList = [...this.blogList, ...data?.data];
        }

        // console.log('Fetching Data:', this.blogList);
      },

      (error) => {
        console.error('Error Fetching Data:', error);
      }
    );
  }
  onScroll() {
    if (this.totalPages === this.pIndex) {
      return false;
    }
    this.pIndex++;
    // this.pSize += 10;
    this.fetchBlogList();
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  subscribeBox() {
    this.subscribeFormSubmit = true;
    if (this.subscribeForm.valid) {
      const email = this.subscribeForm.value;
      this.service.post_data('subscribeBoxMailIdTechlab', '', email).subscribe(
        (response) => {
          if (response && response.status && response.status == 200) {
            this.model.open(MessageSuccessComponent);
          } else {
            this.helper.showAlert(4, 'Already Subscribed');
          }
          this.subscribeForm.reset({
            email: '',
          });
          this.subscribeFormSubmit = false;
        },
        (error) => {
          this.helper.showAlert(4, 'Something went wrong');
          this.subscribeFormSubmit = false;
        }
      );
    }
  }
}
