import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SeoService } from '../seo.service';

@Component({
    selector: 'app-web-development',
    templateUrl: 'web-development.component.html',
    styleUrls: ['./web-development.component.scss']
})

export class WebDevelopmentComponent implements OnInit {

    customOptionsOne: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
          0: {
            items: 1
          },
          400: {
            items: 2
          },
          740: {
            items: 2
          },
          940: {
            items: 4
          }
        },
        nav: true
      }


      customOptionsTwo: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
          0: {
            items: 1
          },
          400: {
            items: 3
          },
          740: {
            items: 3
          },
          940: {
            items: 5
          }
        },
        nav: true
      }
    
    constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

    ngOnInit() { 

      this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Top Web App Development Company | TechwareLab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Best web development company in India, UAE and USA offers the best web app development services and website design for your enterprises & startups with experienced developers. ",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Best web development company in India, UAE and USA offers the best web app development services and website design for your enterprises & startups with experienced developers. ",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/ser-1.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Top Web App Development Company | TechwareLab",
    });
    this.meta.updateTag({
      property: "og:description", 
      content:
        "Best web development company in India, UAE and USA offers the best web app development services and website design for your enterprises & startups with experienced developers. ",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/web-app-development",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/ser-1.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Top Web App Development Company | TechwareLab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Best web development company in India, UAE and USA offers the best web app development services and website design for your enterprises & startups with experienced developers. ",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Top Web App Development Company | TechwareLab",
    });
    }


    createLinkForCanonicalURL() {
      this.seoService.createLinkForCanonicalURL();
    } 
}