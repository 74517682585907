<section class="contact-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>Turning big ideas into <br> success stories
                </h1>
                <h2 class="cmn-sub-heading">At Techware Lab, we’re passionate about driving growth with innovative technologies and an agile approach. We’re all about starting small, scaling fast, and achieving more.</h2>
            </div>
            <div class="col-md-12">
                <h4><span>Got an idea?</span> Get in touch with us by filling out our form below.<br> We’re ready for your next challenge. </h4>
                <button><img src="/assets/images/arrow-down.png" alt="Arrow Down"></button>
            </div>
        </div>
    </div>
</section>

<section class="contact-content-wrapper">
    <div class="container">
        <div *ngIf="loader" class="common_loader">
            <img src="assets/images/loader.gif">
        </div>
        <div class="row">
            <div class="col-md-6">
                <h1 class="cmn-heading">Get in touch</h1>
                <!-- Zoho CRM form script -->
                <div  id="zoho-form" class="zoho-form-wrapper w-100" #zohoFormContainer>

                    <iframe *ngIf="submitedForm" class="iframeClass" id="zohoIframe" src="about:blank" width="100%" height="700" #zohoIframe></iframe>

                </div>
                <!-- <form [formGroup]="contactForm" (ngSubmit)="contactFormSubmit()"> -->
                    <!-- <div class="form-group">
                        <div class="row">
                            <div class="col-md-11">
                                <input type="text" formControlName="name" class="form-control" placeholder="Name">
                            </div>
                            <div class="col-md-1 star">*</div>
                        </div>
                        <div class="s_error" *ngIf="!contactForm.controls['name'].valid && (contactForm.controls['name'].dirty || contactForm.controls['name'].touched || contactSubmit)">
                            <div class="s_validation" *ngIf="contactForm.controls['name'].hasError('required')">
                                Provide a Name
                            </div>
                            <div class="s_validation" *ngIf="contactForm.controls['name'].hasError('pattern')">Provide a Name</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-11">
                                <input type="email" formControlName="email" class="form-control" placeholder="Email">
                            </div>
                            <div class="col-md-1 star"> * </div>
                        </div>
                        <div class="s_error" *ngIf="!contactForm.controls['email'].valid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched || contactSubmit)">
                            <div class="s_validation" *ngIf="contactForm.controls['email'].hasError('required')">Provide an Email</div>
                            <div class="s_validation" *ngIf="contactForm.controls['email'].hasError('pattern')">Provide a Valid Email Id</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-11">
                                <select class="form-control" formControlName="country">
                                    <option disabled value="0" [selected]="true">Select Country</option>
                                    <option *ngFor="let contry of countryList.default">
                                    {{contry.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-1 star"> * </div>
                        </div>
                        <div class="s_error" *ngIf="!contactForm.controls['country'].valid && (contactForm.controls['country'].dirty || contactForm.controls['country'].touched || contactSubmit)">
                            <div class="s_validation" *ngIf="contactForm.controls['country'].hasError('required')">
                                Choose Country
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-11">
                                <input type="number" formControlName="phone" class="form-control" placeholder="Phone" onkeydown="javascript: return event.keyCode == 69 ? false : true">
                            </div>
                            <div class="col-md-1 star"> * </div>
                        </div>
                        <div class="s_error" *ngIf="!contactForm.controls['phone'].valid && (contactForm.controls['phone'].dirty || contactForm.controls['phone'].touched || contactSubmit)">
                            <div class="s_validation" *ngIf="contactForm.controls['phone'].hasError('required')">
                                Provide a Phone number
                            </div>
                            <div class="s_validation" *ngIf="contactForm.controls['phone'].hasError('pattern')">Provide a Phone number</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-11">
                                <select class="form-control" formControlName="reason">
                                    <option disabled value="0" [selected]="true">What are you looking for?</option>
                                    <option value="App Development">App Development</option>
                                    <option value="Enterprise Software Development">Enterprise Software Development</option>
                                    <option value="Consulting">Consulting</option>
                                    <option value="Marketing">Marketing</option>
                                    <option value="Dedicated Teams">Dedicated Teams</option>
                                    <option value="Dedicated Teams">Other</option>
                                </select>
                            </div>
                            <div class="col-md-1 star"> * </div>
                        </div>
                        <div class="s_error" *ngIf="!contactForm.controls['reason'].valid && (contactForm.controls['reason'].dirty || contactForm.controls['reason'].touched || contactSubmit)">
                            <div class="s_validation" *ngIf="contactForm.controls['reason'].hasError('required')">
                                Choose an Option From Above
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="form-group">
                        <select class="form-control" formControlName="budget">
                            <option disabled value="0" [selected]="true">Your budget</option>
                            <option value="5K - 25K USD">5K - 25K USD</option>
                            <option value="25K - 50K USD">25K - 50K USD</option>
                            <option value="50K - 100K USD">50K - 100K USD</option>
                            <option value="100k+ USD">100k+ USD</option>
                        </select>
                        <div class="s_error" *ngIf="!contactForm.controls['budget'].valid && (contactForm.controls['budget'].dirty || contactForm.controls['budget'].touched || contactSubmit)">
                            <div class="s_validation" *ngIf="contactForm.controls['budget'].hasError('required')">
                                Choose a Budget
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="form-group">
                        <div class="row">
                            <div class="col-md-11">
                                <textarea class="form-control" rows="5" style="height: auto;" formControlName="requirement" placeholder="Please describe your requirements"></textarea>
                            </div>
                            <div class="col-md-1 star"> * </div>
                        </div>
                        <div class="s_error" *ngIf="!contactForm.controls['requirement'].valid && (contactForm.controls['requirement'].dirty || contactForm.controls['requirement'].touched || contactSubmit)">
                            <div class="s_validation" *ngIf="contactForm.controls['requirement'].hasError('required')">
                                Provide Your Requirements
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="form-group">
                        <ngx-recaptcha2 #captchaElem siteKey="6LfJslsaAAAAADOmYnec6RuzeNQyhfoHbTeZ4OiM" useGlobalDomain="false" size="size" hl="lang" theme="theme" type="type" formControlName="recaptcha">

                        </ngx-recaptcha2>
                    </div> -->
                    <!-- <div>
                        <button class="submit-btn">
                            Let's talk
                        </button>
                    </div> -->
                <!-- </form> -->
            </div>
            <div class="col-md-6 mt-md-0 mt-5">
                <div class="address-content">
                    <h2 class="red">India</h2>
                    <h3>TransAsia Cyber Park<br>Infopark Phase - II<br>Tower 1, 8th Floor<br>Ambalamedu P.O, Kochi<br>Kerala, India<br>682303</h3>
                    <ul class="whatsapp">
                        <li><a href="https://api.whatsapp.com/send/?phone=919567879002&text=Hello%21+Thanks+for+writing+to+Techware+Lab+-+the+leading+boutique+software+development+company.+We+have+delivered+our+web+and+mobile+solutions+and+custom+software+to+clients+across+the+world+since+2012.+How+can+we+help+you%3F&app_absent=0" target="_blank" class="phone">+91 95 678 79002</a></li>
                    </ul>
                </div>
                <div class="address-content">
                    <h2 class="yellow">UAE</h2>
                    <h3>3103 Latifa Towers<br>Sh. Zayed Road<br>PO Box 282893<br>Dubai, United Arab Emirates</h3>
                    <ul>
                        <li class="call-icon">+971 56 415 5430</li>
                    </ul>
                </div>
                <!-- <div class="address-content">
                    <h2 class="blue">Qatar Office</h2>
                    <h3>Techware Software Solutions Pvt Ltd, P.O<br> Box- 21479, 35 Al- Zagheen Street, Fereej </h3>
                    <ul>
                        <li>+91 9811869727,</li>
                        <li>+91 9811869727,</li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</section>