import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-doctor-appointment-app',
  templateUrl: './doctor-appointment-app.component.html',
  styleUrls: ['./doctor-appointment-app.component.scss']
})
export class DoctorAppointmentAppComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  constructor(private title: Title, private meta: Meta, private seoService: SeoService,private model: MatDialog, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {

    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is TDoc medical appointment scheduling software?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "TDoc provides patients with online booking based on doctors' real-time availability and offers a better experience for patients and their healthcare practitioners."
        }
      },{
        "@type": "Question",
        "name": "How can I build a doctor appointment app like Practo or Zocdoc?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We would need to build a mobile application and a web-based admin panel that includes all the features of a doctor appointment application. Every application is unique, and the technology stack would vary according to the requirements and features of the project."
        }
      },{
        "@type": "Question",
        "name": "How much does it cost to build a doctor appointment app?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "It's impossible to provide a direct quote without knowing the specifics of what your app needs. Cost is typically determined by what features are required, what functionality the platform needs, and turnaround time. A simple doctor appointment app may start at $5,000."
        }
      },{
        "@type": "Question",
        "name": "How do I make an appointment with a doctor on TDoc?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Patients can search doctors by specialty and book an appointment through the app. TDoc also provides scheduling service, providing free of charge medical care facilities for end users by integrating information about medical practices and doctor's individual schedules in a central location.
    
    The end user searchable database includes specialities, range of services, office locations, photographs, educational backgrounds, and more. For each doctor, users are able to review the free slots in the schedule and make appointments for those slots."
        }
      }]
    }
  `;
   
    this._renderer2.appendChild(this._document.body, script);

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Online Doctor Booking & Appointment Management Software"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Get the online medical doctor appointment scheduling software and booking management app system for clinics, doctors, hospitals that makes scheduling on-demand doctor’s appointments quick and easy.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Get the online medical doctor appointment scheduling software and booking management app system for clinics, doctors, hospitals that makes scheduling on-demand doctor’s appointments quick and easy.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Online Doctor Booking & Appointment Management Software",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Get the online medical doctor appointment scheduling software and booking management app system for clinics, doctors, hospitals that makes scheduling on-demand doctor’s appointments quick and easy.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/doctor-appointment-app",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Online Doctor Booking & Appointment Management Software",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Get the online medical doctor appointment scheduling software and booking management app system for clinics, doctors, hospitals that makes scheduling on-demand doctor’s appointments quick and easy.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Online Doctor Booking & Appointment Management Software",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 

  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }



}
