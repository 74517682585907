import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';


@Component({
    selector: 'app-partnership',
    templateUrl: 'partnership.component.html',
    styleUrls: ['./partnership.component.scss']
})

export class PartnershipComponent implements OnInit {
    constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

    ngOnInit(): void {

        this.createLinkForCanonicalURL();
    
        this.title.setTitle(
          "Partnership | Techware Lab"
        );
        this.meta.updateTag({
          name: "description",
          content:
            "Become a Techware Lab partner and grow your business faster than ever. We stay focused on creating value for our clients with ongoing support from us.",
        });
        this.meta.updateTag({
          property: "og:description",
          content:
            "Become a Techware Lab partner and grow your business faster than ever. We stay focused on creating value for our clients with ongoing support from us.",
        });
        this.meta.updateTag({
          property: "og:image",
          content:
            "https://techwarelab.com/assets/images/og_logo.png",
        });
        this.meta.updateTag({
          property: "og:title",
          content:
            "Partnership | Techware Lab",
        });
        this.meta.updateTag({
          property: "og:description",
          content:
            "Become a Techware Lab partner and grow your business faster than ever. We stay focused on creating value for our clients with ongoing support from us.",
        });
        this.meta.updateTag({
          property: "og:url",
          content:
            "https://techwarelab.com/partnership",
        });
        this.meta.updateTag({
          name: "twitter:image",
          content:
            "https://techwarelab.com/assets/images/og_logo.png",
        });
        this.meta.updateTag({
          name: "twitter:title",
          content:
            "Partnership | Techware Lab",
        });
        this.meta.updateTag({
          name: "twitter:description",
          content:
            "Become a Techware Lab partner and grow your business faster than ever. We stay focused on creating value for our clients with ongoing support from us.",
        });
        this.meta.updateTag({
          name: "twitter:domain",
          content:
            "Partnership | Techware Lab",
        });
      }
    
      createLinkForCanonicalURL() {
        this.seoService.createLinkForCanonicalURL();
      } 
}