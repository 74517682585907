<section class="career-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="circle-wrapper">
            <h1>
                TOffice: Office  <br/>
                Attendance and Payroll <br/>
                Management Solution
            </h1>
            <h2 class="cmn-sub-heading">
                Meet your personal secretary. From basic administrative tasks to complex HR requirements, TOffice handles all your attendance and payroll needs through one convenient platform.
            </h2>
            <a (click)="requestQuote()" target="_blank">Request a demo</a>
          </div>
        </div>
        <div class="col-md-5">
          <img
            src="/assets/images/toffice-img-1.png"
            class="img-fluid"
            alt="toffice"
          />
        </div>
      </div>
    </div>
</section>

<section class="whatistaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-5" data-aos="fade-right">
        <img
          src="/assets/images/toffice-img-2.png"
          class="img-fluid"
          alt="toffice"
        />
      </div>
      <div class="col-md-7">
        <div class="content">
          <h1 class="cmn-heading">What Is TOffice?</h1>
          <p>
            Are you running an office space and finding it difficult to keep track of all your employee records and payrolls? Stop waiting for the record to be sorted, and pick up an innovative new answer from Techware Lab. TOffice is an easy-to-use software suite for handling all your payroll and vacation/leave management needs.
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="50">
        <div class="taxi-box">
          <img
            src="/assets/images/toffice-icon-1.png"
            class="img-fluid"
            alt="toffice"
          />
          <h2>
            Go Paperless Has Never Been Easier
          </h2>
          <p>
            Forget slow and error-prone paper-based records. TOffice lets you handle complete administrative details entirely online so you don’t have to worry about getting left behind in the digital age. 
          </p>
        </div>
      </div>
      <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="150">
        <div class="taxi-box">
          <img
            src="/assets/images/toffice-icon-2.png"
            class="img-fluid"
            alt="toffice"
          />
          <h2>
            Accelerate Office Tasks
          </h2>
          <p>
            TOffice thrives by supporting systems like human resource management, employee payroll management, and employee compliance management. Intuitive and automatic processing software means you can go back to focusing on higher-priority tasks.
          </p>
        </div>
      </div>
      <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="250">
        <div class="taxi-box">
          <img
            src="/assets/images/toffice-icon-3.png"
            class="img-fluid"
            alt="toffice"
          />
          <h2>
            Enhance Human Resources Capabilities
          </h2>
          <p>
            Whether you’re a small or large organization, TOffice is the best-automated HR solution that will meet your unique office requirements. The app seamlessly organizes records of everything either over the web or on your mobile device.
          </p>
        </div>
      </div>
      <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="250">
        <div class="taxi-box">
          <img
            src="/assets/images/toffice-icon-4.png"
            class="img-fluid"
            alt="toffice"
          />
          <h2>
            Integrate All Your HR Tasks
          </h2>
          <p>
            Track, share, and evaluate your employees and activities with a transformational one-stop HR management software. TOffice uses a central system of records to integrate everything you do
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="ttaxi-features-wrapper">
    <div class="container">
      <div class="row mt-5">
        <div class="col-md-4">
          <h1 class="cmn-heading">TOffice Features</h1>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 order-md-0 order-1">
          <ul class="list-2">
            <li>
              <span>1</span>
              <h6>User Management</h6>
            </li>
            <li>
              <span>2</span>
              <h6>Role Management</h6>
            </li>
            <li>
              <span>3</span>
              <h6>Attendance Management</h6>
            </li>
            <li>
              <span>4</span>
              <h6>Profile Management</h6>
            </li>
            <li>
              <span>5</span>
              <h6>Leave Management</h6>
            </li>
            <li>
              <span>6</span>
              <h6>Report Management</h6>
            </li>
            <li>
              <span>7</span>
              <h6>Project Management</h6>
            </li>
          </ul>
        </div>
        <div class="col-md-6 order-md-1 order-0">
          <img
            src="/assets/images/toffice-img-4.png"
            class="img-fluid"
            alt="toffice"
          />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-5">
          <img
            src="/assets/images/toffice-img-5.png"
            class="img-fluid"
            alt="toffice"
          />
        </div>
        <div class="col-md-7">
          <h2 class="cmn-heading">Main Modules and Features</h2>
          <ul class="list-3">
            <li>
              <span>1</span>
              <h6>User Management</h6>
            </li>
            <li>
              <span>2</span>
              <h6>Daily Attendance Mark-in/Mark-out</h6>
            </li>
            <li>
              <span>3</span>
              <h6>Attendance Management</h6>
            </li>
            <li>
              <span>4</span>
              <h6>Work Status and Report Management</h6>
            </li>
            <li>
              <span>5</span>
              <h6>Role Management</h6>
            </li>
            <li>
              <span>6</span>
              <h6>Profile Management</h6>
            </li>
            <li>
              <span>7</span>
              <h6>Daily Feeds, Thoughts, Appreciations, 
                and Upcoming Events</h6>
            </li>
            <li>
              <span>8</span>
              <h6>Employee/User Evaluation and Project Management</h6>
            </li>
            <li>
              <span>9</span>
              <h6>Custom Attendance</h6>
            </li>
            <li>
              <span>10</span>
              <h6>Mark-in/Mark-out Features in Case of 
                System or Network Error</h6>
            </li>
            <button routerLink="/contact">Let’s get started</button>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="abouttaxi-wrapper">
    <div class="container">
      <div class="row pb-4">
        <div class="col-md-7 order-md-0 order-1">
          <div class="content">
            <h1 class="cmn-heading">About Techware Lab</h1>
            <p>
                Techware Lab provides expert design capabilities and software solutions that upgrade your business through industry-specific apps. The company’s technology-driven approach gives office managers and administrators the tools to optimize workflow and increase everyday productivity. Combining an unparalleled dedication to client satisfaction and office task optimization, TOffice makes employee payroll management and office administration a painless process.
            </p>
          </div>
        </div>
        <div class="col-md-5 order-md-1 order-0" data-aos="fade-left">
          <img
            src="/assets/images/toffice-img-6.png"
            class="img-fluid"
            alt="toffice"
          />
        </div>
      </div>
    </div>
  </section>
  
  <section class="footer-banner-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="cmn-heading">
            Start Working Smarter Today With TOffice Employee Payroll Management Software
          </h1>
          <h2 class="cmn-sub-heading">
            Inefficient offices mean lower productivity. Boost your office space today with TOffice from Techware Lab.
          </h2>
          <button routerLink="/contact">Let’s get started</button>
        </div>
      </div>
    </div>
  </section>
  
  
  <section class="faq-wrapper">
    <div class="container">
      <h1 class="cmn-heading">Frequently Asked Questions</h1>
      <mat-accordion>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
                What is an employee payroll management system?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Payroll software is a solution used by HRs professionals to manage the entire lifecycle of end-to-end payroll operations. TOffice payroll software allows you to quickly enrol your employees into payroll, giving you complete profile information for your employees in one place. Profiles can include basic information like salary details, tax forms, and payment information.Effective payroll management systems like TOffice save time, money, and boost employee productivity.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
                Why do you need a payroll management system?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Any business with more than one employee needs a payroll system. Staying compliant with current regulations requires businesses and organizations to have a payroll management system in place. Investing in a payroll management system will help your organization stay productive. Choose a proven payroll solution like TOffice and simplify your payroll management system. Have questions? Get in touch with our team today by emailing hello@techwarelab.com
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
                What are the benefits of payroll software?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Payroll software allows you to automate tasks, so you can focus on more important things. This type of software also boosts organizational visibility by eliminating large files, cabinets, and other traditional forms of document storage. With the TOffice payroll software, all of your data is synced, making it easy to manage attendance, leave, reimbursement, TDS, and other HR and payroll processes.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
                How much does payroll software cost?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Your payroll services cost will depend on the types of features you want your software to have. Full-service payroll is more expensive than basic payroll systems. Pricing starts at $5,000 and changes based on your requirements. For more information about pricing, please contact us at hello@techwarelab.com.
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>
  
  <section class="relates-solutions-wrapper">
    <div class="container">
      <h1 class="cmn-heading">Related Solutions</h1>
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
          <div class="related-box" routerLink="/legal-case-management-software">
              <img src="/assets/images/new-related-img1.png" class="img-fluid" alt="Images">
          </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="related-box" routerLink="/doctor-appointment-app">
                <img src="/assets/images/new-related-img2.png" class="img-fluid" alt="Images">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="related-box" routerLink="/taxi-management-system">
                <img src="/assets/images/new-related-img3.png" class="img-fluid" alt="Images">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="related-box" routerLink="/taxi-dispatch-software">
                <img src="/assets/images/new-related-img4.png" class="img-fluid" alt="Images">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="related-box" routerLink="/grocery-app-development">
                <img src="/assets/images/new-related-img5.png" class="img-fluid" alt="Images">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="related-box" routerLink="/online-food-ordering-system">
                <img src="/assets/images/new-related-img6.png" class="img-fluid" alt="Images">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="related-box" routerLink="/car-rental-app-development">
                <img src="/assets/images/new-related-img7.png" class="img-fluid" alt="Images">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="related-box" routerLink="/movie-ticketing-software">
                <img src="/assets/images/new-related-img8.png" class="img-fluid" alt="Images">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="related-box" routerLink="/beauty-salon-software">
                <img src="/assets/images/new-related-img9.png" class="img-fluid" alt="Images">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="related-box" routerLink="/automotive-appointment-software">
                <img src="/assets/images/new-related-img10.png" class="img-fluid" alt="Images">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="related-box" routerLink="/matrimony-app-development">
                <img src="/assets/images/new-related-img11.png" class="img-fluid" alt="Images">
            </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </section>