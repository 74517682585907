<section class="gitex-navbar">
  <div class="container">
    <div class="wrapper">
      <img src="/assets/images/logo.svg" class="img-fluid" alt="Logo" />
      <button class="request-quote-btn" (click)="requestQuote()">Request a Quote</button>
    </div>
  </div>
</section>

<section class="gitex-banner">
  <div class="gitex-inner">
    <div class="banner-content">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-7">
            <div class="text-content">
              <h1>
                Software innovation<br />
                tailored to your business needs.
              </h1>
              <p>
                Gaining a competitive advantage in today’s fast-paced digital
                world can be challenging. Transform your business and support
                growth by partnering with a boutique software development firm
                that’s all-in on your growth story.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-5 mt-md-6 mt-0" #gitexForm>
            <div class="banner-form">
              <div class="demo-form">
                <div class="top-text pr-md-2">
                  <h5>Excited? Let’s Talk We are Listening.</h5>
                </div>
                <div class="quote-wrapper">
                <form [formGroup]="softwareDevForm" (ngSubmit)="softwareSubmit()">
                  <div>
                    <div class="form-group">
                      <input
                        type="text"
                        formControlName="name"
                        class="form-control"
                        placeholder="Name"
                      />
                      <div class="s_error" *ngIf="!softwareDevForm.controls['name'].valid && (softwareDevForm.controls['name'].dirty || softwareDevForm.controls['name'].touched || softwareDevSubmit)">
                        <div class="s_validation" *ngIf="softwareDevForm.controls['name'].hasError('required')">
                            Provide a Name
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <input
                        type="email"
                        formControlName="email"
                        class="form-control"
                        placeholder="Email"
                      />
                      <div class="s_error" *ngIf="!softwareDevForm.controls['email'].valid && (softwareDevForm.controls['email'].dirty || softwareDevForm.controls['email'].touched || softwareDevSubmit)">
                        <div class="s_validation" *ngIf="softwareDevForm.controls['email'].hasError('required')">Provide an Email</div>
                        <div class="s_validation" *ngIf="softwareDevForm.controls['email'].hasError('pattern')">Provide a Valid Email Id</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <input
                        type="Number"
                        formControlName="phone"
                        class="form-control"
                        placeholder="Phone"
                      />
                      <div class="s_error" *ngIf="!softwareDevForm.controls['phone'].valid && (softwareDevForm.controls['phone'].dirty || softwareDevForm.controls['phone'].touched || softwareDevSubmit)">
                        <div class="s_validation" *ngIf="softwareDevForm.controls['phone'].hasError('required')">
                            Provide a Phone number
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <textarea
                        rows="5"
                        placeholder="Please describe your requirements"
                        formControlName="requirement"
                        class="form-control"
                      ></textarea>
                      <div class="s_error" *ngIf="!softwareDevForm.controls['requirement'].valid && (softwareDevForm.controls['requirement'].dirty || softwareDevForm.controls['requirement'].touched || softwareDevSubmit)">
                        <div class="s_validation" *ngIf="softwareDevForm.controls['requirement'].hasError('required')">
                            Provide Your Requirements
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <button>Talk to our Experts</button>
                    </div>
                  </div>
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="welcome-text">
  <div class="container">
    <h1 class="cmn-heading">
      A personalized approach that brings your business vision to life
    </h1>
    <p>
      Techware Lab uses an agile methodology to keep all aspects of a project
      collaborative and flexible. Boost brand awareness, customer loyalty, and
      revenue with our leading custom development services.
    </p>
  </div>
</section>

<section class="our-services-landingpage">
  <div class="container">
    <h1 class="cmn-heading">Our Services</h1>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <div class="service-box yellow">
          <img src="/assets/images/ser-1.png" alt="Service Icon" />
          <h3>
            Web App <br />
            Development
          </h3>
          <p>
            Enrich customer relationships and grow operations exponentially with
            a fully-customized web application that understands your business
            needs and goals.
          </p>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box blue">
          <img src="/assets/images/ser-2.png" alt="Service Icon" />
          <h3>
            Mobile <br />
            Development
          </h3>
          <p>
            Put your business in the hands of customers with an outstanding
            mobile experience. Boost brand awareness, customer loyalty, and
            revenue with our leading mobile development services.
          </p>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box red">
          <img src="/assets/images/ser-3.png" alt="Service Icon" />
          <h3>
            Digital <br />
            Marketing
          </h3>
          <p>
            The right marketing can turn big ideas into success stories. Attract
            and win new customers with a compelling digital strategy designed by
            a professional team of marketing specialists.
          </p>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box blue">
          <img src="/assets/images/ser-4.png" alt="Service Icon" />
          <h3>
            Business <br />
            Consulting
          </h3>
          <p>
            Technology is complex. Get the most out of digital innovation by
            following a clearly-defined roadmap for change. Modernize your
            software and implement new systems without the risk.
          </p>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box red">
          <img src="/assets/images/ser-5.png" alt="Service Icon" />
          <h3>
            Proof-of-Concept <br />
            and Prototyping
          </h3>
          <p>
            Cut costs, reduce risk, and position your business for success with
            rapid prototyping. Techware Lab lets you build with confidence by
            clarifying priorities and eliminating uncertainty ahead of time.
          </p>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box yellow">
          <img src="/assets/images/ser-6.png" alt="Service Icon" />
          <h3>
            Ecommerce <br />
            Development
          </h3>
          <p>
            Got something to sell? Maximize profits with a sleek and effortless
            ecommerce portal tailored to your exact business needs. Deliver an
            exceptional customer experience across any device.
          </p>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>

<section class="technologies-workon-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Technologies we work on</h1>
    <p>We have extensive knowledge in making web and mobile applications.</p>
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="tab-one"
          data-toggle="pill"
          href="#tab-content-one"
          role="tab"
          aria-controls="tab-content-one"
          aria-selected="true"
          >Backend Technologies</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="tab-two"
          data-toggle="pill"
          href="#tab-content-two"
          role="tab"
          aria-controls="tab-content-two"
          aria-selected="false"
          >Frontend Technologies</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="tab-three"
          data-toggle="pill"
          href="#tab-content-three"
          role="tab"
          aria-controls="tab-content-three"
          aria-selected="false"
          >Database Technologies</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="tab-four"
          data-toggle="pill"
          href="#tab-content-four"
          role="tab"
          aria-controls="tab-content-four"
          aria-selected="false"
          >Mobile Technologies</a
        >
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="tab-content-one"
        role="tabpanel"
        aria-labelledby="tab-one"
      >
        <div class="row mt-5">
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-1.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-2.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-3.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-4.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="tab-content-two"
        role="tabpanel"
        aria-labelledby="tab-two"
      >
        <div class="row mt-5">
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-5.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-6.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-7.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="tab-content-three"
        role="tabpanel"
        aria-labelledby="tab-three"
      >
        <div class="row mt-5">
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-8.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-9.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-10.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-11.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-12.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="tab-content-four"
        role="tabpanel"
        aria-labelledby="tab-four"
      >
        <div class="row mt-5">
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-13.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-14.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-15.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
          <div class="col-md-3">
            <img
              src="/assets/images/technology-logo-16.png"
              class="img-fluid"
              alt="Technology"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="customized-solutions">
  <div class="container">
    <h1 class="cmn-heading">Customized Business Solutions.</h1>
    <p>
      We offer a diverse range of solutions all designed to find a new
      competitive edge for your business.
    </p>
    <owl-carousel-o [options]="customOptions2">
      <ng-template carouselSlide>
        <div class="service-box blue">
          <img src="/assets/images/t-doc.png" class="logo" alt="Logo" />
          <img src="/assets/images/product-2.png" class="template" alt="Logo" />
          <h6>Simplified Scheduling for Doctors</h6>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box yellow">
          <img src="/assets/images/t-cab.png" class="logo" alt="Logo" />
          <img
            src="/assets/images/product-1.webp"
            class="template"
            alt="Logo"
          />
          <h6>Taxi Booking and Rental App</h6>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box red">
          <img src="/assets/images/t-court.png" class="logo" alt="Logo" />
          <img src="/assets/images/product-3.png" class="template" alt="Logo" />
          <h6>Build Your Law Practice</h6>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box yellow">
          <img src="/assets/images/t-taxi.png" class="logo" alt="Logo" />
          <img
            src="/assets/images/product-4.webp"
            class="template"
            alt="Logo"
          />
          <h6>Connecting People and Places</h6>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box blue">
          <img src="/assets/images/t-cart.png" class="logo" alt="Logo" />
          <img
            src="/assets/images/product-5.webp"
            class="template"
            alt="Logo"
          />
          <h6>Your Online Grocery Store</h6>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box red">
          <img src="/assets/images/t-food.png" class="logo" alt="Logo" />
          <img
            src="/assets/images/product-6.webp"
            class="template"
            alt="Logo"
          />
          <h6>Connecting People and Places</h6>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box yellow">
          <img src="/assets/images/t-ride.png" class="logo" alt="Logo" />
          <img src="/assets/images/product-7.png" class="template" alt="Logo" />
          <h6>Car Rental Business App</h6>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box blue">
          <img src="/assets/images/t-salon.png" class="logo" alt="Logo" />
          <img src="/assets/images/product-8.png" class="template" alt="Logo" />
          <h6>Beauty Salon Business App</h6>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box red">
          <img src="/assets/images/t-ticket.png" class="logo" alt="Logo" />
          <img src="/assets/images/product-9.png" class="template" alt="Logo" />
          <h6>Skip the Movie Queue</h6>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box yellow">
          <img src="/assets/images/t-mechanic.png" class="logo" alt="Logo" />
          <img
            src="/assets/images/product-10.webp"
            class="template"
            alt="Logo"
          />
          <h6>Online Booking App for Mechanics</h6>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box blue">
          <img src="/assets/images/t-soulmate.png" class="logo" alt="Logo" />
          <img
            src="/assets/images/product-11.png"
            class="template"
            alt="Logo"
          />
          <h6>Online Match Making App</h6>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="service-box red">
          <img src="/assets/images/t-office.png" class="logo" alt="Logo" />
          <img
            src="/assets/images/product-12.png"
            class="template"
            alt="Logo"
          />
          <h6>Employee Attendance and Payroll App</h6>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>

<section class="portfolio-content-wrapper">
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <h1 class="cmn-heading">Our Portfolio</h1>
              <h2 class="cmn-sub-heading">Techware Lab designs and develops mobile and web apps<br>that will delight your users and grow your business.</h2>
              <ul class="nav nav-pills mb-3 mt-5" id="pills-tab" role="tablist">
                  
                  <li class="nav-item">
                      <a class="nav-link active" id="pills-app-tab" data-toggle="pill" href="#pills-app" role="tab" aria-controls="pills-app" aria-selected="true">Mobile App</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" id="pills-web-tab" data-toggle="pill" href="#pills-web" role="tab" aria-controls="pills-web" aria-selected="false">Web App</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" id="pills-website-tab" data-toggle="pill" href="#pills-website" role="tab" aria-controls="pills-website" aria-selected="true">Website</a>
                  </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-app" role="tabpanel" aria-labelledby="pills-app-tab">
                      <div class="row">
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/cloud-trave-p.webp" class="img-fluid" alt="Travel App">
                                      <div class="logo-container">
                                          <img src="/assets/images/travel-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>Travel App</h3>
                                  <h4><i>A social application for travel influencers</i></h4>
                                  <p>Travel App is a social application where travel and hospitality providers can partner with influencers. Influencers can post their travel-related photos which users can view, like, comment and share. Users can also easily
                                      search for particular destinations and book hotels and travel experiences based on influencers' posts. In return, influencers can make money and get discounts on their travel bookings. We worked closely with our
                                      client to determine the business logic, workflow and designs, before building the app. </p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/pass-the-peas.webp" class="img-fluid" alt="Recipe sharing app">
                                      <div class="logo-container">
                                          <img src="/assets/images/peaz-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>Pass the Peaz</h3>
                                  <h4><i>A delicious way to pass on your family's recipes</i></h4>
                                  <p>This platform allows users to connect with friends and deepen their familial ties by passing along family traditional recipes. This started as a small project so that our client could save his family's old recipes from
                                      different generations in one place, and grew to become a platform where friends and family could add/share their own recipes with an interactive experience. Our client shared his requirements, and we integrated
                                      all the pieces and built the app.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/one-voices.webp" class="img-fluid" alt="Mobile justice app">
                                      <div class="logo-container">
                                          <img src="/assets/images/voices-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>1voices</h3>
                                  <h4><i>The easy way to crowdfund for a social cause</i></h4>
                                  <p>This project provides a platform where users can share their cases of injustice, start campaigns, raise funds and get expert advice. Users can also join in as Civilians/Lawyers/Lawmakers/Contributors to drive solutions
                                      for cases. We did everything for this application from gathering requirements to creating interactive designs, and from conducting user research to completing the platform to enable crowd-sourced help for each case.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/finmart.webp" class="img-fluid" alt="Mobile CRM">
                                      <div class="logo-container">
                                          <img src="/assets/images/finmart-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>Finmart</h3>
                                  <h4><i>A seamless CRM app for financial services</i></h4>
                                  <p>Finmart is a CRM designed to help financial aggregators manage collect, manage and create personalized offers for leads in compliance with industry regulations. Agents can easily track the status of their leads, delegate
                                      tasks to the sales team and record all relevant information. We worked with the client to improve the workflows, integrate various tools and build the web-based application for different operating systems and browsers.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/myround-tab.png" class="img-fluid" alt="Drink app">
                                      <div class="logo-container">
                                          <img src="/assets/images/round-tab-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>MyRoundTab</h3>
                                  <h4><i>A drink offering app to buy or offer a drink to friends and family</i></h4>
                                  <p>MyRoundTab lets you buy a drink for anyone, anywhere - all users have to do is pay for it in the app and the recipient can claim their drink from select partners. This special app caters to a new generation of consumers who can order, exchange and personalize their drink gifts to friends and family. We worked with them to determine the requirements, user flows and best practices to build this app where users can add their friends and also find the right establishments.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/clinx.png" class="img-fluid" alt="Online dating app solutions">
                                      <div class="logo-container">
                                          <img src="/assets/images/clink-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>Clink</h3>
                                  <h4><i>The nightlife dating app where the drink comes first</i></h4>
                                  <p>Clink is a safe digital mobile platform that empowers users to decide who they want to connect with tonight in the same bar or club. The app is integrated with popular point-of-sale systems and can be used to purchase drinks via the app or directly at the bar. Clink wants to break uncomfortable social barriers and help users meet each other face-to-face over a drink.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/thestu.png" class="img-fluid" alt="Recording studio app">
                                      <div class="logo-container">
                                          <img src="/assets/images/thestu-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>TheStu</h3>
                                  <h4><i>The smartest way to manage recording studios with a simple app</i></h4>
                                  <p>TheStu is a booking app to manage studio bookings online with easy-to-use, highly customizable features that cater specifically to the studio needs. Manual bookings can be quite a tough task to keep up with and manage. We built TheStu so it can easily offer studio services online and effectively manage all types of tasks from studio bookings to engineer workload. We provided consulting and development services. </p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/getmi.png" class="img-fluid" alt="Clothing app">
                                      <div class="logo-container">
                                          <img src="/assets/images/getmi-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>GetMi</h3>
                                  <h4><i>The best retail store app for shopping from home</i></h4>
                                  <p>GetMi is a shopping platform which includes apps for users, retail stores and delivery executives. Through the store, customers can view, browse, and buy what they like. The store owner gets to know that the delivery executive has arrived within the radius of the store - this feature makes GetMi different from other shopping apps. The platform is in high demand due to its sophisticated features for multiple types of users. With our requirement gathering and UI/UX workflows, we built the mobile platform quickly and with comprehensive features.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/lawyer.png" class="img-fluid" alt="Legal appointment booking software">
                                      <div class="logo-container">
                                          <img src="/assets/images/lawyer-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>Lawyer 360</h3>
                                  <h4><i>A fully-customizable, new-age legal management app</i></h4>
                                  <p>Lawyer360 is an app for attorneys and law firms to manage cases and save time. Lawyers can easily look through all the reservations and check the details of clients. Lawyer360 offers customizable booking for clients, where reserving a spot takes only a few seconds. It delivers the effortless experience clients expect while automating the law firm's intake process and management. We worked with Lawyer 360 to build an easy and intuitive way for prospective clients to book the nearest lawyers for online consultations.</p>
                              </div>
                          </div>
                          
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/soteria.png" class="img-fluid" alt="Emergency alert app">
                                      <div class="logo-container">
                                          <img src="/assets/images/soteria-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>Soteria</h3>
                                  <h4><i>Unique social safety, emergency and Information app</i></h4>
                                  <p>Soteria is the ultimate social safety app. As a network, Soteria provides real-time information pushed to the devices so users can stay updated about the roads and areas close to where they are and live. With our expertise, we build the mobile application which shares validated, real-time and relevant broadcasts originating from a 24/7 manned Incident Control Centre (ICC) with a diverse network of information feeds.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/dearest.png" class="img-fluid" alt="Family tree builder">
                                      <div class="logo-container">
                                          <img src="/assets/images/dearest-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>Dearest</h3>
                                  <h4><i>The popular app for building family tree and family stories anytime, anywhere</i></h4>
                                  <p>Dearest is an online genealogy social networking software solution for users to create, preserve and share their family records, resources, discoveries, while also collaborating with friends and family. The app combines social networking with online family trees and connects families with records. We enjoyed helping our customer build this app so all of a family’s information (photos, stories, life events, and more) can be preserved in a single location for generations to come.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/drukride.png" class="img-fluid" alt="Bus booking app">
                                      <div class="logo-container">
                                          <img src="/assets/images/drukride-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>DrukRide</h3>
                                  <h4><i>An online bus booking web app for bus transportation services</i></h4>
                                  <p>DrukRide is an automated online bus booking web application that makes easy configuration in bus routes. DrukRide app makes the most convenient booking service for buses from the largest community of drivers and operators and cashless payments in-app. Manage bus ticket booking on the app by selecting any of the designated options to pick up and drop off points, view, or cancel your bus ticket by following a few simple steps.</p>
                              </div>
                          </div>
                          
                          <!-- <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/zyva.png" class="img-fluid" alt="Portfolio Image">
                                      <div class="logo-container">
                                          <img src="/assets/images/zyva-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>Zyva</h3>
                                  <h4><i>A seamless CRM app for financial services</i></h4>
                                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  </p>
                              </div>
                          </div> -->
                      </div>
                  </div>
                  <div class="tab-pane fade" id="pills-web" role="tabpanel" aria-labelledby="pills-web-tab">
                      <div class="row">
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/daraz.webp" class="img-fluid" alt="Online marketplace">
                                      <div class="logo-container">
                                          <img src="/assets/images/daraz-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>Daraz</h3>
                                  <h4><i>The leading online marketplace in South Asia</i></h4>
                                  <p>Daraz is an online marketplace and logistics company that operates in South Asia and Southeast Asia. As a subsidiary of Alibaba, Daraz is focused on entering new markets and providing a best-in-class experience for
                                      users. We played an integral role in successfully designing and integrating a secure travel booking into their platform.</p>
                              </div>
                          </div>
                          
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/one-voice-web.png" class="img-fluid" alt="Crowdfunding app">
                                      <div class="logo-container">
                                          <img src="/assets/images/voices-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>1voices</h3>
                                  <h4><i>The easy way to crowdfund for a social cause</i></h4>
                                  <p>This project provides a platform where users can share their cases of injustice, start campaigns, raise funds and get expert advice. Users can also join in as Civilians/Lawyers/Lawmakers/Contributors to drive solutions
                                      for cases. We did everything for this application from gathering requirements to creating interactive designs, and from conducting user research to completing the platform to enable crowd-sourced help for each case.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/equator-web.png" class="img-fluid" alt="Travel portal app">
                                      <div class="logo-container">
                                          <img src="/assets/images/equator-logo.svg" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>Equator</h3>
                                  <h4><i>The leading online travel portal dedicated to serve large companies</i></h4>
                                  <p>Equator Travel Management is an online travel portal dedicated to serving large corporate companies with airfares, hotel bookings, airport transfers, air charter, and other travel products. We supported, advised and managed the development of this web application to enable online Travel Agents to easily search, purchase, and cancel flight tickets within the current buyer platform.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/medsale-web.png" class="img-fluid" alt="Medical equipments online">
                                      <div class="logo-container">
                                          <img src="/assets/images/medscale-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>Med Sale</h3>
                                  <h4><i>The trusted source for medical equipment needs</i></h4>
                                  <p>MediSale is a trusted medical equipment resource for hospitals, clinics, surgery centers, doctors’ offices, ambulances, and other medical facilities. With a focus on ensuring quality and customer satisfaction, we developed a web app so their customers can create an account and order the medical supplies and equipments they need. We created several workflows and built quality checks into the portal.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/zyva-web.png" class="img-fluid" alt="Online clothes shopping India">
                                      <div class="logo-container">
                                          <img src="/assets/images/zyva-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>Zyva</h3>
                                  <h4><i>The leading fashion apparel brand in India </i></h4>
                                  <p>Zyva is a leading clothing and apparel brand in India. As the ultimate fashion destination for most unique styles that are handpicked, on-trend, and at affordable prices, they wanted to build an online shop. We built a custom eCommerce platform that looks professional and unique, while also providing important features to allow them to manage their online orders and deliveries.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/drukeride-web.png" class="img-fluid" alt="Online bus booking">
                                      <div class="logo-container">
                                          <img src="/assets/images/drukride-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>DrukeRide</h3>
                                  <h4><i>An online bus booking web app for transportation services</i></h4>
                                  <p>DrukRide is an automated online bus booking web application that enables easy bookings and helps determine customized bus routes. The web app makes the most convenient booking service for buses from the largest community of drivers and operators, while also offering cashless payments in-app. We developed the bus ticket booking app and included many processes such as allowing users to select pick up and drop off points, view, or cancel their bus tickets.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="tab-pane fade show" id="pills-website" role="tabpanel" aria-labelledby="pills-website-tab">
                      <div class="row">
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/kinder-ville-website.png" class="img-fluid" alt="Early learning center">
                                      <div class="logo-container">
                                          <img src="/assets/images/kinderville-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>KinderVille</h3>
                                  <h4><i>An SEO-friendly website for the leading early learning centers for children</i></h4>
                                  <p>Kinderville provides quality care and bilingual early years education for nurseries, preschools and daycares. Their curriculum is based on a multi-disciplinary thematic approach to learning. Children learn through structured play as they create, construct, and reflect through meaningful experiences. We developed their WordPress website for all of their locations across UAE, India and Canada.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/mhg-web.png" class="img-fluid" alt="Wellness retreat">
                                      <div class="logo-container">
                                          <img src="/assets/images/mhg-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>MHG Healthcare</h3>
                                  <h4><i>Discover the world’s premier holistic retreats with their beautiful and informative website</i></h4>
                                  <p>MHG Healthcare is a leading healthcare and wellness company which offers customers concierge service to book health clinics and wellness retreats across the world. MHG helps customers find the best treatments for their customers’ unique needs. We worked with MHG to develop their branding, digital marketing and website so they can successfully launch their company.</p>
                              </div>
                          </div>
                          <!-- <div class="col-md-6">
                              <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                  <div class="portfolio-image">
                                      <img src="/assets/images/ticket-robo.webp" class="img-fluid" alt="Online bus ticket booking">
                                      <div class="logo-container">
                                          <img src="/assets/images/ticket-logo.png" class="img-fluid" alt="Logo">
                                      </div>
                                  </div>
                                  <h3>TicketRobo</h3>
                                  <p>Ticketrobo is a bus ticket booking platform that provides users with an easy interface to seamlessly book tickets across India with over 2000+ routes and 2000+ reliable partners. We assisted our client with customizing
                                      our existing ticketing solution for their business needs.</p>
                              </div>
                          </div> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<!-- <section class="portfolio-content-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">Portfolio</h1>
        <ul class="nav nav-pills mb-3 mt-5" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="pills-app-tab"
              data-toggle="pill"
              href="#pills-app"
              role="tab"
              aria-controls="pills-app"
              aria-selected="true"
              >Mobile App</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="pills-web-tab"
              data-toggle="pill"
              href="#pills-web"
              role="tab"
              aria-controls="pills-web"
              aria-selected="false"
              >Web App</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="pills-website-tab"
              data-toggle="pill"
              href="#pills-website"
              role="tab"
              aria-controls="pills-website"
              aria-selected="true"
              >Website</a
            >
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-app"
            role="tabpanel"
            aria-labelledby="pills-app-tab"
          >
            
          </div>
          <div
            class="tab-pane fade"
            id="pills-web"
            role="tabpanel"
            aria-labelledby="pills-web-tab"
          >
            
          </div>
          <div
            class="tab-pane fade show"
            id="pills-website"
            role="tabpanel"
            aria-labelledby="pills-website-tab"
          >
            
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="testimonial-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <h1 class="cmn-heading">What our Clients say</h1>
      </div>
      <div class="col-md-4"></div>
    </div>
    <div id="demo" class="carousel slide mt-5" data-ride="carousel">
      <!-- The slideshow -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row">
            <div class="col-md-3">
              <div class="test-circle">
                <div class="test-profile">
                  <img
                    src="/assets/images/testimonial-image-1.jpg"
                    alt="Profile"
                  />
                </div>
                <h3>Josef Myers</h3>
                <h4>That Level, CEO, USA</h4>
              </div>
            </div>
            <div class="col-md-9">
              <div class="test-content">
                <p>
                  “I've been outsourcing some tasks to offshore developers for
                  over 10 years now, and I've had the best experience with
                  Techware by far. They follow the development and design
                  requirements perfectly, and most importantly they communicate
                  any questions or concerns early in the project, which saves
                  everyone a lot of time. For every phase of the project
                  management, development, and deployment, they work quickly and
                  effectively. So whenever I send them a project, I know there's
                  no need to worry about them completing correctly and on time.”
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-md-3">
              <div class="test-circle">
                <div class="test-profile">
                  <img src="/assets/images/AbdulSattar.jpg" alt="Profile" />
                </div>
                <h3>AbdulSattar Zahid</h3>
                <h4>
                  Alibaba Product Growth,<br />
                  Dubai
                </h4>
              </div>
            </div>
            <div class="col-md-9">
              <div class="test-content">
                <p>
                  “In a nutshell, I consider them to be a member of our team,
                  who just don't sit with us. I have always found the Techware
                  team very compliant. I have never gotten the feeling that
                  getting to a goal is important to me and to them we are just
                  another client, but I have always gotten the feeling that we
                  are one team and I value that over all the disagreements and
                  what not. As a testament to this feedback, what started from
                  one project has transformed our partnership in a very long one
                  and we hope to continue that. ”
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-md-3">
              <div class="test-circle">
                <div class="test-profile">
                  <img
                    src="/assets/images/testimonial-image-3.jpg"
                    alt="Profile"
                  />
                </div>
                <h3>Danette Copestake</h3>
                <h4>
                  IT Director,<br />
                  Wyelands Bank
                </h4>
              </div>
            </div>
            <div class="col-md-9">
              <div class="test-content">
                <p>
                  "Techware Lab and I worked together on a number of exciting
                  innovation and R&D projects. Nixon was my lead technical
                  consultant. He is a knowledgeable IT consultant who is highly
                  collaborative in his approach. He is always quick to respond
                  to questions / issues offering solutions to complex problems
                  and often thinks ‘out of the box’. Nixon is intelligent,
                  diligent and thorough in his approach and maintains a sense of
                  humour. He was a highly valued member of my team, often going
                  above and beyond what is expected of him. I would welcome him
                  back to my team and strongly recommend him to future
                  employers."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right">
        <a class="carousel-control-prev" href="#demo" data-slide="prev"> </a>
        <a class="carousel-control-next" href="#demo" data-slide="next"> </a>
      </div>
    </div>
  </div>
</section>

<section class="idea-wrapper">
  <div class="container">
    <h1 class="cmn-heading">
      Bring your big idea to market with our team of designers, developers, and
      business strategists.
    </h1>
    <h2>
      Book your FREE 30 minutes consultation with us and get your project
      moving!
    </h2>
    <button (click)="scrollToElement(gitexForm)">Talk to our Experts</button>
  </div>
</section>
