import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from 'src/app/request-quote/request-quote.component';

@Component({
  selector: 'app-performance-marketing-serice',
  templateUrl: './performance-marketing-serice.component.html',
  styleUrls: ['./performance-marketing-serice.component.scss']
})
export class PerformanceMarketingSericeComponent implements OnInit {


  constructor(private model: MatDialog) { }

  ngOnInit(): void {
  }
  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }

}
