import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-graphic-design-important',
  templateUrl: './graphic-design-important.component.html',
  styleUrls: ['./graphic-design-important.component.scss'],
})
export class GraphicDesignImportantComponent implements OnInit {
  public fbLink: string = environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;
  constructor(
    private title: Title,
    private meta: Meta,
    private seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle('Importance of Graphic Design in Marketing');
    this.meta.updateTag({
      name: 'description',
      content:
        "Graphic creatives play a pivotal role in all aspects of a business's marketing efforts, and we'll elaborate on why and how you can leverage graphic design.",
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        "Graphic creatives play a pivotal role in all aspects of a business's marketing efforts, and we'll elaborate on why and how you can leverage graphic design.",
    });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://techwarelab.com/assets/images/GraphicDesignImp-Blog.webp',
    });
    this.meta.updateTag({
      property: 'og:title',
      content: 'Importance of Graphic Design in Marketing',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        "Graphic creatives play a pivotal role in all aspects of a business's marketing efforts, and we'll elaborate on why and how you can leverage graphic design.",
    });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://techwarelab.com/importance-of-graphic-design-in-marketing',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://techwarelab.com/assets/images/GraphicDesignImp-Blog.webp',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Importance of Graphic Design in Marketing',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        "Graphic creatives play a pivotal role in all aspects of a business's marketing efforts, and we'll elaborate on why and how you can leverage graphic design.",
    });
    this.meta.updateTag({
      name: 'twitter:domain',
      content: 'Importance of Graphic Design in Marketing',
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `unseen-risks-in-mobile-app-development-industry`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u', `https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }
}
