<section class="about-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>Helping businesses harness <br> the power of digital innovation
                </h1>
                <h2 class="cmn-sub-heading">At Techware Lab, we’re passionate about driving growth with innovative technologies and an agile approach. We’re all about starting small, scaling fast, and achieving more.</h2>
                <button routerLink="/contact">Ready to get started?</button>
            </div>
        </div>
    </div>
</section>

<section class="about-content-wrapper">
    <div class="container">
        <div class="row pb-5">
            <div class="col-md-12 mb-5">
                <h1 class="cmn-heading">What drives us?</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/about-pic-1.webp" class="img-fluid" alt="End to end software development">
            </div>
            <div class="col-md-6">
                <div class="about-box">
                    <h2 class="blue">We believe in <br> quality.
                    </h2>
                    <p>We believe in end-to-end development that scales. From product ideation to system rollout, our goal is to think big, build smart, and deliver excellence. That means we work tirelessly to achieve the outcomes you want without ever compromising.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 order-md-1 order-0 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/about-pic-2.webp" class="img-fluid" alt="Cutting edge technologies">
            </div>
            <div class="col-md-6 order-md-0 order-1">
                <div class="about-box">
                    <h2 class="blue">We believe in expertise <br> you can rely on
                    </h2>
                    <p>We’ve got the knowledge, experience, and cutting-edge technologies to bring your business vision to life. Whether you’re interested in product engineering or online marketing, our diverse and talented team has got you covered.

                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/about-pic-3.webp" class="img-fluid" alt="Partnership for the goals">
            </div>
            <div class="col-md-6">
                <div class="about-box">
                    <h2 class="blue">We believe in total <br> transparency
                    </h2>
                    <p>We want you to make informed decisions that lead to better outcomes. We respect our clients by offering clear and comprehensive insight into all aspects of our work. Got a question? We’re always here to help.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 order-md-1 order-0 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/about-pic-4.webp" class="img-fluid" alt="Successful business partnership">
            </div>
            <div class="col-md-6 order-md-0 order-1">
                <div class="about-box">
                    <h2 class="blue">We believe in forming <br> long-term partnerships.
                    </h2>
                    <p>We’re more than just software vendors. Your success is our success. That’s why we rely on industry-leading expertise to identify digital initiatives that align with your short- and long-term business objectives.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/about-pic-5.webp" class="img-fluid" alt="Building a collaborative team environment">
            </div>
            <div class="col-md-6">
                <div class="about-box">
                    <h2 class="blue">We believe in <br> collaboration
                    </h2>
                    <p>We put the agile methodology to work to create a collaborative environment that expedites your time to market, ensures you get customized solutions that solve your needs, and keep your teams involved throughout the development process.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Meet our leadership team</h1>
                <h2 class="cmn-sub-heading">Meet our award-winning team of leading digital strategists, customer-<br>focused designers, and talented software developers. We’re ready for your next challenge</h2>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-3" data-aos="fade-up-right" data-aos-delay="50">
                <div class="team-image-back blue">
                    <img src="/assets/images/team-1.webp" class="img-fluid team-photo" alt="Team Member">
                </div>
                <h3>Nixon Solomon</h3>
                <h4>CEO, Founder
                    <a href="https://www.linkedin.com/in/nixonsolomon/" target="_blank"><img src="/assets/images/linkedin.png" alt="Linkedin"></a>
                </h4>
            </div>
            <div class="col-md-3" data-aos="fade-up-right" data-aos-delay="150">
                <div class="team-image-back red">
                    <img src="/assets/images/team-2.webp" class="img-fluid team-photo" alt="Team Member">
                </div>
                <h3>Joby Jose</h3>
                <h4>COO, Founder
                    <a href="https://www.linkedin.com/in/jobyjose89/" target="_blank"><img src="/assets/images/linkedin.png" alt="Linkedin"></a>
                </h4>
            </div>
            <div class="col-md-3" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-image-back yellow">
                    <img src="/assets/images/team-3.webp" class="img-fluid team-photo" alt="Team Member">
                </div>
                <h3>Maithili Sagar</h3>
                <h4>CCO
                    <a href="https://www.linkedin.com/in/maithilisagar/" target="_blank"><img src="/assets/images/linkedin.png" alt="Linkedin"></a>
                </h4>
            </div>
        </div>
    </div>
</section>

<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Ready to gain a competitive edge?</h1>
                <h2 class="cmn-sub-heading">Join our network of 2,400 clients across 25 countries. Stay ahead of digital-born competitors by partnering with Techware Lab. </h2>
                <button routerLink="/contact"> Let's get started</button>
            </div>
        </div>
    </div>
</section>