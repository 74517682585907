<section class="header-banner">
    <div class="container">
        <div class="text-content">
            <h1>Full Stack & Custom Software <br> Development Services </h1>  
            <h6>Work with a leading software development company that offers fast, efficient, and <br> scalable software development solutions specifically to your use cases.</h6>
            <button routerLink="/services">Explore our services</button>
        </div>
    </div>
</section>
<section class="techware-wrapper">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col-md-5 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/fullstack-software-img-1.png" class="img-fluid" alt="fullstack-software">
            </div>
            <div class="col-md-7">
                <div class="help-box mt-4 mt-md-0">
                    <h1 class="cmn-heading">
                        Techware Lab Manages Software <br> Development Needs With <br> Trending Technology 
                    </h1>
                    <p>
                        We provide top-of-the-line software development services, helping our customers determine the best-fitting software solutions for their business goals, providing opportunities for seamless application integration, and more.
                    </p>
                    <button routerLink="/contact">Get a quote</button>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <section class="testimonial-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h1 class="cmn-heading">Who We Work With</h1>
          <p>See other companies who have partnered with Techware Lab for software <br> development services.</p>
        </div>
        <div class="col-md-4"></div>
      </div>
      <div id="demo" class="carousel slide mt-5" data-ride="carousel">
        <!-- The slideshow
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-img.png"
                      alt="Profile"
                    />
                  </div>
                  <h3>Josef Myers</h3>
                  <h4>That Level, CEO, USA</h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “ I’ve been outsourcing some tasks to offshore developers for over 10 years now, and I’ve had the best experience with Techware by far. They follow the development and design requirements perfectly, and most importantly they communicate any questions or concerns early in the project, which saves everyone a lot of time. For every phase of the project management, development, and deployment, they work quickly and effectively. So whenever I send them a project, I know there’s no need to worry about them completing correctly and on time.”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img src="/assets/images/AbdulSattar.jpg" alt="Profile" />
                  </div>
                  <h3>AbdulSattar Zahid</h3>
                  <h4>
                    Alibaba Product Growth,<br />
                    Dubai
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “In a nutshell, I consider them to be a member of our team,
                    who just don't sit with us. I have always found the Techware
                    team very compliant. I have never gotten the feeling that
                    getting to a goal is important to me and to them we are just
                    another client, but I have always gotten the feeling that we
                    are one team and I value that over all the disagreements and
                    what not. As a testament to this feedback, what started from
                    one project has transformed our partnership in a very long one
                    and we hope to continue that. ”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-image-3.jpg"
                      alt="Profile"
                    />
                  </div>
                  <h3>Danette Copestake</h3>
                  <h4>
                    IT Director,<br />
                    Wyelands Bank
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    "Techware Lab and I worked together on a number of exciting
                    innovation and R&D projects. Nixon was my lead technical
                    consultant. He is a knowledgeable IT consultant who is highly
                    collaborative in his approach. He is always quick to respond
                    to questions / issues offering solutions to complex problems
                    and often thinks ‘out of the box’. Nixon is intelligent,
                    diligent and thorough in his approach and maintains a sense of
                    humour. He was a highly valued member of my team, often going
                    above and beyond what is expected of him. I would welcome him
                    back to my team and strongly recommend him to future
                    employers."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <a class="carousel-control-prev" href="#demo" data-slide="prev"> </a>
          <a class="carousel-control-next" href="#demo" data-slide="next"> </a>
        </div>
      </div>
    </div>
</section> -->

<section class="brands-wrapper">
    <div class="container">
      <div class="row mt-5 mb-4 mt-md-0">
        <div class="col-md-12 text-center">
          <h1 class="cmn-heading">Who We Work With</h1>
          <h2 class="cmn-sub-heading">
            See other companies who have partnered with Techware Lab for website
            design<br />
            and development services.
          </h2>
        </div>
      </div>
  
      <owl-carousel-o [options]="customOptionsOne">
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-one.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-two.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-three.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-four.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-eigt.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-nine.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-logo-five.png"
              class="img-fluid brand-img-one"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-logo-four.png"
              class="img-fluid brand-img-two"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-three.png"
              class="img-fluid brand-img-three"
              alt="Images"
            />
          </div>
        </ng-template>
  
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-five.png"
              class="img-fluid brand-img-four"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-six.png"
              class="img-fluid brand-img-five"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-sev.png"
              class="img-fluid brand-img-six"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-ten.png"
              class="img-fluid brand-img-seven"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-elv.png"
              class="img-fluid brand-img-eigt"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-tw.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </section>


<section class="software-wrapper">
    <div class="container">
        <div class="row mb-md-5 mt-5">
            <div class="col-md-7">
                <div class="help-box">
                    <h1 class="cmn-heading">
                        Reliable Software Solutions <br> for Small, Medium and Large <br> Enterprises
                    </h1>
                    <p> Our team of skilled developers work diligently to design, engineer, deliver, and support various software types. We support businesses at each stage of their software development.</p>
                </div>
            </div>
            <div class="col-md-5 mt-3 mt-md-0" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/fullstack-software-img-2.png" class="img-fluid" alt="fullstack-software">
            </div>
        </div>
    </div>
</section>






<section class="design-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Software Development Services</h1>
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/fullstack-software-img-3.png" class="img-fluid side-img" alt="fullstack-software">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-5 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Software Consultations and Ongoing Support
                                         </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        By working closely with you to analyze and measure your current software development process, we determine the best ways to provide alternative solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Custom Software Development
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        Customize the details of your software solution with us in a way that suits your business needs.
                                       </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Software Product Development
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            We help you sell SaaS software for digital products and develop a quality and consistent product flow.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Software Integration
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            Improve your software with the data integration and trustworthy API we provide you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<section class="approach-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Our Approach</h1>
                <h2 class="cmn-sub-heading ">Get assistance at every stage of your software development or reconfiguration process through our agile four-step approach.
                </h2>
            </div>
        </div>
        <div class="row mb-md-5 mt-md-4">
            <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box blue">
                    <img src="/assets/images/fullstack-software-icon-1.png" alt="enterprise Icon">
                    <h3> Research and Discovery </h3>
                    <p>Our team conducts the appropriate research about your business to gauge an overall vision and direction for your software development plan. </p>
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="250">
                <div class="approach-box red">
                    <img src="/assets/images/fullstack-software-icon-2.png" alt="enterprise Icon">
                    <h3>Process Development</h3>
                    <p>We enter planning mode and decipher a compatible development process that aligns with your business values.</p>
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box red">
                    <img src="/assets/images/fullstack-software-icon-3.png" alt="enterprise Icon">
                    <h3>Delivery </h3>
                    <p>Your software development plan is implemented, and we double down on structure, design, and customer deliverables—then we test it all. </p>
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box yellow">
                    <img src="/assets/images/fullstack-software-icon-4.png" alt="enterprise Icon">
                    <h3>Ongoing Support</h3>
                    <p>Even when the job is done, we’re not. After discovering the results of your software development process, we work with you to reiterate and strategize for top performing results. 
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="software-wrapper">
    <div class="container">
        <div class="row mb-md-5 mt-5">
            <div class="col-md-5 mt-3 mt-md-0" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/fullstack-software-img-4.png" class="img-fluid" alt="fullstack-software">
            </div>
            <div class="col-md-7">
                <div class="help-box pt-5">
                    <h1 class="cmn-heading">
                        Software Development That <br> Works for Both iOS and <br> Android Devices
                    </h1>
                    <p> Develop a competitive browser-based experience compatible for all iOS and Android devices.</p>
                </div>
            </div>
            
        </div>
    </div>
</section>


<section class="mobile-app-wrapper">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">What We Develop</h1>
                <p>Our team creates immersive and transferrable software development solutions for various purposes.</p>
            </div>
        </div>
        <div class="row side">
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/fullstack-software-one-1.png" class="img-fluid img-ind-one" alt="Images">
                    <h5 class="cmn-sub-heading">Mobile Apps </h5>
                    
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/fullstack-software-one-2.png" class="img-fluid img-ind-two" alt="Images">
                    <h5 class="cmn-sub-heading">Web Application Development</h5>
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/fullstack-software-one-3.png" class="img-fluid img-ind-three" alt="Images">
                    <h5 class="cmn-sub-heading">Desktop Application</h5>
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/fullstack-software-one-4.png" class="img-fluid img-ind-four" alt="Images">
                    <h5 class="cmn-sub-heading">SaaS Products</h5>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="mobile-app-wrapper-two">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12">
                <h1 class="cmn-heading">We Prioritize Technology and Innovation</h1>
                <p>Our team creates immersive and transferrable software development solutions for various purposes.</p>
            </div>
        </div>
        <div class="row side">
            <div class="col-md-4">
                <div class="team-box red text-center">
                    <img src="/assets/images/fullstack-software-two-1.png" class="img-fluid img-ind-one" alt="Images">
                    <h5 class="cmn-sub-heading">Artificial Intelligence</h5>
                </div>
            </div>
            <div class="col-md-4">
                <div class="team-box blue text-center">
                    <img src="/assets/images/fullstack-software-two-2.png" class="img-fluid img-ind-one" alt="Images">
                    <h5 class="cmn-sub-heading">Virtual Reality</h5>
                </div>
            </div>
            <div class="col-md-4">
                <div class="team-box yellow text-center">
                    <img src="/assets/images/fullstack-software-two-3.png" class="img-fluid img-ind-one" alt="Images">
                    <h5 class="cmn-sub-heading">Blockchain</h5>
                </div>
            </div>
            <div class="col-md-4">
                <div class="team-box yellow text-center">
                    <img src="/assets/images/fullstack-software-two-4.png" class="img-fluid img-ind-one" alt="Images">
                    <h5 class="cmn-sub-heading">Big Data </h5>
                </div>
            </div>
            <div class="col-md-4">
                <div class="team-box red text-center">
                    <img src="/assets/images/fullstack-software-two-5.png" class="img-fluid img-ind-one" alt="Images">
                    <h5 class="cmn-sub-heading">Data Science</h5>
                    
                </div>
            </div>
            <div class="col-md-4">
                <div class="team-box blue text-center">
                    <img src="/assets/images/fullstack-software-two-6.png" class="img-fluid img-ind-one" alt="Images">
                    <h5 class="cmn-sub-heading">Augmented Reality</h5>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Build Cutting-Edge Software With Our <br> Proven Team</h1>
                <h5 class="cmn-sub-heading">Improve your business with quality software and services that support your goals across multiple industry verticals. Our developers champion the latest technology solutions and will ensure your software development outcomes benefit every area of your business.</h5>
                <button routerLink="/contact">Get a quote now</button>
            </div>
        </div>
    </div>
</section>








