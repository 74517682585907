<div class="join-mat">
    <mat-dialog-content>
        <section class="quote-wrapper">
            <span class="close_btn" (click)="closeChatDialog()"><img src="/assets/images/close-icon.png" alt="Close"></span>
            <h2>Join Our Team</h2>
            <form [formGroup]="contactForm" (ngSubmit)="contactFormSubmit()" enctype="multipart/form-data" #joinForm>
              <div class="row">
              <div class="col-md-6">
                  <label class="headLabel">Open Positions</label>
                  <div class="form-group">
                      <select class="form-control" formControlName="reason">
                          <option disabled value="0" [selected]="true">Select</option>
                          <option value="QA Test Lead">QA Test Lead</option>
                          <option value="IOS Developer">IOS Developer</option>
                          <option value="Technical Manager">Technical Manager</option>
                          <option value="Business Development Executive">Business Development Executive</option>
                      </select>
                      <div class="s_error" *ngIf="!contactForm.controls['reason'].valid && (contactForm.controls['reason'].dirty || contactForm.controls['reason'].touched || contactSubmit)">
                          <div class="s_validation" *ngIf="contactForm.controls['reason'].hasError('required')">
                              Choose a Position
                          </div>
                      </div>
                  </div>
                  </div>
              </div>
                <label>Basic Info</label>
                <div class="row">
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" formControlName="name" class="form-control" placeholder="Name">
                          <div class="s_error" *ngIf="!contactForm.controls['name'].valid && (contactForm.controls['name'].dirty || contactForm.controls['name'].touched || contactSubmit)">
                              <div class="s_validation" *ngIf="contactForm.controls['name'].hasError('required')">
                                  Provide a Name
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="Number" formControlName="phone" class="form-control" placeholder="Phone">
                          <div class="s_error" *ngIf="!contactForm.controls['phone'].valid && (contactForm.controls['phone'].dirty || contactForm.controls['phone'].touched || contactSubmit)">
                              <div class="s_validation" *ngIf="contactForm.controls['phone'].hasError('required')">
                                  Provide a Phone number
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="email" formControlName="email" class="form-control" placeholder="Email">
                          <div class="s_error" *ngIf="!contactForm.controls['email'].valid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched || contactSubmit)">
                              <div class="s_validation" *ngIf="contactForm.controls['email'].hasError('required')">Provide an Email</div>
                              <div class="s_validation" *ngIf="contactForm.controls['email'].hasError('pattern')">Provide a Valid Email Id</div>
                          </div>
                      </div>
                  </div>
                </div>
          
                <label>Professional Details</label>
                <div class="row">
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" formControlName="highestQualification" class="form-control" placeholder="Highest Qualification Held">
                          <div class="s_error" *ngIf="!contactForm.controls['highestQualification'].valid && (contactForm.controls['highestQualification'].dirty || contactForm.controls['highestQualification'].touched || contactSubmit)">
                              <div class="s_validation" *ngIf="contactForm.controls['highestQualification'].hasError('required')">
                                  Provide a Highest Qualification
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" formControlName="totalExperience" class="form-control" placeholder="Total Experience">
                          <div class="s_error" *ngIf="!contactForm.controls['totalExperience'].valid && (contactForm.controls['totalExperience'].dirty || contactForm.controls['totalExperience'].touched || contactSubmit)">
                              <div class="s_validation" *ngIf="contactForm.controls['totalExperience'].hasError('required')">
                                Provide a Total Experience
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" formControlName="currentSalary" class="form-control" placeholder="Current Salary">
                          <div class="s_error" *ngIf="!contactForm.controls['currentSalary'].valid && (contactForm.controls['currentSalary'].dirty || contactForm.controls['currentSalary'].touched || contactSubmit)">
                              <div class="s_validation" *ngIf="contactForm.controls['currentSalary'].hasError('required')">
                                  Provide a Current Salary
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" formControlName="expectedSalary" class="form-control" placeholder="Expected Salary">
                          <div class="s_error" *ngIf="!contactForm.controls['expectedSalary'].valid && (contactForm.controls['expectedSalary'].dirty || contactForm.controls['expectedSalary'].touched || contactSubmit)">
                              <div class="s_validation" *ngIf="contactForm.controls['expectedSalary'].hasError('required')">
                                  Provide a Expected Salary
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" formControlName="noticePeriod" class="form-control" placeholder="Notice Period">
                          <div class="s_error" *ngIf="!contactForm.controls['noticePeriod'].valid && (contactForm.controls['noticePeriod'].dirty || contactForm.controls['noticePeriod'].touched || contactSubmit)">
                              <div class="s_validation" *ngIf="contactForm.controls['noticePeriod'].hasError('required')">
                                  Provide a Notice Period
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <label>Additional Info</label>
                <div class="form-group">
                    <textarea rows="5" placeholder="Please describe your requirements " formControlName="requirement" class="form-control"></textarea>
                </div>
                <label for="">Attach your Resume</label>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input  [hidden]="true" type="file" formControlName="file" (change)="onFileChange($event)" class="form-control" placeholder="Attach your Resume" #fileSelect>
                            <input readonly style="cursor: pointer" placeholder="Attach your Resume" formControlName="areaIcon" (click)="fileSelect.click()" class="form-control"/>
                            <div style="cursor: pointer;margin-top: -45px; cursor: pointer;margin-top: -31px; margin-right: 13px; float: right;" (click)="fileSelect.click();">
                                <img  src="/assets/images/upload-icon.png" alt="icons" style="width: 20px">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p class="attachText">
                            PDF only, not more than 2MB
                        </p>
                    </div>
                </div>
                <div class="s_error" *ngIf="!contactForm.controls['file'].valid && (contactForm.controls['file'].dirty || contactForm.controls['file'].touched || contactSubmit)">
                    <div class="s_validation" *ngIf="contactForm.controls['file'].hasError('required')">
                        Please Attach Your Resume
                    </div>
                </div>
                <div class="form-group">
                    <div class="row submitBtn">
                        <button type="submit">Send</button>
                        <button (click)="clearBtn()" class="clearBtn">Clear</button>
                    </div>
                </div>
            </form>
          </section>
    </mat-dialog-content>
</div>