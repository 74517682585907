import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-case-study-mangosuite',
  templateUrl: './case-study-mangosuite.component.html',
  styleUrls: ['./case-study-mangosuite.component.scss']
})
export class CaseStudyMangosuiteComponent implements OnInit {

  mangosuiteData: any = {
    heading: {
      title: 'Mangosuite',
      description: 'A CRM designed specifically for financial products to capture, track, and seamlessly share customer data in one place.',
      buttonText: 'TALK TO OUR EXPERTS',
      imageUrl: "../../assets/images/case-study-mangosuite-heading-section-img.png"
    },
    about: {
      title: 'About Mangosuite',
      description: ['Mangosuite is a purpose-built CRM platform explicitly designed for the finance industry. They aim to help banking & finance companies "streamline their workflow, automate repetitive tasks, and provide better service to their clients." Mangosuite includes various features like contact management, deal tracking, and task management.'],
      imageUrl: "../../assets/images/case-study-mangosuite-about-section-img.png"
    },
    story: {
      title: 'Story',
      description: [
        'Techware Lab worked on Mangosuite for our client BanqMart, a financial marketplace that offers banking products in a digital, modern, and accessible way. The project took one year to complete and ended with a CRM specified to BanqMart’s unique needs.',
        'Mangosuite offers a range of features to manage leads and customer relationships. One of the critical features of Mangosuite is its dashboard, which allows users to track the status of leads through every stage of the customer journey, from intake to closed deals. The dashboards are customizable with flexible widgets that can be modified to display and access the data users care about most.',
      ],
      buttonText:'VIEW MORE',
      imageUrl: "../../assets/images/case-study-mangosuite-story-section-img.png",
      viewMoreContent: [
        '<p>Another essential feature of Mangosuite is its lead management tools. The platform ensures all leads are accessible, assignable, tracked, and include the most up-to-date information. It also has lead filters for categorizing leads in the pipeline, making it easier for everyone to stay organized.</p>',
        '<p>Mangosuite also includes reporting tools that turn the data collected into actionable reports that improve the decision-making process. The platform allows for configuring user roles and permissions for agents, managers, and administrators. It also supports bulk uploading, enabling the quick addition of large lead lists to the platform.</p>',
        '<p>Finally, Mangosuite enables users to measure KPIs, focusing on their goals with deep insights into lead status, agent performance, attendance, and more. It is a custom-made CRM designed to help BanqMart\'s direct selling agents manage their leads and customer relationships more effectively.</p>',
      ]
      
    },
    plainBanner: {
      title: 'Get Your Financial CRM Designed with Our Top-Notch Development Solution',
      buttonText: 'CONTACT US'
    },
    results: {
      title: 'Results',
      description: [
        'Overall, Mangosuite has been a great success, providing BanqMart with a custom-made CRM and app that meets its direct selling agents\' specific needs. Techware Lab is proud to have been a part of this project and looks forward to continuing to work with Mangosuite in the future.'
      ],
      imageUrl: "../../assets/images/case-study-mangosuite-results-section-img.png"
    }
  };

  constructor(private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle(
      "Case Study MangoSuite  | TechwareLab"
    );
  }

}
