import { HttpInterceptor, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';
import {Location } from '@angular/common';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private location:Location,private router: Router,private spinnerService: SpinnerService,private _router:Router) {}

  getHeaders() {
    // const { token } = getLocalStorageItem('AUTH_TOKEN') || {};
    // const  token  = localStorage.getItem('AUTH_TOKEN')  || null;

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
        'Access-Control-Allow-Headers': 'Authorization',
        // 'Authorization': token ? token : '',
        'Auth': 'CODE_INFOTECH_AUTH'
      }),
    };
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req.clone(this.getHeaders());
    
    this.spinnerService.show();
    return next.handle(authReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.spinnerService.hide();
          if (event.body && event.body.status === '403') {
            // logout on 403
          }
        }
      }),
      catchError((error ) => {
        if (error instanceof HttpErrorResponse) {
          this.spinnerService.hide();
          if (error.status === 403) {
            localStorage.clear();
            // this.router.navigateByUrl('http://localhost:4500');
            // window.location.href =environment.goBackUrl
            this.location.back();
          }
          return throwError(error);
        }
      })
    );
  }
}
