<section class="gitex-navbar">
  <div class="container">
    <div class="wrapper">
      <img src="/assets/images/logo.svg" class="img-fluid" alt="Logo" />
    </div>
  </div>
</section>

<section class="contact-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-7">
        <div class="main-heading">
          <h1>Transform Your Vision into Reality With Techware Lab</h1>
          <h5>
            <!-- Why go solo when we can achieve greatness together? At Techware Lab,
            we don't just build custom software; we build partnerships. Our aim?
            To blend quality, value, and unparalleled service into applications
            tailored just for your business. -->
            <ul>
              <li>Build scalable web and mobile applications</li>
              <li>Experienced pool of developers</li>
              <li>Agile Development Methodology</li>
              <li>Effective testing before technology transfer</li>
              <li>Recognized for best-in-class support</li>
            </ul>
          </h5>
        </div>
      </div>
      <div class="col-5">
        <div class="contactMain">
          <div class="contact">
            <div class="contactForm mb-5">
              <div class="contactHead">
                <h4>Share Your App Concept</h4>
              </div>
              <div class="form-wrapper">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                  <div class="row">
                    <div class="col-12">
                      <div class="contact-item w-100 my-2">
                        <input
                          type="text"
                          class="style-input w-100"
                          placeholder="Full Name"
                          formControlName="name"
                        />
                      </div>
                      <div
                        class="s_error"
                        *ngIf="
                          (!form.controls['name'].valid &&
                            (form.controls['name'].dirty ||
                              form.controls['name'].touched)) ||
                          submitted
                        "
                      >
                        <div
                          class="s_validation"
                          *ngIf="form.controls['name'].hasError('required')"
                        >
                          *
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="contact-item w-100 my-2">
                        <input
                          type="email"
                          class="style-input w-100"
                          placeholder="Work Email"
                          formControlName="email"
                        />
                      </div>
                      <div
                        class="s_error"
                        *ngIf="
                          (!form.controls['email'].valid &&
                            (form.controls['email'].dirty ||
                              form.controls['email'].touched)) ||
                          submitted
                        "
                      >
                        <div
                          class="s_validation"
                          *ngIf="form.controls['email'].hasError('required')"
                        >
                          *
                        </div>
                        <div
                          class="s_validation"
                          *ngIf="form.controls['email'].hasError('email')"
                        >
                          Invalid
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-sm-3">
                        <div class="w-100 mb-2">
                          <mat-form-field
                            class="selectOption"
                            *ngIf="countryList"
                          >
                            <mat-select
                              formControlName="code"
                              class="selectOption style"
                            >
                              <mat-option
                                *ngFor="let item of countryList[0]"
                                [value]="item.code"
                                >{{ item.code }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div> -->
                    <div class="col-12">
                      <div class="contact-item">
                        <input
                          type="text"
                          class="style-input w-100"
                          placeholder="Mobile"
                          formControlName="phone"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div
                        class="s_error"
                        *ngIf="
                          (!form.controls['phone'].valid &&
                            (form.controls['phone'].dirty ||
                              form.controls['phone'].touched)) ||
                          submitted
                        "
                      >
                        <div
                          class="s_validation"
                          *ngIf="form.controls['phone'].hasError('required')"
                        >
                          *
                        </div>
                        <div
                          class="s_validation"
                          *ngIf="form.controls['phone'].hasError('pattern')"
                        >
                          Invalid
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="contact-item w-100 my-2">
                        <select name="" id="" formControlName="category">
                          <option value="" disabled selected>
                            What are you looking for?
                          </option>
                          <option value="Software Development">
                            Software Development
                          </option>
                          <option value="Mobile App Development">
                            Mobile App Development
                          </option>
                          <option value="Android App Development">
                            Android App Development
                          </option>
                          <option value="iOS App Development">
                            iOS App Development
                          </option>
                          <option value="Web App Development">
                            Web App Development
                          </option>
                          <option value="Website Development">
                            Website Development
                          </option>
                          <option value="Ecommerce App Development">
                            Ecommerce App Development
                          </option>
                          <option value="IoT App Development">
                            IoT App Development
                          </option>
                          <option value="Chatbot Development">
                            Chatbot Development
                          </option>
                        </select>
                      </div>
                      <div
                        class="s_error"
                        *ngIf="
                          (!form.controls['category'].valid &&
                            (form.controls['category'].dirty ||
                              form.controls['category'].touched)) ||
                          submitted
                        "
                      >
                        <div
                          class="s_validation"
                          *ngIf="form.controls['category'].hasError('required')"
                        >
                          *
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="contact-item w-100 my-2">
                        <textarea
                          class="style-input w-100"
                          placeholder="Describe your requirements"
                          formControlName="message"
                        ></textarea>
                      </div>
                      <div
                        class="s_error"
                        *ngIf="
                          (!form.controls['message'].valid &&
                            (form.controls['message'].dirty ||
                              form.controls['message'].touched)) ||
                          submitted
                        "
                      >
                        <div
                          class="s_validation"
                          *ngIf="form.controls['message'].hasError('required')"
                        >
                          *
                        </div>
                      </div>
                    </div>
                    <div class="col-12 my-2 text-center">
                      Your idea is protected with us.
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <input
                          type="submit"
                          class="submit-button custom-app-dev-submit-button w-100 my-2"
                          value="Talk With Our Experts"
                          [disabled]="!form.valid"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<br />
<section>
  <div class="custom-app">
    <div class="container">
      <div class="row">
        <div class="col-7">
          <img
            src="../../assets/images/custom-app-img1.png"
            alt=""
            class="w-100"
          />
        </div>
        <div class="col-5">
          <h2>Why Choose Us?</h2>
          <h5>A Commitment to Excellence in Custom Application Development</h5>
          <div class="item-1">
            <span>Experience</span>
            <p>
              With years of experience in custom application development, we
              offer expert solutions that cater to your unique business needs.
            </p>
          </div>
          <div class="item-2">
            <span>Technical Skills</span>
            <p>
              Our team of seasoned engineers specializes in custom web and
              mobile application development.
            </p>
          </div>
          <div class="item-3">
            <span>Client-Centric Approach</span>
            <p>
              Your needs come first. We offer flexible engagement models and
              project timelines tailored to your business goals.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<br />
<br />
<section>
  <div class="services">
    <div class="container">
      <h2>Our Services</h2>
      <h5>A Wide Array of Custom Application Services Tailored for You</h5>
      <div class="service-content pt-5">
        <div class="content content-1 px-5 pt-5 pb-3 mb-3">
          <img src="../../assets/images/mobile-app.png" alt="mobile-app" />
          <h5>Custom Mobile App Development</h5>
          <p>
            Harness the power of mobile by creating apps designed for iOS and
            Android platforms.
          </p>
        </div>
        <div class="content content-2 px-5 pt-5 pb-3 mb-3">
          <img src="../../assets/images/web-app.png" alt="web-app" />
          <h5>Custom Web Application Development Services</h5>
          <p>
            Build dynamic, robust web applications to achieve business
            objectives.
          </p>
        </div>
        <div class="content content-3 px-5 pt-5 pb-3 mb-3">
          <img
            src="../../assets/images/custom-software.png"
            alt="custom-software"
          />
          <h5>Custom Business Software Development</h5>
          <p>
            Integrate unique software solutions into your business model for
            increased productivity.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="deliver">
  <div class="container">
    <h2>How We Deliver</h2>
    <h4>A Proven Methodology for Custom Software Application Development</h4>
    <h6></h6>
    <div class="row mt-4">
      <div class="col-7 p-4">
        <img
          src="../../assets/images/deliver.png"
          alt="development"
          class="w-100"
        />
      </div>
      <div class="col-5">
        <h5>
          Our approach to custom software application development includes:
        </h5>
        <ul>
          <li>Helping You Find the Right Tech</li>
          <li>24x7 time zone-aligned development</li>
          <li>Flexible Agile Approach</li>
          <li>Simple, reliable UI/UX designs</li>
          <li>Continuous Testing and Improvement</li>
          <li>Global Data Security Guarantee</li>
          <li>Superior version control and code repositories</li>
          <li>Superior communication and project management</li>
          <li>Ongoing maintenance and support</li>
          <li>Thorough Project Documentation</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<br />
<br />
<section class="portfolio">
  <div class="container">
    <h2 class="pt-5">Portfolio</h2>
    <h5>Our Success Stories</h5>
    <p>
      Our portfolio boasts many projects, from custom web applications to robust
      mobile solutions for diverse industries.
    </p>
    <a href="https://techwarelab.com/portfolio" target="_blank"
      ><button>Learn more</button></a
    >
  </div>
</section>

<br />
<br />

<section class="investment">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <h2>Investment & ROI</h2>
        <h5 class="mt-4">Understanding the Cost of Custom App Development</h5>
        <p class="mt-3">
          While the custom app development cost can vary, we ensure to:
        </p>
        <ul class="mt-3">
          <li>Every dollar spent aims for maximum ROI.</li>
          <li>Committed to delivering long-term value.</li>
          <li>Aligning solutions with business growth metrics.</li>
          <li>Development cycles are designed to meet your deadlines.</li>
          <li>Detailed cost breakdowns are provided upfront.</li>
          <li>No hidden fees or surprise charges.</li>
          <li>Regular updates and milestone tracking.</li>
        </ul>
      </div>
      <div class="col-6">
        <img
          src="../../assets/images/investment.png"
          alt="investment"
          class="w-100 mt-5"
        />
      </div>
    </div>
  </div>
</section>
<br />
<br />
<section class="enterprise">
  <div class="container">
    <div class="row">
      <div class="col-5">
        <img
          src="../../assets/images/enterprise.png"
          alt="enterprise"
          class="w-100"
        />
      </div>
      <div class="col-7">
        <h2 class="pl-3">For Enterprises</h2>
        <h5 class="mt-3 pl-3">
          Specialized Services for Large-Scale Custom Enterprise Application
          Development
        </h5>
        <p class="mt-4 pl-3">
          We offer enterprise-grade scalability, robustness, and data security
          solutions. These solutions are designed to meet the complex needs of
          large organizations.
        </p>
      </div>
    </div>
  </div>
</section>

<br />
<br />

<section class="start">
  <div class="container">
    <h2 class="py-5">
      By investing in custom application development with Techware Lab, you're
      investing in a future of endless possibilities.
    </h2>
    <a>
      <button (click)="scrollToTop()">Get Started</button>
    </a>
  </div>
</section>

<footer class="techware-footer-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <img
          src="/assets/images/logo-white.png"
          class="img-fluid footer-logo"
          alt="Logo"
        />
        <p>
          Techware Lab is a highly professional software development team based
          out of Kochi, India with product and sales offices in Canada and UAE.
          We have delivered our web and mobile solutions and custom software to
          clients across the world since 2012.
        </p>
      </div>
      <div class="col-md-12">
        <div class="footer-social">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/TechwareLabPvtLtd"
                target="_blank"
                ><img src="/assets/images/facebook.png" alt="Facebook"
              /></a>
            </li>
            <li>
              <a href="https://www.instagram.com/techwarelab/" target="_blank"
                ><img src="/assets/images/instagram.png" alt="Instagram"
              /></a>
            </li>

            <li>
              <a href="https://twitter.com/TechWareWeb" target="_blank"
                ><img src="/assets/images/twitter.png" alt="Twitter"
              /></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-12 text-center ml-4">
        Phone - +971 56 415 5430, 09633292433
      </div>
      <div class="col-md-12">
        <h3 class="credit">© 2023 Techware Lab. All rights reserved.</h3>
      </div>
    </div>
  </div>
</footer>
