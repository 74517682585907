import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';
import { BlogDetailsService } from 'src/providers/blog-details.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  blog: any;
  blogSlug: any;
  searchParams: any;
  plink: any;

  public fbLink: string = environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;

  constructor(
    private title: Title,
    private meta: Meta,
    private seoService: SeoService,
    private blogService: BlogDetailsService,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {

    this.fetchBlog();

    // this.createLinkForCanonicalURL();

    // this.title.setTitle(
    //   "Ecommerce App Development Cost in 2022 | Techware Lab"
    // );
    // this.meta.updateTag({
    //   name: "description",
    //   content:
    //     "Several factors influence the cost of developing an e-commerce app. Each may differ in terms of features and design. This article discusses the cost of developing an eCommerce app in 2022.",
    // });
    // this.meta.updateTag({
    //   property: "og:description",
    //   content:
    //     "Several factors influence the cost of developing an e-commerce app. Each may differ in terms of features and design. This article discusses the cost of developing an eCommerce app in 2022.",
    // });
    // this.meta.updateTag({
    //   property: "og:image",
    //   content:
    //     "https://techwarelab.com/assets/images/blog-img-17.png",
    // });
    // this.meta.updateTag({
    //   property: "og:title",
    //   content:
    //     "Ecommerce App Development Cost in 2022 | Techware Lab",
    // });
    // this.meta.updateTag({
    //   property: "og:description",
    //   content:
    //     "Several factors influence the cost of developing an e-commerce app. Each may differ in terms of features and design. This article discusses the cost of developing an eCommerce app in 2022.",
    // });
    // this.meta.updateTag({
    //   property: "og:url",
    //   content:
    //     "https://techwarelab.com/ecommerce-app-development-cost-2022",
    // });
    // this.meta.updateTag({
    //   name: "twitter:image",
    //   content:
    //     "https://techwarelab.com/assets/images/blog-img-17.png",
    // });
    // this.meta.updateTag({
    //   name: "twitter:title",
    //   content:
    //     "Ecommerce App Development Cost in 2022 | Techware Lab",
    // });
    // this.meta.updateTag({
    //   name: "twitter:description",
    //   content:
    //     "Several factors influence the cost of developing an e-commerce app. Each may differ in terms of features and design. This article discusses the cost of developing an eCommerce app in 2022.",
    // });
    // this.meta.updateTag({
    //   name: "twitter:domain",
    //   content:
    //     "Ecommerce App Development Cost in 2022 | Techware Lab",
    // });
  }

  fetchBlog() {
    /** test code */

    this.route.params.subscribe((params) => {
      this.blogSlug = params['blogSlug'];
      console.log('Blog Slug:', this.blogSlug);
      this.blogService.getBlog(this.blogSlug).subscribe(
        (data) => {
          this.blog = data; // Assign API data to the blog object
          console.log("meta info - above ", this.blog.data);
          // Set the title and meta tags based on API data
          this.title.setTitle(this.blog?.data?.metaTitle);
          this.meta.updateTag({
            name: "description",
            content:
              this.blog?.data?.metaDescription,
          });
          this.meta.updateTag({
            property: "og:description",
            content:
              this.blog?.data?.metaDescription,
          });
          this.meta.updateTag({
            property: "og:image",
            content:
              this.blog?.data?.featuredImage,
          });
          this.meta.updateTag({
            property: "og:title",
            content:
              this.blog?.data?.metaTitle,
          });
          this.meta.updateTag({
            property: "og:description",
            content:
              this.blog?.data?.metaDescription,
          });
          this.meta.updateTag({
            property: "og:url",
            content:
            `https://techwarelab.com/${this.blog?.data?.slug}`,
          });
          this.meta.updateTag({
            name: "twitter:image",
            content:
              this.blog?.data?.featuredImage,
          });
          this.meta.updateTag({
            name: "twitter:title",
            content:
              this.blog?.data?.metaTitle,
          });
          this.meta.updateTag({
            name: "twitter:description",
            content:
              this.blog?.data?.metaDescription,
          });
          this.meta.updateTag({
            name: "twitter:domain",
            content:
              this.blog?.data?.metaTitle,
          });
          this.createLinkForCanonicalURL();
        },
        (error) => {
          console.error('Error Fetching Data:', error);
        }
      );
    });

    /** test code */
    // this.blogService.getBlog(this.blogSlug).subscribe(
    //   (data) => {
    //     console.log('Fetched Data: data ', data);
    //     this.blog = data;
    //     console.log('Fetching Data:', this.blog);
    //   },
    //   (error) => {
    //     console.error('Error Fetching Data:', error);
    //   }
    // );
    /** test code */
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    this.searchParams = new URLSearchParams();
    this.plink = this.blog?.data?.slug;
    switch (mediaType) {
      case 'facebook':
        this.searchParams.set('u', `https://techwarelab.com/${this.plink}`);
        this.navUrl = this.fbLink + this.searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        this.searchParams.set('url', `https://techwarelab.com/${this.plink}`);
        this.navUrl = this.tLink + this.searchParams;
        return window.open(this.navUrl, '_blank');

      case 'twitter':
        this.searchParams.set('Click Here', `https://techwarelab.com/${this.plink}`);
        this.navUrl = this.gLink + this.searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        this.searchParams.set('', `https://techwarelab.com/${this.plink}`);
        this.navUrl = this.wLink + this.searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }
}
