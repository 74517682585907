import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-nodejs-for-large-scale',
  templateUrl: './nodejs-for-large-scale.component.html',
  styleUrls: ['./nodejs-for-large-scale.component.scss'],
})
export class NodejsForLargeScaleComponent implements OnInit {
  public fbLink: string = environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;
  constructor(
    private title: Title,
    private meta: Meta,
    private seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      'The Pros and Cons of Using Node.js for Large-Scale Web Applications'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'Node.js is great for making applications that need to work in real time, and it has lots of benefits for businesses.',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Node.js is great for making applications that need to work in real time, and it has lots of benefits for businesses.',
    });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://techwarelab.com/assets/images/nodejs-for-large-scale.webp',
    });
    this.meta.updateTag({
      property: 'og:title',
      content:
        'The Pros and Cons of Using Node.js for Large-Scale Web Applications',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Node.js is great for making applications that need to work in real time, and it has lots of benefits for businesses.',
    });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://techwarelab.com/pros-and-cons-of-using-nodejs',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://techwarelab.com/assets/images/nodejs-for-large-scale.webp',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'The Pros and Cons of Using Node.js for Large-Scale Web Applications',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Node.js is great for making applications that need to work in real time, and it has lots of benefits for businesses.',
    });
    this.meta.updateTag({
      name: 'twitter:domain',
      content:
        'The Pros and Cons of Using Node.js for Large-Scale Web Applications',
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `pros-and-cons-of-using-nodejs`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u', `https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }
}
