import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-how-itstaff-augmentation',
  templateUrl: './how-itstaff-augmentation.component.html',
  styleUrls: ['./how-itstaff-augmentation.component.scss']
})
export class HowITStaffAugmentationComponent implements OnInit {

  public fbLink: string = environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;
  constructor(
    private title: Title,
    private meta: Meta,
    private seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      'How IT Staff Augmentation Can Fuel Your Growth'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'Unlock your business potential with IT staff augmentation. Boost growth and efficiency through expert talent and flexible resource allocation',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Unlock your business potential with IT staff augmentation. Boost growth and efficiency through expert talent and flexible resource allocation',
    });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://techwarelab.com/assets/images/it-staff-augmentation-fuel-your-growth.webp',
    });
    this.meta.updateTag({
      property: 'og:title',
      content:
        'How IT Staff Augmentation Can Fuel Your Growth',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Unlock your business potential with IT staff augmentation. Boost growth and efficiency through expert talent and flexible resource allocation',
    });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://techwarelab.com/how-it-staff-augmentation-can-fuel-your-growth',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://techwarelab.com/assets/images/it-staff-augmentation-fuel-your-growth.webp',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'How IT Staff Augmentation Can Fuel Your Growth',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Unlock your business potential with IT staff augmentation. Boost growth and efficiency through expert talent and flexible resource allocation',
    });
    this.meta.updateTag({
      name: 'twitter:domain',
      content:
        'How IT Staff Augmentation Can Fuel Your Growth',
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `how-it-staff-augmentation-can-fuel-your-growth`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u', `https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }

}
