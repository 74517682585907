import { PartnerLandingPageComponent } from './partner-landing-page/partner-landing-page.component';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatTabsModule } from '@angular/material/tabs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { IndexComponent } from './index/index.component';
import { AboutComponent } from './about/about.component';
import { BlogComponent } from './blog/blog.component';

import { ContactComponent } from './contact/contact.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ProductsComponent } from './products/products.component';
import { ServicesComponent } from './services/services.component';
import { WeHaveRebrandedComponent } from './we-have-rebranded/we-have-rebranded.component';
import { RequestQuoteComponent } from './request-quote/request-quote.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MessageSuccessComponent } from './message-success/message-success.component';
import { WhyBusinessNeedsChatbotComponent } from './why-business-needs-chatbot/why-business-needs-chatbot.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CareersComponent } from './careers/careers.component';
import { BenefitsChatbotForBusinessComponent } from './benefits-chatbot-for-business/benefits-chatbot-for-business.component';
import { MobileAppIdeasComponent } from './mobile-app-ideas/mobile-app-ideas.component';
import { CareerFormComponent } from './career-form/career-form.component';
import { HowMuchCostDevelopOndemandDeliveryAppComponent } from './how-much-cost-develop-ondemand-delivery-app/how-much-cost-develop-ondemand-delivery-app.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TaxiDispatchSoftwareComponent } from './taxi-dispatch-software/taxi-dispatch-software.component';
import { ThingsToPlanMobileAppDevelopmentComponent } from './things-to-plan-mobile-app-development/things-to-plan-mobile-app-development.component';
import { BestPartnerForYourStartupComponent } from './best-partner-for-your-startup/best-partner-for-your-startup.component';
import { DoctorAppointmentAppComponent } from './doctor-appointment-app/doctor-appointment-app.component';
import { LegalCaseManagementSoftwareComponent } from './legal-case-management-software/legal-case-management-software.component';
import { TaxiManagementSystemComponent } from './taxi-management-system/taxi-management-system.component';
import { GitexLandingPageComponent } from './gitex-landing-page/gitex-landing-page.component';
import { PartnershipComponent } from './partnership/partnership.component';
import { StaffAugmentationComponent } from './staff-augmentation/staff-augmentation.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { MobileAppDevelopmentComponent } from './mobile-app-development/mobile-app-development.component';
import { HiringRemoteDevelopersFromIndiaComponent } from './hiring-remote-developers-from-india/hiring-remote-developers-from-india.component';
import { WhyFlutterForMobileAppDevelopmentComponent } from './why-flutter-for-mobile-app-development/why-flutter-for-mobile-app-development.component';
import { GroceryAppDevelopmentComponent } from './grocery-app-development/grocery-app-development.component';
import { OnlineFoodOrderingSystemComponent } from './online-food-ordering-system/online-food-ordering-system.component';
import { IosDevelopmentComponent } from './ios-development/ios-development.component';
import { SoftwareDevelopmentComponent } from './software-development/software-development.component';
import { BenefitsOfItStaffAugmentationServicesComponent } from './benefits-of-it-staff-augmentation-services/benefits-of-it-staff-augmentation-services.component';
import { AndroidAppDevelopmentComponent } from './android-app-development/android-app-development.component';
import { BrandingServicesComponent } from './branding-services/branding-services.component';
import { HowBuildFoodDeliveryAppLikeUberEatsComponent } from './how-build-food-delivery-app-like-uber-eats/how-build-food-delivery-app-like-uber-eats.component';
import { TopMobileAppDevelopmentFrameworksAppDevelopersComponent } from './top-mobile-app-development-frameworks-app-developers/top-mobile-app-development-frameworks-app-developers.component';
import { MatrimonyAppDevelopmentComponent } from './matrimony-app-development/matrimony-app-development.component';
import { AutomotiveAppointmentSoftwareComponent } from './automotive-appointment-software/automotive-appointment-software.component';
import { BeautySalonSoftwareComponent } from './beauty-salon-software/beauty-salon-software.component';
import { PayrollManagementSystemComponent } from './payroll-management-system/payroll-management-system.component';
import { CarRentalAppDevelopmentComponent } from './car-rental-app-development/car-rental-app-development.component';
import { HowDoFreeAppsMakeMoneyComponent } from './how-do-free-apps-make-money/how-do-free-apps-make-money.component';
import { MovieTicketingSoftwareComponent } from './movie-ticketing-software/movie-ticketing-software.component';
import { EnterpriseDevelopmentComponent } from './enterprise-development/enterprise-development.component';
import { EcommerceWebSolutionsComponent } from './ecommerce-web-solutions/ecommerce-web-solutions.component';
import { SoftwareDevelopmentSolutionsComponent } from './software-development-solutions/software-development-solutions.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { WebAnalyticsComponent } from './web-analytics/web-analytics.component';
import { BusinessConsultingComponent } from './business-consulting/business-consulting.component';
import { UserResearchAndDiscoveryComponent } from './user-research-and-discovery/user-research-and-discovery.component';
import { GotoMarketStrategyComponent } from './goto-market-strategy/goto-market-strategy.component';
import { UiUxDesignTrendsComponent } from './ui-ux-design-trends/ui-ux-design-trends.component';
import { HowToEnsureMobileAppSecurityComponent } from './how-to-ensure-mobile-app-security/how-to-ensure-mobile-app-security.component';
import { EcommerceAppDevelopmentComponent } from './ecommerce-app-development/ecommerce-app-development.component';
import { BenefitsOfUsingFirebaseComponent } from './benefits-of-using-firebase/benefits-of-using-firebase.component';
import { ImportanceOfEcommerceDuringComponent } from './importance-of-ecommerce-during/importance-of-ecommerce-during.component';
import { JoinOurTeamQuoteComponent } from './join-our-team-quote/join-our-team-quote.component';
import { SuccessfulUIDesignComponent } from './successful-ui-design/successful-ui-design.component';
import { LibraryManagementSystemComponent } from './library-management-system/library-management-system.component';
import { ECommerceTrendsComponent } from './ecommerce-trends/ecommerce-trends.component';
import { HealthcareAppDevelopmentComponent } from './healthcare-app-development/healthcare-app-development.component';
import { BenefitsOfOnDemandComponent } from './benefits-of-on-demand/benefits-of-on-demand.component';
import { UnseenRisksInMobileAppDevelopmentIndustryComponent } from './unseen-risks-in-mobile-app-development-industry/unseen-risks-in-mobile-app-development-industry.component';
import { GraphicDesignImportantComponent } from './graphic-design-important/graphic-design-important.component';
import { RedesigningYourWebsiteComponent } from './redesigning-your-website/redesigning-your-website.component';
import { EducationPopupComponent } from './education-popup/education-popup.component';
import { WhyLegalAppointmentComponent } from './why-legal-appointment/why-legal-appointment.component';
import { YourTaxiBusinessNeedsComponent } from './your-taxi-business-needs/your-taxi-business-needs.component';
import { MongoDBBlogComponent } from './mongo-dbblog/mongo-dbblog.component';
import { ECommerceSEOComponent } from './e-commerce-seo/e-commerce-seo.component';
import { ChatGPTExperienceComponent } from './chat-gptexperience/chat-gptexperience.component';
import { NodejsForLargeScaleComponent } from './nodejs-for-large-scale/nodejs-for-large-scale.component';
import { WhiteLabelingBlogComponent } from './white-labeling-blog/white-labeling-blog.component';
import { HowITStaffAugmentationComponent } from './how-itstaff-augmentation/how-itstaff-augmentation.component';
import { BuildingForSuccessComponent } from './building-for-success/building-for-success.component';
import { FromClicksToCustomersComponent } from './from-clicks-to-customers/from-clicks-to-customers.component';
import { CostOfEcommerceWebsiteDevelopmentComponent } from './cost-of-ecommerce-website-development/cost-of-ecommerce-website-development.component';
import { StayAheadoftheCurveComponent } from './stay-aheadofthe-curve/stay-aheadofthe-curve.component';
import { SEOTrendsFor2023Component } from './seotrends-for2023/seotrends-for2023.component';
import { VuejsVsReactComponent } from './vuejs-vs-react/vuejs-vs-react.component';
import { TheImportanceOfInclusiveWebsiteDesignComponent } from './the-importance-of-inclusive-website-design/the-importance-of-inclusive-website-design.component';
import { CustomAppDevelopmentComponent } from './custom-app-development/custom-app-development.component';

// test import
import { ApolloModule, APOLLO_OPTIONS, Apollo } from 'apollo-angular';
import { InMemoryCache } from 'apollo-cache-inmemory';
// import { HttpLink } from 'apollo-angular/http';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { blogConfig } from 'src/environments/server.config';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SpinnerService } from './shared/spinner.service';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { CustomHttpInterceptor } from './shared/http.interceptor';
import { UiUxServiceComponent } from './ui-ux-service/ui-ux-service.component';
import { SocialMediaOptimizationComponent } from './social-media-optimization/social-media-optimization/social-media-optimization.component';
import { TestingQaServiceComponent } from './testing-qa-service/testing-qa-service/testing-qa-service.component';
import { SearchEngineOptimizationComponent } from './search-engine-optimization/search-engine-optimization/search-engine-optimization.component';
import { PerformanceMarketingSericeComponent } from './performance-marketing-service/performance-marketing-serice/performance-marketing-serice.component';
import { ItConsultingComponent } from './it-consulting/it-consulting.component';
import { MarketingAutomationComponent } from './marketing-automation/marketing-automation.component';
import { DevopsConsultingComponent } from './devops-consulting/devops-consulting.component';
import { DedicatedDevelopmentTeamComponent } from './dedicated-development-team/dedicated-development-team.component';
import { CustomSoftwareDevelopmentServiceComponent } from './custom-software-development-service/custom-software-development-service.component';
import { ContentMarketingServiceComponent } from './content-marketing-service/content-marketing-service.component';
import { CaseStudyDarazComponent } from './case-study-daraz/case-study-daraz.component';
import { HeadingSectionComponent } from './heading-section/heading-section/heading-section.component';
import { AboutSectionComponent } from './about-section/about-section.component';
import { StorySectionComponent } from './story-section/story-section.component';
import { PlainBannerComponent } from './plain-banner/plain-banner.component';
import { ResultsSectionComponent } from './results-section/results-section.component';
import { FormSectionComponent } from './form-section/form-section.component';
import { CaseStudyKpComponent } from './case-study-kp/case-study-kp.component';
import { CaseStudyXlriComponent } from './case-study-xlri/case-study-xlri.component';
import { CaseStudyMangosuiteComponent } from './case-study-mangosuite/case-study-mangosuite.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    IndexComponent,
    AboutComponent,
    BlogComponent,
    ContactComponent,
    PortfolioComponent,
    ProductsComponent,
    ServicesComponent,
    WeHaveRebrandedComponent,
    RequestQuoteComponent,
    MessageSuccessComponent,
    WhyBusinessNeedsChatbotComponent,
    ThankYouComponent,
    PageNotFoundComponent,
    CareersComponent,
    BenefitsChatbotForBusinessComponent,
    MobileAppIdeasComponent,
    CareerFormComponent,
    HowMuchCostDevelopOndemandDeliveryAppComponent,
    TaxiDispatchSoftwareComponent,
    ThingsToPlanMobileAppDevelopmentComponent,
    BestPartnerForYourStartupComponent,
    DoctorAppointmentAppComponent,
    LegalCaseManagementSoftwareComponent,
    TaxiManagementSystemComponent,
    GitexLandingPageComponent,
    PartnershipComponent,
    StaffAugmentationComponent,
    WebDevelopmentComponent,
    MobileAppDevelopmentComponent,
    HiringRemoteDevelopersFromIndiaComponent,
    WhyFlutterForMobileAppDevelopmentComponent,
    GroceryAppDevelopmentComponent,
    OnlineFoodOrderingSystemComponent,
    IosDevelopmentComponent,
    SoftwareDevelopmentComponent,
    BenefitsOfItStaffAugmentationServicesComponent,
    AndroidAppDevelopmentComponent,
    BrandingServicesComponent,
    HowBuildFoodDeliveryAppLikeUberEatsComponent,
    TopMobileAppDevelopmentFrameworksAppDevelopersComponent,
    MatrimonyAppDevelopmentComponent,
    AutomotiveAppointmentSoftwareComponent,
    BeautySalonSoftwareComponent,
    PayrollManagementSystemComponent,
    CarRentalAppDevelopmentComponent,
    HowDoFreeAppsMakeMoneyComponent,
    MovieTicketingSoftwareComponent,
    EnterpriseDevelopmentComponent,
    EcommerceWebSolutionsComponent,
    SoftwareDevelopmentSolutionsComponent,
    DigitalMarketingComponent,
    WebAnalyticsComponent,
    BusinessConsultingComponent,
    UserResearchAndDiscoveryComponent,
    GotoMarketStrategyComponent,
    UiUxDesignTrendsComponent,
    HowToEnsureMobileAppSecurityComponent,
    EcommerceAppDevelopmentComponent,
    BenefitsOfUsingFirebaseComponent,
    ImportanceOfEcommerceDuringComponent,
    JoinOurTeamQuoteComponent,
    SuccessfulUIDesignComponent,
    LibraryManagementSystemComponent,
    ECommerceTrendsComponent,
    HealthcareAppDevelopmentComponent,
    BenefitsOfOnDemandComponent,
    UnseenRisksInMobileAppDevelopmentIndustryComponent,
    GraphicDesignImportantComponent,
    RedesigningYourWebsiteComponent,
    EducationPopupComponent,
    WhyLegalAppointmentComponent,
    YourTaxiBusinessNeedsComponent,
    MongoDBBlogComponent,
    ECommerceSEOComponent,
    ChatGPTExperienceComponent,
    NodejsForLargeScaleComponent,
    WhiteLabelingBlogComponent,
    HowITStaffAugmentationComponent,
    BuildingForSuccessComponent,
    FromClicksToCustomersComponent,
    CostOfEcommerceWebsiteDevelopmentComponent,
    StayAheadoftheCurveComponent,
    SEOTrendsFor2023Component,
    VuejsVsReactComponent,
    TheImportanceOfInclusiveWebsiteDesignComponent,
    PartnerLandingPageComponent,
    CustomAppDevelopmentComponent,
    BlogDetailsComponent,
    BlogComponent,
    PartnerLandingPageComponent,
    UiUxServiceComponent,
    SocialMediaOptimizationComponent,
    TestingQaServiceComponent,
    SearchEngineOptimizationComponent,
    PerformanceMarketingSericeComponent,
    ItConsultingComponent,
    MarketingAutomationComponent,
    DevopsConsultingComponent,
    DedicatedDevelopmentTeamComponent,
    CustomSoftwareDevelopmentServiceComponent,
    ContentMarketingServiceComponent,
    
    CaseStudyDarazComponent,
    CaseStudyKpComponent,
    CaseStudyXlriComponent,
    CaseStudyMangosuiteComponent,
    HeadingSectionComponent,
    AboutSectionComponent,
    StorySectionComponent,
    PlainBannerComponent,
    ResultsSectionComponent,
    FormSectionComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxCaptchaModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    MatExpansionModule,
    CarouselModule,
    MatTabsModule,
    MatSelectModule,
    ApolloModule,
    HttpLinkModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
  ],
  exports: [ApolloModule], // added
  providers: [
    Meta,
    Apollo,
    SpinnerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: blogConfig
          }),
        };
      },
      deps: [HttpLink],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
