<section class="career-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="circle-wrapper">
          <h1>
            TFood: Online Food <br />
            Ordering Software <br />
            Built for Your Restaurant
          </h1>
          <h2 class="cmn-sub-heading">
            Beautifully crafted dishes delivered right to the door. Start
            serving delicious food with an online ordering system that’s
            tailored to your unique brand.
          </h2>
          <a (click)="requestQuote()" target="_blank">Request a demo</a>
        </div>
      </div>
      <div class="col-md-5">
        <img
          src="/assets/images/tfood-img-1.png"
          class="img-fluid"
          alt="ttaxi"
        />
      </div>
    </div>
  </div>
</section>

<section class="whatistaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-5" data-aos="fade-right">
        <img
          src="/assets/images/tfood-img-2.png"
          class="img-fluid"
          alt="ttaxi"
        />
      </div>
      <div class="col-md-7">
        <div class="content">
          <h1 class="cmn-heading">What Is TFood?</h1>
          <p>
            Put your menu right in the palms of your potential customers with
            TFood. This all-in-one food ordering app lets customers order
            directly online for convenient and quick remote food delivery.
            Whether you’re a fast-food chain, a hotel cafe, or a high-end
            vendor, our online food ordering system is made for you.
          </p>
          <p>
            Give your users more control over the meals they order. With TFood,
            customers can choose from available dishes, add them to their
            shopping cart, select between delivery and pickup, finalize delivery
            details, make payment, and receive their food.
          </p>
          <p>
            The growing on-demand delivery service trend continues to reshape
            the restaurant industry. Take advantage of that trend and grow your
            restaurant by reaching new customers all throughout your city.
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
        <div class="taxi-box">
          <img
            src="/assets/images/tfood-icon-1.png"
            class="img-fluid"
            alt="TTaxi"
          />
          <h2>
            Sort Through a Wide Selection <br />
            of Cuisines Easily
          </h2>
          <p>
            Utilize search filters to find what you want to eat quickly. TFood
            lets customers find individual restaurants and branches through
            Google’s location services and check for customer reviews right from
            the app
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
        <div class="taxi-box">
          <img
            src="/assets/images/tfood-icon-2.png"
            class="img-fluid"
            alt="TTaxi"
          />
          <h2>
            Make Taking Payments Easy<br />
            and Seamless
          </h2>
          <p>
            Customers enjoy real-time status updates and easily view order
            histories on one platform. TFood’s online payment gateway also makes
            it easy to complete payments so you just have to worry about
            enjoying your meal when it gets to your door.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
        <div class="taxi-box">
          <img
            src="/assets/images/tfood-icon-3.png"
            class="img-fluid"
            alt="TTaxi"
          />
          <h2>
            Display Unique Restaurant<br />
            Offerings on One Platform
          </h2>
          <p>
            TFood makes it easy for potential customers to find your restaurant
            and its unique offerings in one place. With a responsive UI, it’s
            easy to browse through your menu, view images of the restaurant, and
            check out deals and discounts.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="ttaxi-features-wrapper">
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-4">
        <h1 class="cmn-heading">TFood Features</h1>
        <img
          src="/assets/images/tfood-img-3.png"
          class="img-fluid"
          alt="TTaxi"
        />
      </div>
      <div class="col-md-8 mt-5">
        <h2>User App</h2>
        <ul class="list-1">
          <li>
            <span>1</span>
            <h6>Pickup and Destination by Type / Map</h6>
          </li>
          <li>
            <span>2</span>
            <h6>See Product Details & Description</h6>
          </li>
          <li>
            <span>3</span>
            <h6>Confirmation of Products & Delivery details</h6>
          </li>
          <li>
            <span>4</span>
            <h6>Manage Quantity — Add to Cart product</h6>
          </li>
          <li>
            <span>5</span>
            <h6>Multiple Order</h6>
          </li>
          <li>
            <span>6</span>
            <h6>Add Complete Delivery Information</h6>
          </li>
          <li>
            <span>7</span>
            <h6>Review and Rating</h6>
          </li>
          <li>
            <span>8</span>
            <h6>Order Cancellation</h6>
          </li>
          <li>
            <span>9</span>
            <h6>Payment Method -Cash On Delivery</h6>
          </li>
          <li>
            <span>10</span>
            <h6>Emergency Contact</h6>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6 order-md-0 order-1">
        <h2>Rider App</h2>
        <ul class="list-2">
          <li>
            <span>1</span>
            <h6>Register and login</h6>
          </li>
          <li>
            <span>2</span>
            <h6>Profile</h6>
          </li>
          <li>
            <span>3</span>
            <h6>Legal Document Evaluation</h6>
          </li>
          <li>
            <span>4</span>
            <h6>License</h6>
          </li>
          <li>
            <span>5</span>
            <h6>Vehicle Registration</h6>
          </li>
          <li>
            <span>6</span>
            <h6>Accept Orders</h6>
          </li>
          <li>
            <span>7</span>
            <h6>Update Orders</h6>
          </li>
          <li>
            <span>8</span>
            <h6>View past delivery history</h6>
          </li>
          <li>
            <span>10</span>
            <h6>View Earnings</h6>
          </li>
        </ul>
      </div>
      <div class="col-md-6 order-md-1 order-0">
        <img
          src="/assets/images/tfood-img-4.png"
          class="img-fluid"
          alt="TTaxi"
        />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-4">
        <img
          src="/assets/images/tfood-img-5.png"
          class="img-fluid"
          alt="TTaxi"
        />
      </div>
      <div class="col-md-8">
        <h2>Admin Dashboard</h2>
        <ul class="list-3">
          <li>
            <span>1</span>
            <h6>Restaurant Management</h6>
          </li>
          <li>
            <span>2</span>
            <h6>Restaurant Owner Management</h6>
          </li>
          <li>
            <span>3</span>
            <h6>Customer Management</h6>
          </li>
          <li>
            <span>4</span>
            <h6>Delivery Rider Management</h6>
          </li>
          <li>
            <span>5</span>
            <h6>Rating Management</h6>
          </li>
          <li>
            <span>6</span>
            <h6>Product Management</h6>
          </li>
          <li>
            <span>7</span>
            <h6>Category Management</h6>
          </li>
          <li>
            <span>8</span>
            <h6>Favorite Management</h6>
          </li>
          <li>
            <span>9</span>
            <h6>Order Management</h6>
          </li>
          <li>
            <span>10</span>
            <h6>Region Management</h6>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="abouttaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-7 order-md-0 order-1">
        <div class="content">
          <h1 class="cmn-heading">About Techware Lab</h1>
          <p>
            Techware Lab provides best-in-class web design capabilities and
            software solutions through industry-specific apps to upgrade your
            business. Our team delivers unique technological solutions for iOS
            app development and Android app development that make restaurant
            food ordering and delivery a breeze for customers and restaurants.
            TFood’s integrated user interface means satisfying those food
            cravings is easier than ever.
          </p>
        </div>
      </div>
      <div class="col-md-5 order-md-1 order-0" data-aos="fade-left">
        <img
          src="/assets/images/tfood-img-6.png"
          class="img-fluid"
          alt="ttaxi"
        />
      </div>
    </div>
  </div>
</section>

<section class="footer-banner-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">Bring the Restaurant Experience Online With TFood</h1>
        <h2 class="cmn-sub-heading">
          Give your restaurant a competitive edge with TFood. It is time to equip your restaurant business with a mobile online ordering app to raise direct sales. Techware Lab can help.
        </h2>
        <button routerLink="/contact">Start serving remotely</button>
      </div>
    </div>
  </div>
</section>

<section class="faq-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Frequently Asked Questions</h1>
    <mat-accordion>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How do online ordering systems work?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          TFood is an online food ordering system that allows restaurant businesses to accept and manage orders placed through a website or mobile app. TFood operates similarly to online takeaway: your customer orders online, completes their payment, and can choose pickup or delivery for their order.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Will developing an online restaurant ordering system be useful for my restaurant? </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Yes. Busy work-life schedules mean more customers want to experience their favorite restaurants from the comfort of their home. Your restaurant's website or mobile app allows customers to easily browse the menu, customize dishes, and place orders. Additionally, it can save their favorite orders so they can easily reorder them in the future.

        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How much does it cost to build a food delivery app like Zomato?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          The cost of developing an application starts at $5,000. Costs vary depending on what features, functionality, and integrations the app needs. Additional factors that influence cost include location, number of developers required, turnaround time, and more.

        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            I am a non-technical person, can you help me find the right technology for my start-up project?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Yes, of course! Feel free to get in touch with us to discuss the right technology for your idea by emailing hello@techwarelab.com.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>

<section class="relates-solutions-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Related Solutions</h1>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <div class="related-box" routerLink="/legal-case-management-software">
            <img src="/assets/images/new-related-img1.png" class="img-fluid" alt="Images">
        </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/doctor-appointment-app">
              <img src="/assets/images/new-related-img2.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-management-system">
              <img src="/assets/images/new-related-img3.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-dispatch-software">
              <img src="/assets/images/new-related-img4.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/grocery-app-development">
              <img src="/assets/images/new-related-img5.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/car-rental-app-development">
              <img src="/assets/images/new-related-img7.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/movie-ticketing-software">
              <img src="/assets/images/new-related-img8.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/beauty-salon-software">
              <img src="/assets/images/new-related-img9.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/automotive-appointment-software">
              <img src="/assets/images/new-related-img10.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/matrimony-app-development">
              <img src="/assets/images/new-related-img11.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/payroll-management-system">
              <img src="/assets/images/new-related-img12.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>
