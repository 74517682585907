<section class="gitex-navbar">
    <div class="container">
      <div class="wrapper">
        <img src="/assets/images/logo.svg" class="img-fluid" alt="Logo" />
      </div>
    </div>
  </section>
  
  <div class="new-sec">
    <section class="career-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <div class="circle-wrapper">
              <h1>
                Education Software <br />
                Development
              </h1>
              <h2 class="cmn-sub-heading">
                Boost Your Institution with Our Innovative Software Solutions
              </h2>
              <a (click)="OpenPopup()"
                >Book Your Free Consultation</a
              >
            </div>
          </div>
          <div class="col-md-5">
            <div class="banner-img">
              <img
                src="/assets/images/software-new.webp"
                class="img-fluid"
                alt="ttaxi"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section class="whatistaxi-wrapper">
      <div class="container">
        <div class="row pb-4">
          <div class="col-md-6" data-aos="fade-right" data-aos-duration="3000">
            <img
              src="/assets/images/software-dev-1.png"
              class="img-fluid"
              alt="ttaxi"
            />
          </div>
          <div class="col-md-6">
            <div class="content">
              <h1 class="cmn-heading">
                Empower Your Students with <br />
                Our Education Software Solutions
              </h1>
              <p>
                Our education software solutions are designed to empower students
                and teachers alike. We understand the importance of providing
                quality education to students, and our software is designed to
                make learning more engaging, interactive, and effective.
              </p>
              <p>
                Our software solutions include tools for personalized learning,
                interactive assessments, progress tracking, and more. They allow
                students to take charge of their learning journey, track their
                progress, and get real-time feedback.
              </p>
              <p>
                Teachers can easily create and share content with students, while
                students can access materials on the go using their devices. Our
                tools also help teachers identify areas where students may need
                extra support and provide resources to help them succeed.
              </p>
              <p>
                Let’s help our students and teachers reach their full potential.
                Contact us today to learn about how we can help you enhance your
                classroom experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section class="about-content-wrapper background-wrap">
      <div class="container">
        <div class="row pb-5">
          <div class="col-md-12 mb-1">
            <div class="text-head">
              <h2>User-Friendly & Interactive Custom Education Software</h2>
              <p>
                We develop innovative solutions to help our clients improve the
                quality of education
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-md-6 order-md-1 order-0 text-right"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div >
              <img
                src="/assets/images/software-img-1.png"
                class="img-fluid"
                alt="Cutting edge technologies"
              />
            </div>
          </div>
          <div class="col-md-6 order-md-0 order-1">
            <div class="about-box">
              <h2>ERP Management Solution</h2>
              <p>
                We provide ERP management solutions tailored to the education
                industry. Our platforms streamline administrative and academic
                operations, including student management, admissions, fee
                collection, and monitoring. They also enhance efficiency and
                promote a modern and digital learning environment.
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6" data-aos="fade-up" data-aos-duration="3000">
            <div >
              <img
                src="/assets/images/software-img-2.png"
                class="img-fluid"
                alt="End to end software development"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="about-box">
              <h2>LMS Module</h2>
              <p>
                Our LMS modules are designed to offer a user-friendly platform for
                educators, administrators, and students. Our solutions help our
                clients manage and access course materials, assignments, grades,
                and other important information with a few simple clicks.
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div
            class="col-md-6 order-md-1 order-0 text-right"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div >
              <img
                src="/assets/images/software-img-3.png"
                class="img-fluid"
                alt="Successful business partnership"
              />
            </div>
          </div>
          <div class="col-md-6 order-md-0 order-1">
            <div class="about-box">
              <h2>HRMS for Institution Employees</h2>
              <p>
                We develop HRMS solutions tailored for educational institutions.
                Our clients have enjoyed streamlined employee management,
                automated attendance tracking, efficient payroll management, and
                quick performance evaluation processes. Find out how an HRMS will
                increase efficiency and transparency within your educational
                institution.
              </p>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="col-md-6" data-aos="fade-up" data-aos-duration="3000">
            <div >
              <img
                src="/assets/images/software-img-4.png"
                class="img-fluid"
                alt="Partnership for the goals"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="about-box background-blue">
              <h2>CRM Module</h2>
              <p>
                Our CRM module is designed to manage interactions and
                relationships with students, parents, and other stakeholders. It
                enables tracking of inquiries, admissions, academic progress, and
                communication with students and parents. It also facilitates the
                creation of reports and analytics to improve decision-making and
                student outcomes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section class="about-content-wrapper wrapper-one">
      <div class="container">
        <div class="row pb-5">
          <div class="col-md-12 mb-1">
            <div class="text-head">
              <h2>
                Features That Make Your Institution’s <br />
                Management Processes Flawless
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-md-6 order-md-1 order-0 text-right"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div >
              <img
                src="/assets/images/software-img-5.png"
                class="img-fluid"
                alt="Cutting edge technologies"
              />
            </div>
          </div>
          <div class="col-md-6 order-md-0 order-1">
            <div class="about-box">
              <h2>Academic Management</h2>
              <p>
                We developed a comprehensive Academic Management system for
                educational institutions. The system offers an all-in-one solution
                for managing admissions, student data, course schedules,
                attendance, grades, and so much more. This platform allows
                educational institutions to streamline their academic operations
                and enhance their administrative efficiency.
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6" data-aos="fade-up" data-aos-duration="3000">
            <div >
              <img
                src="/assets/images/software-img-6.png"
                class="img-fluid"
                alt="End to end software development"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="about-box">
              <h2>Admission Management</h2>
              <p>
                Techware Lab ensures that the system streamlines the admission
                process by automating application submission, document
                verification, and applicant communication. It also provides
                real-time updates to administrators and integrates with existing
                student information systems.
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div
            class="col-md-6 order-md-1 order-0 text-right"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div >
              <img
                src="/assets/images/software-img-7.png"
                class="img-fluid"
                alt="Successful business partnership"
              />
            </div>
          </div>
          <div class="col-md-6 order-md-0 order-1">
            <div class="about-box">
              <h2>Alumni Management</h2>
              <p>
                We ensure institutions with a centralized database manage and
                engage with their alumni community, enabling seamless
                communication, event management, and fundraising efforts. The
                user-friendly and customizable system has robust security features
                to protect sensitive information.
              </p>
            </div>
          </div>
        </div>
  
        <div class="row mt-4">
          <div class="col-md-6" data-aos="fade-up" data-aos-duration="3000">
            <div >
              <img
                src="/assets/images/software-img-8.png"
                class="img-fluid"
                alt="Partnership for the goals"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="about-box">
              <h2>
                Education Software Development <br />
                and Maintenance
              </h2>
              <p>
                Techware Lab specializes in developing and maintaining education
                software. Our team of experts creates innovative solutions that
                help students learn more effectively and efficiently. We are
                committed to providing high-quality products that enhance the
                educational experience for both teachers and students.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section class="welcome-set">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>
              Why Choose Techware Lab as Your <br />
              Education Software Development Company?
            </h1>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
            <div class="feature-box">
              <h3 class="red">Dedicated Team</h3>
              <p>
                Our dedicated team of experts is ready to create your custom
                education software solution. We work closely with you from concept
                to deployment to develop a tailored solution that meets your
                unique needs.
              </p>
            </div>
          </div>
          <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
            <div class="feature-box">
              <h3 class="blue">Agile Processes</h3>
              <p>
                Our approach involves collaboration, frequent testing, and
                iterative development to ensure that the software meets the needs
                of teachers and students. This approach helps us to deliver
                high-quality software solutions in a timely and cost-effective
                manner.
              </p>
            </div>
          </div>
          <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
            <div class="feature-box">
              <h3 class="yellow">Specialized For Your Success</h3>
              <p>
                We are an education software company that specializes in
                developing custom solutions for schools, universities, and
                training centers. Our software is designed to help educators
                create engaging and effective learning experiences, while also
                streamlining administrative tasks.
              </p>
            </div>
          </div>
          <div class="col-md-4 mt-4" data-aos="fade-up-right" data-aos-delay="50">
            <div class="feature-box">
              <h3 class="red">Being Transparent</h3>
              <p>
                Transparency is a core value when it comes to creating educational
                software. We believe in involving our clients every step of the
                way, from the initial concept to the final product. We keep
                communication open, provide regular updates, and strive for
                complete transparency to ensure a successful project.
              </p>
            </div>
          </div>
          <div
            class="col-md-4 mt-4"
            data-aos="fade-up-right"
            data-aos-delay="150"
          >
            <div class="feature-box">
              <h3 class="blue">Competitive Prices</h3>
              <p>
                We offer competitive prices for our clients to create education
                software. Our team of experts is dedicated to delivering
                high-quality and affordable software solutions that meet the
                unique needs of our clients.
              </p>
            </div>
          </div>
          <div
            class="col-md-4 mt-4"
            data-aos="fade-up-right"
            data-aos-delay="250"
          >
            <div class="feature-box">
              <h3 class="yellow">Customer Satisfaction</h3>
              <p>
                Customer satisfaction is our top priority when creating
                educational software for our clients. We take the time to
                understand their needs and develop solutions that meet and exceed
                their expectations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section class="footer-banner-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>Ready to Build Interactive Custom Education Software?</h1>
  
            <button (click)="OpenPopup()">Book Your Free Consultation</button>
          </div>
        </div>
      </div>
    </section>
  
    <section class="testimonial-wrapper">
      <div class="container">
        <div id="demo" class="carousel slide mt-5" data-ride="carousel">
          <!-- The slideshow -->
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md-4">
                  <div
                    class="portfolio-box"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div class="portfolio-image">
                      <img
                        src="/assets/images/blog-new-1.png"
                        class="img-fluid"
                        alt="Mobile CRM"
                      />
                    </div>
                    <h3>XLRI</h3>
  
                    <p>
                      XLRI is a leading business school in Jamshedpur, India. One
                      of the oldest business schools in India and is consistently
                      ranked among the top B-schools in the country. We designed
                      an ERP system to enable XLRI to manage its educational
                      programs and operations more effectively helping them
                      achieve its goals.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    class="portfolio-box"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div class="portfolio-image">
                      <img
                        src="/assets/images/blog-new-2.png"
                        class="img-fluid"
                        alt="Banqmart-web"
                      />
                    </div>
                    <h3>KinderPass</h3>
  
                    <p>
                      KinderPass is an all-in-one childcare management software
                      and app for daycares, nurseries, and preschools. We designed
                      and built the Kinderpass App to create a fast and secure
                      line of communication between childcare centers, parents,
                      and staff. It simplifies and automates daily operations so
                      management can focus on delivering outstanding childcare to
                      children.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    class="portfolio-box"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div class="portfolio-image">
                      <img
                        src="/assets/images/blog-new-3.png"
                        class="img-fluid"
                        alt="KPI website"
                      />
                    </div>
                    <h3>Kindersteps</h3>
  
                    <p>
                      Kindersteps is a parenting app with daily milestone-based
                      recommended activities and crucial information to support
                      the development of children between the ages of 0-5. We
                      worked closely with our client to plan, design and develop
                      the app so parents can create profiles for their children
                      and access a wide range of activities, milestones, articles,
                      tracking tools and so much more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-md-4">
                  <div
                    class="portfolio-box"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div class="portfolio-image">
                      <img
                        src="/assets/images/blog-new-1.png"
                        class="img-fluid"
                        alt="Mobile CRM"
                      />
                    </div>
                    <h3>XLRI</h3>
  
                    <p>
                      XLRI is a leading business school in Jamshedpur, India. One
                      of the oldest business schools in India and is consistently
                      ranked among the top B-schools in the country. We designed
                      an ERP system to enable XLRI to manage its educational
                      programs and operations more effectively helping them
                      achieve its goals.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    class="portfolio-box"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div class="portfolio-image">
                      <img
                        src="/assets/images/blog-new-2.png"
                        class="img-fluid"
                        alt="Banqmart-web"
                      />
                    </div>
                    <h3>KinderPass</h3>
  
                    <p>
                      KinderPass is an all-in-one childcare management software
                      and app for daycares, nurseries, and preschools. We designed
                      and built the Kinderpass App to create a fast and secure
                      line of communication between childcare centers, parents,
                      and staff. It simplifies and automates daily operations so
                      management can focus on delivering outstanding childcare to
                      children.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    class="portfolio-box"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div class="portfolio-image">
                      <img
                        src="/assets/images/blog-new-3.png"
                        class="img-fluid"
                        alt="KPI website"
                      />
                    </div>
                    <h3>Kindersteps</h3>
  
                    <p>
                      Kindersteps is a parenting app with daily milestone-based
                      recommended activities and crucial information to support
                      the development of children between the ages of 0-5. We
                      worked closely with our client to plan, design and develop
                      the app so parents can create profiles for their children
                      and access a wide range of activities, milestones, articles,
                      tracking tools and so much more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-md-4">
                  <div
                    class="portfolio-box"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div class="portfolio-image">
                      <img
                        src="/assets/images/blog-new-1.png"
                        class="img-fluid"
                        alt="Mobile CRM"
                      />
                    </div>
                    <h3>XLRI</h3>
  
                    <p>
                      XLRI is a leading business school in Jamshedpur, India. One
                      of the oldest business schools in India and is consistently
                      ranked among the top B-schools in the country. We designed
                      an ERP system to enable XLRI to manage its educational
                      programs and operations more effectively helping them
                      achieve its goals.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    class="portfolio-box"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div class="portfolio-image">
                      <img
                        src="/assets/images/blog-new-2.png"
                        class="img-fluid"
                        alt="Banqmart-web"
                      />
                    </div>
                    <h3>KinderPass</h3>
  
                    <p>
                      KinderPass is an all-in-one childcare management software
                      and app for daycares, nurseries, and preschools. We designed
                      and built the Kinderpass App to create a fast and secure
                      line of communication between childcare centers, parents,
                      and staff. It simplifies and automates daily operations so
                      management can focus on delivering outstanding childcare to
                      children.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    class="portfolio-box"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div class="portfolio-image">
                      <img
                        src="/assets/images/blog-new-3.png"
                        class="img-fluid"
                        alt="KPI website"
                      />
                    </div>
                    <h3>Kindersteps</h3>
  
                    <p>
                      Kindersteps is a parenting app with daily milestone-based
                      recommended activities and crucial information to support
                      the development of children between the ages of 0-5. We
                      worked closely with our client to plan, design and develop
                      the app so parents can create profiles for their children
                      and access a wide range of activities, milestones, articles,
                      tracking tools and so much more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <a class="carousel-control-prev" href="#demo" data-slide="prev"> </a>
            <a class="carousel-control-next" href="#demo" data-slide="next"> </a>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer class="techware-footer-wrapper">
    <div (click)="scrollToTop()" class="scroll-top">
      <img
      src="/assets/images/scroll-top-icon.svg"
      class="img-fluid footer-logo"
      alt="Logo"
    />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <img
            src="/assets/images/logo-white.png"
            class="img-fluid footer-logo"
            alt="Logo"
          />
          <p>
            Techware Lab is a highly professional software development team based
            out of Kochi, India with product and sales offices in Canada and UAE.
            We have delivered our web and mobile solutions and custom software to
            clients across the world since 2012.
          </p>
        </div>
        <div class="col-md-12">
          <div class="footer-social">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/TechwareLabPvtLtd"
                  target="_blank"
                  ><img src="/assets/images/facebook.png" alt="Facebook"
                /></a>
              </li>
              <li>
                <a href="https://www.instagram.com/techwarelab/" target="_blank"
                  ><img src="/assets/images/instagram.png" alt="Instagram"
                /></a>
              </li>
  
              <li>
                <a href="https://twitter.com/TechWareWeb" target="_blank"
                  ><img src="/assets/images/twitter.png" alt="Twitter"
                /></a>
              </li>
            </ul>
          </div>
          <div class="phone">Phone - 09567879002</div>
        </div>
        <div class="col-md-12">
          <h3 class="credit">© 2023 Techware Lab. All rights reserved.</h3>
        </div>
      </div>
    </div>
  </footer>
  