import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { JoinOurTeamQuoteComponent } from '../join-our-team-quote/join-our-team-quote.component';
import { SeoService } from '../seo.service';
import { MatDialog } from '@angular/material/dialog';
import { CareerService } from 'src/providers/career.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  careerList: any;
  categoryList: any;
  constructor(
    private title: Title,
    private meta: Meta,
    private model: MatDialog,
    private seoService: SeoService,
    private careerService: CareerService
  ) {

  }

  ngOnInit(): void {

    this.fetchCareer();
    this.fetchCategory();

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Job Openings at Techware Lab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Find all the job opportunities here and send your resume to follow up the process.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Find all the job opportunities here and send your resume to follow up the process.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Job Openings at Techware Lab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Find all the job opportunities here and send your resume to follow up the process.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/careers",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Job Openings at Techware Lab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Find all the job opportunities here and send your resume to follow up the process.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Job Openings at Techware Lab",
    });
  }

  fetchCareer() {
    this.careerService.getCareers().subscribe(
      (data) => {
        this.careerList = data;
        //console.log('Fetching Data:', this.careerList);
      },
      (error) => {
        console.error('Error Fetching Data:', error);
      }
    );
  }
  fetchCategory() {
    this.careerService.getCategory().subscribe(
      (data) => {
        this.categoryList = data;
        //console.log('Fetching Data:', this.categoryList);
      },
      (error) => {
        console.error('Error Fetching Data:', error);
      }
    );
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  joinTeamQuote() {
    this.model.open(JoinOurTeamQuoteComponent);
  }
}


