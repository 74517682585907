<section class="portfolio-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>Empowering businesses <br> with custom software solutions
                </h1>
                <h2 class="cmn-sub-heading">At Techware Lab, we design, develop, and scale a diverse range of digital products across all industries. Take a<br> look at our feature-rich, white label applications below.</h2>
                <button routerLink="/contact">Ready to get started?</button>
            </div>
        </div>
    </div>
</section>

<section class="portfolio-content-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Portfolio</h1>
                <h2 class="cmn-sub-heading">Techware Lab designs and develops mobile and web apps<br>that will delight your users and grow your business.</h2>
                <ul class="nav nav-pills mb-3 mt-5" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="pills-banking-tab" data-toggle="pill" href="#pills-banking" role="tab" aria-controls="pills-banking" aria-selected="true">Banking & Finance</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-food-tab" data-toggle="pill" href="#pills-food" role="tab" aria-controls="pills-food" aria-selected="false">Food & Beverages</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-commerce-tab" data-toggle="pill" href="#pills-commerce" role="tab" aria-controls="pills-commerce" aria-selected="true">E-commerce</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-logistics-tab" data-toggle="pill" href="#pills-logistics" role="tab" aria-controls="pills-logistics" aria-selected="true">Logistics</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-traveling-tab" data-toggle="pill" href="#pills-traveling" role="tab" aria-controls="pills-traveling" aria-selected="true">Traveling</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-lifestyle-tab" data-toggle="pill" href="#pills-lifestyle" role="tab" aria-controls="pills-lifestyle" aria-selected="true">Lifestyle</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-beauty-tab" data-toggle="pill" href="#pills-beauty" role="tab" aria-controls="pills-beauty" aria-selected="true">Beauty</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-social-tab" data-toggle="pill" href="#pills-social" role="tab" aria-controls="pills-social" aria-selected="true">Social</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-dating-tab" data-toggle="pill" href="#pills-dating" role="tab" aria-controls="pills-dating" aria-selected="true">Dating</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-music-tab" data-toggle="pill" href="#pills-music" role="tab" aria-controls="pills-music" aria-selected="true">Music</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-legal-tab" data-toggle="pill" href="#pills-legal" role="tab" aria-controls="pills-legal" aria-selected="true">Legal</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-healthcare-tab" data-toggle="pill" href="#pills-healthcare" role="tab" aria-controls="pills-healthcare" aria-selected="true">Healthcare</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-childcare-tab" data-toggle="pill" href="#pills-childcare" role="tab" aria-controls="pills-childcare" aria-selected="true">ChildCare</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-education-tab" data-toggle="pill" href="#pills-education" role="tab" aria-controls="pills-education" aria-selected="true">Education</a>
                    </li>
                    
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-banking" role="tabpanel" aria-labelledby="pills-banking-tab">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/finmart.webp" class="img-fluid" alt="Mobile CRM">
                                        <div class="logo-container">
                                            <img src="/assets/images/finmart-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>Finmart</h3>
                                    <h4><i>A seamless CRM app for financial services</i></h4>
                                    <p>Finmart is a CRM designed to help financial aggregators manage collect, manage and create personalized offers for leads in compliance with industry regulations. Agents can easily track the status of their leads, delegate
                                        tasks to the sales team and record all relevant information. We worked with the client to improve the workflows, integrate various tools and build the web-based application for different operating systems and browsers.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/banqmart-web.png" class="img-fluid" alt="Banqmart-web">
                                        <div class="logo-container">
                                            <img src="/assets/images/banqmart-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>Banqmart</h3>
                                    <h4><i>A financial marketplace app with an Affiliate Program </i></h4>
                                    <p>BanqMart is a fintech startup that helps consumers find the best financial products for their needs. We developed a CRM platform to help BanqMart add, manage and follow-up with their leads and customers.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/KPI-website.png" class="img-fluid" alt="KPI website">
                                        <div class="logo-container">
                                            <img src="/assets/images/KPI-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>KPI</h3>
                                    <h4><i>An award-winning Oracle NetSuite ERP solution provider</i></h4>
                                    <p>KPI is a Business Advisory and Accounting firm looking to update their website. We worked closely with the client to understand their needs. We then designed and developed their website in Angular and integrated the NetSuite-support system.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/portfolio-mango.png" class="img-fluid" alt="KPI website">
                                        <div class="logo-container" routerLink="/case-study-mangosuite">
                                            <img src="/assets/images/mangosuite-logo.svg" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3 routerLink="/case-study-mangosuite">Mangosuite</h3>
                                    <h4><i>A CRM designed specifically for financial products to capture, track, and seamlessly share customer data in one place.</i></h4>
                                    <p>Mangosuite has been a great success, providing BanqMart with a custom-made CRM and app that meets its direct selling agents' specific needs. Techware Lab is proud to have been a part of this project and looks forward to continuing to work with Mangosuite in the future.</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-food" role="tabpanel" aria-labelledby="pills-food-tab">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/pass-the-peas.webp" class="img-fluid" alt="Recipe sharing app">
                                        <div class="logo-container">
                                            <img src="/assets/images/peaz-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>Pass the Peaz</h3>
                                    <h4><i>A delicious way to pass on your family's recipes</i></h4>
                                    <p>This platform allows users to connect with friends and deepen their familial ties by passing along family traditional recipes. This started as a small project so that our client could save his family's old recipes from
                                        different generations in one place, and grew to become a platform where friends and family could add/share their own recipes with an interactive experience. Our client shared his requirements, and we integrated
                                        all the pieces and built the app.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/myround-tab.png" class="img-fluid" alt="Drink app">
                                        <div class="logo-container">
                                            <img src="/assets/images/round-tab-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>MyRoundTab</h3>
                                    <h4><i>A drink offering app to buy or offer a drink to friends and family</i></h4>
                                    <p>MyRoundTab lets you buy a drink for anyone, anywhere - all users have to do is pay for it in the app and the recipient can claim their drink from select partners. This special app caters to a new generation of consumers who can order, exchange and personalize their drink gifts to friends and family. We worked with them to determine the requirements, user flows and best practices to build this app where users can add their friends and also find the right establishments.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" id="pills-commerce" role="tabpanel" aria-labelledby="pills-commerce-tab">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/getmi.png" class="img-fluid" alt="Clothing app">
                                        <div class="logo-container">
                                            <img src="/assets/images/getmi-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>GetMi</h3>
                                    <h4><i>The best retail store app for shopping from home</i></h4>
                                    <p>GetMi is a shopping platform which includes apps for users, retail stores and delivery executives. Through the store, customers can view, browse, and buy what they like. The store owner gets to know that the delivery executive has arrived within the radius of the store - this feature makes GetMi different from other shopping apps. The platform is in high demand due to its sophisticated features for multiple types of users. With our requirement gathering and UI/UX workflows, we built the mobile platform quickly and with comprehensive features.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/lensbooking-website.png" class="img-fluid" alt="lensbooking website">
                                        <div class="logo-container">
                                            <img src="/assets/images/lensbooking-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>Lensbooking</h3>
                                    <h4><i>A WooCommerce integrated website for a contact lenses brand</i></h4>
                                    <p>LensBooking is a o      ne-stop destination for Premium Clear and Colored Contact Lenses, founded in Dubai and Saudi Arabia. We developed the website for Lensbooking and enabled worldwide shipping through its WooCommerce integrated eCommerce application.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/daraz.webp" class="img-fluid" alt="Online marketplace">
                                        <div class="logo-container" routerLink="/case-study-daraz">
                                            <img src="/assets/images/daraz-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3 routerLink="/case-study-daraz">Daraz</h3>
                                    <h4><i>The leading online marketplace in South Asia</i></h4>
                                    <p>Daraz is an online marketplace and logistics company that operates in South Asia and Southeast Asia. As a subsidiary of Alibaba, Daraz is focused on entering new markets and providing a best-in-class experience for
                                        users. We played an integral role in successfully designing and integrating a secure travel booking into their platform.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-logistics" role="tabpanel" aria-labelledby="pills-logistics-tab">
                        <div class="col-md-6">
                            <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                <div class="portfolio-image">
                                    <img src="/assets/images/daraz.webp" class="img-fluid" alt="Online marketplace">
                                    <div class="logo-container">
                                        <img src="/assets/images/daraz-logo.png" class="img-fluid" alt="Logo">
                                    </div>
                                </div>
                                <h3>Daraz</h3>
                                <h4><i>The leading online marketplace in South Asia</i></h4>
                                <p>Daraz is an online marketplace and logistics company that operates in South Asia and Southeast Asia. As a subsidiary of Alibaba, Daraz is focused on entering new markets and providing a best-in-class experience for
                                    users. We played an integral role in successfully designing and integrating a secure travel booking into their platform.</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" id="pills-commerce" role="tabpanel" aria-labelledby="pills-commerce-tab">
                        <div class="col-md-6">
                            <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                <div class="portfolio-image">
                                    <img src="/assets/images/getmi.png" class="img-fluid" alt="Clothing app">
                                    <div class="logo-container">
                                        <img src="/assets/images/getmi-logo.png" class="img-fluid" alt="Logo">
                                    </div>
                                </div>
                                <h3>GetMi</h3>
                                <h4><i>The best retail store app for shopping from home</i></h4>
                                <p>GetMi is a shopping platform which includes apps for users, retail stores and delivery executives. Through the store, customers can view, browse, and buy what they like. The store owner gets to know that the delivery executive has arrived within the radius of the store - this feature makes GetMi different from other shopping apps. The platform is in high demand due to its sophisticated features for multiple types of users. With our requirement gathering and UI/UX workflows, we built the mobile platform quickly and with comprehensive features.</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-traveling" role="tabpanel" aria-labelledby="pills-traveling-tab">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/cloud-trave-p.webp" class="img-fluid" alt="Travel App">
                                        <div class="logo-container">
                                            <img src="/assets/images/travel-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>Travel App</h3>
                                    <h4><i>A social application for travel influencers</i></h4>
                                    <p>Travel App is a social application where travel and hospitality providers can partner with influencers. Influencers can post their travel-related photos which users can view, like, comment and share. Users can also easily
                                        search for particular destinations and book hotels and travel experiences based on influencers' posts. In return, influencers can make money and get discounts on their travel bookings. We worked closely with our
                                        client to determine the business logic, workflow and designs, before building the app. </p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/drukeride-web.png" class="img-fluid" alt="Online bus booking">
                                        <div class="logo-container">
                                            <img src="/assets/images/drukride-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>DrukeRide</h3>
                                    <h4><i>An online bus booking web app for transportation services</i></h4>
                                    <p>DrukRide is an automated online bus booking web application that enables easy bookings and helps determine customized bus routes. The web app makes the most convenient booking service for buses from the largest community of drivers and operators, while also offering cashless payments in-app. We developed the bus ticket booking app and included many processes such as allowing users to select pick up and drop off points, view, or cancel their bus tickets.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/equator-web.png" class="img-fluid" alt="Travel portal app">
                                        <div class="logo-container">
                                            <img src="/assets/images/equator-logo.svg" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>Equator</h3>
                                    <h4><i>The leading online travel portal dedicated to serve large companies</i></h4>
                                    <p>Equator Travel Management is an online travel portal dedicated to serving large corporate companies with airfares, hotel bookings, airport transfers, air charter, and other travel products. We supported, advised and managed the development of this web application to enable online Travel Agents to easily search, purchase, and cancel flight tickets within the current buyer platform.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/ticket-robo.webp" class="img-fluid" alt="Online bus ticket booking">
                                        <div class="logo-container">
                                            <img src="/assets/images/ticketbus-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>Bus booking app</h3>
                                    <p>Bus booking app is a bus ticket booking platform that provides users with an easy interface to seamlessly book tickets across India with over 2000+ routes and 2000+ reliable partners. We assisted our client with customizing our existing ticketing solution for their business needs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" id="pills-lifestyle" role="tabpanel" aria-labelledby="pills-lifestyle-tab">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/zyva-web.png" class="img-fluid" alt="Online clothes shopping India">
                                        <div class="logo-container">
                                            <img src="/assets/images/zyva-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>Zyva</h3>
                                    <h4><i>The leading fashion apparel brand in India </i></h4>
                                    <p>Zyva is a leading clothing and apparel brand in India. As the ultimate fashion destination for most unique styles that are handpicked, on-trend, and at affordable prices, they wanted to build an online shop. We built a custom eCommerce platform that looks professional and unique, while also providing important features to allow them to manage their online orders and deliveries.</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-social" role="tabpanel" aria-labelledby="pills-social-tab">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/one-voices.webp" class="img-fluid" alt="Mobile justice app">
                                        <div class="logo-container">
                                            <img src="/assets/images/voices-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>1voices</h3>
                                    <h4><i>The easy way to crowdfund for a social cause</i></h4>
                                    <p>This project provides a platform where users can share their cases of injustice, start campaigns, raise funds and get expert advice. Users can also join in as Civilians/Lawyers/Lawmakers/Contributors to drive solutions
                                        for cases. We did everything for this application from gathering requirements to creating interactive designs, and from conducting user research to completing the platform to enable crowd-sourced help for each case.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/soteria.png" class="img-fluid" alt="Emergency alert app">
                                        <div class="logo-container">
                                            <img src="/assets/images/soteria-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>Soteria</h3>
                                    <h4><i>Unique social safety, emergency and Information app</i></h4>
                                    <p>Soteria is the ultimate social safety app. As a network, Soteria provides real-time information pushed to the devices so users can stay updated about the roads and areas close to where they are and live. With our expertise, we build the mobile application which shares validated, real-time and relevant broadcasts originating from a 24/7 manned Incident Control Centre (ICC) with a diverse network of information feeds.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/dearest.png" class="img-fluid" alt="Family tree builder">
                                        <div class="logo-container">
                                            <img src="/assets/images/dearest-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>Dearest</h3>
                                    <h4><i>The popular app for building family tree and family stories anytime, anywhere</i></h4>
                                    <p>Dearest is an online genealogy social networking software solution for users to create, preserve and share their family records, resources, discoveries, while also collaborating with friends and family. The app combines social networking with online family trees and connects families with records. We enjoyed helping our customer build this app so all of a family’s information (photos, stories, life events, and more) can be preserved in a single location for generations to come.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" id="pills-dating" role="tabpanel" aria-labelledby="pills-dating-tab">
                        <div class="col-md-6">
                            <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                <div class="portfolio-image">
                                    <img src="/assets/images/clinx.png" class="img-fluid" alt="Online dating app solutions">
                                    <div class="logo-container">
                                        <img src="/assets/images/clink-logo.png" class="img-fluid" alt="Logo">
                                    </div>
                                </div>
                                <h3>Clink</h3>
                                <h4><i>The nightlife dating app where the drink comes first</i></h4>
                                <p>Clink is a safe digital mobile platform that empowers users to decide who they want to connect with tonight in the same bar or club. The app is integrated with popular point-of-sale systems and can be used to purchase drinks via the app or directly at the bar. Clink wants to break uncomfortable social barriers and help users meet each other face-to-face over a drink.</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-music" role="tabpanel" aria-labelledby="pills-music-tab">
                        <div class="col-md-6">
                            <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                <div class="portfolio-image">
                                    <img src="/assets/images/thestu.png" class="img-fluid" alt="Recording studio app">
                                    <div class="logo-container">
                                        <img src="/assets/images/thestu-logo.png" class="img-fluid" alt="Logo">
                                    </div>
                                </div>
                                <h3>TheStu</h3>
                                <h4><i>The smartest way to manage recording studios with a simple app</i></h4>
                                <p>TheStu is a booking app to manage studio bookings online with easy-to-use, highly customizable features that cater specifically to the studio needs. Manual bookings can be quite a tough task to keep up with and manage. We built TheStu so it can easily offer studio services online and effectively manage all types of tasks from studio bookings to engineer workload. We provided consulting and development services. </p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" id="pills-legal" role="tabpanel" aria-labelledby="pills-legal-tab">
                        <div class="col-md-6">
                            <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                <div class="portfolio-image">
                                    <img src="/assets/images/lawyer.png" class="img-fluid" alt="Legal appointment booking software">
                                    <div class="logo-container">
                                        <img src="/assets/images/lawyer-logo.png" class="img-fluid" alt="Logo">
                                    </div>
                                </div>
                                <h3>Lawyer 360</h3>
                                <h4><i>A fully-customizable, new-age legal management app</i></h4>
                                <p>Lawyer360 is an app for attorneys and law firms to manage cases and save time. Lawyers can easily look through all the reservations and check the details of clients. Lawyer360 offers customizable booking for clients, where reserving a spot takes only a few seconds. It delivers the effortless experience clients expect while automating the law firm's intake process and management. We worked with Lawyer 360 to build an easy and intuitive way for prospective clients to book the nearest lawyers for online consultations.</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-healthcare" role="tabpanel" aria-labelledby="pills-healthcare-tab">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/medsale-web.png" class="img-fluid" alt="Medical equipments online">
                                        <div class="logo-container">
                                            <img src="/assets/images/medscale-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>Med Sale</h3>
                                    <h4><i>The trusted source for medical equipment needs</i></h4>
                                    <p>MediSale is a trusted medical equipment resource for hospitals, clinics, surgery centers, doctors’ offices, ambulances, and other medical facilities. With a focus on ensuring quality and customer satisfaction, we developed a web app so their customers can create an account and order the medical supplies and equipments they need. We created several workflows and built quality checks into the portal.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/mhg-web.png" class="img-fluid" alt="Wellness retreat">
                                        <div class="logo-container">
                                            <img src="/assets/images/mhg-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>MHG Healthcare</h3>
                                    <h4><i>Discover the world’s premier holistic retreats with their beautiful and informative website</i></h4>
                                    <p>MHG Healthcare is a leading healthcare and wellness company which offers customers concierge service to book health clinics and wellness retreats across the world. MHG helps customers find the best treatments for their customers’ unique needs. We worked with MHG to develop their branding, digital marketing and website so they can successfully launch their company.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" id="pills-childcare" role="tabpanel" aria-labelledby="pills-childcare-tab">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/kinderPass-web.png" class="img-fluid" alt="KinderPass-web">
                                        <div class="logo-container"     routerLink="/case-study-kinderpass">
                                            <img src="/assets/images/kinderPass-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3 routerLink="/case-study-kinderpass">KinderPass</h3>
                                    <h4><i>A comprehensive business operations app for childcare management</i></h4>
                                    <p>KinderPass is an all-in-one childcare management platform for daycares, nurseries, and preschools. We provided staffing, digital marketing and consulting services to build the comprehensive platform with easy-to-use features, including secure child and family data storage, enrollment and waitlist management, online billing and payments, accounting, reporting, and child development reports.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/kinderSteps-website.png" class="img-fluid" alt="KinderSteps website">
                                        <div class="logo-container">
                                            <img src="/assets/images/kinderSteps-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>KinderSteps</h3>
                                    <h4><i>An engaging parenting app with daily recommended activities and expert advice</i></h4>
                                    <p>Kindersteps is an app that helps parents capture and celebrate every moment as their child actively develops lifelong skills. We worked with the Founder to design and launch their website with digital marketing tools to help the website rank and attract users. </p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/kinder-ville-website.png" class="img-fluid" alt="Early learning center">
                                        <div class="logo-container">
                                            <img src="/assets/images/kinderville-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>KinderVille</h3>
                                    <h4><i>An SEO-friendly website for the leading early learning centers for children</i></h4>
                                    <p>Kinderville provides quality care and bilingual early years education for nurseries, preschools and daycares. Their curriculum is based on a multi-disciplinary thematic approach to learning. Children learn through structured play as they create, construct, and reflect through meaningful experiences. We developed their WordPress website for all of their locations across UAE, India and Canada.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" id="pills-education" role="tabpanel" aria-labelledby="pills-education-tab">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/portfolio-xlri.png" class="img-fluid" alt="KinderPass-web">
                                        <div class="logo-container"     routerLink="/case-study-xlri">
                                            <img src="/assets/images/xlri-logo.svg" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3 routerLink="/case-study-xlri">XLRI</h3>
                                    <h4><i>XLRI, India's Oldest B-School, Pioneering Economic and Social Transformation Since 1949.</i></h4>
                                    <p>Techware Lab's education ERP solution provided XLRI with a comprehensive suite of tools to manage its educational programs and operations more effectively. The result was a highly functional and intuitive system that has been instrumental in helping XLRI achieve its goals.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" id="pills-beauty" role="tabpanel" aria-labelledby="pills-beauty-tab">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="portfolio-box" data-aos="fade-up" data-aos-duration="3000">
                                    <div class="portfolio-image">
                                        <img src="/assets/images/thedubaidolls-website.png" class="img-fluid" alt="thedubaidolls website">
                                        <div class="logo-container">
                                            <img src="/assets/images/thedubaidolls-logo.png" class="img-fluid" alt="Logo">
                                        </div>
                                    </div>
                                    <h3>The Dubai Dolls</h3>
                                    <h4><i>A user-centric e-commerce website for a cruelty-free beauty brand</i></h4>
                                    <p>The Dubai Dolls is a cruelty-free beauty brand with a purpose. The co-founders wanted to create an engaging website which would showcase their brand and products. We developed a Shopify integrated e-commerce website for them.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Ready to work with us?</h1>
                <h2 class="cmn-sub-heading">We’re always looking for our next challenge. Bring your big idea to market with our team of designers, developers, and business strategists.</h2>
                <button routerLink="/contact"> Let's get started</button>
            </div>
        </div>
    </div>
</section>