import { Component, OnInit ,Inject, PLATFORM_ID } from '@angular/core';
import { MatDialogRef,MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { WebService } from "../../providers/web.service";
import { HelperService } from "../../providers/helper.service";
import { MessageSuccessComponent } from "../message-success/message-success.component";
import * as countries from "./../contact/countries.json";
import { isPlatformBrowser } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-request-quote',
  templateUrl: './request-quote.component.html',
  styleUrls: ['./request-quote.component.scss']
})
export class RequestQuoteComponent implements OnInit {
  contactForm;
  countryList: any = new Array();
  contactSubmit: boolean;
  loader: boolean;
  constructor(private model: MatDialog,private dialogRef: MatDialogRef<RequestQuoteComponent>,public service: WebService, public helper: HelperService, @Inject(PLATFORM_ID) private platformId: Object) { 
    this.contactSubmit = false;
    this.countryList = countries;
    this.loader = false;
  }

  ngOnInit(): void {
    this.loadZohoScript();

    this.contactForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
      ]),
      country: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
      reason: new FormControl("",[Validators.required]),
      requirement: new FormControl("", [Validators.required])
    });
  }
  contactFormSubmit() {
    this.contactSubmit = true;
    if (this.contactForm.valid) {
      this.loader = true;
      let userObj = this.contactForm.value;
      this.service.post_data("requestQuoteContactForm", "", userObj).subscribe(
        (response) => {
          if (response && response.status && response.status == 200) {
            this.model.open(MessageSuccessComponent);
          } else {
            this.helper.showAlert(4, "Something went wrong");
          }
          this.contactForm.reset({
            name: "",
            email: "",
            country: "0",
            phone: "",
            reason: "0",
            requirement: ""
          });
          this.contactSubmit = false;
          this.loader = false;
        },
        (error) => {
          this.helper.showAlert(4, "Something went wrong");
          this.contactSubmit = false;
          this.loader = false;
        }
      );
    }
  }

  closeChatDialog() {
    this.dialogRef.close();
  }

  loadZohoScript(): void {

    const iframe = document.getElementById('zohoIframeModal') as HTMLIFrameElement;

    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;


    const script = iframeDoc.createElement('script');
    // const script = this.renderer.createElement('script');
    script.src = 'https://crm.zoho.com/crm/WebFormServeServlet?rid=dd8e9bd6a58ef545664ba3b6af0e88fabc47f674ef55409c3c0e436d4206125aaa0c212ede1b24ea0a4d1116c0ea164bgid5d2cd02a92e38954fda2f318dd487414991c498255342bd8c1859d1f24fa759e&script=$sYG';
    script.id = 'formScript6239895000000955096';
    script.type = 'text/javascript';
    script.async = true;


    const style = iframeDoc.createElement('style');

    style.innerHTML =   `
    
    #crmWebToEntityForm {
      
      background-color: var(--bgcolor-secondary);
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-radius: 12px;
      padding: 0px !important;
      position: absolute;
      background-color: transparent !important;
      
      
      #webform6239895000001543022 {
        #formsubmit {
          max-width: 300px;
          background-color: #ffffff;
          border: 1px solid #da2a80 !important;
          color: #da2a80 !important;
          padding: 8px 35px;
          border: 2px solid black;
          border-radius: 30px;
          font-weight: 700;
        }
    
        input {
          width: 100%;
          max-width: 750px;
          padding: 10px;
          border: 2px solid #d8d8d8;
          border-radius: 30px;
          @media screen and (max-width: 1200px) {
            padding: 20px;
          }
          @media screen and (max-width: 992px) {
            padding: 16px;
          }
          @media screen and (max-width: 768px) {
            padding: 12px;
          }
        }
        .zcwf_title {
          display: none;
        }
        .zcwf_row {
          width: 100%;
          align-items: center;
          display: flex;
          @media (max-width: 1200px) {
            flex-direction: column;
            align-items: flex-start;
          }
          // @media (max-width: 600px) {
          //   justify-content: space-between;
          // }
        }
        .zcwf_col_lab {
          @media (max-width: 768px) {
            width: 100% !important;
          }
          label {
            font-weight: 800;
            width: 100%;
            // @media screen and (max-width: 576px) {
            //   max-width: 140px;
            //   font-size: 12px;
            // }
          }
        }
        #reCaptchaField {
          font-weight: 800;
          @media (max-width: 600px) {
            font-size: 12px !important;
          }
        }
        .zcwf_col_fld {
          display: flex;
          align-items: center;
          @media (max-width: 768px) {
            flex-direction: column;
            width: 100%;
          }
          #imgid6239895000000955096 {
            width: 35%;
            @media (max-width: 576px) {
              width: 80%;
            }
          }
          a {
            color: black;
          }
          select,
          textarea {
            width: 100%;
            padding: 10px;
            border-radius: 30px;
            @media (max-width: 600px) {
              padding: 12px;
            }
          }
          input[type="reset"] {
            display: none;
          }
        }
      }
    }
    
    `

    script.onload = () => {
      this.measureFormHeight();
      // const leadSourceField = document.querySelector('#Lead_Source') as HTMLSelectElement;
      // if (leadSourceField) {
      //   leadSourceField.value = 'techware';
      // }
    };

    iframeDoc.body.appendChild(script);
    iframeDoc.head.appendChild(style);
    // Append the script to the zohoFormContainer
    // this.renderer.appendChild(this.zohoFormContainer.nativeElement, script);
  }

  measureFormHeight(): void {
    setTimeout(() => {
      const formElement = document.getElementById('webform6239895000000955096');
      if (formElement) {
        if (isPlatformBrowser(this.platformId)) {
          this.measureFormHeight();
          window.addEventListener('resize', this.measureFormHeight.bind(this));
        }
      } else {
        console.log('Form element not found');
      }
    }, 10);
  }


}
