import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { SeoService } from '../seo.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-online-food-ordering-system',
  templateUrl: './online-food-ordering-system.component.html',
  styleUrls: ['./online-food-ordering-system.component.scss']
})
export class OnlineFoodOrderingSystemComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }


  constructor(private model: MatDialog, private title: Title, private meta: Meta, private seoService: SeoService) { }


  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Online Food Ordering App Development | Food Delivery Software"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Looking for an online food ordering system for your restaurant? Start serving delicious food with an online food ordering system that’s tailored to your unique brand in India, UAE, and USA.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Looking for an online food ordering system for your restaurant? Start serving delicious food with an online food ordering system that’s tailored to your unique brand in India, UAE, and USA.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Online Food Ordering App Development | Food Delivery Software",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Looking for an online food ordering system for your restaurant? Start serving delicious food with an online food ordering system that’s tailored to your unique brand in India, UAE, and USA.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/online-food-ordering-system",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Online Food Ordering App Development | Food Delivery Software",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Looking for an online food ordering system for your restaurant? Start serving delicious food with an online food ordering system that’s tailored to your unique brand in India, UAE, and USA.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Online Food Ordering App Development | Food Delivery Software",
    });
  }

  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }
}
