import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-taxi-dispatch-software',
  templateUrl: './taxi-dispatch-software.component.html',
  styleUrls: ['./taxi-dispatch-software.component.scss']
})
export class TaxiDispatchSoftwareComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  constructor(private title: Title, private meta: Meta, private seoService: SeoService,private model: MatDialog, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {

    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
  
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "Can Techware Lab build a white label taxi app like Uber for me?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, of course. You can develop your own white-label taxi booking app solution that you can rebrand and resell under your name. With our taxi dispatch system, you can attract more customers to your business."
        }
      },{
        "@type": "Question",
        "name": "How customizable is the solution?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Our pre-built taxi app solution is ready to use out of the box. You can always add or remove features as per your requirements."
        }
      },{
        "@type": "Question",
        "name": "How much does a taxi dispatch system cost?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The cost of a basic app with common features will start at $5,000. The final cost of the taxi dispatch system depends on the scale and complexity of the project and the hourly rate of the development team. Want to find out how much it costs to develop a taxi booking app with Techware Lab? Request a free quote."
        }
      },{
        "@type": "Question",
        "name": "Does TTaxi support additional languages other than English?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes. TTaxi offers taxi businesses worldwide and supports languages other than English."
        }
      },{
        "@type": "Question",
        "name": "Does TTaxi accept payments in local currency?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes. TTaxi accepts multiple credit cards and currencies. All funds are settled in your local currency, and come with built-in global support."
        }
      }]
    }
    
  
`;
 
  this._renderer2.appendChild(this._document.body, script);


    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Taxi Dispatch Software | Taxi Booking App Development"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Whether you’re looking for a taxi dispatch software solution or a custom uber-like white label taxi booking app, TTaxi can help streamline your business online. Expect a smooth ride with our feature-rich best taxi app development.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Whether you’re looking for a taxi dispatch software solution or a custom uber-like white label taxi booking app, TTaxi can help streamline your business online. Expect a smooth ride with our feature-rich best taxi app development.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Taxi Dispatch Software | Taxi Booking App Development",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Whether you’re looking for a taxi dispatch software solution or a custom uber-like white label taxi booking app, TTaxi can help streamline your business online. Expect a smooth ride with our feature-rich best taxi app development.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/taxi-dispatch-software",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Taxi Dispatch Software | Taxi Booking App Development",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Whether you’re looking for a taxi dispatch software solution or a custom uber-like white label taxi booking app, TTaxi can help streamline your business online. Expect a smooth ride with our feature-rich best taxi app development.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Taxi Dispatch Software | Taxi Booking App Development",
    });

  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 

  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }


}
