import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { Meta, Title } from "@angular/platform-browser";;
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-business-consulting',
  templateUrl: './business-consulting.component.html',
  styleUrls: ['./business-consulting.component.scss']
})
export class BusinessConsultingComponent implements OnInit {

  customOptionsOne: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  constructor(private model: MatDialog, private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "#1 IT Business Consulting Services Company | IT Consulting"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Techware Lab offers IT business consulting services across UAE,USA & India,we create digital innovation by following a clearly-defined roadmap for your business.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Techware Lab offers IT business consulting services across UAE,USA & India,we create digital innovation by following a clearly-defined roadmap for your business.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/ser-4.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "#1 IT Business Consulting Services Company | IT Consulting",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Techware Lab offers IT business consulting services across UAE,USA & India,we create digital innovation by following a clearly-defined roadmap for your business.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/business-consulting",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/ser-2.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "#1 IT Business Consulting Services Company | IT Consulting",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Techware Lab offers IT business consulting services across UAE,USA & India,we create digital innovation by following a clearly-defined roadmap for your business.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "#1 IT Business Consulting Services Company | IT Consulting",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

}
