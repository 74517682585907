<section class="header-banner">
  <div class="container">
    <div class="text-content">
      <h1>
        Branding and Graphic <br />
        Design Services
      </h1>
      <h5>
        Build a Winning Brand That Stands Out<br />
        in Your Market
      </h5>
      <h6>
        Work with a team of experienced designers to help you visualize<br />
        and accomplish your branding and marketing goals.
      </h6>
      <button routerLink="/products">View our latest projects</button>
    </div>
  </div>
</section>

<section class="techware-wrapper">
  <div class="container">
    <div class="row mb-md-5">
      <div
        class="col-md-5 text-right"
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        <img
          src="/assets/images/branding-vector.png"
          class="img-fluid"
          alt="Cutting edge technologies"
        />
      </div>
      <div class="col-md-7">
        <div class="help-box mt-4 mt-md-0">
          <h1 class="cmn-heading">
            Welcome to Techware Lab, <br />
            the Place That Gets Your Brand <br />
            Seen, Heard, and Known
          </h1>
          <p>
            Our team offers expertise in brand configuration and design to
            ensure your business has a memorable impact and stands against
            competition. We help brand your public identity in a unique,
            creative, and innovative way that connects you to the right
            customers.
          </p>
          <button (click)="requestQuote()" target="_blank">Get a quote</button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-list-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading text-center">
          Our Branding & Graphic Design Services
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
        <div class="service-box yellow">
          <img src="/assets/images/branding-vector-1.svg" alt="Service" />
          <h3>Web App Development</h3>
          <p>
            Enrich customer relationships and grow operations exponentially with
            a fully-customized web application that understands your business
            needs and goals.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
        <div class="service-box blue">
          <img src="/assets/images/branding-vector-2.svg" alt="Service" />
          <h3>Mobile Development</h3>
          <p>
            Put your business in the hands of customers with an outstanding
            mobile experience. Boost brand awareness, customer loyalty, and
            revenue with our leading mobile development services.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
        <div class="service-box red">
          <img src="/assets/images/branding-vector-3.svg" alt="Service" />
          <h3>Digital Marketing</h3>
          <p>
            The right marketing can turn big ideas into success stories. Attract
            and win new customers with a compelling digital strategy designed by
            a professional team of marketing specialists.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
        <div class="service-box blue">
          <img src="/assets/images/branding-vector-4.svg" alt="Service" />
          <h3>Business Consulting</h3>
          <p>
            Technology is complex. Get the most out of digital innovation by
            following a clearly-defined roadmap for change. Modernize your
            software and implement new systems without the risk.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
        <div class="service-box red">
          <img src="/assets/images/branding-vector-5.svg" alt="Service" />
          <h3>Proof-of-Concept and Prototyping</h3>
          <p>
            Cut costs, reduce risk, and position your business for success with
            rapid prototyping. Techware Lab lets you build with confidence by
            clarifying priorities and eliminating uncertainty ahead of time.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
        <div class="service-box yellow">
          <img src="/assets/images/branding-vector-6.svg" alt="Service" />
          <h3>Ecommerce Development</h3>
          <p>
            Got something to sell? Maximize profits with a sleek and effortless
            ecommerce portal tailored to your exact business needs. Deliver an
            exceptional customer experience across any device.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="set-apart-wrapper">
  <div class="container">
    <h1 class="cmn-heading">What Sets Us Apart</h1>
    <div class="row mt-5 pt-5">
      <div class="col-md-4">
        <div class="inner-box">
          <span><img src="/assets/images/apart-icon-1.svg" alt="Icon" /></span>
          <h3>With You Every Step of the Way</h3>
          <p>
            Writing the perfect story for your business can seem daunting. We’re
            with you at every stage of the brand and marketing process.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="inner-box blue">
          <span><img src="/assets/images/apart-icon-2.svg" alt="Icon" /></span>
          <h3>Fast and Effective</h3>
          <p>
            We organize a realistic and progressive timeline to ensure our
            deliverables meet your business needs right on schedule.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="inner-box yellow">
          <span><img src="/assets/images/apart-icon-3.svg" alt="Icon" /></span>
          <h3>Stand in a Category of Your Own</h3>
          <p>
            You’ll never have to worry about similarities between your brand
            identity and your competitors’. Our experts bring a fresh
            perspective to every client we work with.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="testimonial-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <h1 class="cmn-heading">Who We Work With</h1>
        <h2 class="cmn-sub-heading">
          See other companies who have partnered with Techware Lab for branding
          and design services.
        </h2>
      </div>
      <div class="col-md-4"></div>
    </div>
    <div id="demo" class="carousel slide mt-5" data-ride="carousel">
      <!-- The slideshow
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row">
            <div class="col-md-3">
              <div class="test-circle">
                <div class="test-profile">
                  <img
                    src="/assets/images/testimonial-image-1.jpg"
                    alt="Profile"
                  />
                </div>
                <h3>Josef Myers</h3>
                <h4>That Level, CEO, USA</h4>
              </div>
            </div>
            <div class="col-md-9">
              <div class="test-content">
                <p>
                  “I've been outsourcing some tasks to offshore developers for
                  over 10 years now, and I've had the best experience with
                  Techware by far. They follow the development and design
                  requirements perfectly, and most importantly they communicate
                  any questions or concerns early in the project, which saves
                  everyone a lot of time. For every phase of the project
                  management, development, and deployment, they work quickly and
                  effectively. So whenever I send them a project, I know there's
                  no need to worry about them completing correctly and on time.”
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-md-3">
              <div class="test-circle">
                <div class="test-profile">
                  <img src="/assets/images/AbdulSattar.jpg" alt="Profile" />
                </div>
                <h3>AbdulSattar Zahid</h3>
                <h4>
                  Alibaba Product Growth,<br />
                  Dubai
                </h4>
              </div>
            </div>
            <div class="col-md-9">
              <div class="test-content">
                <p>
                  “In a nutshell, I consider them to be a member of our team,
                  who just don't sit with us. I have always found the Techware
                  team very compliant. I have never gotten the feeling that
                  getting to a goal is important to me and to them we are just
                  another client, but I have always gotten the feeling that we
                  are one team and I value that over all the disagreements and
                  what not. As a testament to this feedback, what started from
                  one project has transformed our partnership in a very long one
                  and we hope to continue that. ”
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-md-3">
              <div class="test-circle">
                <div class="test-profile">
                  <img
                    src="/assets/images/testimonial-image-3.jpg"
                    alt="Profile"
                  />
                </div>
                <h3>Danette Copestake</h3>
                <h4>
                  IT Director,<br />
                  Wyelands Bank
                </h4>
              </div>
            </div>
            <div class="col-md-9">
              <div class="test-content">
                <p>
                  "Techware Lab and I worked together on a number of exciting
                  innovation and R&D projects. Nixon was my lead technical
                  consultant. He is a knowledgeable IT consultant who is highly
                  collaborative in his approach. He is always quick to respond
                  to questions / issues offering solutions to complex problems
                  and often thinks ‘out of the box’. Nixon is intelligent,
                  diligent and thorough in his approach and maintains a sense of
                  humour. He was a highly valued member of my team, often going
                  above and beyond what is expected of him. I would welcome him
                  back to my team and strongly recommend him to future
                  employers."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right">
        <a class="carousel-control-prev" href="#demo" data-slide="prev"> </a>
        <a class="carousel-control-next" href="#demo" data-slide="next"> </a>
      </div>
    </div>
  </div>
</section> -->


<section class="brands-wrapper">
  <div class="container">
    <div class="row mt-5 mb-4 mt-md-0">
      <div class="col-md-12 text-center">
        <h1 class="cmn-heading">Who We Work With</h1>
        <h2 class="cmn-sub-heading">
          See other companies who have partnered with Techware Lab for website
          design<br />
          and development services.
        </h2>
      </div>
    </div>

    <owl-carousel-o [options]="customOptionsOne">
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-one.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-two.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-three.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-four.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/logo-eigt.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/logo-nine.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-logo-five.png"
            class="img-fluid brand-img-one"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-logo-four.png"
            class="img-fluid brand-img-two"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/logo-three.png"
            class="img-fluid brand-img-three"
            alt="Images"
          />
        </div>
      </ng-template>

      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/logo-five.png"
            class="img-fluid brand-img-four"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/logo-six.png"
            class="img-fluid brand-img-five"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/logo-sev.png"
            class="img-fluid brand-img-six"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-ten.png"
            class="img-fluid brand-img-seven"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-elv.png"
            class="img-fluid brand-img-eigt"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-tw.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>

<section class="footer-banner-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">
          Build a Competitive Brand Identity With Our Team of Graphic Design
          Experts
        </h1>
        <h2 class="cmn-sub-heading">
          Our team of graphic designers, marketers and brand specialists
          prioritize your core values and a business, and translate them into
          compelling and relatable content that increases engagement and
          retention. <br /><br />
          Get started on your marketing campaign, website content design, mobile
          app development, logo design, brand identity design, or video
          production by getting a quote with us today.
        </h2>
        <button routerLink="/contact">Get a quote now</button>
      </div>
    </div>
  </div>
</section>
