import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-library-management-system',
  templateUrl: './library-management-system.component.html',
  styleUrls: ['./library-management-system.component.scss']
})
export class LibraryManagementSystemComponent implements OnInit {
  public fbLink: string = environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;

  constructor(
    private title: Title,
    private meta: Meta,
    private seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle('10 Reasons Why You Need A Library Management System');
    this.meta.updateTag({
      name: 'description',
      content:
        "Library management systems facilitate the administrators to keep an eye on the library department's all functions. Read this blog to know the top 10 reasons why the library management system is important",
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        "Library management systems facilitate the administrators to keep an eye on the library department's all functions. Read this blog to know the top 10 reasons why the library management system is important",
    });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://techwarelab.com/assets/images/blog-img-23.jpg',
    });
    this.meta.updateTag({
      property: 'og:title',
      content: '10 Reasons Why You Need A Library Management System',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        "Library management systems facilitate the administrators to keep an eye on the library department's all functions. Read this blog to know the top 10 reasons why the library management system is important",
    });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://techwarelab.com/why-you-need-a-library-management-system',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://techwarelab.com/assets/images/blog-img-23.jpg',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: '10 Reasons Why You Need A Library Management System',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        "Library management systems facilitate the administrators to keep an eye on the library department's all functions. Read this blog to know the top 10 reasons why the library management system is important",
    });
    this.meta.updateTag({
      name: 'twitter:domain',
      content: '10 Reasons Why You Need A Library Management System',
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `why-you-need-a-library-management-system`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u', `https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }

}
