import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-case-study-kp',
  templateUrl: './case-study-kp.component.html',
  styleUrls: ['./case-study-kp.component.scss']
})
export class CaseStudyKpComponent implements OnInit {

  kpData: any = {
    heading: {
      title: 'KinderPass',
      description: 'KinderPass is an all-in-one childcare management platform for daycares, nurseries, and preschools.',
      buttonText: 'TALK TO OUR EXPERTS',
      imageUrl: "../../assets/images/case-study-kp-heading-section-img.png"
    },
    about: {
      title: 'About KinderPass',
      description: ['KinderPass is a childcare management application developed by Techware Lab that provides an easy-to-use and comprehensive solution to help daycare centers and preschools manage their day-to-day activities efficiently. The application has features such as attendance tracking, billing and payments, messaging, and learning activities that are designed to simplify the management process for childcare centers.'],
      imageUrl: "../../assets/images/case-study-kp-about-section-img.png"
    },
    story: {
      title: 'Story',
      description: [
        'The development of KinderPass took approximately two years from conception to launch. The project involved a team of designers, developers, and testers who worked together to create a seamless and user-friendly application.',
        'The development process involved several stages, including:',
        'Research: The first stage involved researching the childcare industry to identify the challenges and pain points that childcare centers face. This research helped to identify the key features that would be included in the application.',
      ],
      buttonText:'VIEW MORE',
      imageUrl: "/assets/images/case-study-kp-story-section-img.png",
      viewMoreContent: [
        '<p>Design: Once the key features were identified, the design team created wireframes and prototypes of the application. The design team worked closely with the development team to ensure that the design was feasible and could be developed according to the project timeline.</p>',
        '<p>Development: The development team worked on building the application, which involved coding and testing. The team used agile development methodologies to ensure that the application was developed in a structured and efficient manner.</p>',
        '<p>Testing: Once the application was developed, the testing team tested the application to identify any bugs or issues. The testing phase involved both manual and automated testing to ensure that the application was stable and free from errors.',
        '<p>Launch: After the testing phase, the application was launched, and childcare centers could start using the application.</p>',
        '<p>KinderPass provides an all-in-one solution for childcare centers to manage their daily activities with ease. Here are some of the features that make KinderPass stand out from the rest:</p>',
        '<p>KinderPass allows daycare centers to track attendance accurately, providing real-time data that helps them plan their schedules accordingly. Parents can also see their child\'s attendance records through the application.</p>',
        '<p>KinderPass provides an automated billing and payment system that eliminates manual processes that are time-consuming and prone to errors. The application sends invoices and receipts to parents automatically, and they can make payments through the app.</p>',
        '<p>The application provides a messaging platform that enables parents and childcare providers to communicate easily. Parents can get real-time updates on their child\'s activities, while childcare providers can communicate with parents about events, activities, and other important information.</p>',
        '<p>KinderPass provides age-appropriate learning activities for children, which can be accessed through the application. These activities are designed to encourage learning and development in children.</p>'
      ]
      
    },
    plainBanner: {
      title: 'Get Your Comprehensive Childcare Management App Created with Our Top-Notch Development Solution',
      buttonText: 'CONTACT US'
    },
    results: {
      title: 'Results',
      description: [
        'KinderPass has been a success since its launch, and it has helped many childcare centers manage their daily activities more efficiently. The application has received positive feedback from childcare providers and parents, who appreciate its ease of use and comprehensive features.',
        'KinderPass has revolutionized daycare providers to manage childcare with features including scheduling, communication, attendance tracking, and billing. KinderPass\'s success is attributed to our commitment to developing a project that met their specific needs. We made sure that it\'s user-friendly and accessible on multiple platforms.'
      ],
      imageUrl: "../../assets/images/case-study-kp-result-section-img.png"
    }
  };

  constructor(private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle(
      "Case Study KinderPass  | TechwareLab"
    );
  }


}
