import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';

@Injectable({
  providedIn: 'root'
})

export class HelperService {
  constructor(public toastr: ToastrManager, private router : Router) {

  }

  showAlert(type: number = 4, message: string = 'Bad Request') {
    switch (type) {
      case 1: this.toastr.successToastr(message,'',{
        position: 'top-center'
    }); break;
      case 2: this.toastr.infoToastr(message,'',{
        position: 'top-center'
    }); break;
      case 3: this.toastr.warningToastr(message,'',{
        position: 'top-center'
    }); break;
      case 4: this.toastr.errorToastr(message,'',{
        position: 'top-center'
    }); break;
    }
  }

  goToPage(path: any, data = {}) {
    this.router.navigate(['/'+path], data);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
