import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from 'src/app/request-quote/request-quote.component';

@Component({
  selector: 'app-search-engine-optimization',
  templateUrl: './search-engine-optimization.component.html',
  styleUrls: ['./search-engine-optimization.component.scss']
})
export class SearchEngineOptimizationComponent implements OnInit {


  constructor(private model: MatDialog) { }

  ngOnInit(): void {
  }
  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }


}
