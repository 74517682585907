import { Component, OnInit } from '@angular/core';
declare var ZFWidget:any;

@Component({
  selector: 'app-career-form',
  templateUrl: './career-form.component.html',
  styleUrls: ['./career-form.component.scss']
})
export class CareerFormComponent implements OnInit {

  

  pricingTableComponentOptions = {
    id: 'zf-widget-root-id',
    product_id: '2-af63b261dc2e0358fdbd15e2c9066cf2b1d696ba014e3b56170c90ed9a54e6ccf0b48bbb8f7f67d2dcd6c241ff4b3036a073444c5559f6aae8f94ecca8061732',
    template: 'elegant_pro',
    most_popular_plan: '',
    is_group_by_frequency: false,
    group_options: [

    ],
    plans: [
      {
  plan_code: 'SILVER_01'
},
{
  plan_code: 'GOLD_01'
},
{
  plan_code: 'PLATINUM_01'
},

    ],
    theme: { color: '#2AC497', theme_color_light: ''},
    button_text: 'Subscribe',
    product_url: 'https://subscriptions.zoho.in',
    price_caption: '',
    language_code: 'en'
};

ngAfterViewInit() {
  setTimeout(()=>{
    ZFWidget.init('zf-pricing-table', this.pricingTableComponentOptions);
  }, 6000);

}

  constructor() { }

  ngOnInit(): void {
  }

}
