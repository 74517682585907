import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as countries from "../contact/countries.json";
import { WebService } from '../providers/web.service';
import { MessageSuccessComponent } from '../message-success/message-success.component';
import { MatDialog } from '@angular/material/dialog';
import { HelperService } from '../providers/helper.service';
import { SeoService } from '../seo.service';
import { Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss']
})
export class FormSectionComponent implements OnInit {
  caseStudyForm;
  countryList: any = new Array();
  formSubmit: boolean;

  constructor(public service: WebService, private model: MatDialog, private router: Router, public helper: HelperService, private seoService: SeoService) { 
    this.formSubmit = false;
    this.countryList = countries;
   }

  ngOnInit() {
    this.caseStudyForm = new FormGroup({
      name: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z ]{2,30}$"),
      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
      ]),
      country: new FormControl("", [Validators.required]),
      phone: new FormControl("", [
        Validators.required, 
        Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"),
      ]),
      service: new FormControl("",[Validators.required]),
      requirement: new FormControl("", [Validators.required]),
    });
  }

  caseStudyFormSubmit() {
    this.formSubmit = true;
    if (this.caseStudyForm.valid) {
      let userObj = this.caseStudyForm.value;
      this.service.post_data("contactFormTechlab", "", userObj).subscribe(
        (response) => {
          if (response && response.status && response.status == 200) {
            this.model.open(MessageSuccessComponent);
          } else {
            this.helper.showAlert(4, "Something went wrong");
          }
          this.caseStudyForm.reset({
            name: "",
            email: "",
            country: "0",
            phone: "",
            service: "0",
            requirement: ""
          });
          this.formSubmit = false;
        },
        (error) => {
          this.helper.showAlert(4, "Something went wrong");
          this.formSubmit = false;
        }
      );
    }
  }
  msgSuccess() {
    this.model.open(MessageSuccessComponent);
  }
}
