import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-case-study-daraz',
  templateUrl: './case-study-daraz.component.html',
  styleUrls: ['./case-study-daraz.component.scss']
})
export class CaseStudyDarazComponent implements OnInit{

  darazData: any = {
    heading: {
      title: 'Daraz - An Alibaba Group Company',
      description: 'Daraz is the leading online marketplace in South Asia, fostering connections between millions of customers and a vast network of sellers.',
      buttonText: 'TALK TO OUR EXPERTS',
      imageUrl: "../../assets/images/case-study-daraz-heading-section-img.png"
    },
    about: {
      title: 'About Daraz - An Alibaba Group Company',
      description: ['Daraz is an e-commerce platform that offers a wide range of products to customers in Pakistan, Sri Lanka, Nepal, Bhutan, and Myanmar. With its user-friendly interface and easy-to-use features, Daraz has become the go-to shopping solution for many people in these countries.'],
      imageUrl: "../../assets/images/case-study-daraz-about-section-img.png"
    },
    story: {
      title: 'Story',
      description: [
        'In the fast-paced world of e-commerce, having a reliable and user-friendly platform is crucial. This is where Techware Lab comes in. We developed a web application for Daraz, an Alibaba Group company, in under eight months.',
        ' The success of this project speaks volumes about Techware Lab\'s expertise in the field.The success of Daraz is primarily due to its web and mobile app, which Techware Lab developed. The project involved 20 employees who worked tirelessly to ensure that the app was up and running in a short amount of time.'
      ],
      buttonText:'VIEW MORE',
      imageUrl: "../../assets/images/case-study-daraz-story-section-img.png",
      viewMoreContent: [
        '<p>Daraz is similar to Flipkart, one of India\'s leading e-commerce platforms. It means that customers familiar with Flipkart can navigate the Daraz platform easily.</p>',
        '<p>It offers many features that make shopping on Daraz a breeze. Customers can browse a vast selection of products, compare prices, and read reviews from other customers. They can also easily place orders and make payments using various payment options.</p>',
        '<p>One of the critical features of the Daraz platform is its user-friendly interface. We designed Daraz in such a way that the customers can easily find what they want. The search bar is prominently displayed on the home page, and customers can also browse through different categories of products.</p>',
        '<p>The Daraz platform also offers several value-added services to its customers. For example, customers can track their orders in real-time and benefit from a hassle-free return policy if they are unsatisfied with their purchases.</p>'
      ]
      
    },
    plainBanner: {
      title: 'Get Your Ecommerce Marketplace Created with Our Top-Notch Development Solution',
      buttonText: 'CONTACT US'
    },
    results: {
      title: 'Results',
      description: ['The success of the Daraz platform is a testament to the hard work and dedication of the team at Techware Lab. By developing a reliable and user-friendly platform, we have helped Daraz become one of the leading e-commerce platforms in Pakistan, Sri Lanka, Nepal, Bhutan, and Myanmar. By working closely with the client and leveraging our expertise in the field, we developed a web and mobile app that has become the go-to shopping solution for millions of people in the region.'],
      imageUrl: "../../assets/images/case-study-daraz-result-section-img.png"
    }
  };

  constructor(private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle(
      "Case Study Daraz - An Alibaba Group  | TechwareLab"
    );
  }
 
}
