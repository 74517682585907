<section class="uiuxSectionWrapper">
    <div class="heroSection">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="heroContent">
              <div>
                <h1>Testing and QA Services</h1>
                <h5>Quality First, Elevate with Testing</h5>
                <p>
                    At Techware Lab, we believe in delivering flawless software that stands up to the highest industry standards. Our dedicated Testing and QA team ensures your software is functional, reliable, and user-friendly, making your business shine and stand out in the competitive market.
                </p>
                <button (click)="requestQuote()">Get Started</button>
              </div>
  
              <div class="pinkBgDiv"></div>
              <div class="yellowBgDiv"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="heroImgWrapper">
              <img src="../../../assets/images/testingHeroImage.png" />
            </div>
            <div class="heroImageBgWrapper">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M48.4,-55.6C62.1,-46.3,71.9,-30.4,74.1,-13.7C76.3,3,70.9,20.4,62.6,37.4C54.3,54.3,43,70.8,28,75.8C13,80.8,-5.8,74.2,-21.1,65.4C-36.4,56.6,-48.1,45.6,-58.3,31.8C-68.4,18,-77.1,1.4,-74.7,-13.4C-72.3,-28.2,-59,-41.3,-44.7,-50.5C-30.5,-59.7,-15.2,-64.9,1.1,-66.2C17.4,-67.5,34.8,-64.9,48.4,-55.6Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
            <div class="heroImageWrapper2">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M65.2,-21.4C72.9,2.3,59.4,32.7,36.8,48.7C14.2,64.6,-17.4,66,-40.1,50.6C-62.8,35.2,-76.5,2.9,-68.4,-21.6C-60.2,-46.1,-30.1,-62.8,-0.7,-62.5C28.8,-62.3,57.6,-45.2,65.2,-21.4Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="serviceSection">
      <div class="container">
        <h2>Our Offerings</h2>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../../assets/images/testingFntImage.png" />
            <h3>Functional Testing</h3>
            <p>
                We delve deep into your software’s operations, ensuring every function works as intended. Our experts use manual and automated testing methods, ensuring all aspects of your software are thoroughly checked.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/testingPerformanceImage.png" />
            <h3>Performance Testing</h3>
            <p>
                We ensure your software doesn’t just work but works efficiently. Through stress, load, and scalability testing, we ensure your application remains responsive even under intense workloads.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/testingUsabilityImage.png" />
            <h3>Usability Testing</h3>
            <p>
                Your software’s usability is paramount. Our team tests the software’s interface, ensuring it’s intuitive, user-friendly, and meets the expectations of the end-users.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../../assets/images/testingSecurityImage.png" />
            <h3>Security Testing</h3>
            <p>
                Protecting user data and ensuring the robustness of your software against malicious attacks is our top priority. We identify vulnerabilities and ensure they are addressed before they can be exploited.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/testingCompatibilityImage.png" />
            <h3>Compatibility Testing</h3>
            <p>
                We ensure your software functions seamlessly across different devices, browsers, and operating systems, offering a consistent user experience to all users.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/testingDeliveryImage.png" />
            <h3>Continuous Integration & Continuous Delivery (CI/CD)</h3>
            <p>
                In today’s agile world, continuous updates are the norm. We ensure your updates not only get delivered faster but also without compromising on quality.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="featureSection">
      <div class="blueBgDiv"></div>
      <div class="pinkBgDiv"></div>
      <div class="yellowBgDiv"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <img src="../../../assets/images/testingFeatureImage.png" />
          </div>
          <div class="col-lg-6">
            <h2>Why Choose Techware Lab for Testing and QA?</h2>
            <div>
              <h6>Expert Team</h6>
              <p>
                Our QA specialists are trained rigorously and are equipped with the latest tools and methodologies.
              </p>
            </div>
            <div>
              <h6>Cost-Effective Solutions</h6>
              <p>
                High quality sometimes means high cost. We provide top-notch services at competitive prices.
              </p>
            </div>
            <div>
              <h6>Custom Solutions</h6>
              <p>
                We understand that each software is unique. Our strategies are tailored to fit the specific needs of your project.
              </p>
            </div>
            <div>
              <h6>Timely Delivery</h6>
              <p>
                We believe in clear and open communication. Our team will keep you updated at every stage of the testing process
              </p>
            </div>
            <div>
              <h6>Transparent Communication</h6>
              <p>
                We understand the importance of time in the digital world. Our team is committed to delivering projects within the stipulated timelines.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="ctaWrapper">
      <div class="container">
        <h1>Let’s Build Perfection Together</h1>
        <p>
            Join hands with Techware Lab, and let’s create software that’s not just functional but exemplary. Contact us today to discuss how we can elevate your software to the next level.
        </p>
  
        <button routerLink="/contact">Book Your Free Consultation</button>
      </div>
    </div>
  </section>
  