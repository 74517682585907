<section class="career-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="circle-wrapper">
                    <h1>TCourt: Find a Lawyer <br>
                        or Attorney and Book <br>
                        Online Instantly
                    </h1>
                    <h2 class="cmn-sub-heading">An attorney at the ready for all your legal needs. Connect clients with lawyers using TCourt’s intuitive online appointment booking and case management platform for lawyers and attorneys. With our appointment scheduling software for law firms, you will never forget a client appointment again.
                    </h2>
                    <a (click)="requestQuote()" target="_blank">Request a demo</a>
                </div>
            </div>
            <div class="col-md-5">
                <img src="/assets/images/tcourt-img-1.png" class="img-fluid" alt="ttaxi">
            </div>
        </div>
    </div>
</section>

<section class="whatistaxi-wrapper">
    <div class="container">
        <div class="row pb-4">
            <div class="col-md-5" data-aos="fade-right">
                <img src="/assets/images/tcourt-img-2.png" class="img-fluid" alt="ttaxi">
            </div>
            <div class="col-md-7">
                <div class="content">
                    <h1 class="cmn-heading">What Is TCourt?</h1>
                    <p>Have you ever wanted to launch a powerful app for booking lawyers but found the work too daunting? Techware Lab presents TCourt for advanced software solutions that serve the needs of modern law firms and upgrade your online reach.

                    </p>
                    <p>
                        T-Court is an online appointment scheduling software for lawyers and law firms. The app features user-friendly and intuitive features to help manage and grow your law practice. The platform provides scalable and customizable tools to manage a law practice of any size.
                    </p>
                    <p>T-Court is the ideal solution for individual lawyers too, giving them a powerful platform to scale a new law practice. This software works to maximize productivity and profitability by giving you all the functionality you need to stay organized and ahead of the curve.</p>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="taxi-box">
                    <img src="/assets/images/tcourt-icon-1.png" class="img-fluid" alt="TTaxi">
                    <h2>Help Users Find the Best <br>
                        Candidates
                        </h2>
                    <p>Empower users with granular control over their selection of attorneys. TCourt allows users to choose legal professionals based on country, reviews and ratings, and even specializations.

                    </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="taxi-box">
                    <img src="/assets/images/tcourt-icon-2.png" class="img-fluid" alt="TTaxi">
                    <h2>Boost Bookings With a <br>
                        Stunning and Intuitive UI</h2>
                    <p>TCourt’s user-friendly dashboard helps encourage more bookings. Personalize the look of your legal appointment booking app to your unique brand guidelines with professional design and intuitive technological features..
                    </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="taxi-box">
                    <img src="/assets/images/tcourt-icon-3.png" class="img-fluid" alt="TTaxi">
                    <h2>Take A Technology-Driven 
                        Approach to Law
                        </h2>
                    <p>TCourt’s efficient platform offers a simple comparison of the best lawyers from across the country and seamless client consultation over voice calls, email, or physical meetings. This unparalleled flexibility is what sets TCourt apart from traditional booking methods.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ttaxi-features-wrapper">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-4">
                <h1 class="cmn-heading">TCourt Features</h1>
                <img src="/assets/images/tcourt-img-3.png" class="img-fluid" alt="TTaxi">
            </div>
            <div class="col-md-8">
                <ul class="list-1">
                    <li><span>1</span> <h6>Pay Only for your Session</h6></li>
                    <li><span>2</span> <h6>Review the attorneys</h6></li>
                    <li><span>3</span> <h6>Q and A session with the lawyers</h6></li>
                    <li><span>4</span> <h6>Choose the best lawyers based on reviews and ratings</h6></li>
                    <li><span>5</span> <h6>Payment Gateway integrated</h6></li>
                    <li><span>6</span> <h6>Search Lawyers by Country, City, Specialization, Experience, and Court Wise like District, High Court, Supreme Court</h6></li>
                </ul>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-6 order-md-0 order-1">
                <div class="content">
                    <h2 class="cmn-heading">About Techware Lab</h2>
                    <p>Techware Lab provides top design capabilities and software solutions that help elevate your business through industry-specific apps. Techware Lab is a technology-driven company that will put your law firm app onto your client’s home screen. With a deep understanding of the demands law firms face, TCourt provides law professionals with the advanced tools that make connecting clients and lawyers easy, flexible and quick. Our experience with software and mobile app development allow us to create solutions that will help you grow and manage your business.
                    </p>
                </div>
            </div>
            <div class="col-md-6 order-md-1 order-0">
                <img src="/assets/images/tcourt-img-4.png" class="img-fluid" alt="TTaxi">
            </div>
        </div>
    </div>
</section>

<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Modernize Your Law Firm With Next-
                    Gen Law Firm Software</h1>
                <h2 class="cmn-sub-heading">App technologies are fueling the mobile tech revolution. TCourt makes the process of running a law firm and booking lawyers fast and effortless.
                </h2>
                <button routerLink="/contact">Learn more today</button>
            </div>
        </div>
    </div>
</section>

<section class="faq-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Frequently Asked Questions</h1>
        <mat-accordion>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                    What kind of software do most law firms use?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>Investing in an appointment booking system allows your customers to easily book appointments with your law firm, making all of your upcoming appointments visible to you and your teams—in one centralized location. TCourt removes the manual processes associated with matching staff schedules with the availability of your customers, letting you focus on running an efficient and profitable law firm.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    What does legal appointment booking software do?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>TCourt is a lawyer and attorney scheduling software that offers user-friendly and intuitive features to help you manage, grow, and streamline operations through scalable and customizable tools.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How can I make an appointment with a lawyer on TCourt?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Our lawyer scheduling system can be used for booking appointments with attorneys, lawyers, tax consultants, dispute resolution professionals, and more. It allows you to plan reservations efficiently and better manage your client’s time. TCourt is a leading solution designed to maximize productivity and profitability at your law firm.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Is TCourt good for law firms?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Yes. The legal case booking software acts as your firm’s first touch-point with clients. It allows prospective clients to view available time slots to meet with their chosen legal service provider, book appointments, and more.</p>
              </mat-expansion-panel>
        </mat-accordion>
    </div>
</section>

<section class="relates-solutions-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Related Solutions</h1>
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/doctor-appointment-app">
                    <img src="/assets/images/new-related-img2.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/taxi-management-system">
                    <img src="/assets/images/new-related-img3.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/taxi-dispatch-software">
                    <img src="/assets/images/new-related-img4.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/grocery-app-development">
                    <img src="/assets/images/new-related-img5.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/online-food-ordering-system">
                    <img src="/assets/images/new-related-img6.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/car-rental-app-development">
                    <img src="/assets/images/new-related-img7.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/movie-ticketing-software">
                    <img src="/assets/images/new-related-img8.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/beauty-salon-software">
                    <img src="/assets/images/new-related-img9.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/automotive-appointment-software">
                    <img src="/assets/images/new-related-img10.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/matrimony-app-development">
                    <img src="/assets/images/new-related-img11.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/payroll-management-system">
                    <img src="/assets/images/new-related-img12.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>