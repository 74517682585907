<app-heading-section 
   [title]="kpData.heading.title"
   [description]="kpData.heading.description"
   [buttonText]="kpData.heading.buttonText"
   [imageUrl]="kpData.heading.imageUrl">
</app-heading-section>

<app-about-section 
   [title]="kpData.about.title"
   [description]="kpData.about.description"
   [imageUrl]="kpData.about.imageUrl">
</app-about-section>

<app-story-section
    [title]="kpData.story.title"
    [description]="kpData.story.description"
    [imageUrl]="kpData.story.imageUrl"
    [buttonText]="kpData.story.buttonText"
    [viewMoreContent]="kpData.story.viewMoreContent"
    >
</app-story-section>

<app-plain-banner 
   [title]="kpData.plainBanner.title"
   [buttonText]="kpData.plainBanner.buttonText">
</app-plain-banner>

<app-results-section 
   [title]="kpData.results.title"
   [description]="kpData.results.description"
   [imageUrl]="kpData.results.imageUrl">
</app-results-section>

<app-form-section></app-form-section>


