import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-marketing-service',
  templateUrl: './content-marketing-service.component.html',
  styleUrls: ['./content-marketing-service.component.scss']
})
export class ContentMarketingServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
