<section class="blog-details-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>Techware Lab: Why (and how) we rebranded
                </h1>
                <h2 class="cmn-sub-heading">April 2021</h2>
                <div class="row">
                    <div class="social-share col-1 col-md-1">
                      <ul>
                          <li (click)="share('facebook')"><img src="/assets/images/facebook.png" alt="Facebook"></li>
                          <li (click)="share('linkedin')"><img src="/assets/images/linked-icon.png" alt="LinkedIn"></li>
                          <li (click)="share('twitter')"><img src="/assets/images/twitter.png" alt="Twitter"></li>
                          <li (click)="share('whatsapp')"><img src="/assets/images/whatsapp-icon.png" alt="whatsapp"></li>
                      </ul>
                    </div>
                    <div class="col-11 col-md-11">
                        <img src="/assets/images/blog-img-1.png" class="img-fluid" alt="Why Techware software solutions  rebranded to Techware Lab">

                    </div>
                  </div>
                <p>We are proud to share that Techware Software Solution is now Techware Lab. Apart from a new name, we have a new brand identity as well. While this is a significant change, our core beliefs haven’t changed. Our unwavering commitment to
                    our values, our customers and our core philosophy remains the same.
                </p>
                <p>
                    Our new youthful, fresh and confident look better reflects who we are: Innovative. Focused. Reliable. Agile. This is what has always defined us, and this will continue to define us in the days to come.
                </p>
                <p>
                    But why did we decide to rebrand? Let's back-up first.
                </p>
                <h3>Why did we rebrand?
                </h3>
                <ul>
                    <li><b>We wanted to refresh our style:</b> Last year, we started analyzing our brand identity and discovered that our branding was overwhelming and outdated. It no longer reflected our culture, our people or our work. We just looked like
                        any other software development company when there was so much that differentiated us from our competitors.
                    </li>
                    <li><b>Our business has expanded:</b> When we started almost a decade ago, we were a tiny software development company based out of our CEO’s college dorm room. A lot has changed since. Our team has grown, we launched new products and
                        services, we developed in-depth expertise across technologies and expanded our business into most industries. As we evolved and worked with customers across the world, we also wanted to make sure our brand identity reflected the
                        level of strategic thinking and focus that goes into our work.</li>
                    <li><b>Our website needed an update:</b> We spend so much time providing great work to our customers that we didn’t take the time to update our own website as often as we should. It was time to practice what we preach. When we finally
                        decided to update our website, we realized it would be best to completely overhaul everything.
                    </li>
                </ul>
                <p>In short, we decided to rebrand to represent who we are today and to accelerate our company's success.</p>
                <div class="mt-5 mb-5 text-center">
                    <img src="/assets/images/logo-old.png" alt="Old Logo">
                </div>
                <h3>How did we rebrand?</h3>
                <p>Honestly, it’s never the right time to rebrand. It’s definitely disruptive and needs resources, but we knew it was time. Here are some tips based on our experience.</p>
                <ul>
                    <li><b>Start with the basics:</b> We went back to the basics. We started by reviewing our mission and our values. In our case, we realized that nothing had changed - we still value our customers and focus on providing innovative solutions
                        that will move their businesses forward.
                    </li>
                    <li><b>Check your name:</b> Our name “Techware Software Solutions” was long and boring. We saw how outdated our branding looked. So we started by tackling our name. Everyone in the management team shared their ideas in a brainstorming
                        session. The one thing that we all loved was the idea of introducing the word “lab”. We believed that it described the experience of working with us and how customers can get solutions that are made specifically for their business
                        needs (just like if we were making them in a lab). We also liked the feeling of innovation that came with the word “lab” - it fit perfectly with our commitment to providing innovative solutions to our customers. That’s how the
                        name Techware Lab was born.</li>
                    <li><b>Get a new logo:</b> Our logo was clearly outdated and we had decided on a new name, so we worked with our designer to reimagine our logo. We wanted to introduce bold colours and modern typography to show our innovative and friendly
                        culture. After several rounds of designs, we fell in love with our new logo which felt more fun, friendly and our own.
                        <div class="image-list text-center">
                            <img src="/assets/images/logo-doodle.png " class="img-fluid " alt="Logo Branding ">
                            <img src="/assets/images/new-logo.jpg " class="img-fluid " alt="Logo Branding ">
                        </div>
                    </li>

                    <li><b>Review your website, social media and content:</b> Finally, it was time to see if each component of our online presence showcased our new brand. This is the part that took a long time. We redesigned our website, updated our content,
                        created new social media posts, tweaked our messaging, and got our team onboard. Doing all of this allowed us to emphasize our core values and improve our brand identity. Consistency was key!

                    </li>
                </ul>

                <h3>What now?</h3>
                <p>A lot of love and hard work went into our rebrand. And these new updates are a small reflection of the big changes we're making across the company to better serve our customers and expand our business. We are opening an office in Canada,
                    building an AI team and launching new products. We will share more information soon!</p>
                <p>We’d also like to take the opportunity to thank our customers and business partners. We appreciate your continued confidence in us and your support. Please feel free to reach out with any thoughts, requests, or compliments!</p>
                <h3>About Techware Lab:</h3>
                <p>Techware Lab is a boutique <a href="https://techwarelab.com" target="_blank"> software development company.</a> We have delivered our services to clients across the world since 2012 and across most industries. Our services include web and mobile application development, product management,
                    custom software development, eCommerce solutions, enterprise development, business consulting, go-to-market strategy and digital marketing.</p>
                <p>Our mission is to provide the best development services for our customers while also continuing to innovate with our white label products and our new approaches to business problems. </p>
                <p>For more information, please contact us below.</p>
                <div class="row">
                    <div id="disqus_thread "></div>
                    <script>
                        /**
                         *  RECOMMENDED CONFIGURATION VARIABLES: EDIT AND UNCOMMENT THE SECTION BELOW TO INSERT DYNAMIC VALUES FROM YOUR PLATFORM OR CMS.
                         *  LEARN WHY DEFINING THESE VARIABLES IS IMPORTANT: https://disqus.com/admin/universalcode/#configuration-variables    */
                        /*
                        var disqus_config = function () {
                        this.page.url = PAGE_URL;  // Replace PAGE_URL with your page's canonical URL variable
                        this.page.identifier = PAGE_IDENTIFIER; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
                        };
                        */
                        (function() { // DON'T EDIT BELOW THIS LINE
                            var d = document,
                                s = d.createElement('script');
                            s.src = 'https://techwarelab.disqus.com/embed.js';
                            s.setAttribute('data-timestamp', +new Date());
                            (d.head || d.body).appendChild(s);
                        })();
                    </script>
                    <noscript>Please enable JavaScript to view the <a href="https://disqus.com/?ref_noscript ">comments powered by Disqus.</a></noscript>
                </div>
            </div>
            <!-- <div class="col-md-4 "></div> -->
        </div>
    </div>
</section>

<section class="related-blog">
    <div class="container">
        <h2>Related Blog</h2>
        <div class="row">
            <div class="col-md-3">
                <div class="related-box">
                    <img src="/assets/images/blog-img-5.png" class="img-fluid" alt="Chatbots for Business">
                    <h1 routerLink="/why-business-needs-chatbot">Top 6 reasons why your business needs a chatbot</h1>
                </div>
            </div>
            <div class="col-md-3">
                <div class="related-box">
                    <img src="/assets/images/blog-img-1.png" class="img-fluid" alt="Why Techware software solutions  rebranded to Techware Lab">
                    <h1 routerLink="/we-have-rebranded">Techware Lab: Why (and how) we rebranded</h1>
                </div>
            </div>
            <div class="col-md-3">
                <div class="related-box">
                    <img src="/assets/images/blog-img-3.png" class="img-fluid" alt="Blog Image">
                    <h1 routerLink="/benefits-chatbot-for-business">Chatbots for Business: 4 Benefits You Need to Know</h1>
                </div>
            </div>
            <div class="col-md-3">
                <div class="related-box">
                    <img src="/assets/images/blog-img-4.png" class="img-fluid" alt="Mobile App Ideas">
                    <h1 routerLink="/mobile-app-ideas">7 Best Mobile App Ideas to Consider in 2022</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="footer-banner-wrapper ">
    <div class="container ">
        <div class="row ">
            <div class="col-md-12 ">
                <h3 class="cmn-heading ">Ready to work with us?
                </h3>
                <h2 class="cmn-sub-heading ">We’re always looking for our next challenge. Bring your big idea to market with our team of designers,<br> developers, and business strategists.</h2>
                <button routerLink="/contact">Let's get started</button>
            </div>
        </div>
    </div>
</section>