<section class="uiuxSectionWrapper">
    <div class="heroSection">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="heroContent">
              <div>
                <h1>IT Consulting</h1>
                <h5>Your Roadmap to IT Empowerment</h5>
                <p>
                    In the modern business landscape, technology drives innovation and competitive edge. At Techware Lab, we provide comprehensive IT consulting services to empower businesses, streamline operations, and catalyze growth.
                </p>
                <button (click)="requestQuote()">Get Started</button>
              </div>
  
              <div class="pinkBgDiv"></div>
              <div class="yellowBgDiv"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="heroImgWrapper">
              <img src="../../assets/images/itHeroImage.png" />
            </div>
            <div class="heroImageBgWrapper">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M48.4,-55.6C62.1,-46.3,71.9,-30.4,74.1,-13.7C76.3,3,70.9,20.4,62.6,37.4C54.3,54.3,43,70.8,28,75.8C13,80.8,-5.8,74.2,-21.1,65.4C-36.4,56.6,-48.1,45.6,-58.3,31.8C-68.4,18,-77.1,1.4,-74.7,-13.4C-72.3,-28.2,-59,-41.3,-44.7,-50.5C-30.5,-59.7,-15.2,-64.9,1.1,-66.2C17.4,-67.5,34.8,-64.9,48.4,-55.6Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
            <div class="heroImageWrapper2">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M65.2,-21.4C72.9,2.3,59.4,32.7,36.8,48.7C14.2,64.6,-17.4,66,-40.1,50.6C-62.8,35.2,-76.5,2.9,-68.4,-21.6C-60.2,-46.1,-30.1,-62.8,-0.7,-62.5C28.8,-62.3,57.6,-45.2,65.2,-21.4Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="serviceSection">
      <div class="container">
        <h2>Our Service</h2>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../assets/images/itStrategyImae.png" />
            <h3>IT Strategy Development</h3>
            <p>
                Align your IT initiatives with business goals. Our team crafts robust IT strategies as a roadmap to tech-driven success.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/itTechImage.png" />
            <h3>Technology Assessment</h3>
            <p>
                Understand your current tech landscape. We evaluate your existing technology infrastructure, identifying areas of improvement and potential risks.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/itSystemImage.png" />
            <h3>System Integration</h3>
            <p>
                Eliminate silos and enhance efficiency. We help integrate disparate systems, fostering seamless data flow and platform communication.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../assets/images/itSecurityImage.png" />
            <h3>IT Security Consulting</h3>
            <p>
                Safeguard your digital assets. Our experts analyze vulnerabilities, recommend preventive measures, and ensure compliance with global security standards.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/itCloudImage.png" />
            <h3>Cloud Consulting</h3>
            <p>
                Harness the power of the cloud. From choosing the suitable cloud model to migration and optimization, we’ve got you covered.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/itDigitalImage.png" />
            <h3>Digital Transformation</h3>
            <p>
                Embark on a digital journey. We guide businesses in leveraging modern technologies, ensuring they stay relevant and agile in a rapidly changing environment.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="featureSection">
      <div class="blueBgDiv"></div>
      <div class="pinkBgDiv"></div>
      <div class="yellowBgDiv"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <img src="../../assets/images/itFeatureImage.png" />
          </div>
          <div class="col-lg-6">
            <h2>Why Partner with Techware Lab for IT Consulting?</h2>
            <div>
              <h6>Expertise & Experience</h6>
              <p>
                Our consultants bring a wealth of knowledge, drawing from years of experience in diverse IT domains.
              </p>
            </div>
            <div>
              <h6>Customized Solutions</h6>
              <p>
                We recognize the uniqueness of each business. Our recommendations and strategies are tailored specifically to your needs.
              </p>
            </div>
            <div>
              <h6>Holistic Approach</h6>
              <p>
                We don’t just focus on technology; we consider its impact on your business, ensuring a balanced and holistic approach.
              </p>
            </div>
            <div>
              <h6>Transparent Communication</h6>
              <p>
                Stay informed. Our team ensures clear communication throughout the consulting process, ensuring all stakeholders are on the same page.
              </p>
            </div>
            <div>
              <h6>Result-Oriented</h6>
              <p>
                Our goal is not just to advise but to drive tangible results, ensuring a substantial ROI for your IT investments.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="ctaWrapper">
      <div class="container">
        <h1>Unlock Your IT Potential with Techware Lab</h1>
        <p>
            Embrace the future with confidence. With Techware Lab’s IT Consulting services, navigate the intricate world of technology with clarity and purpose. Contact us today, and let’s chart a path to tech-driven success!
        </p>
  
        <button routerLink="/contact">Book A Free Consultation</button>
      </div>
    </div>
  </section>
  