<section class="uiuxSectionWrapper">
    <div class="heroSection">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="heroContent">
              <div>
                <h1>Content Marketing Services</h1>
                <h5>Narrate Your Brand’s Story with Impact</h5>
                <p>
                    We recognize the power of storytelling in the digital landscape. Our Content Marketing Services are tailored to echo your brand’s essence, engaging your audience with impactful narratives and driving meaningful results.
                </p>
                <button>Get Started</button>
              </div>
  
              <div class="pinkBgDiv"></div>
              <div class="yellowBgDiv"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="heroImgWrapper">
              <img src="../../assets/images/cmsHeroImage.png" />
            </div>
            <div class="heroImageBgWrapper">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M48.4,-55.6C62.1,-46.3,71.9,-30.4,74.1,-13.7C76.3,3,70.9,20.4,62.6,37.4C54.3,54.3,43,70.8,28,75.8C13,80.8,-5.8,74.2,-21.1,65.4C-36.4,56.6,-48.1,45.6,-58.3,31.8C-68.4,18,-77.1,1.4,-74.7,-13.4C-72.3,-28.2,-59,-41.3,-44.7,-50.5C-30.5,-59.7,-15.2,-64.9,1.1,-66.2C17.4,-67.5,34.8,-64.9,48.4,-55.6Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
            <div class="heroImageWrapper2">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M65.2,-21.4C72.9,2.3,59.4,32.7,36.8,48.7C14.2,64.6,-17.4,66,-40.1,50.6C-62.8,35.2,-76.5,2.9,-68.4,-21.6C-60.2,-46.1,-30.1,-62.8,-0.7,-62.5C28.8,-62.3,57.6,-45.2,65.2,-21.4Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    
  
    <div class="featureSection">
      <div class="blueBgDiv"></div>
      <div class="pinkBgDiv"></div>
      <div class="yellowBgDiv"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <img src="../../assets/images/cmsFeatureImage.png" />
          </div>
          <div class="col-lg-6">
            <h2>Why Partner with Techware Lab for Content Marketing?</h2>
            <div>
              <h6>Strategic Content Creation</h6>
              <p>
                We blend creativity with strategy to produce content that captivates your audience and aligns perfectly with your marketing objectives.
              </p>
            </div>
            <div>
              <h6>Audience-Centric Approach</h6>
              <p>
                Understanding your audience is at the heart of what we do. We craft content that speaks directly to the needs, challenges, and aspirations of your target demographic.
              </p>
            </div>
            <div>
              <h6>SEO-Driven Content</h6>
              <p>
                Visibility is critical. Our content not only tells your story but is also optimized for search engines, ensuring your message is found by those who seek it.
              </p>
            </div>
            <div>
              <h6>Multichannel Proficiency</h6>
              <p>
                Whether it’s blogs, white papers, infographics, or social media posts, we tailor your content to perform across all platforms and mediums.
              </p>
            </div>
            <div>
              <h6>Performance Tracking</h6>
              <p>
                With our comprehensive analytics, you’ll gain insights into your content’s performance, allowing data-driven decisions to fuel further success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="serviceSection">
      <div class="container">
        <h2>Our Content Marketing Services</h2>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../assets/images/cmsContentImage.png" />
            <h3>Content Strategy Development</h3>
            <p>to ensure a unified and effective approach to your brand’s messaging across all channels.</p>
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/cmsProductionImage.png" />
            <h3>Content Production</h3>
            <p>
              that encompasses a range of formats, from written articles to videos, infographics, and more.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/cmsOptimization.png" />
            <h3>Content Optimization</h3>
            <p>for enhancing visibility and engagement, using the latest SEO and content performance techniques.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../assets/images/cmsDistributionImage.png" />
            <h3>Content Distribution</h3>
            <p>across the right channels to reach your audience where they are most active.</p>
          </div>
          <div class="col-lg-4">
            <img src="../../assets/images/cmsAnalysis.png" />
            <h3>Content Performance Analysis</h3>
            <p>to continuously refine and optimize the content strategy based on real-world data and feedback.</p>
          </div>
        </div>
      </div>
    </div>
  
    <div class="quoteSection">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <img src="../../assets/images/cmsQuoteImage.png" />
          </div>
          <div class="col-md-6">
            <div class="quoteContent">
              <div>
                <p>Your Brand, Amplified</p>
              </div>
              <p>
                Ready to wield the power of words and visuals in a way that transforms your online presence? Reach out to Techware Lab, where your brand’s message becomes an unforgettable story told across the digital universe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="ctaWrapper">
      <div class="container">
        <h1>
          Content that Captivates, Converts

        </h1>
        <p>Success in every sentence! We craft content that tells your brand story. Ready to narrate success?
        </p>
  
        <button>Book A Free Consultation
        </button>
      </div>
    </div>
  </section>
  