<section class="products-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>Empowering businesses <br> with custom software solutions
                </h1>
                <h2 class="cmn-sub-heading">At Techware Lab, we design, develop, and scale a diverse range of digital products across all industries. Take a<br> look at our feature-rich, white label applications below.</h2>
            </div>
        </div>
    </div>
</section>

<section class="products-list-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Products </h1>
                <h2 class="cmn-sub-heading">We offer a diverse range of solutions all designed to find a new competitive edge for<br> your business. </h2>
            </div>
        </div>
    </div>
    <div class="product-left mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-md-4" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/product-1.webp" class="img-fluid" alt="Taxi app development company">
                </div>
                <div class="col-md-8">
                    <div class="product-content">
                        <img src="/assets/images/t-cab.png" class="img-fluid" alt="Product Logo">
                        <h3>Build your taxi business online with this advanced cab booking software</h3>
                        <p>Build your taxi business online with this advanced cab booking software. Our intuitive mobile tools let you integrate an easy-to-use app that streamlines running a taxi business, simplifies the booking process, and more.</p>
                        <button routerLink="/taxi-management-system">View More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="product-right mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-md-4 order-md-1 order-0" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/product-2.png" class="img-fluid" alt="Doctor appointment app">
                </div>
                <div class="col-md-8">
                    <div class="product-content order-md-0 order-1">
                        <img src="/assets/images/t-doc.png" class="img-fluid" alt="Product Logo">
                        <h3>Build strong physician-patient relationships with simplified app for doctors</h3>
                        <p>Build strong physician-patient relationships with an online app that focuses on creating positive patient experiences. TDoc improves the booking process by making it easy for patients to find you, book an appointment, make
                            changes, and receive updates about their appointments.</p>
                        <button routerLink="/doctor-appointment-app">View More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="product-left random-circles-effect-right mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-md-4" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/product-3.png" class="img-fluid" alt="Online appointment booking for lawyers">
                </div>
                <div class="col-md-8">
                    <div class="product-content">
                        <img src="/assets/images/t-court.png" class="img-fluid" alt="Product Logo">
                        <h3>Instant online appointment scheduling software for lawyers and law firms</h3>
                        <p>TCourt has everything you need to build a technology-driven law firm. Grow your business with powerful functionality that lets you connect with clients online, filter searches based on specializations, in-app payment integration,
                            and more.</p>
                            <button routerLink="/legal-case-management-software">View More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="product-right random-circles-effect-left mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-md-4 order-md-1 order-0" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/product-4.webp" class="img-fluid" alt="Taxi dispatch software">
                </div>
                <div class="col-md-8">
                    <div class="product-content order-md-0 order-1">
                        <img src="/assets/images/t-taxi.png" class="img-fluid" alt="Product Logo">
                        <h3>Expect a smooth ride with our feature-rich seamless taxi booking software</h3>
                        <p>TTaxi makes taxi transportation easy. Simplify your fleet management and scale your taxi business with a seamless app that directly connects taxi drivers with customers. This solution includes an admin panel, driver app, and a
                            passenger app.</p>
                            <button routerLink="/taxi-dispatch-software">View More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="product-left mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-md-4" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/product-5.webp" class="img-fluid" alt="Grocery app development">
                </div>
                <div class="col-md-8">
                    <div class="product-content">
                        <img src="/assets/images/t-kart.png" class="img-fluid" alt="Product Logo">
                        <h3>Premier solution for modern online grocery shopping that’s fast and easy</h3>
                        <p>Create an online storefront for your grocery store. Improve customer experiences with full visibility into your products, an intuitive ordering experience, and the ability to give your customers flexible, on-demand access to the
                            groceries they need.</p>
                            <button routerLink="/grocery-app-development">View More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="product-right mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-md-4 order-md-1 order-0" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/product-6.webp" class="img-fluid" alt="Food ordering system">
                </div>
                <div class="col-md-8">
                    <div class="product-content order-md-0 order-1">
                        <img src="/assets/images/t-food.png" class="img-fluid" alt="Product Logo">
                        <h3>Start serving delicious food with an online ordering system for your brand</h3>
                        <p>Stay competitive with a professional-looking mobile food ordering app designed just for restaurants and food service businesses. TFood lets your customers browse your menu, order, and pay within the app—protecting your
                            margins in the competitive restaurant industry.</p>
                            <button routerLink="/online-food-ordering-system">View More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="product-left random-circles-effect-right mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-md-4" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/product-7.png" class="img-fluid" alt="Car rental app development">
                </div>
                <div class="col-md-8">
                    <div class="product-content">
                        <img src="/assets/images/t-ride.png" class="img-fluid" alt="Product Logo">
                        <h3>Seamless app that streamlines all aspects of running a car rental business</h3>
                        <p>Experience a seamless app that streamlines all aspects of running a car rental business. Connect with customers, improve the booking process, keep customers notified, and take advantage of a range of customizable features.</p>
                        <button routerLink="/car-rental-app-development">View More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="product-right random-circles-effect-left mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-md-4 order-md-1 order-0" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/product-8.png" class="img-fluid" alt="Beauty salon management software">
                </div>
                <div class="col-md-8">
                    <div class="product-content order-md-0 order-1">
                        <img src="/assets/images/t-salon.png" class="img-fluid" alt="Product Logo">
                        <h3>Take full control of the booking process and receive appointment updates</h3>
                        <p>Take full control of the booking process with TSalon. This user-friendly app features an intuitive UI and simplifies all aspects of managing a beauty salon. Clients can quickly book, modify, and receive appointment updates
                            through the app
                        </p>
                        <button routerLink="/beauty-salon-software">View More</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="product-left mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-md-4" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/product-9.png" class="img-fluid" alt="Online movie ticket booking system">
                </div>
                <div class="col-md-8">
                    <div class="product-content">
                        <img src="/assets/images/t-ticket.png" class="img-fluid" alt="Product Logo">
                        <h3>Take your theatre business to the next level with an online booking system</h3>
                        <p>TTicket makes it easy for your customers to find the best movie tickets online. Customers can search trending movies, view available seats, book tickets, and more. This all-in-one app turns going to the movies into an easy
                            and stress-free experience, so your customers can sit back and enjoy the show.</p>
                        <button routerLink="/movie-ticketing-software">View More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="product-right mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-md-4 order-md-1 order-0" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/product-10.webp" class="img-fluid" alt="Automotive Appointment & Scheduling Software">
                </div>
                <div class="col-md-8">
                    <div class="product-content order-md-0 order-1">
                        <img src="/assets/images/t-mechanic.png" class="img-fluid" alt="Product Logo">
                        <h3>Ideal online booking solution for auto mechanics looking to stay organized</h3>
                        <p>TMechanic helps mechanics and auto shops offer a seamless customer experience. Save time and money with a centralized app for booking, storing appointment details, and powerful functionality that lets you scale and grow your
                            car repair and service business.</p>
                        <button routerLink="/automotive-appointment-software">View More</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="product-left mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-md-4" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/product-11.png" class="img-fluid" alt="Dating app development company">
                </div>
                <div class="col-md-8">
                    <div class="product-content">
                        <img src="/assets/images/t-soulmate.png" class="img-fluid" alt="Product Logo">
                        <h3>Dating app for your matrimony agency with powerful search functionality</h3>
                        <p>Make it easy for your users to find their perfect match with our powerful search functionality, enhanced profiles, and modern user experience.  TSoulmate combines brilliant technological solutions and real-world match matching strategies to bring people together on one easy-to-use dating app system. </p>
                        <button routerLink="/matrimony-app-development">View More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="product-right mt-5">
        <div class="container">
            <div class="row pt-5">
                <div class="col-md-4 order-md-1 order-0" data-aos="fade-up" data-aos-duration="3000">
                    <img src="/assets/images/product-12.png" class="img-fluid" alt="Employee payroll management solution">
                </div>
                <div class="col-md-8">
                    <div class="product-content order-md-0 order-1">
                        <img src="/assets/images/t-office.png" class="img-fluid" alt="Product Logo">
                        <h3>Transform the way you track the attendance and handle payroll processes</h3>
                        <p>Transform the way you track attendance and handle payroll. TOffice enhances your human resources and payroll processes with streamlined functionality for user and role management, attendance, employee profiles, vacation time, project management, and customized reports.
                        </p>
                        <button routerLink="/payroll-management-system">View More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Ready to gain a competitive edge?
                </h1>
                <h2 class="cmn-sub-heading">Join our network of 2,400 clients across 25 countries. Stay ahead of digital-born competitors by partnering with Techware Lab. </h2>
                <button routerLink="/contact"> Work with us</button>
            </div>
        </div>
    </div>
</section>