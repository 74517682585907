import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { Meta, Title } from "@angular/platform-browser";;
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-digital-marketing',
  templateUrl: './digital-marketing.component.html',
  styleUrls: ['./digital-marketing.component.scss']
})
export class DigitalMarketingComponent implements OnInit {

  customOptionsOne: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  constructor(private model: MatDialog, private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Digital Marketing Solutions & Online Marketing Services Company| TechwareLab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Attract customers with our compelling digital marketing strategy designed by a professional team of digital marketing specialists. We offer Digital Marketing Services across US,UAE & India.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Attract customers with our compelling digital marketing strategy designed by a professional team of digital marketing specialists. We offer Digital Marketing Services across US,UAE & India.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/ser-3.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Digital Marketing Solutions & Online Marketing Services Company| TechwareLab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Attract customers with our compelling digital marketing strategy designed by a professional team of digital marketing specialists. We offer Digital Marketing Services across US,UAE & India.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/digital-marketing",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/ser-3.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Digital Marketing Solutions & Online Marketing Services Company| TechwareLab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Attract customers with our compelling digital marketing strategy designed by a professional team of digital marketing specialists. We offer Digital Marketing Services across US,UAE & India.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Digital Marketing Solutions & Online Marketing Services Company| TechwareLab",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

}
