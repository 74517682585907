import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-legal-case-management-software',
  templateUrl: './legal-case-management-software.component.html',
  styleUrls: ['./legal-case-management-software.component.scss']
})
export class LegalCaseManagementSoftwareComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  constructor(private title: Title, private meta: Meta, private seoService: SeoService,private model: MatDialog, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {

    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What kind of software do most law firms use?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Investing in an appointment booking system allows your customers to easily book appointments with your law firm, making all of your upcoming appointments visible to you and your teams--in one centralized location. TCourt removes the manual processes associated with matching staff schedules with the availability of your customers, letting you focus on running an efficient and profitable law firm."
        }
      },{
        "@type": "Question",
        "name": "What does legal appointment booking software do?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "TCourt is a lawyer and attorney scheduling software that offers user-friendly and intuitive features to help you manage, grow, and streamline operations through scalable and customizable tools."
        }
      },{
        "@type": "Question",
        "name": "How can I make an appointment with a lawyer on TCourt?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Our lawyer scheduling system can be used for booking appointments with attorneys, lawyers, tax consultants, dispute resolution professionals, and more. It allows you to plan reservations efficiently and better manage your client's time. TCourt is a leading solution designed to maximize productivity and profitability at your law firm."
        }
      },{
        "@type": "Question",
        "name": "Is TCourt good for law firms?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes. The legal case booking software acts as your firm's first touch-point with clients. It allows prospective clients to view available time slots to meet with their chosen legal service provider, book appointments, and more."
        }
      }]
    }
`;
 
  this._renderer2.appendChild(this._document.body, script);

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Legal Case Booking  & Appointment Management Software"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Connect clients with law firms, lawyers or attorneys using an online legal case appointment booking app and case management software. With our appointment scheduling software for law firms, you will never forget a client appointment again.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Connect clients with law firms, lawyers or attorneys using an online legal case appointment booking app and case management software. With our appointment scheduling software for law firms, you will never forget a client appointment again.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Legal Case Booking  & Appointment Management Software",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Connect clients with law firms, lawyers or attorneys using an online legal case appointment booking app and case management software. With our appointment scheduling software for law firms, you will never forget a client appointment again.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/legal-case-management-software",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Legal Case Booking  & Appointment Management Software",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Connect clients with law firms, lawyers or attorneys using an online legal case appointment booking app and case management software. With our appointment scheduling software for law firms, you will never forget a client appointment again.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Legal Case Booking  & Appointment Management Software",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }

}
