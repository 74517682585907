import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { MessageSuccessComponent } from '../message-success/message-success.component';
import { Router } from '@angular/router';
import {HelperService} from '../../providers/helper.service'
declare var $: any;
declare const window: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    classApplied = false;

  constructor(private helperservice:HelperService,private model: MatDialog, public router: Router) { }

  ngOnInit(): void {
  }


  sNavHide(){
      $('.open-services:hover .megamenu-services-wrapper').attr("style" , "display:none !important")
      setTimeout(()=>{
      $('.open-services .megamenu-services-wrapper').removeAttr("style")
      },1000)
  }
  pNavHide(){
    $('.open-products:hover .megamenu-products-wrapper').attr("style" , "display:none !important")
    setTimeout(()=>{
    $('.open-products .megamenu-products-wrapper').removeAttr("style")
    },1000)
  }

  toggleClass() {
    this.classApplied = !this.classApplied;
  }

    @HostListener('window:scroll', [])
   onWindowScroll() {
    $(window).scroll(function () {
      const sticky = $('.techware-navbar'),
        scroll = $(window).scrollTop();

      if (scroll > 10) sticky.addClass('scrolled_navbar');
      else sticky.removeClass('scrolled_navbar');
    });

    window.addEventListener('load', function () {
      const sticky = $('.techware-navbar'),
        scroll = $(window).scrollTop();

      if (scroll > 10) sticky.addClass('scrolled_navbar');
      else sticky.removeClass('scrolled_navbar');
    });
   } 

  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }

   msgSuccess() {

    this.model.open(MessageSuccessComponent);

  }
  
  shouldDisplayNavbar(): any{

    const url = this.router.url;
    return (
      !url.includes('/software-development') &&
      !url.includes('/education-software-development') &&
      !url.includes('/education-software-development-one') &&
      !(url === '/custom-app-development' || url.includes('/custom-app-development?')))

  }


}
