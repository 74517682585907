<section class="uiuxSectionWrapper">


    <div class="heroSection">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="heroContent">
              <div>
                <h1>Dedicated Development Teams</h1>
                <h5>Crafting Excellence, One Team at a Time</h5>
                <p>
                    At Techware Lab, we believe in creating synergistic relationships between your vision and our technical expertise. Our dedicated development teams are tailored to fit the unique needs of each project, ensuring that your goals are met with precision, quality, and efficiency.
                </p>
                <button (click)="requestQuote()">Get Started</button>
              </div>
  
              <div class="pinkBgDiv"></div>
              <div class="yellowBgDiv"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="heroImgWrapper">
              <img src="../../assets/images/ddtHeroImage.png" />
            </div>
            <div class="heroImageBgWrapper">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M48.4,-55.6C62.1,-46.3,71.9,-30.4,74.1,-13.7C76.3,3,70.9,20.4,62.6,37.4C54.3,54.3,43,70.8,28,75.8C13,80.8,-5.8,74.2,-21.1,65.4C-36.4,56.6,-48.1,45.6,-58.3,31.8C-68.4,18,-77.1,1.4,-74.7,-13.4C-72.3,-28.2,-59,-41.3,-44.7,-50.5C-30.5,-59.7,-15.2,-64.9,1.1,-66.2C17.4,-67.5,34.8,-64.9,48.4,-55.6Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
            <div class="heroImageWrapper2">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M65.2,-21.4C72.9,2.3,59.4,32.7,36.8,48.7C14.2,64.6,-17.4,66,-40.1,50.6C-62.8,35.2,-76.5,2.9,-68.4,-21.6C-60.2,-46.1,-30.1,-62.8,-0.7,-62.5C28.8,-62.3,57.6,-45.2,65.2,-21.4Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="featureSection">
        <div class="blueBgDiv"></div>
        <div class="pinkBgDiv"></div>
        <div class="yellowBgDiv"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <img src="../../assets/images/ddtFeatureImage.png" />
            </div>
            <div class="col-lg-6">
              <h2>Why Choose Techware Lab’s Dedicated Development Teams?</h2>
              <div>
                <h6>Expertise Across Domains</h6>
                <p>
                    Our developers come with a deep understanding of various industries and niches, ensuring that we align with your business objectives seamlessly.
                </p>
              </div>
              <div>
                <h6>Scalable Solutions</h6>
                <p>
                    Whether you’re a startup looking for your first development team or an enterprise seeking to augment your existing capabilities, our teams are designed to scale as per your needs.
                </p>
              </div>
              <div>
                <h6>Transparent Communication</h6>
                <p>
                    We believe in a refined work approach. Regular updates, milestone tracking, and open communication channels keep you in the loop.
                </p>
              </div>
              <div>
                <h6>Cost-Effective</h6>
                <p>
                    With Techware Lab, you get high-quality development solutions without the overhead costs. Only pay for the expertise you need.
                </p>
              </div>
              <div>
                <h6>Integrated Approach</h6>
                <p>
                    Our teams are not just about coding. We integrate market research, user experience, and quality assurance to ensure a holistic approach to your project.
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </div>


   
    
    <div class="quoteSection">
      <div class="container">
        <div class="row">
            
            <div class="col-md-6">
              <div class="quoteContent">
               <div>
                  <p>Customized Solutions for Every Need
                  </p>
                 
               </div>
               <p>Whether you're looking to build a new mobile application, design an intuitive web platform, or even create a robust backend system, our dedicated teams can adapt and cater to your precise requirements.
              </p>
             
              </div>
            </div>
            <div class="col-md-6">
              <img src="../../assets/images/ddtQuoteImage.png" />
            </div>
        </div>
      </div>
    </div>
  
    <div class="ctaWrapper">
      <div class="container">
        <h1>Your Vision, Our Dedicated Expertise
        </h1>
        <p>
          Entrust your project to the hands of experts. With Techware Lab's dedicated development teams, you're not just hiring developers; you're partnering with a team committed to bringing your vision to life.
        </p>
  
        <button routerLink="/contact">get started</button>
      </div>
    </div>
  </section>
  