<section class="heading-section">
    <div class="container">
      <div class="row">
        <div class="col-md-6 alibaba-title">
          <h1>{{ title }}</h1>
          <p>{{ description }}</p>
          <button (click)="requestQuote()">{{ buttonText }}</button>
        </div>
        <div class="col-md-6 heading-img">
            <img [src]="imageUrl" alt="heading Image" class="img-fluid">
        </div>
      </div>
    </div>
  </section>
  