<section class="about-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>Thank you for contact us.<br>
                    We will get back to you shortly.
                </h1>
            </div>
        </div>
    </div>
</section>

<section class="next-main-wrapper">
    <div class="container">
        <h1 class="cmn-heading">What’s Next</h1>
        <div class="row">
            <div class="col-md-4">
                <div class="next-box">
                    <h2 class="red">1</h2>
                    <h3>We will get in touch</h3>
                    <!-- <h4>within a business day</h4>
                    <p>An engagement manager at Peerbits will get back to you. Should you have any further information to share, you can share it with the manager.</p> -->
                </div>
            </div>
            <div class="col-md-4">
                <div class="next-box">
                    <h2 class="yellow">2</h2>
                    <h3>We will work together<br> to create a proposal</h3>
                    <!-- <h4>Expect it 2-3 days</h4>
                    <p>An engagement manager at Peerbits will get back to you. Should you have any further information to share, you can share it with the manager.</p> -->
                </div>
            </div>
            <div class="col-md-4">
                <div class="next-box">
                    <h2 class="blue">3</h2>
                    <h3>We will deliver a top solution<br> for your business needs</h3>
                    <!-- <h4>You are in safe hands</h4>
                    <p>An engagement manager at Peerbits will get back to you. Should you have any further information to share, you can share it with the manager.</p> -->
                </div>
            </div>
        </div>
    </div>
</section>