<section class="header-banner">
  <div class="container">
    <div class="text-content">
      <h1>Android App Development</h1>
      <h5>Agile Android App Development for the Modern Business</h5>
      <h6>
        Work with an Android development company that can transform your app<br>
        from concept to launch with a stand-out user experience, full-stack<br>
        support, and accessible features across all Android devices.
      </h6>
      <button (click)="requestQuote()" target="_blank">Get estimation for your project</button>
    </div>
  </div>
</section>

<section class="techware-wrapper">
  <div class="container">
    <div class="row mb-md-5">
      <div
        class="col-md-5 text-right"
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        <img
          src="/assets/images/design-mon.webp"
          class="img-fluid"
          alt="Cutting edge technologies"
        />
      </div>
      <div class="col-md-7">
        <div class="help-box mt-4 mt-md-0">
          <h1 class="cmn-heading">
            We’re Techware Lab — An <br />
            Efficient, Fast, and Affordable <br />
            Way to ManageYour Android <br />
            App Development Needs
          </h1>
          <p>
            Our team of front-end, back-end, and full-stack developers work
            diligently to champion the next generation of high-performing
            Android application development services.
          </p>
          <p>
            We support businesses with ongoing app development support at each
            stage.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="brands-wrapper">
  <div class="container">
    <div class="row mt-5 mb-4 mt-md-0">
      <div class="col-md-12 text-center">
        <h1 class="cmn-heading">Who We Work With</h1>
        <h2 class="cmn-sub-heading">
          See other companies who have partnered with Techware Lab for website
          design<br />
          and development services.
        </h2>
      </div>
    </div>

    <owl-carousel-o [options]="customOptionsOne">
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-one.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-two.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-three.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-four.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/logo-eigt.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/logo-nine.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-logo-five.png"
            class="img-fluid brand-img-one"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-logo-four.png"
            class="img-fluid brand-img-two"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/logo-three.png"
            class="img-fluid brand-img-three"
            alt="Images"
          />
        </div>
      </ng-template>

      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/logo-five.png"
            class="img-fluid brand-img-four"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/logo-six.png"
            class="img-fluid brand-img-five"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/logo-sev.png"
            class="img-fluid brand-img-six"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-ten.png"
            class="img-fluid brand-img-seven"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-elv.png"
            class="img-fluid brand-img-eigt"
            alt="Images"
          />
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="brand-box">
          <img
            src="/assets/images/brand-tw.png"
            class="img-fluid"
            alt="Images"
          />
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>

<section class="design-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">Our Android Development Services</h1>
        <h2 class="cmn-sub-heading">
          Find out why other companies love working with Techware Lab when it
          comes to Android app development.
        </h2>

        <div class="row mt-5">
          <div class="col-md-5">
            <img
              src="/assets/images/ios-development-vector.svg"
              class="img-fluid side-img"
              alt="Mobile app development company"
            />
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-6 mt-5 mb-3">
            <div class="accordion" id="accordionExample">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Experience Seamless Android App Development
                    </button>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <p>
                      We help you incorporate a tailored mobile user interface
                      and user experience design for the typical Android user.
                      Choose the best-fitting Android web and mobile solutions
                      that meet the needs of your business and customers.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Front-end, Back-end, and Full-stack Support
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <p>Our multidisciplinary and flexible team of front-end, back-end, and full-stack developers provide scalable Android solutions at any and every stage of your mobile app development project.</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Android Software Performance Testing
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  class="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">Techware Lab ensures digital security and top performance within the Android development process. Our AI automated testing process will ensure that your application is glitch and hacker-free.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="approach-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <h1 class="cmn-heading">Our Approach</h1>
        <h2 class="cmn-sub-heading">
          We offer assistance at every stage of your mobile app development to
          ensure <br />you have support from start to finish.
        </h2>
      </div>
    </div>
    <div class="row mb-md-5 mt-md-4">
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
        <div class="approach-box blue">
          <img src="/assets/images/ios-approach-1.svg" alt="Service Icon" />
          <h3>Establishing a blueprint</h3>
          <p>
            First, we build various wireframes for your Android app that both
            cater to your user needs and business objectives. With the help of
            clients, mockups are shortlisted and a final prototype is
            determined.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
        <div class="approach-box red">
          <img src="/assets/images/ios-approach-2.svg" alt="Service Icon" />
          <h3>App development</h3>
          <p>
            Your Android prototype comes alive here. Our developers spend
            extensive time configuring the code, interface design, and internal
            hardware for the app. Software programming is then debugged several
            times before source code integration.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
        <div class="approach-box yellow">
          <img src="/assets/images/ios-approach-3.svg" alt="Service Icon" />
          <h3>User Testing, Go-to-Market, & Ongoing Support</h3>
          <p>
            Your Android prototype comes alive here. Our developers spend
            extensive time configuring the code, interface design, and internal
            hardware for the app. Software programming is then debugged several
            times before source code integration.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="device-wrapper">
  <div class="container">
    <h1 class="cmn-heading">
      Adaptable Software Development for Android Devices
    </h1>
    <h2 class="cmn-sub-heading">
      We develop a competitive browser-based experience compatible for all
      Android devices including tablets, laptops, Android Wear, Android TV, and
      mobile devices.
    </h2>
    <div class="row mt-5">
      <div class="col-md-3 text-center mb-5">
        <img
          src="/assets/images/android-device-1.png"
          class="img-fluid"
          alt="Device"
        />
        <h3>Tablets</h3>
      </div>
      <div class="col-md-3 text-center mb-5">
        <img
          src="/assets/images/android-device-2.png"
          class="img-fluid"
          alt="Device"
        />
        <h3>Laptops</h3>
      </div>
      <div class="col-md-3 text-center mb-5">
        <img
          src="/assets/images/android-device-3.png"
          class="img-fluid"
          alt="Device"
        />
        <h3>Android TV</h3>
      </div>
      <div class="col-md-3 text-center mb-5">
        <img
          src="/assets/images/android-device-4.png"
          class="img-fluid"
          alt="Device"
        />
        <h3>Android Wear</h3>
      </div>
    </div>
  </div>
</section>

<section class="cases-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Android App Development Use Cases</h1>
    <h2 class="cmn-sub-heading">Using our custom Android app development services provides more opportunities for your business to host a library of Android’s unique capabilities.</h2>
    <div class="row mt-5">
      <div class="col-md-4 text-center mb-5">
        <img src="/assets/images/cases-vector-1.svg" class="img-fluid" alt="Icon">
        <h3>Streamline your <br>
          software integrations</h3>
      </div>
      <div class="col-md-4 text-center mb-5">
        <img src="/assets/images/cases-vector-2.svg" class="img-fluid" alt="Icon">
        <h3>Dominate the market <br>
          with unlimited innovation</h3>
      </div>
      <div class="col-md-4 text-center mb-5">
        <img src="/assets/images/cases-vector-3.svg" class="img-fluid" alt="Icon">
        <h3>Maximize revenue <br>
          generation</h3>
      </div>
    </div>
  </div>
</section>

<section class="mobile-app-wrapper">
  <div class="container">
    <div class="row mt-5 mb-4 mt-md-0">
      <div class="col-md-12 text-left">
        <h1 class="cmn-heading">
          Discover iOS Apps that Host Top Industry Verticals
        </h1>
        <h2 class="cmn-sub-heading">
          Our iOS app library is equipped with the most popular App Store
          features.
        </h2>
      </div>
    </div>
    <div class="row side">
      <div class="col-md-2">
        <div class="team-box text-center">
          <img
            src="/assets/images/ind-one.png"
            class="img-fluid img-ind-one"
            alt="Images"
          />
          <p>Public Sector</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="team-box text-center">
          <img
            src="/assets/images/ind-two.png"
            class="img-fluid img-ind-two"
            alt="Images"
          />
          <p>Healthcare</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="team-box text-center">
          <img
            src="/assets/images/ind-three.png"
            class="img-fluid img-ind-three"
            alt="Images"
          />
          <p>Enterprise</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="team-box text-center">
          <img
            src="/assets/images/ind-four.png"
            class="img-fluid img-ind-four"
            alt="Images"
          />
          <p>Banking & Finance</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="team-box text-center">
          <img
            src="/assets/images/ind-five.png"
            class="img-fluid img-ind-five"
            alt="Images"
          />
          <p>Hospitality</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="team-box text-center">
          <img
            src="/assets/images/ind-six.png"
            class="img-fluid img-ind-six"
            alt="Images"
          />
          <p>Retail & Ecommerce</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="team-box text-center">
          <img
            src="/assets/images/ind-sev.png"
            class="img-fluid img-ind-sev"
            alt="Images"
          />
          <p>Travel & Tourism</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="team-box text-center">
          <img
            src="/assets/images/ind-eigt.png"
            class="img-fluid img-ind-eigt"
            alt="Images"
          />
          <p>Food & Beverage</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="team-box text-center">
          <img
            src="/assets/images/ind-nine.png"
            class="img-fluid img-ind-nine"
            alt="Images"
          />
          <p>Transport & Logistic</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="team-box text-center">
          <img
            src="/assets/images/ind-ten.png"
            class="img-fluid img-ind-ten"
            alt="Images"
          />
          <p>On-Demand Delivery</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="team-box text-center">
          <img
            src="/assets/images/ind-elv.png"
            class="img-fluid img-ind-elv"
            alt="Images"
          />
          <p>Social & Communication</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="team-box text-center">
          <img
            src="/assets/images/ind-tw.png"
            class="img-fluid img-ind-tw"
            alt="Images"
          />
          <p>Education & Elearning</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="technologies-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">Technology Stack</h1>
        <h2 class="cmn-sub-heading">
          Our team of diverse stakeholders are skilled to manage the following
          technologies that support iOS software.
        </h2>
        <div class="row mt-5">
          <div class="col-md-5">
            <img
              src="/assets/images/ios-technology.png"
              class="img-fluid"
              alt="Mobile app development company"
            />
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-6 mt-5 mb-3">
            <div class="accordion" id="accordionExample">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Languages
                    </button>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <ul>
                      <li>Java C</li>
                      <li>Kotlin</li>
                      <li>C++</li>
                      <li>C#</li>
                      <li>Python</li>
                      <li>Dart</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Operating System
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <ul>
                      <li>Android</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Tools
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  class="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <ul>
                      <li>Android Studio</li>
                      <li>Android Virtual Device (AVD) Manager</li>
                      <li>Eclipse</li>
                      <li>Fabric</li>
                      <li>FlowUp</li>
                      <li>Genymotion</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="footer-banner-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">
          Build Your Next Android App With Our Proven Team
        </h1>
        <h2 class="cmn-sub-heading">
          Diversify your company’s business opportunities in the digital world
          with an Android app—it’s familiar, intuitive, and offers a simple
          integration process. We’re equipped with a three-step ship-to-market
          approach, propelling your enterprise’s overall success.
        </h2>
        <button routerLink="/contact">Let's get started</button>
      </div>
    </div>
  </div>
</section>
