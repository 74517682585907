import { Component, OnInit, ViewChild, ChangeDetectorRef, Renderer2, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { WebService } from "../../providers/web.service";
import { HelperService } from "../../providers/helper.service";
import * as countries from "./countries.json";
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';
import { MessageSuccessComponent } from '../message-success/message-success.component';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';




declare var $: any;
//declare const window: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('zohoFormContainer', { static: true }) zohoFormContainer!: ElementRef;
  contactForm;
  countryList: any = new Array();
  contactSubmit: boolean;
  loader: boolean;

  submitedForm:boolean;

  /* @ViewChild('captchaElem') captchaElem;*/
  constructor(private title: Title, private meta: Meta, public service: WebService, private router: Router, public helper: HelperService, private cdr: ChangeDetectorRef, private seoService: SeoService, private model: MatDialog, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: Object) {
    this.contactSubmit = false;
    this.countryList = countries;
    this.loader = false;
  }



  ngOnInit() {
    this.submitedForm = true;
    this.createLinkForCanonicalURL();
    this.loadZohoScript();

    this.contactForm = new FormGroup({
      name: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z ]{2,30}$"),
      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
      ]),
      country: new FormControl("", [Validators.required]),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"),
      ]),
      reason: new FormControl("", [Validators.required]),
      // budget: new FormControl("", [Validators.required]),
      requirement: new FormControl("", [Validators.required]),
      // recaptcha: new FormControl("", [Validators.required]),
    });

    this.title.setTitle(
      "Contact our Sales Team - Techware Lab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "We’re all about starting small, scaling fast, and achieving more. We’d love to hear your ideas. Fill out the form below, we will get in touch with you ",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "We’re all about starting small, scaling fast, and achieving more. We’d love to hear your ideas. Fill out the form below, we will get in touch with you",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Contact our Sales Team - Techware Lab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "We’re all about starting small, scaling fast, and achieving more. We’d love to hear your ideas. Fill out the form below, we will get in touch with you",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/contact",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Contact our Sales Team - Techware Lab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "We’re all about starting small, scaling fast, and achieving more. We’d love to hear your ideas. Fill out the form below, we will get in touch with you ",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Contact our Sales Team - Techware Lab",
    });
  }

  loadZohoScript(): void {


    setTimeout(() => {

      const iframe = document.getElementById('zohoIframe') as HTMLIFrameElement;

      const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
  
  
      const script = iframeDoc.createElement('script');
      // const script = this.renderer.createElement('script');
      script.src = 'https://crm.zoho.com/crm/WebFormServeServlet?rid=dd8e9bd6a58ef545664ba3b6af0e88fabc47f674ef55409c3c0e436d4206125aaa0c212ede1b24ea0a4d1116c0ea164bgid5d2cd02a92e38954fda2f318dd487414991c498255342bd8c1859d1f24fa759e&script=$sYG';
      script.id = 'formScript6239895000001543022';
      script.type = 'text/javascript';
      script.async = true;
  
  
      const style = iframeDoc.createElement('style');
  
      style.innerHTML = `
      
      #crmWebToEntityForm {
        
        background-color: var(--bgcolor-secondary);
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 12px;
        padding: 0px !important;
        position: absolute;
        background-color: transparent !important;
        
        
        #webform6239895000001543022 {
          #formsubmit {
            max-width: 300px;
            background-color: #ffffff;
            border: 1px solid #da2a80 !important;
            color: #da2a80 !important;
            padding: 8px 35px;
            border: 2px solid black;
            border-radius: 30px;
            font-weight: 700;
          }
      
          input {
            width: 100%;
            max-width: 750px;
            padding: 10px;
            border: 2px solid #d8d8d8;
            border-radius: 30px;
            @media screen and (max-width: 1200px) {
              padding: 20px;
            }
            @media screen and (max-width: 992px) {
              padding: 16px;
            }
            @media screen and (max-width: 768px) {
              padding: 12px;
            }
          }
          .zcwf_title {
            display: none;
          }
          .zcwf_row {
            width: 100%;
            align-items: center;
            display: flex;
            @media (max-width: 1200px) {
              flex-direction: column;
              align-items: flex-start;
            }
            // @media (max-width: 600px) {
            //   justify-content: space-between;
            // }
          }
          .zcwf_col_lab {
            @media (max-width: 768px) {
              width: 100% !important;
            }
            label {
              font-weight: 800;
              width: 100%;
              // @media screen and (max-width: 576px) {
              //   max-width: 140px;
              //   font-size: 12px;
              // }
            }
          }
          #reCaptchaField {
            font-weight: 800;
            @media (max-width: 600px) {
              font-size: 12px !important;
            }
          }
          .zcwf_col_fld {
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
            }
            #imgid6239895000000955096 {
              width: 35%;
              @media (max-width: 576px) {
                width: 80%;
              }
            }
            a {
              color: black;
            }
            select,
            textarea {
              width: 100%;
              padding: 10px;
              border-radius: 30px;
              @media (max-width: 600px) {
                padding: 12px;
              }
            }
            input[type="reset"] {
              display: none;
            }
          }
        }
      }
      
      `
  
      script.onload = () => {
        this.measureFormHeight();
        // const leadSourceField = document.querySelector('#Lead_Source') as HTMLSelectElement;
        // if (leadSourceField) {
        //   leadSourceField.value = 'techware';
        // }
      };
  
      iframeDoc.body.appendChild(script);
      iframeDoc.head.appendChild(style);
      // Append the script to the zohoFormContainer
      // this.renderer.appendChild(this.zohoFormContainer.nativeElement, script);
  
      

      setTimeout(() => {

        const form  = iframeDoc.querySelector('form')
        console.log(form,'Form first');

        if(form){
          form.addEventListener('submit', (event)=>{
            event.preventDefault();
            console.log('Form submitted successfully');
    
            this.submitedForm = false
          })
        }
      }, 1000);
      
    
      
    }, 500);

   
    
  }

  measureFormHeight(): void {
    setTimeout(() => {
      const formElement = document.getElementById('webform6239895000000955096');
      if (formElement) {
        if (isPlatformBrowser(this.platformId)) {
          this.measureFormHeight();
          window.addEventListener('resize', this.measureFormHeight.bind(this));
        }
      } else {
        console.log('Form element not found');
      }
    }, 10);
  }

  // setupResizeListener(): void {
  //   const zohoForm = document.getElementById('zoho-form');
  //   const crmWebToEntityForm = document.getElementById('crmWebToEntityForm');

  //   if (zohoForm && crmWebToEntityForm) {
  //     // Function to handle resize events
  //     const adjustDimensions = () => {
  //       const zohoFormRect = zohoForm.getBoundingClientRect();

  //       // Adjust `crmWebToEntityForm` to match `zoho-form` dimensions and position
  //       this.renderer.setStyle(crmWebToEntityForm, 'top', `${zohoFormRect.top}px`);
  //       this.renderer.setStyle(crmWebToEntityForm, 'left', `${zohoFormRect.left}px`);
  //       this.renderer.setStyle(crmWebToEntityForm, 'width', `${zohoFormRect.width}px`);
  //       this.renderer.setStyle(crmWebToEntityForm, 'height', `${zohoFormRect.height}px`);

  //       // Set `bottom` and `right` properties
  //       const bottom = window.innerHeight - zohoFormRect.bottom;
  //       const right = window.innerWidth - zohoFormRect.right;
  //       this.renderer.setStyle(crmWebToEntityForm, 'bottom', `${bottom}px`);
  //       this.renderer.setStyle(crmWebToEntityForm, 'right', `${right}px`);

  //     };

  //     // Call the function initially
  //     adjustDimensions();

  //     // Listen for window resize events
  //     window.addEventListener('resize', () => {
  //       // Use requestAnimationFrame for smoother updates
  //       requestAnimationFrame(adjustDimensions);
  //     });
  //   }
  // }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      // window.removeEventListener('resize', this.measureFormHeight.bind(this));
    }
  }

  contactFormSubmit() {
    this.contactSubmit = true;
    if (this.contactForm.valid) {
      this.loader = true;
      let userObj = this.contactForm.value;
      // this.captchaElem.resetCaptcha();
      this.service.post_data("contactFormTechlab", "", userObj).subscribe(
        (response) => {
          if (response && response.status && response.status == 200) {
            this.model.open(MessageSuccessComponent);
          } else {
            this.helper.showAlert(4, "Something went wrong");
          }
          this.contactForm.reset({
            name: "",
            email: "",
            country: "0",
            phone: "",
            reason: "0",
            // budget: "0",
            requirement: ""
          });
          this.contactSubmit = false;
          this.loader = false;
        },
        (error) => {
          this.helper.showAlert(4, "Something went wrong");
          this.contactSubmit = false;
          this.loader = false;
        }
      );
    }
  }

  navLink(path: any, data = null) {
    this.router.navigateByUrl(path, /* Removed unsupported properties by Angular migration: queryParams. */ {});
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  msgSuccess() {

    this.model.open(MessageSuccessComponent);

  }

}
