import { Component, OnInit } from '@angular/core';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-software-development-service',
  templateUrl: './custom-software-development-service.component.html',
  styleUrls: ['./custom-software-development-service.component.scss']
})
export class CustomSoftwareDevelopmentServiceComponent implements OnInit {


  constructor(private model: MatDialog) { }

  ngOnInit(): void {
  }
  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }

}
