<app-heading-section 
   [title]="xlriData.heading.title"
   [description]="xlriData.heading.description"
   [buttonText]="xlriData.heading.buttonText"
   [imageUrl]="xlriData.heading.imageUrl">
</app-heading-section>

<app-about-section 
   [title]="xlriData.about.title"
   [description]="xlriData.about.description"
   [imageUrl]="xlriData.about.imageUrl">
</app-about-section>

<app-story-section
   [title]="xlriData.story.title"
   [description]="xlriData.story.description"
   
   [imageUrl]="xlriData.story.imageUrl"
   [buttonText]="xlriData.story.buttonText"
   [viewMoreContent]="xlriData.story.viewMoreContent"
   >
   
</app-story-section>

<app-plain-banner 
   [title]="xlriData.plainBanner.title"
   [buttonText]="xlriData.plainBanner.buttonText">
</app-plain-banner>

<app-results-section 
   [title]="xlriData.results.title"
   [description]="xlriData.results.description"
   [imageUrl]="xlriData.results.imageUrl">
</app-results-section>

<app-form-section></app-form-section>


