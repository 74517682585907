<section class="career-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="circle-wrapper">
            <h1>
                TSoulmate: Online Dating <br/>
                App Solutions For Your <br/>
                Matchmaking Agency
            </h1>
            <h2 class="cmn-sub-heading">
                The perfect match for your business: find out how TSoulmate empowers your matrimony agency with powerful search functionality, enhanced profiles, and a modern user experience.
            </h2>
            <a (click)="requestQuote()" target="_blank">Request a demo</a>
          </div>
        </div>
        <div class="col-md-5">
          <img
            src="/assets/images/tsoulmate-img-1.png"
            class="img-fluid"
            alt="tsoulmate"
          />
        </div>
      </div>
    </div>
</section>

<section class="whatistaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-5" data-aos="fade-right">
        <img
          src="/assets/images/tsoulmate-img-2.png"
          class="img-fluid"
          alt="tsoulmate"
        />
      </div>
      <div class="col-md-7">
        <div class="content">
          <h1 class="cmn-heading">What Is TSoulmate?</h1>
          <p>
            Are you planning to start a premium matrimony or matchmaking agency, but don’t know how to begin? We at Techware Lab proudly present our new software, TSoulmate, the best solution for running matrimonial services like matrimonial and dating app development. 
          </p>
          <p>
            Tap into growing online dating trends with TSoulmate. TSoulmate makes it easy for your users to find the perfect match. Users can filter results based on specific details like interests, hobbies, religion, and more.
          </p>
          <p>
            TSoulmate is a powerful and user-friendly application built on PHP and the Laravel framework, giving your company a reliable solution that can scale as you grow.
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
        <div class="taxi-box">
          <img
            src="/assets/images/tsoulmate-icon-1.png"
            class="img-fluid"
            alt="tsoulmate"
          />
          <h2>
            A Superior Script for Searching
          </h2>
          <p>
            TSoulmate’s online wedding portal uses a script to help find the perfect match for you. Filter through thousands of candidates quickly and accurately, and let the administrator panel controls make the script unique to each individual.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
        <div class="taxi-box">
          <img
            src="/assets/images/tsoulmate-icon-2.png"
            class="img-fluid"
            alt="tsoulmate"
          />
          <h2>
            Finding the Perfect Match
          </h2>
          <p>
            You can find all types of information about your perfect partner, including even minute details like religion, star signs, moon signs, interests, and hobbies. Everything you’d ever want to know is here.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
        <div class="taxi-box">
          <img
            src="/assets/images/tsoulmate-icon-3.png"
            class="img-fluid"
            alt="tsoulmate"
          />
          <h2>
            A Seamless Experience From App to Match
          </h2>
          <p>
            Thanks to the intuitive UI of TSoulmate, registration is fast, and navigating through the application is easier than ever.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="ttaxi-features-wrapper">
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-5">
        <h1 class="cmn-heading">TSoulmate Features</h1>
        <img
          src="/assets/images/tsoulmate-img-3.png"
          class="img-fluid"
          alt="TSoulmate"
        />
      </div>
      <div class="col-md-6">
        <ul class="list-1">
          <li>
            <span>1</span>
            <h6>Speedy Registration</h6>
          </li>
          <li>
            <span>2</span>
            <h6>Sort Matches by Category and Use Filters</h6>
          </li>
          <li>
            <span>3</span>
            <h6>Search Engine-Friendly URLs</h6>
          </li>
          <li>
            <span>4</span>
            <h6>Manage Payment Options</h6>
          </li>
          <li>
            <span>5</span>
            <h6>Fully Customized App</h6>
          </li>
          <li>
            <span>6</span>
            <h6>Protected Administrator’s Area</h6>
          </li>
        </ul>
        <button routerLink="/contact">Let’s get started</button>
      </div>
    </div>
  </div>
</section>

<section class="abouttaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-7 order-md-0 order-1">
        <div class="content">
          <h1 class="cmn-heading">About Techware Lab</h1>
          <p>
            Techware Lab provides top design capabilities and expert software solutions across an array of industry-specific apps. The company’s dedication to client satisfaction and technology-driven business enhancement gives match makers and relationship specialists the tools they need to run efficient matrimonial services and help match potential soulmates. TSoulmate combines brilliant technological solutions and real world match matching strategies to bring people together on one easy-to-use dating app system.
          </p>
        </div>
      </div>
      <div class="col-md-5 order-md-1 order-0" data-aos="fade-left">
        <img
          src="/assets/images/tsoulmate-img-4.png"
          class="img-fluid"
          alt="tsoulmate"
        />
      </div>
    </div>
  </div>
</section>

<section class="footer-banner-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">
          Find Your Better Half With TSoulmate
        </h1>
        <h2 class="cmn-sub-heading">
          It’s never too late to find yourself a better half. Techware Labs’ new online matrimonial software, TSoulmate, is ready to take on the task. Empower your matrimonial service today.
        </h2>
        <button routerLink="/contact">Let’s get started</button>
      </div>
    </div>
  </div>
</section>


<section class="faq-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Frequently Asked Questions</h1>
    <mat-accordion>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How do I start my own dating app?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Defining and following the targeted audience is an essential stage that significantly influences the success of your dating app. Creating a dating app in a particular niche can be a game-changer for your dating app startup. Targeting a niche audience allows you to focus on your differentiator, so you can build a strategy that engages your audience and builds loyalty. Choosing the right mobile app development company is a key factor in your project’s success. Have questions about our software development services? Contact us by emailing hello@techwarelab.com to learn more
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How much does it cost to build a dating app?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          The cost of building a dating app depends on the feature set, app complexity, development service rates, and whether the app is going to be developed from scratch. Pricing for our Tinder-like app typically starts around $5,000. Our platform is fully customizable, allowing you to add all the unique features you need when creating a custom app for your product.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How do I make my dating app successful?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          A successful dating app is all about building a loyal and engaged community. One of the easiest ways to do this is to offer a user-friendly and intuitive experience that attracts users.

        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How long does it take to build a dating software?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Development times vary depending on a variety of factors. On average, a dating app can take up to 4 months to develop. However, the amount of development time required may be different. For an accurate estimate, please get in touch by emailing hello@techwarelab.com.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>

<section class="relates-solutions-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Related Solutions</h1>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <div class="related-box" routerLink="/legal-case-management-software">
            <img src="/assets/images/new-related-img1.png" class="img-fluid" alt="Images">
        </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/doctor-appointment-app">
              <img src="/assets/images/new-related-img2.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-management-system">
              <img src="/assets/images/new-related-img3.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-dispatch-software">
              <img src="/assets/images/new-related-img4.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/grocery-app-development">
              <img src="/assets/images/new-related-img5.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/online-food-ordering-system">
              <img src="/assets/images/new-related-img6.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/car-rental-app-development">
              <img src="/assets/images/new-related-img7.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/movie-ticketing-software">
              <img src="/assets/images/new-related-img8.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/beauty-salon-software">
              <img src="/assets/images/new-related-img9.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/automotive-appointment-software">
              <img src="/assets/images/new-related-img10.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/payroll-management-system">
              <img src="/assets/images/new-related-img12.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>