import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-top-mobile-app-development-frameworks-app-developers',
  templateUrl: './top-mobile-app-development-frameworks-app-developers.component.html',
  styleUrls: ['./top-mobile-app-development-frameworks-app-developers.component.scss']
})
export class TopMobileAppDevelopmentFrameworksAppDevelopersComponent implements OnInit {

  public fbLink:string=environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;


  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Top 5 Mobile App Development Frameworks for App Developers"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "It is not so easy to choose the most powerful app development framework because all of them are used for different purposes. Read about different mobile app development frameworks for app developers.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "It is not so easy to choose the most powerful app development framework because all of them are used for different purposes. Read about different mobile app development frameworks for app developers.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-13.jpg",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Top 5 Mobile App Development Frameworks for App Developers",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "It is not so easy to choose the most powerful app development framework because all of them are used for different purposes. Read about different mobile app development frameworks for app developers.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/best-mobile-app-development-frameworks",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-13.jpg",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Top 5 Mobile App Development Frameworks for App Developers",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "It is not so easy to choose the most powerful app development framework because all of them are used for different purposes. Read about different mobile app development frameworks for app developers.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Top 5 Mobile App Development Frameworks for App Developers",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `best-mobile-app-development-frameworks`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u',`https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');
  
      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }

}
