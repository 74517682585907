import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';

@Component({
  selector: 'app-user-research-and-discovery',
  templateUrl: './user-research-and-discovery.component.html',
  styleUrls: ['./user-research-and-discovery.component.scss']
})
export class UserResearchAndDiscoveryComponent implements OnInit {

  customOptionsOne: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  constructor(private model: MatDialog) { }

  ngOnInit(): void {
  }

  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

}
