import { Component, OnInit, HostListener } from '@angular/core';
import { WebService } from '../providers/web.service';
import { MatDialog } from '@angular/material/dialog';
import { HelperService } from '../../providers/helper.service';
import { Meta, Title } from '@angular/platform-browser';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as countries from './countries.json';
import { MessageSuccessComponent } from '../message-success/message-success.component';
import { Router } from '@angular/router';

declare var $: any;
declare const window: any;
@Component({
  selector: 'app-custom-app-development',
  templateUrl: './custom-app-development.component.html',
  styleUrls: ['./custom-app-development.component.scss'],
})
export class CustomAppDevelopmentComponent implements OnInit {
  form!: FormGroup;
  submitted = false;
  countryList: any;
  loader = false;

  constructor(
    private title: Title,
    private meta: Meta,
    private fb: FormBuilder,
    public service: WebService,
    private model: MatDialog,
    public helper: HelperService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.countryList = Object.values(JSON.parse(JSON.stringify(countries)));
    this.contactFormInit();

    this.title.setTitle(
      ' Custom Application Development Services | Techware Lab'
    );
    this.meta.updateTag({
      name: 'description',
      content: '',
    });
  }

  contactFormInit() {
    this.form = this.fb.group({
      name: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z ]{2,30}$')],
      ],
      category: ['', [Validators.required]],
      // code: ['+91', [Validators.required]],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'
          ),
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      this.loader = true;
      let userObj = this.form.value;
      this.service.post_data('customAppLandingFormData', '', userObj).subscribe(
        (response) => {
          if (response && response.status && response.status == 200) {
            // this.model.open(MessageSuccessComponent);
            this.router.navigate(['/thank-you']);
          } else if (response && response.status && response.status == 500) {
            this.helper.showAlert(4, response.message);
          } else {
            this.helper.showAlert(4, 'Something went wrong');
          }
          this.form.reset({
            name: '',
            email: '',
            category: '',
            phone: '',
            message: '',
          });
          this.submitted = false;
          this.loader = false;
        },
        (error) => {
          this.helper.showAlert(4, 'Something went wrong');
          this.submitted = false;
          this.loader = false;
        }
      );
    }
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    $(window).scroll(function () {
      const sticky = $('.gitex-navbar'),
        scroll = $(window).scrollTop();

      if (scroll > 10) sticky.addClass('scrolled_navbar');
      else sticky.removeClass('scrolled_navbar');
    });

    window.addEventListener('load', function () {
      const sticky = $('.gitex-navbar'),
        scroll = $(window).scrollTop();

      if (scroll > 10) sticky.addClass('scrolled_navbar');
      else sticky.removeClass('scrolled_navbar');
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
