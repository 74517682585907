<section class="services-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>Boutique Software <br> Development
                </h1>
                <h2>Rise above your competition</h2>
                <h3 class="cmn-sub-heading">Our goal is to help businesses test, build, scale, and embrace innovation with custom<br> software solutions tailored to their exact needs.</h3>
            </div>
        </div>
    </div>
</section>

<section class="software-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <img src="/assets/images/software.webp" class="img-fluid d-md-none d-block" alt="Software">
            </div>
            <div class="col-md-7">
                <h1 class="cmn-heading">Software development that matches the speed of your business</h1>
                <p>We believe in startups. That’s why we rely on industry-leading expertise and cutting-edge tools to get your big idea to market as fast as possible. Launch a winning product, fulfill your business vision, and live up to the promise of your
                    brand with Techware Lab.</p>
            </div>
        </div>
    </div>
</section>

<section class="service-list-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Services </h1>
                <h2 class="cmn-sub-heading">We offer a diverse range of solutions all designed to find a new competitive edge for your business. </h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="service-box yellow">
                    <img src="/assets/images/ser-1.png" alt="Service">
                    <h3>Web App Development</h3>
                    <p>Enrich customer relationships and grow operations exponentially with a fully-customized web application that understands your business needs and goals. </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="service-box blue">
                    <img src="/assets/images/ser-2.png" alt="Service">
                    <h3>Mobile Development </h3>
                    <p>Put your business in the hands of customers with an outstanding mobile experience. Boost brand awareness, customer loyalty, and revenue with our leading mobile development services. </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="service-box red">
                    <img src="/assets/images/ser-3.png" alt="Service">
                    <h3>Digital Marketing </h3>
                    <p>The right marketing can turn big ideas into success stories. Attract and win new customers with a compelling digital strategy designed by a professional team of marketing specialists.
                    </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="service-box blue">
                    <img src="/assets/images/ser-4.png" alt="Service">
                    <h3>Business Consulting
                    </h3>
                    <p>Technology is complex. Get the most out of digital innovation by following a clearly-defined roadmap for change. Modernize your software and implement new systems without the risk.
                    </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="service-box red">
                    <img src="/assets/images/ser-it-staff.png" alt="Service">
                    <h3> IT Staff Augmentation  </h3>
                    <p>Reliable, and cost-effective method of increasing the size and productivity of your teams with minimal effort. Techware Lab can help you strike the right balance between progress and cost.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="service-box yellow">
                    <img src="/assets/images/ser-6.png" alt="Service">
                    <h3>Ecommerce Development
                    </h3>
                    <p>Got something to sell? Maximize profits with a sleek and effortless ecommerce portal tailored to your exact business needs. Deliver an exceptional customer experience across any device.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="service-box red">
                    <img src="/assets/images/ser-software.png" alt="Service">
                    <h3>Software Development</h3>
                    <p>Translate client requirements into customized software solutions of superior quality with advanced technology. With our team of  industry experts and the latest IT advancements, we deliver products and solutions that perfectly tailor to the requirements  of our customers.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="service-box yellow">
                    <img src="/assets/images/ser-web-analytics.png" alt="Service">
                    <h3>Web analytics</h3>
                    <p>Turn your data into actionable insights that improve your organization’s decision-making activities. We can be your trusted partner to keep you on top of your organization’s data analytics, lead generation, and marketing tactics.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="service-box blue">
                    <img src="/assets/images/ser-enterprise.png" alt="Service">
                    <h3>Enterprise Development</h3>
                    <p>Improve your current operations and mitigate IT complications with a modern and integrated solution. Our team of experts can tailor a unique digital strategy suitable for your business goals and equip you with the tools and technology which makes you stand out amongst your competitors.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <h1 class="cmn-heading">What do our clients have <br> to say about us? </h1>
                <h2 class="cmn-sub-heading">We believe in delivering tangible value. Don’t believe us?<br> Hear from our network of industry leaders. </h2>
            </div>
            <div class="col-md-4">

            </div>
        </div>
        <div id="demo" class="carousel slide mt-5" data-ride="carousel">



            <!-- The slideshow -->
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="test-circle">
                                <div class="test-profile">
                                    <img src="/assets/images/testimonial-image-1.jpg" alt="Profile">
                                </div>
                                <h3>Josef Myers</h3>
                                <h4>That Level, CEO, USA</h4>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="test-content">
                                <p>“I've been outsourcing some tasks to offshore developers for over 10 years now, and I've had the best experience with Techware by far. They follow the development and design requirements perfectly, and most importantly
                                    they communicate any questions or concerns early in the project, which saves everyone a lot of time. For every phase of the project management, development, and deployment, they work quickly and effectively. So whenever
                                    I send them a project, I know there's no need to worry about them completing correctly and on time.”</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="test-circle">
                                <div class="test-profile">
                                    <img src="/assets/images/AbdulSattar.jpg" alt="Profile">
                                </div>
                                <h3>AbdulSattar Zahid</h3>
                                <h4>Alibaba Product Growth,<br> Dubai</h4>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="test-content">
                                <p>“In a nutshell, I consider them to be a member of our team, who just don't sit with us. I have always found the Techware team very compliant. I have never gotten the feeling that getting to a goal is important to me and
                                    to them we are just another client, but I have always gotten the feeling that we are one team and I value that over all the disagreements and what not. As a testament to this feedback, what started from one project
                                    has transformed our partnership in a very long one and we hope to continue that. ”</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="test-circle">
                                <div class="test-profile">
                                    <img src="/assets/images/testimonial-image-3.jpg" alt="Profile">
                                </div>
                                <h3>Danette Copestake</h3>
                                <h4>IT Director, <br> Wyelands Bank</h4>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="test-content">
                                <p>"Techware Lab and I worked together on a number of exciting innovation and R&D projects. Nixon was my lead technical consultant. He is a knowledgeable IT consultant who is highly collaborative in his approach. He is always quick to respond to questions / issues offering solutions to complex problems and often thinks ‘out of the box’. Nixon is intelligent, diligent and thorough in his approach and maintains a sense of humour. He was a highly valued member of my team, often going above and beyond what is expected of him.  I would welcome him back to my team and strongly recommend him to future employers."
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <a class="carousel-control-prev" href="#demo" data-slide="prev">
                </a>
                <a class="carousel-control-next" href="#demo" data-slide="next">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Merging business and technology to<br> create success</h1>
                <h2 class="cmn-sub-heading">Are you ready to transform innovation into a market-ready product? Extend your reach, achieve operational excellence, and maximize profits by partnering with Techware Lab.</h2>
                <button routerLink="/contact">Work with us</button>
            </div>
        </div>
    </div>
</section>