import { Component, OnInit } from '@angular/core';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dedicated-development-team',
  templateUrl: './dedicated-development-team.component.html',
  styleUrls: ['./dedicated-development-team.component.scss']
})
export class DedicatedDevelopmentTeamComponent implements OnInit {


  constructor(private model: MatDialog) { }

  ngOnInit(): void {
  }
  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }

}
