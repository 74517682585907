<section class="quote-wrapper">
    <span class="close_btn" (click)="closeChatDialog()"><img src="/assets/images/close-icon.png" alt="Close"></span>
    
    <form [formGroup]="submitForm" (ngSubmit)="submitFormSubmit()">
        <div class="form-group">
            <input type="text" formControlName="name" class="form-control" placeholder="Full Name">
            <div class="s_error" *ngIf="!submitForm.controls['name'].valid && (submitForm.controls['name'].dirty || submitForm.controls['name'].touched || contactSubmit)">
                <div class="s_validation" *ngIf="submitForm.controls['name'].hasError('required')">
                    Provide a Name
                </div>
            </div>
        </div>
        <div class="form-group">
            <input type="email" formControlName="email" class="form-control" placeholder="Email">
            
             <div class="s_error" *ngIf="!submitForm.controls['email'].valid && (submitForm.controls['email'].dirty || submitForm.controls['email'].touched || contactSubmit)">
                <div class="s_validation" *ngIf="submitForm.controls['email'].hasError('required')">Provide an Email</div>
                <div class="s_validation" *ngIf="submitForm.controls['email'].hasError('pattern')">Provide a Valid Email Id</div>
            </div> 
        </div>
        <div class="form-group">
            <select class="form-control" formControlName="country">
                <option disabled value="0" [selected]="true">Select Country</option>
                <option *ngFor="let contry of countryList.default">
                {{contry.name}}
                </option>
            </select>
            
            <div class="s_error" *ngIf="!submitForm.controls['country'].valid && (submitForm.controls['country'].dirty || submitForm.controls['country'].touched || contactSubmit)">
                <div class="s_validation" *ngIf="submitForm.controls['country'].hasError('required')">
                    Choose Country
                </div>
            </div>
        </div>
        <div class="form-group">
            <input type="Number" formControlName="phone" class="form-control" placeholder="Phone Number">
            
            <div class="s_error" *ngIf="!submitForm.controls['phone'].valid && (submitForm.controls['phone'].dirty || submitForm.controls['phone'].touched || contactSubmit)">
                <div class="s_validation" *ngIf="submitForm.controls['phone'].hasError('required')">
                    Provide a Phone number
                </div>
            </div>
        </div>
     
        <div class="form-group">
            <textarea rows="5" placeholder="Message" formControlName="message" class="form-control"></textarea>
            
            <div class="s_error" *ngIf="!submitForm.controls['message'].valid && (submitForm.controls['message'].dirty || submitForm.controls['message'].touched || contactSubmit)">
                <div class="s_validation" *ngIf="submitForm.controls['message'].hasError('required')">
                    Provide Your Message
                </div>
            </div>
        </div>
        <div class="form-group">
            <button>Submit Now</button>
        </div>
    </form>
 
</section>