<section class="uiuxSectionWrapper">
  <div class="heroSection">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="heroContent">
            <div>
              <h1>UI/UX Design Service</h1>
              <h5>Seamless Experiences, Strategic Designs </h5>
              <p>
                Our team of experienced UI/UX designers blends the art of visual design with the science of user behavior to create digital solutions that are not only beautiful but also intuitive and user-friendly.

              </p>
              <button (click)="requestQuote()">Get Started</button>
            </div>

            <div class="pinkBgDiv"></div>
            <div class="yellowBgDiv"></div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="heroImgWrapper">
            <img src="../../assets/images/uiuxHeroImage.png" />
          </div>
          <div class="heroImageBgWrapper">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#E6FBFF"
                d="M48.4,-55.6C62.1,-46.3,71.9,-30.4,74.1,-13.7C76.3,3,70.9,20.4,62.6,37.4C54.3,54.3,43,70.8,28,75.8C13,80.8,-5.8,74.2,-21.1,65.4C-36.4,56.6,-48.1,45.6,-58.3,31.8C-68.4,18,-77.1,1.4,-74.7,-13.4C-72.3,-28.2,-59,-41.3,-44.7,-50.5C-30.5,-59.7,-15.2,-64.9,1.1,-66.2C17.4,-67.5,34.8,-64.9,48.4,-55.6Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
          <div class="heroImageWrapper2">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#E6FBFF"
                d="M65.2,-21.4C72.9,2.3,59.4,32.7,36.8,48.7C14.2,64.6,-17.4,66,-40.1,50.6C-62.8,35.2,-76.5,2.9,-68.4,-21.6C-60.2,-46.1,-30.1,-62.8,-0.7,-62.5C28.8,-62.3,57.6,-45.2,65.2,-21.4Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="serviceSection">
    <div class="container">
      <h2>Our Service</h2>
      <div class="row">
        <div class="col-lg-4">
          <img src="../../assets/images/userInterfaceImage.png" />
          <h3>User Interface (UI) Design</h3>
          <p>
            We create visually appealing designs that reflect your brand’s
            identity and enhance user engagement.
          </p>
        </div>
        <div class="col-lg-4">
          <img src="../../assets/images/userExperienceImage.png" />
          <h3>User Experience (UX) Design</h3>
          <p>
            We focus on understanding the user’s journey, optimizing navigation,
            and ensuring a seamless interaction with the digital product.
          </p>
        </div>
        <div class="col-lg-4">
          <img src="../../assets/images/uiuxinteractiveImag.png" />
          <h3>Interactive Prototyping</h3>
          <p>
            Test and refine your ideas with interactive prototypes that give you
            a realistic preview of the final product.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <img src="../../assets/images/uiuxresponssiveImage.png" />
          <h3>Responsive Web Design</h3>
          <p>
            We ensure your digital solution looks and functions perfectly across
            all devices, whether desktop, tablet, or mobile.
          </p>
        </div>
        <div class="col-lg-4">
          <img src="../../assets/images/uiuxtestingImage.png" />
          <h3>User Testing</h3>
          <p>
            We gather honest user feedback to refine and improve the product,
            ensuring it meets user expectations.
          </p>
        </div>
        <div class="col-lg-4">
          <img src="../../assets/images/uiuxsystemCreationImage.png" />
          <h3>Design System Creation</h3>
          <p>
            Establish consistent branding and design patterns across all your
            digital assets.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="featureSection">
    <div class="blueBgDiv"></div>
    <div class="pinkBgDiv"></div>
    <div class="yellowBgDiv"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <img src="../../assets/images/uiuxfeatureImage.png" />
        </div>
        <div class="col-lg-6">
          <h2>Why Choose Techware Lab for UI/UX Design?</h2>
          <div>
            <h6>Experienced Team</h6>
            <p>
              Our designers are experts in the latest design tools and trends,
              ensuring your digital assets are current and competitive.
            </p>
          </div>
          <div>
            <h6>Collaborative Approach</h6>
            <p>
              We work closely with our clients, ensuring your vision and
              business goals are at the forefront of our design strategy.
            </p>
          </div>
          <div>
            <h6>Holistic Solutions</h6>
            <p>
              We consider all aspects of the user’s journey, from the initial
              landing page to the final conversion point.
            </p>
          </div>
          <div>
            <h6>Cost-effective</h6>
            <p>
              We provide top-tier design services at competitive rates, ensuring
              you get the best value for your investment.
            </p>
          </div>
          <div>
            <h6>Timely Deliveries</h6>
            <p>
              We understand the importance of time in the digital world. Our
              team is committed to delivering projects within the stipulated
              timelines.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ctaWrapper">
    <div class="container">
      <h1>Let's Design the Future Together</h1>
      <p>
        Ready to elevate your digital presence with top-notch UI/UX design?
        Contact us today to discuss your project and get a free consultation.
      </p>

      <button routerLink="/contact">Book Your Free Consultation</button>
    </div>
  </div>
</section>
