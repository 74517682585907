import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-case-study-xlri',
  templateUrl: './case-study-xlri.component.html',
  styleUrls: ['./case-study-xlri.component.scss']
})
export class CaseStudyXlriComponent implements OnInit {

  xlriData: any = {
    heading: {
      title: 'XLRI (Xavier School of Management)',
      description: 'XLRI, India\'s Oldest B-School, Pioneering Economic and Social Transformation Since 1949.',
      buttonText: 'TALK TO OUR EXPERTS',
      imageUrl: "../../assets/images/case-study-xlri-heading-section-img.png"
    },
    about: {
      title: 'About XLRI',
      description: [
        'Established in 1949, XLRI (Xavier School of Management) is one of the oldest business schools in India and is consistently ranked among the top B-schools in the country. XLRI is known for its strong focus on ethics and sustainability in management education. The school has several centers and institutes dedicated to research and training in corporate responsibility, sustainability, and social entrepreneurship.',
        'XLRI has a diverse student community, with students from India and several other countries. The school has a strong alumni network, with many graduates holding leadership positions in top companies in India and worldwide.'
      ],
      imageUrl: "../../assets/images/case-study-xlri-about-section-img.png"
    },
    story: {
      title: 'Story',
      description: [
        'Techware Lab was assigned the task of developing an education ERP for XLRI, which included LMS, CRM, HRMS, admission, academic, and alumni management. The team conducted an extensive analysis of XLRI\'s operations and developed a solution that addressed their pain points. The ERP was developed within 1.5 years, and it was implemented in phases to ensure smooth integration with the existing systems. The development process involved several stages, including requirement gathering, system design, coding, testing, and implementation.'
      ],
      buttonText:'VIEW MORE',
      imageUrl: "../../assets/images/case-study-xlri-story-section-img.png",
      viewMoreContent: [
        '<p>Education ERP had a significant impact on XLRI\'s operations. The ERP streamlined their administrative and academic processes, enabling them to focus on their core activities of teaching and research. The LMS module enabled faculty members to manage their courses and assessments more efficiently, and the CRM module enabled them to track the progress of students and communicate with them effectively. The HRMS module enabled XLRI to manage their employee records, attendance, and payroll more efficiently, enabling them to focus on their core activities.</p>',
        '<p>Let’s take a closer look at the features of the education ERP solution that we provided for XLRI:</p>',
        '<h4>Admissions made simple</h4>',
        '<ul><li>Simplified application process.</li><li>Resolve issues through ticket raising.</li><li>Conduct or schedule interviews for new students.</li><li>Ease of collecting student data.</li><li>Tracking the progress of each application.</li><li>Streamlined fee payments & invoices.</li><li>Manage student records and other documents on the cloud.</li><li>Ease of generating ID cards for students online.</li><li>Generate forms in minutes.</li></ul>',
        '<h4>Efficient academic operations</h4>',
        '<ul><li>Ease in course management.</li><li>Prepare or schedule live sessions in minutes.</li><li>Prepare group forums to discuss heated topics.</li><li>Prepare assignments for students effortlessly.</li><li>Manage the calendar effectively.</li><li>Update or notify students with an automatic notification center.</li><li>Live attendance tracking.</li><li>Create groups for students\' interactivity.</li><li>Manage students\' elective course selection and registration.</li><li>Creation of course program through a program planner.</li><li>Announcement to keep students updated.</li></ul>',
        '<h4>Manage exams efficiently</h4>',
        '<ul><li>Conduct online exams seamlessly whether it\'s MCQs, long-type answers, or more.</li><li>Helped teachers to create assessments quickly.</li><li>Test students\' comprehension skills effortlessly.</li><li>Generate certifications & mark sheets after course completion.</li></ul>',
        '<h4>Streamlined employee management with HRMS</h4>',
        '<ul><li>Ease of managing the leave process to apply for leave, leave approval, and leave balances tracker.</li><li>Manage the holiday calendar, and employees can view upcoming holidays.</li><li>Manage employee information seamlessly.</li><li>Data recovery and protection to avoid information leakage.</li><li>Generate payslips and track payments.</li><li>Attendance tracking for XLRI employees.</li><li>The recruitment process is easier to manage.</li></ul>',
        '<h4>Improved operations by streamlining administrative processes</h4>',
        '<ul><li>Helped XLRI to create pages for extracurricular activities.</li><li>Give & manage users’ permissions.</li><li>Customizable dashboard according to everyone’s KPIs.</li><li>Easily store and organize media files.</li><li>Made it easy for different departments to collaborate on projects.</li><li>Enhanced communication with students & teachers through the chat system.</li></ul>',
        '<h4>Efficient alumni management system</h4>',
        '<ul><li>Alumni registration for any events that you conduct.</li><li>Raise funds and other payments.</li><li>Manage or conduct reunions.</li><li>User-friendly and customizable system.</li><li>Protect sensitive information.</li></ul>',
        '<p>In conclusion, adding these comprehensive features allowed XLRI to provide a better learning experience for its students, while streamlining its administrative processes. The admin team at XLRI now saves at least 10 hours a week due to our ERP.</p>'
      ]
      
    },
    plainBanner: {
      title: 'Get Your Education ERP Software Created with Our Top-Notch Development Solution',
      buttonText: 'CONTACT US'
    },
    results: {
      title: 'Results',
      description: ['Overall, Techware Lab\'s education ERP solution provided XLRI with a comprehensive suite of tools to manage its educational programs and operations more effectively. The result was a highly functional and intuitive system that has been instrumental in helping XLRI achieve its goals.'],
      imageUrl: "../../assets/images/case-study-xlri-result-section-img.png"
    }
  };

  constructor(private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle(
      "Case Study XLRI (Xavier School of Management)  | TechwareLab"
    );
  }

}
