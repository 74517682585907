<section class="career-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="circle-wrapper">
            <h1>
              TMechanic: Online <br/>
              Bookings Solution  <br/>
              for Auto Mechanics
            </h1>
            <h2 class="cmn-sub-heading">
              Fast repairs to get you back on the road. TMechanic is the ideal mechanic online booking solution for auto mechanics looking to stay organized, increase service bookings, and improve service availability.
            </h2>
            <a (click)="requestQuote()" target="_blank">Request a demo</a>
          </div>
        </div>
        <div class="col-md-5">
          <img
            src="/assets/images/tmechanic-img-1.png"
            class="img-fluid"
            alt="tmechanic"
          />
        </div>
      </div>
    </div>
</section>

<section class="whatistaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-5" data-aos="fade-right">
        <img
          src="/assets/images/tmechanic-img-2.png"
          class="img-fluid"
          alt="tmechanic"
        />
      </div>
      <div class="col-md-7">
        <div class="content">
          <h1 class="cmn-heading">What Is TMechanic?</h1>
          <p>
            The designers, developers, and marketers of Techware Lab have come together to craft a new solution for auto mechanics appointment and scheduling systems. Let potential customers find your automobile repair services and book mechanics within seconds. 
          </p>
          <p>
            TMechanic gives your users everything they need to learn about the services you offer, track past bookings, schedule appointments, and more.
          </p>
          <p>
            Make it easy for your customers to book the services they need with one platform that does it all. With TMechanic, you’ll spend less time managing administrative tasks and more time focusing on the services you offer.
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
        <div class="taxi-box">
          <img
            src="/assets/images/tmechanic-icon-1.png"
            class="img-fluid"
            alt="tmechanic"
          />
          <h2>
            Choose From the Best
          </h2>
          <p>
            No matter what vehicle you have, find the perfect mechanic anytime from anywhere. Pick the most experienced professionals at the tap of a finger
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
        <div class="taxi-box">
          <img
            src="/assets/images/tmechanic-icon-2.png"
            class="img-fluid"
            alt="tmechanic"
          />
          <h2>
            Work With Anyone
          </h2>
          <p>
            The app is compatible with Android, iOS, and Windows platforms. No matter who is looking for auto repair work, your business is ready to meet the demand.
          </p>
        </div>
      </div>
      <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
        <div class="taxi-box">
          <img
            src="/assets/images/tmechanic-icon-3.png"
            class="img-fluid"
            alt="tmechanic"
          />
          <h2>
            Stay On Top of Scheduling
          </h2>
          <p>
            TMechanic helps keep track of your bookings, making it easier to see your appointment history and find new appointment times quickly
          </p>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="ttaxi-features-wrapper">
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-5">
        <h1 class="cmn-heading">TMechanic Features</h1>
        <img
          src="/assets/images/tmechanic-img-3.png"
          class="img-fluid"
          alt="tmechanic"
        />
      </div>
      <div class="col-md-6">
        <ul class="list-1">
          <li>
            <span>1</span>
            <h6>Complete Mechanic Booking Solution</h6>
          </li>
          <li>
            <span>2</span>
            <h6>Stunning UI Design</h6>
          </li>
          <li>
            <span>3</span>
            <h6>List of Available Mechanics</h6>
          </li>
          <li>
            <span>4</span>
            <h6>Track Booking Details</h6>
          </li>
          <li>
            <span>5</span>
            <h6>Choose the Most Experienced Mechanics</h6>
          </li>
          <li>
            <span>6</span>
            <h6>Android, iOS, and Windows Compatibility</h6>
          </li>
        </ul>
        <button routerLink="/contact">Let’s get started</button>
      </div>
    </div>
  </div>
</section>

<section class="abouttaxi-wrapper">
  <div class="container">
    <div class="row pb-4">
      <div class="col-md-7 order-md-0 order-1">
        <div class="content">
          <h1 class="cmn-heading">About Techware Lab</h1>
          <p>
            Techware Lab provides expert design capabilities and top software solutions that help elevate your business through industry-specific apps. Choose the professionals in web design, software/mobile development and curation to bring the process of booking an auto mechanic to the technological age. TMechanic makes finding auto mechanics a painless process so car owners get the automobile repair services they need, when they need it.
          </p>
        </div>
      </div>
      <div class="col-md-5 order-md-1 order-0" data-aos="fade-left">
        <img
          src="/assets/images/tmechanic-img-4.png"
          class="img-fluid"
          alt="tmechanic"
        />
      </div>
    </div>
  </div>
</section>

<section class="footer-banner-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="cmn-heading">
          Repair Your Mechanic Business With TMechanic Mechanics Dispatching Software
        </h1>
        <h2 class="cmn-sub-heading">
          Watch your client base expand when you choose TMechanic for your auto repair booking software or automotive appointment software. Work with Techware Lab today.
        </h2>
        <button routerLink="/contact">Let’s get started</button>
      </div>
    </div>
  </div>
</section>


<section class="faq-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Frequently Asked Questions</h1>
    <mat-accordion>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            What is automotive appointment software?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          TMechanic automotive scheduling software is made for auto service centers, tire shops, car mechanics, oil change providers, window replacements, car washes, and other car-related service providers. It allows your customers to schedule and reschedule from any device, at their convenience, ensuring a personalized and informative experience.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How do I build mechanic scheduling software for my auto repair shop?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Auto service centers and repair shops are rapidly switching to software to streamline operations. While repairs are still done manually, software ensures efficiency by improving appointment scheduling, customer interactions, and more.
        </p>
        <p>
          If you’re getting ready to open a shop or in the market for auto repair shop management software, contact us at hello@techwarelab.com.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Why do you need auto repair scheduling software?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          TMechanic will simplify the booking experience and eliminate wait times through free online scheduling software for your auto businesses. Our appointment scheduling app for auto mechanics automates your booking process and reduces service interruptions. Customers receive automated booking confirmations, updates, and more when they schedule service through your automotive repair scheduling software.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            How much does it cost to develop a mechanics dispatching software?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          The cost for a basic mechanic booking software will start at $5,000. For large scale enterprises, more advanced features may be required. Your costs will increase based on what features you need, the number of bookings you manage, and turnaround time.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>

<section class="relates-solutions-wrapper">
  <div class="container">
    <h1 class="cmn-heading">Related Solutions</h1>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <div class="related-box" routerLink="/legal-case-management-software">
            <img src="/assets/images/new-related-img1.png" class="img-fluid" alt="Images">
        </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/doctor-appointment-app">
              <img src="/assets/images/new-related-img2.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-management-system">
              <img src="/assets/images/new-related-img3.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/taxi-dispatch-software">
              <img src="/assets/images/new-related-img4.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/grocery-app-development">
              <img src="/assets/images/new-related-img5.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/online-food-ordering-system">
              <img src="/assets/images/new-related-img6.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/car-rental-app-development">
              <img src="/assets/images/new-related-img7.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/movie-ticketing-software">
              <img src="/assets/images/new-related-img8.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/beauty-salon-software">
              <img src="/assets/images/new-related-img9.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/matrimony-app-development">
              <img src="/assets/images/new-related-img11.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
      <ng-template carouselSlide>
          <div class="related-box" routerLink="/payroll-management-system">
              <img src="/assets/images/new-related-img12.png" class="img-fluid" alt="Images">
          </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>