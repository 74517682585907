let baseUrl = '';
baseUrl = 'https://crm-integration.techlabz.in/';

let baseUrl2 = '';
// baseUrl2 = 'https://shahid-81.workspace.techwarelab.com/';
baseUrl2 = 'https://api-techwarelab.techlabz.in/';

export const ImageStorage = baseUrl;
export const apiConfig = baseUrl + 'api/';
export const blogConfig = baseUrl2 + 'graphql/';
