<section class="header-banner">
    <div class="container">
        <div class="text-content">
            <h1>Grow Your Business With <br> Web Analytics & Data- <br> Driven Insights</h1>  
            <h6>Generate even more value out of your data and improve the performance of your website and <br>digital solutions with our web analytics solutions.</h6>
            <a (click)="requestQuote()" target="_blank">Request a demo</a>
        </div>
    </div>
</section>
<section class="techware-wrapper">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col-md-5 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/web-analytics-img-1.png" class="img-fluid" alt="web-analytics">
            </div>
            <div class="col-md-7">
                <div class="help-box mt-4 mt-md-0">
                    <h1 class="cmn-heading">
                        We’re Techware Lab—We Help <br> You Collect and Drive the Most <br> Value Out of Your Data
                    </h1>
                    <p>
                        Our team of skilled developers and analysts know how to unlock the full potential of your data. We optimize your existing online platforms and provide useful recommendations for your website and digital solutions. Partnering with Techware Lab means you have a trusted partner that keeps you on top of your organization’s data analytics, lead generation, and marketing tactics. 
                    </p>
                    <button routerLink="/contact">Get a quote</button>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="design-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Web Analytics Services</h1>
                <h2 class="cmn-sub-heading ">We offer marketing, strategic, and operational guidance to curate successful analytic diagnostics for your website and digital marketing processes.</h2>
           
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/web-analytics-img-2.png" class="img-fluid side-img" alt="web-analytics">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-5 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Analytics Consulting
                                         </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        Get a deep dive into the digital landscape and marketing efforts of your organization. A full audit of your analytical systems gives us a keen understanding of your business goals, holes, and areas for improvement pertaining to your overall effectiveness in the market.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Digital Marketing Campaign Tracking
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        Have our team monitor the effectiveness of your marketing efforts over a period of time to determine if you are maximizing the appropriate marketing opportunities based on your organization’s capabilities. IHere we discover your web traffic records, quality scores, SEO performance, conversion record, and capital spend.
                                       </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Analytics Setup
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            Our team leverages Google Analytics to drive your website’s digital marketing success. Our team is well-versed in using this platform to measure your campaign results, report on customer engagement, collect and analyze customer data, and track conversions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            A/B Testing
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            To ensure that your team is benefiting from the most modern web design and development practices, we conduct periodic A/B test experiments to compare and contrast the overall experience of your website. Based on our findings, we make the necessary adjustments to improve your user engagement and improve your conversion rates.
                                        </p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>





<section class="approach-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Our Approach</h1>
            </div>
        </div>
        <div class="row mb-md-5 mt-md-4">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box blue">
                    <img src="/assets/images/web-analytics-icon-1.png" alt="web-analytics">
                    <h3> Analytics Analysis and Consulting </h3>
                    <p>Here we provide you with a macro analysis of your organization’s current website performance and digital marketing practices. The main purpose of this is to discover the most efficient ways for your organization to drive customer engagement and retention in the most affordable way. </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="approach-box red">
                    <img src="/assets/images/web-analytics-icon-2.png" alt="web-analytics">
                    <h3>Strategic Planning and Next Steps</h3>
                    <p>In this stage, we take the insights and findings from our previous consultations to devise an actionable plan for improvement. We’ll also look into customer data to understand their deep needs and strategize how to best meet them with a customized measurement strategy. </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box yellow">
                    <img src="/assets/images/web-analytics-icon-3.png" alt="web-analytics">
                    <h3>Implementation and Optimization</h3>
                    <p>We set your website up for tracking success and custom parameters based on your business goals and capabilities. Then, all systems are a go and we provide ongoing support for reiteration in the digital and data analysis process.</p>
                </div>
            </div>
        </div>
    </div>
</section>




<section class="techware-innovation-wrapper ">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">What We Analyze</h1>
            </div>
        </div>
        <div class="row side">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="team-box text-center">
                    <img src="/assets/images/web-analytics-icon-one-1.png" class="img-one imgeOne" alt="web-analytics">
                    <h3>SEO</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="team-box text-center">
                    <img src="/assets/images/web-analytics-icon-one-2.png" class="img-one" alt="web-analytics">
                    <h3>Email Marketing</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box text-center">
                    <img src="/assets/images/web-analytics-icon-one-3.png" class="img-one" alt="web-analytics">
                    <h3>Paid Ads</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="team-box text-center">
                    <img src="/assets/images/web-analytics-icon-one-4.png" class="img-one" alt="web-analytics">
                    <h3>Mobile Marketing</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="team-box text-center">
                    <img src="/assets/images/web-analytics-icon-one-5.png" class="img-one" alt="web-analytics">
                    <h3>eCommerce</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box text-center">
                    <img src="/assets/images/web-analytics-icon-one-6.png" class="img-one" alt="web-analytics">
                    <h3>Social Media Optimization</h3>
                </div>
            </div>
        </div>
    </div>
</section> 


<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Turn Your Data Into Actionable Insights That <br> Improve Decision Making</h1>
                <h5 class="cmn-sub-heading">Turn your website and digital solutions into optimized conversion machines. Our web analytics solutions are result-driven and prepare you to compete successfully in the digital business world. <br> <br>
                Are you ready to drive more value out of your data. Get in touch with our web analytics specialists today. </h5>
                <button routerLink="/contact">Get a quote now</button>
            </div>
        </div>
    </div>
</section>








