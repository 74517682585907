<section class="plain-banner">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2>{{ title }}</h2>
        </div>
      </div>
      <button>{{ buttonText }}</button>
    </div>
</section>
  