import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ProductsComponent } from './products/products.component';
import { ServicesComponent } from './services/services.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CareerFormComponent } from './career-form/career-form.component';
import { CareersComponent } from './careers/careers.component';
import { BlogComponent } from './blog/blog.component';

// Techware Services Component
import { DoctorAppointmentAppComponent } from './doctor-appointment-app/doctor-appointment-app.component';
import { LegalCaseManagementSoftwareComponent } from './legal-case-management-software/legal-case-management-software.component';
import { TaxiManagementSystemComponent } from './taxi-management-system/taxi-management-system.component';
import { GitexLandingPageComponent } from './gitex-landing-page/gitex-landing-page.component';
import { PartnershipComponent } from './partnership/partnership.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { MobileAppDevelopmentComponent } from './mobile-app-development/mobile-app-development.component';
import { GroceryAppDevelopmentComponent } from './grocery-app-development/grocery-app-development.component';
import { OnlineFoodOrderingSystemComponent } from './online-food-ordering-system/online-food-ordering-system.component';
import { IosDevelopmentComponent } from './ios-development/ios-development.component';
import { SoftwareDevelopmentComponent } from './software-development/software-development.component';
import { AndroidAppDevelopmentComponent } from './android-app-development/android-app-development.component';
import { BrandingServicesComponent } from './branding-services/branding-services.component';
import { MatrimonyAppDevelopmentComponent } from './matrimony-app-development/matrimony-app-development.component';
import { AutomotiveAppointmentSoftwareComponent } from './automotive-appointment-software/automotive-appointment-software.component';
import { BeautySalonSoftwareComponent } from './beauty-salon-software/beauty-salon-software.component';
import { PayrollManagementSystemComponent } from './payroll-management-system/payroll-management-system.component';
import { WebAnalyticsComponent } from './web-analytics/web-analytics.component';
import { BusinessConsultingComponent } from './business-consulting/business-consulting.component';
import { UserResearchAndDiscoveryComponent } from './user-research-and-discovery/user-research-and-discovery.component';
import { GotoMarketStrategyComponent } from './goto-market-strategy/goto-market-strategy.component';
import { EducationSoftwareDevelopmentComponent } from './education-software-development/education-software-development.component';
import { EducationSoftwareDevelopmentOneComponent } from './education-software-development-one/education-software-development-one.component';
import { CarRentalAppDevelopmentComponent } from './car-rental-app-development/car-rental-app-development.component';
import { MovieTicketingSoftwareComponent } from './movie-ticketing-software/movie-ticketing-software.component';
import { EnterpriseDevelopmentComponent } from './enterprise-development/enterprise-development.component';
import { EcommerceWebSolutionsComponent } from './ecommerce-web-solutions/ecommerce-web-solutions.component';
import { SoftwareDevelopmentSolutionsComponent } from './software-development-solutions/software-development-solutions.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { TaxiDispatchSoftwareComponent } from './taxi-dispatch-software/taxi-dispatch-software.component';
import { StaffAugmentationComponent } from './staff-augmentation/staff-augmentation.component';
import { PartnerLandingPageComponent } from './partner-landing-page/partner-landing-page.component';
import { CustomAppDevelopmentComponent } from './custom-app-development/custom-app-development.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { UiUxServiceComponent } from './ui-ux-service/ui-ux-service.component';
import { SocialMediaOptimizationComponent } from './social-media-optimization/social-media-optimization/social-media-optimization.component';
import { TestingQaServiceComponent } from './testing-qa-service/testing-qa-service/testing-qa-service.component';
import { SearchEngineOptimizationComponent } from './search-engine-optimization/search-engine-optimization/search-engine-optimization.component';
import { PerformanceMarketingSericeComponent } from './performance-marketing-service/performance-marketing-serice/performance-marketing-serice.component';
import { ItConsultingComponent } from './it-consulting/it-consulting.component';
import { MarketingAutomationComponent } from './marketing-automation/marketing-automation.component';
import { DevopsConsultingComponent } from './devops-consulting/devops-consulting.component';
import { DedicatedDevelopmentTeamComponent } from './dedicated-development-team/dedicated-development-team.component';
import { CustomSoftwareDevelopmentServiceComponent } from './custom-software-development-service/custom-software-development-service.component';
import { ContentMarketingServiceComponent } from './content-marketing-service/content-marketing-service.component';
import { CaseStudyDarazComponent } from './case-study-daraz/case-study-daraz.component';
import { CaseStudyKpComponent } from './case-study-kp/case-study-kp.component';
import { CaseStudyXlriComponent } from './case-study-xlri/case-study-xlri.component';
import { CaseStudyMangosuiteComponent } from './case-study-mangosuite/case-study-mangosuite.component';

/**
 * Blog Components
 */

// import { WhyBusinessNeedsChatbotComponent } from './why-business-needs-chatbot/why-business-needs-chatbot.component';
// import { WeHaveRebrandedComponent } from './we-have-rebranded/we-have-rebranded.component';
// import { MobileAppIdeasComponent } from './mobile-app-ideas/mobile-app-ideas.component';
// import { HowMuchCostDevelopOndemandDeliveryAppComponent } from './how-much-cost-develop-ondemand-delivery-app/how-much-cost-develop-ondemand-delivery-app.component';
// import { BenefitsChatbotForBusinessComponent } from './benefits-chatbot-for-business/benefits-chatbot-for-business.component';
// import { ThingsToPlanMobileAppDevelopmentComponent } from './things-to-plan-mobile-app-development/things-to-plan-mobile-app-development.component';
// import { BestPartnerForYourStartupComponent } from './best-partner-for-your-startup/best-partner-for-your-startup.component';
// import { HiringRemoteDevelopersFromIndiaComponent } from './hiring-remote-developers-from-india/hiring-remote-developers-from-india.component';
// import { WhyFlutterForMobileAppDevelopmentComponent } from './why-flutter-for-mobile-app-development/why-flutter-for-mobile-app-development.component';
// import { BenefitsOfItStaffAugmentationServicesComponent } from './benefits-of-it-staff-augmentation-services/benefits-of-it-staff-augmentation-services.component';
// import { HowBuildFoodDeliveryAppLikeUberEatsComponent } from './how-build-food-delivery-app-like-uber-eats/how-build-food-delivery-app-like-uber-eats.component';
// import { TopMobileAppDevelopmentFrameworksAppDevelopersComponent } from './top-mobile-app-development-frameworks-app-developers/top-mobile-app-development-frameworks-app-developers.component';
// import { HowDoFreeAppsMakeMoneyComponent } from './how-do-free-apps-make-money/how-do-free-apps-make-money.component';
// import { UiUxDesignTrendsComponent } from './ui-ux-design-trends/ui-ux-design-trends.component';
// import { HowToEnsureMobileAppSecurityComponent } from './how-to-ensure-mobile-app-security/how-to-ensure-mobile-app-security.component';
// import { EcommerceAppDevelopmentComponent } from './ecommerce-app-development/ecommerce-app-development.component';
// import { BenefitsOfUsingFirebaseComponent } from './benefits-of-using-firebase/benefits-of-using-firebase.component';
// import { ImportanceOfEcommerceDuringComponent } from './importance-of-ecommerce-during/importance-of-ecommerce-during.component';
// import { SuccessfulUIDesignComponent } from './successful-ui-design/successful-ui-design.component';
// import { LibraryManagementSystemComponent } from './library-management-system/library-management-system.component';
// import { ECommerceTrendsComponent } from './ecommerce-trends/ecommerce-trends.component';
// import { HealthcareAppDevelopmentComponent } from './healthcare-app-development/healthcare-app-development.component';
// import { BenefitsOfOnDemandComponent } from './benefits-of-on-demand/benefits-of-on-demand.component';
// import { UnseenRisksInMobileAppDevelopmentIndustryComponent } from './unseen-risks-in-mobile-app-development-industry/unseen-risks-in-mobile-app-development-industry.component';
// import { GraphicDesignImportantComponent } from './graphic-design-important/graphic-design-important.component';
// import { RedesigningYourWebsiteComponent } from './redesigning-your-website/redesigning-your-website.component';
// import { WhyLegalAppointmentComponent } from './why-legal-appointment/why-legal-appointment.component';
// import { YourTaxiBusinessNeedsComponent } from './your-taxi-business-needs/your-taxi-business-needs.component';
// import { MongoDBBlogComponent } from './mongo-dbblog/mongo-dbblog.component';
// import { ECommerceSEOComponent } from './e-commerce-seo/e-commerce-seo.component';
// import { ChatGPTExperienceComponent } from './chat-gptexperience/chat-gptexperience.component';
// import { NodejsForLargeScaleComponent } from './nodejs-for-large-scale/nodejs-for-large-scale.component';
// import { WhiteLabelingBlogComponent } from './white-labeling-blog/white-labeling-blog.component';
// import { HowITStaffAugmentationComponent } from './how-itstaff-augmentation/how-itstaff-augmentation.component';
// import { BuildingForSuccessComponent } from './building-for-success/building-for-success.component';
// import { FromClicksToCustomersComponent } from './from-clicks-to-customers/from-clicks-to-customers.component';
// import { BuildingInnovativeAndroidAppsComponent } from './building-innovative-android-apps/building-innovative-android-apps.component';
// import { CostOfEcommerceWebsiteDevelopmentComponent } from './cost-of-ecommerce-website-development/cost-of-ecommerce-website-development.component';
// import { StayAheadoftheCurveComponent } from './stay-aheadofthe-curve/stay-aheadofthe-curve.component';
// import { SEOTrendsFor2023Component } from './seotrends-for2023/seotrends-for2023.component';
// import { VuejsVsReactComponent } from './vuejs-vs-react/vuejs-vs-react.component';
// import { TheImportanceOfInclusiveWebsiteDesignComponent } from './the-importance-of-inclusive-website-design/the-importance-of-inclusive-website-design.component';
// import { UnlockingIOSAppDevelopmentComponent } from './unlocking-iosapp-development/unlocking-iosapp-development.component';

const routes: Routes = [
  // { path: "index", redirectTo: "" },
  { path: '', component: IndexComponent, pathMatch: 'full' },
  { path: 'about', component: AboutComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'career-form', component: CareerFormComponent },

  { path: 'doctor-appointment-app', component: DoctorAppointmentAppComponent },
  {
    path: 'legal-case-management-software',
    component: LegalCaseManagementSoftwareComponent,
  },
  { path: 'taxi-management-system', component: TaxiManagementSystemComponent },
  { path: 'gitex-technology-week', component: GitexLandingPageComponent },
  { path: 'partnership', component: PartnershipComponent },
  { path: 'web-app-development', component: WebDevelopmentComponent },
  { path: 'mobile-app-development', component: MobileAppDevelopmentComponent },

  {
    path: 'grocery-app-development',
    component: GroceryAppDevelopmentComponent,
  },
  {
    path: 'online-food-ordering-system',
    component: OnlineFoodOrderingSystemComponent,
  },
  { path: 'ios-development', component: IosDevelopmentComponent },
  { path: 'software-development', component: SoftwareDevelopmentComponent },
  {
    path: 'android-app-development',
    component: AndroidAppDevelopmentComponent,
  },
  { path: 'branding-services', component: BrandingServicesComponent },
  {
    path: 'matrimony-app-development',
    component: MatrimonyAppDevelopmentComponent,
  },
  {
    path: 'automotive-appointment-software',
    component: AutomotiveAppointmentSoftwareComponent,
  },
  { path: 'beauty-salon-software', component: BeautySalonSoftwareComponent },
  {
    path: 'payroll-management-system',
    component: PayrollManagementSystemComponent,
  },
  {
    path: 'car-rental-app-development',
    component: CarRentalAppDevelopmentComponent,
  },
  {
    path: 'movie-ticketing-software',
    component: MovieTicketingSoftwareComponent,
  },
  { path: 'enterprise-development', component: EnterpriseDevelopmentComponent },
  {
    path: 'ecommerce-web-solutions',
    component: EcommerceWebSolutionsComponent,
  },
  {
    path: 'software-development-solutions',
    component: SoftwareDevelopmentSolutionsComponent,
  },
  { path: 'digital-marketing', component: DigitalMarketingComponent },
  { path: 'web-analytics', component: WebAnalyticsComponent },
  { path: 'business-consulting', component: BusinessConsultingComponent },
  {
    path: 'user-research-and-discovery',
    component: UserResearchAndDiscoveryComponent,
  },
  { path: 'goto-market-strategy', component: GotoMarketStrategyComponent },
  {
    path: 'education-software-development',
    component: EducationSoftwareDevelopmentComponent,
  },
  {
    path: 'education-software-development-one',
    component: EducationSoftwareDevelopmentOneComponent,
  },
  { path: 'taxi-dispatch-software', component: TaxiDispatchSoftwareComponent },
  { path: 'it-staff-augmentation', component: StaffAugmentationComponent },
    {
    path:'become-our-partner',
    component:PartnerLandingPageComponent
  },
  {
    path:'custom-app-development',
    component:CustomAppDevelopmentComponent
  },
  {
    path:'ui-ux-service',
    component:UiUxServiceComponent
  },
  {
    path:'social-media-optimization',
    component:SocialMediaOptimizationComponent
  },
  {
    path:'testing-qa-service',
    component:TestingQaServiceComponent
  },
  {
    path:'search-engine-optimization',
    component:SearchEngineOptimizationComponent
  },
  {
    path:'performance-marketing-service',
    component:PerformanceMarketingSericeComponent
  },
  {
    path:'it-consulting',
    component:ItConsultingComponent
  },
  {
    path:'marketing-automation',
    component:MarketingAutomationComponent
  },
  {
    path:'devops-consulting',
    component:DevopsConsultingComponent
  },
  {
    path:'dedicated-development-team',
    component:DedicatedDevelopmentTeamComponent
  },
  {
    path:'custom-software-development-service',
    component:CustomSoftwareDevelopmentServiceComponent
  },
  {
    path:'content-markerting-service',
    component: ContentMarketingServiceComponent
  },
  { 
    path:'case-study-daraz', 
    component: CaseStudyDarazComponent 
  },
  { 
    path:'case-study-kinderpass', 
    component: CaseStudyKpComponent 
  },
  { 
    path:'case-study-xlri', 
    component: CaseStudyXlriComponent 
  },
  { 
    path:'case-study-mangosuite', 
    component: CaseStudyMangosuiteComponent 
  },

  /**
   * blog by slug - blog detail
   *
   * */ 
  { path: ':blogSlug', component: BlogDetailsComponent },

  // {
  //   path: 'why-business-needs-chatbot',
  //   component: WhyBusinessNeedsChatbotComponent,
  // },
  // { path: 'we-have-rebranded', component: WeHaveRebrandedComponent },
  // {
  //   path: 'benefits-chatbot-for-business',
  //   component: BenefitsChatbotForBusinessComponent,
  // },
  // { path: 'mobile-app-ideas', component: MobileAppIdeasComponent },
  // {
  //   path: 'how-much-cost-develop-ondemand-delivery-app',
  //   component: HowMuchCostDevelopOndemandDeliveryAppComponent,
  // },
  // {
  //   path: 'things-to-plan-mobile-app-development',
  //   component: ThingsToPlanMobileAppDevelopmentComponent,
  // },
  // {
  //   path: 'finding-best-partner-for-startup',
  //   component: BestPartnerForYourStartupComponent,
  // },
  // {
  //   path: 'hiring-remote-developers-from-india',
  //   component: HiringRemoteDevelopersFromIndiaComponent,
  // },
  // {
  //   path: 'why-flutter-for-mobile-app-development',
  //   component: WhyFlutterForMobileAppDevelopmentComponent,
  // },
  // {
  //   path: 'benefits-of-it-staff-augmentation-services',
  //   component: BenefitsOfItStaffAugmentationServicesComponent,
  // },
  // {
  //   path: 'food-delivery-app-like-uber-eats',
  //   component: HowBuildFoodDeliveryAppLikeUberEatsComponent,
  // },
  // {
  //   path: 'best-mobile-app-development-frameworks',
  //   component: TopMobileAppDevelopmentFrameworksAppDevelopersComponent,
  // },
  // {
  //   path: 'how-do-free-apps-make-money',
  //   component: HowDoFreeAppsMakeMoneyComponent,
  // },
  // { path: 'ui-ux-design-trends-2022', component: UiUxDesignTrendsComponent },
  // {
  //   path: 'how-to-ensure-mobile-app-security',
  //   component: HowToEnsureMobileAppSecurityComponent,
  // },
  // {
  //   path: 'ecommerce-app-development-cost-2022',
  //   component: EcommerceAppDevelopmentComponent,
  // },
  // {
  //   path: 'benefits-of-using-firebase-for-mobile-app-development',
  //   component: BenefitsOfUsingFirebaseComponent,
  // },
  // {
  //   path: 'importance-of-ecommerce-during-covid-19',
  //   component: ImportanceOfEcommerceDuringComponent,
  // },
  // {
  //   path: 'how-to-create-a-successful-ui-design',
  //   component: SuccessfulUIDesignComponent,
  // },
  // {
  //   path: 'why-you-need-a-library-management-system',
  //   component: LibraryManagementSystemComponent,
  // },
  // {
  //   path: 'ecommerce-trends-that-will-take-over-in-2023',
  //   component: ECommerceTrendsComponent,
  // },
  // {
  //   path: 'healthcare-app-development-trends-to-watch-out-for-in-2023',
  //   component: HealthcareAppDevelopmentComponent,
  // },
  // {
  //   path: 'benefits-of-on-demand-delivery-app-for-your-business',
  //   component: BenefitsOfOnDemandComponent,
  // },
  // {
  //   path: 'unseen-risks-in-mobile-app-development-industry',
  //   component: UnseenRisksInMobileAppDevelopmentIndustryComponent,
  // },
  // {
  //   path: 'importance-of-graphic-design-in-marketing',
  //   component: GraphicDesignImportantComponent,
  // },
  // {
  //   path: 'things-you-need-before-redesigning-your-website',
  //   component: RedesigningYourWebsiteComponent,
  // },
  // {
  //   path: 'legal-appointment-scheduling-software-is-a-must-have-tool',
  //   component: WhyLegalAppointmentComponent,
  // },
  // {
  //   path: 'why-your-taxi-business-needs-a-reliable-dispatch-software',
  //   component: YourTaxiBusinessNeedsComponent,
  // },
  // {
  //   path: 'what-is-mongodb-and-why-major-tech-companies-are-adopting-it',
  //   component: MongoDBBlogComponent,
  // },
  // {
  //   path: 'a-guide-to-improving-ecommerce-seo-and-user-experience',
  //   component: ECommerceSEOComponent,
  // },
  // {
  //   path: 'elevate-your-chatgpt-experience-with-these-impactful-prompts',
  //   component: ChatGPTExperienceComponent,
  // },
  // {
  //   path: 'pros-and-cons-of-using-nodejs',
  //   component: NodejsForLargeScaleComponent,
  // },
  // {
  //   path: 'what-is-white-labeling-how-does-it-help-businesses',
  //   component: WhiteLabelingBlogComponent,
  // },
  // {
  //   path: 'how-it-staff-augmentation-can-fuel-your-growth',
  //   component: HowITStaffAugmentationComponent,
  // },
  // {
  //   path: 'strategies-for-effective-website-app-Development',
  //   component: BuildingForSuccessComponent,
  // },
  // {
  //   path: 'creating-an-engaging-ecommerce-website-that-converts',
  //   component: FromClicksToCustomersComponent,
  // },
  // {
  //   path: 'android-apps-best-practices-and-expert-insights',
  //   component: BuildingInnovativeAndroidAppsComponent,
  // },
  // {
  //   path: 'understanding-the-cost-of-e-commerce-website-development',
  //   component: CostOfEcommerceWebsiteDevelopmentComponent,
  // },
  // {
  //   path: 'stay-ahead-of-the-curve-cutting-edge-trends-in-digital-marketing',
  //   component: StayAheadoftheCurveComponent,
  // },
  // { path: 'seo-trends-for-2023', component: SEOTrendsFor2023Component },
  // {
  //   path: 'vuejs-vs-react-a-comprehensive-comparison',
  //   component: VuejsVsReactComponent,
  // },
  // {
  //   path: 'the-importance-of-inclusive-website-design',
  //   component: TheImportanceOfInclusiveWebsiteDesignComponent,
  // },
  // {
  //   path: 'blog-details',
  //   component: BlogDetailsComponent,
  // },

  // redirect urls
  { path: 'contact/beacon.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'Wayfinding-App-for-Enterprises-with-Beacon.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Wayfinding-App-for-Enterprises-with-Beacon.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Real-time-asset-or-resource-tracking-with-beacon.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'technologies.php', redirectTo: '', pathMatch: 'full' },
  { path: 'Medicine-delivery-app.php', redirectTo: '', pathMatch: 'full' },
  { path: 'services/technologies.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'blog/e-learning-mobile-app-development.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Employee-Shift-Scheduling-for-IT-Sector.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'blog/Beacons-and-Digital-Signage–What-the-future-has-in-retail-store.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'services/index.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'blog/Mobile-App-for-Casino-Marketing.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'ticketbazar.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'attract_and_retain_your_retail_store_customers_through_digital_signage.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'what-beacons-can-do-for-your-election-campaign-or-public-political-party-meetings.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'chatbot-development.php', redirectTo: '', pathMatch: 'full' },
  { path: 'healthcare-solutions.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'Proximity-Marketing-Solutions-using-Beacons.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Beacons-and-Digital-Signage–What-the-future-has-in-retail-store.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Medical-Staff-Scheduling-Software-Solution-for-Hospitals-and-Clinics.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Proven-Ways-of-Using-Beacons-to-Redefine-the-Attendee-Experience.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'How_can_Museums_Enhance_the_Visitor_Experience_by_using_Beacons.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Digital-Signage-Software-Solution-for-Corporate-Communication.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Everything-You-Need-to-Know-about-Mobile-Application-Testing.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Why-Fitness-Centre-Should-Adopt-Beacon-Technology.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'How-Much-Does-Digital-Signage-Cost.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'medical-consultation-app.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'Beacons-for-Smart-Education.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'cricketmobileapp-development.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'chatbotforsales.php', redirectTo: '', pathMatch: 'full' },
  { path: 'auto-connect.php', redirectTo: '', pathMatch: 'full' },
  { path: 'gocourt.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'portfolio/mono-color-header/products.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'chatbotforsales.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'Things-to-Plan-for-Before-Developing-Your-Mobile-Application.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'How-Hotels-and-Resorts-Improve-the-Guest-Experience-with-Beacons.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'best-digital-signage-software.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'about/get-quote.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'digital_signage_software_solution_for_public_transportation.php',
    redirectTo: '',
    pathMatch: 'full',
  },

  { path: 'about/development.php', redirectTo: 'about', pathMatch: 'full' },
  { path: 'about/features/', redirectTo: 'about', pathMatch: 'full' },
  { path: 'about.php', redirectTo: 'about', pathMatch: 'full' },
  { path: 'about/about.php', redirectTo: 'about', pathMatch: 'full' },
  { path: 'about/analytics.php', redirectTo: 'about', pathMatch: 'full' },
  { path: 'about/portfolio.php', redirectTo: 'about', pathMatch: 'full' },

  { path: 'services.php', redirectTo: 'services', pathMatch: 'full' },
  { path: 'services/blog.php', redirectTo: 'services', pathMatch: 'full' },
  { path: 'services/about.php', redirectTo: 'services', pathMatch: 'full' },
  {
    path: 'Digital-Signage-Software-for-Advertising-Companies.php',
    redirectTo: 'services',
    pathMatch: 'full',
  },
  {
    path: 'digital_signage_for_salons_and_spas.php',
    redirectTo: 'services',
    pathMatch: 'full',
  },
  { path: 'about/services.php', redirectTo: 'services', pathMatch: 'full' },
  { path: 'about/services.php', redirectTo: 'services', pathMatch: 'full' },

  {
    path: 'products/mobileandweb.php',
    redirectTo: 'products',
    pathMatch: 'full',
  },
  { path: 'services/products.php', redirectTo: 'products', pathMatch: 'full' },

  { path: 'portfolio/page/2/', redirectTo: 'portfolio', pathMatch: 'full' },
  { path: 'caseStudy.php', redirectTo: 'portfolio', pathMatch: 'full' },
  { path: 'portfolio.php', redirectTo: 'portfolio', pathMatch: 'full' },
  { path: 'portfolio/index.php', redirectTo: 'portfolio', pathMatch: 'full' },
  { path: 'portfolio/about.php', redirectTo: 'portfolio', pathMatch: 'full' },
  {
    path: 'portfolio/portfolio.php',
    redirectTo: 'portfolio',
    pathMatch: 'full',
  },
  {
    path: 'portfolio/technologies.php',
    redirectTo: 'portfolio',
    pathMatch: 'full',
  },
  {
    path: 'portfolio/products.php',
    redirectTo: 'portfolio',
    pathMatch: 'full',
  },

  { path: 'about/career.php', redirectTo: 'careers', pathMatch: 'full' },
  { path: 'contact/career.php', redirectTo: 'careers', pathMatch: 'full' },
  { path: 'portfolio/career.php', redirectTo: 'careers', pathMatch: 'full' },
  { path: 'career.php', redirectTo: 'careers', pathMatch: 'full' },
  {
    path: 'contact/technologies.php',
    redirectTo: 'careers',
    pathMatch: 'full',
  },

  { path: 'services/analytics.php', redirectTo: 'services', pathMatch: 'full' },

  {
    path: 'blog/author/geethu-maria-mathew/Things-to-Plan-for-Before-Developing-Your-Mobile-Application.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/Digital-Signage-Software-for-Advertising-Companies.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/Beacons-for-Smart-Education.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/How_can_Museums_Enhance_the_Visitor_Experience_by_using_Beacons.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/Upgrade-Customer-Experience-in-Banking-with-Beacons.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/Digital-Signage-Software-for-Casinos-and-Gaming-Centres.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/beacon.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'Digital-Signage-Solutions-for-Schools-and-Colleges.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/Digital-Signage-Software-Solution-for-Gyms-Fitness-Centre-and-Clubs.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'about/blog.php', redirectTo: 'blog', pathMatch: 'full' },
  { path: 'cityride.php', redirectTo: 'blog', pathMatch: 'full' },
  { path: 'blog/blog.php', redirectTo: 'blog', pathMatch: 'full' },
  {
    path: 'Upgrade-Customer-Experience-in-Banking-with-Beacons.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'blog_mail.php', redirectTo: 'blog', pathMatch: 'full' },
  {
    path: 'blog/How-Hotels-and-Resorts-Improve-the-Guest-Experience-with-Beacons.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/digital_signage_software_solution_for_public_transportation.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'Role-of-mHealth-Apps-in-Healthcare-Evolution.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'Sports-Events-are-Going-the-Beacon-Way-Why.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'blog.php', redirectTo: 'blog', pathMatch: 'full' },
  {
    path: 'All-You-Need-to-Know-about-Internet-of-Things.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/Beacon-Apps-For-Zoos-And-Theme-Parks.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/Mobile-App-Marketing-Plan.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'blog/marketing.php', redirectTo: 'blog', pathMatch: 'full' },
  { path: 'blog/ecommercechatbot.php', redirectTo: 'blog', pathMatch: 'full' },
  {
    path: 'How-Hotels-and-Resorts-Improve-the-Guest-Experience-with-Beacons.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/Beacons-and-Digital-Signage%E2%80%93What-the-future-has-in-retail-store.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/insurancechatbot.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/digital-signage.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/e-learning-mobile-app-development.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/Ondemanddelivery-app-development.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/design_conferences_and_meeting_rooms_efficiently_with_digital_signage.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/How-beacons-make-reatail-store-smarter.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/cricketmobileapp-development.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/Mobile-Apps-for-Libraries-and-Library-Services.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/AppStoreConnectUpdate.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/services.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/Upgrade-Customer-Experience-in-Banking-with-Beacons.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/Employee-Shift-Scheduling-for-IT-Sector.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits_of_digital_signage_in_healthcare_or_hospital.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/Mobile-Apps-for-Hotel-or-Hospitality-Industry.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'Automate-Your-Office-and-Home-with-Beacon-Technology.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'blog/contact.php', redirectTo: 'blog', pathMatch: 'full' },
  {
    path: 'blog/Things-to-Plan-for-Before-Developing-Your-Mobile-Application.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'blog/get-quote.php', redirectTo: 'blog', pathMatch: 'full' },
  {
    path: 'blog/Smart-City-For-a-Connected-World.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/AppStoreConnectUpdate.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'blog/services.php', redirectTo: 'blog', pathMatch: 'full' },
  { path: 'blog/about.php', redirectTo: 'blog', pathMatch: 'full' },
  { path: 'blog/get-quote.php', redirectTo: 'blog', pathMatch: 'full' },
  {
    path: 'blog/benefits-offshore-outsourcing/AppStoreConnectUpdate.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'blog/services.php', redirectTo: 'blog', pathMatch: 'full' },
  {
    path: 'blog/what-beacons-can-do-for-your-election-campaign-or-public-political-party-meetings.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/How-a-Mobile-App-and-IoT-can-Change-the-Insurance-Industry.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/Role-of-mHealth-Apps-in-Healthcare-Evolution.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/All-You-Need-to-Know-about-Internet-of-Things.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/Proximity-Marketing-Solutions-using-Beacons.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'blog/insurancechatbot.php', redirectTo: 'blog', pathMatch: 'full' },
  {
    path: 'blog/benefits-offshore-outsourcing/Upgrade-Customer-Experience-in-Banking-with-Beacons.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/chatbotAi.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/How-a-Mobile-App-and-IoT-can-Change-the-Insurance-Industry.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/digital_signage_for_salons_and_spas.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/about.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },

  { path: 'contact/analytics.php', redirectTo: 'contact', pathMatch: 'full' },
  { path: 'get-quote.php', redirectTo: 'contact', pathMatch: 'full' },
  { path: 'services/get-quote.php', redirectTo: 'contact', pathMatch: 'full' },
  { path: 'contact/blog.php', redirectTo: 'contact', pathMatch: 'full' },
  {
    path: 'products/auto-connect.php',
    redirectTo: 'contact',
    pathMatch: 'full',
  },
  { path: 'analytics.php', redirectTo: 'contact', pathMatch: 'full' },
  { path: 'request-quote.php', redirectTo: 'contact', pathMatch: 'full' },
  { path: 'contact/about.php', redirectTo: 'contact', pathMatch: 'full' },
  {
    path: 'products/domain/get-quote.php',
    redirectTo: 'contact',
    pathMatch: 'full',
  },
  { path: 'contact.php', redirectTo: 'contact', pathMatch: 'full' },
  { path: 'contact/strategy.php', redirectTo: 'contact', pathMatch: 'full' },
  {
    path: 'contact/digital-signage.php',
    redirectTo: 'contact',
    pathMatch: 'full',
  },
  {
    path: 'contact/mobileandweb.php',
    redirectTo: 'contact',
    pathMatch: 'full',
  },
  { path: 'contact/eoffice.php', redirectTo: 'contact', pathMatch: 'full' },

  {
    path: 'progressive-web-app-development.php',
    redirectTo: 'web-app-development',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/get-quote.php',
    redirectTo: 'web-app-development',
    pathMatch: 'full',
  },
  {
    path: 'blog/progressive-web-app-development.php',
    redirectTo: 'web-app-development',
    pathMatch: 'full',
  },
  {
    path: 'mobileandweb.php',
    redirectTo: 'web-app-development',
    pathMatch: 'full',
  },

  {
    path: 'ecommerce-app-development-company.php',
    redirectTo: 'ecommerce-web-solutions',
    pathMatch: 'full',
  },
  {
    path: 'ecommerce-app-development-company.php',
    redirectTo: 'ecommerce-web-solutions',
    pathMatch: 'full',
  },

  {
    path: 'blog/benefits-offshore-outsourcing/White-Label-Apps-for-your-Taxi-or-Limo-Company.php',
    redirectTo: 'taxi-dispatch-software',
    pathMatch: 'full',
  },
  {
    path: 'White-Label-Apps-for-your-Taxi-or-Limo-Company.php',
    redirectTo: 'taxi-dispatch-software',
    pathMatch: 'full',
  },
  {
    path: 'lataxi.php',
    redirectTo: 'taxi-dispatch-software',
    pathMatch: 'full',
  },
  {
    path: 'taxi-business-solutions.php',
    redirectTo: 'taxi-dispatch-software',
    pathMatch: 'full',
  },

  {
    path: 'services/marketing.php',
    redirectTo: 'digital-marketing',
    pathMatch: 'full',
  },
  { path: 'marketing.php', redirectTo: 'digital-marketing', pathMatch: 'full' },
  {
    path: 'products/domain/marketing.php',
    redirectTo: 'digital-marketing',
    pathMatch: 'full',
  },
  {
    path: 'Mobile-App-Marketing-Plan.php',
    redirectTo: 'digital-marketing',
    pathMatch: 'full',
  },

  {
    path: 'blog/cricketmobileapp-development.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },
  {
    path: 'realestatemobileapp.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },
  {
    path: 'development.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },
  {
    path: 'e-learning-mobile-app-development.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },
  {
    path: 'Mobile-Apps-for-Libraries-and-Library-Services.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },
  {
    path: 'why-your-business-needs-mobile-app.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/Mobile-App-Marketing-Plan.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },

  {
    path: 'food-delivery-app.php',
    redirectTo: 'online-food-ordering-system',
    pathMatch: 'full',
  },
  {
    path: 'order-my-food.php',
    redirectTo: 'online-food-ordering-system',
    pathMatch: 'full',
  },

  {
    path: 'blog/Ondemanddelivery-app-development.php',
    redirectTo: 'grocery-app-development',
    pathMatch: 'full',
  },
  {
    path: 'Ondemanddelivery-app-development.php',
    redirectTo: 'grocery-app-development',
    pathMatch: 'full',
  },
  {
    path: 'clickkart.php',
    redirectTo: 'grocery-app-development',
    pathMatch: 'full',
  },
  {
    path: 'grocery-app-development.php',
    redirectTo: 'grocery-app-development',
    pathMatch: 'full',
  },

  {
    path: 'insurancechatbot.php',
    redirectTo: 'benefits-chatbot-for-business',
    pathMatch: 'full',
  },
  {
    path: 'chatbotsforbusiness.php',
    redirectTo: 'benefits-chatbot-for-business',
    pathMatch: 'full',
  },

  {
    path: 'contact/design.php',
    redirectTo: 'branding-services',
    pathMatch: 'full',
  },
  { path: 'design.php', redirectTo: 'branding-services', pathMatch: 'full' },
  {
    path: 'digital-signage.php',
    redirectTo: 'branding-services',
    pathMatch: 'full',
  },

  {
    path: 'staff-augmentation.php',
    redirectTo: 'it-staff-augmentation',
    pathMatch: 'full',
  },
  {
    path: 'products/email/soulmate.php',
    redirectTo: 'matrimony-app-development',
    pathMatch: 'full',
  },
  {
    path: 'products/soulmate.php',
    redirectTo: 'matrimony-app-development',
    pathMatch: 'full',
  },
  {
    path: 'eoffice.php',
    redirectTo: 'payroll-management-system',
    pathMatch: 'full',
  },

  // new
  { path: 'portfolio/strategy.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'blog/Mobile-App-Marketing-Plan.php',
    redirectTo: 'mobile-app-ideas',
    pathMatch: 'full',
  },
  {
    path: 'blog/realestatechatbots.php',
    redirectTo: 'benefits-chatbot-for-business',
    pathMatch: 'full',
  },
  {
    path: 'blog/marketing.php',
    redirectTo: 'digital-marketing',
    pathMatch: 'full',
  },
  {
    path: 'blog/ecommercechatbot.php',
    redirectTo: 'benefits-chatbot-for-business',
    pathMatch: 'full',
  },
  {
    path: 'portfolio/staff-augmentation.php',
    redirectTo: 'it-staff-augmentation',
    pathMatch: 'full',
  },
  {
    path: 'blog/ecommerce-app-development-company.php',
    redirectTo: 'ecommerce-web-solutions',
    pathMatch: 'full',
  },
  {
    path: 'blog/Employee-Shift-Scheduling-for-IT-Sector.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/chatbotsforbusiness.php',
    redirectTo: 'benefits-chatbot-for-business',
    pathMatch: 'full',
  },
  {
    path: 'blog/How-Your-Fitness-Centre-can-Benefit-from-a-Mobile-App.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'products/order-my-food.php',
    redirectTo: 'online-food-ordering-system',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/Digital-Signage-Software-Solutions-for-Banks-and-Financial-Institutions.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/why-your-business-needs-mobile-app.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'products/contact.php', redirectTo: 'products', pathMatch: 'full' },
  { path: 'blog/portfolio.php', redirectTo: 'portfolio', pathMatch: 'full' },
  {
    path: 'blog/author/geethu-maria-mathew/in_store_digital_signage_software_for_supermarkets_grocery_stores.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'blog/development.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'blog/benefits-offshore-outsourcing/Digital-Signage-Software-for-Advertising-Companies.php',
    redirectTo: 'software-development-solutions',
    pathMatch: 'full',
  },
  { path: 'blog/design.php', redirectTo: 'blog', pathMatch: 'full' },
  {
    path: 'blog/benefits-offshore-outsourcing/Beacon-Technology-in-Airports.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/Smart-Library-Not-A-Concept-Anymore.php',
    redirectTo: 'why-you-need-a-library-management-system',
    pathMatch: 'full',
  },
  {
    path: 'blog/chatbotsinbankingindustry.php',
    redirectTo: 'benefits-chatbot-for-business',
    pathMatch: 'full',
  },
  {
    path: 'portfolio/mono-color-header/career.php',
    redirectTo: 'portfolio',
    pathMatch: 'full',
  },
  {
    path: 'blog/grocery-app-development.php',
    redirectTo: 'grocery-app-development',
    pathMatch: 'full',
  },
  { path: 'services/career.php', redirectTo: 'careers', pathMatch: 'full' },
  {
    path: 'blog/realestatemobileapp.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },
  {
    path: 'Meet-techware-solution-at-GITEX-technology-week-2017-dubai.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/Sports-Events-are-Going-the-Beacon-Way-Why.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'staff-augmentation',
    redirectTo: 'it-staff-augmentation',
    pathMatch: 'full',
  },
  {
    path: 'portfolio/mono-color-header/marketing.php',
    redirectTo: 'portfolio',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/index.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/mobileandweb.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'portfolio/mono-color-header/development.php',
    redirectTo: 'portfolio',
    pathMatch: 'full',
  },
  {
    path: 'blog/Everything-You-Need-to-Know-about-Mobile-Application-Testing.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/Connected-Smart-Factory.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/best-digital-signage-software.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'portfolio/mono-color-header/technologies.php',
    redirectTo: 'portfolio',
    pathMatch: 'full',
  },
  { path: 'blog/index.php', redirectTo: 'blog', pathMatch: 'full' },
  {
    path: 'blog/Beacon-Technology-in-Airports.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'Why-Travel-and-Tourism-Industry-Business-Need-to-have-Travel-Mobile-App.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/How-can-beacons-reform-real-estate-industry-marketing.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'Digital-Signage-Software-Solutions-for-Arenas-and-Amusement-Parks.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/fitness-app-development.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/attract_and_retain_your_retail_store_customers_through_digital_signage.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/How-Much-Does-Digital-Signage-Cost.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/All-You-Need-to-Know-about-Internet-of-Things.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'Beacon-Apps-For-Zoos-And-Theme-Parks.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'strategy.php', redirectTo: 'blog', pathMatch: 'full' },
  {
    path: 'Connected-Smart-Factory.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'contact/portfolio.php', redirectTo: 'portfolio', pathMatch: 'full' },
  {
    path: 'Mobile-Apps-for-Hotel-or-Hospitality-Industry.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },
  {
    path: 'blog/in_store_digital_signage_software_for_supermarkets_grocery_stores.php',
    redirectTo: 'grocery-app-developmentt',
    pathMatch: 'full',
  },
  {
    path: 'Smart-City-For-a-Connected-World.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'How-beacons-make-reatail-store-smarter.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'contact/contact.php', redirectTo: 'contact', pathMatch: 'full' },
  {
    path: 'blog/grasp_the_attention_of_the_customers_while_elevator_riding_by_using_digital_signage.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/Digital-Signage-Software-Solution-for-Corporate-Communication.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'How-can-beacons-reform-real-estate-industry-marketing.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'portfolio/get-quote.php', redirectTo: '', pathMatch: 'full' },
  { path: 'portfolio/marketing.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'blog/author/geethu-maria-mathew/digital_signage_software_solution_for_museums_or_gallery.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/ecommercechatbot.php',
    redirectTo: 'benefits-chatbot-for-business',
    pathMatch: 'full',
  },
  {
    path: 'hring-remote-developers-from-india',
    redirectTo: 'it-staff-augmentation',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/eoffice.php',
    redirectTo: 'it-staff-augmentation',
    pathMatch: 'full',
  },
  { path: 'products.php', redirectTo: 'products', pathMatch: 'full' },
  {
    path: 'contact/staff-augmentation.php',
    redirectTo: 'it-staff-augmentation',
    pathMatch: 'full',
  },
  {
    path: 'bookmysalon.php',
    redirectTo: 'beauty-salon-software',
    pathMatch: 'full',
  },
  {
    path: 'Digital-Signage-Software-Solutions-for-Banks-and-Financial-Institutions.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Mobile-App-for-Casino-Marketing.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Why-Fitness-Centre-Should-Adopt-Beacon-Technology.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Revolutionizing-the-Shopping-Experience-in-Shopping-Malls.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/get-quote.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'contact/beacon.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'Wayfinding-App-for-Enterprises-with-Beacon.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Real-time-asset-or-resource-tracking-with-beacon.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/Things-to-Plan-for-Before-Developing-Your-Mobile-Application.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/Digital-Signage-Software-for-Advertising-Companies.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/Beacons-for-Smart-Education.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'technologies.php', redirectTo: 'services', pathMatch: 'full' },
  {
    path: 'blog/author/geethu-maria-mathew/How_can_Museums_Enhance_the_Visitor_Experience_by_using_Beacons.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/author/geethu-maria-mathew/Upgrade-Customer-Experience-in-Banking-with-Beacons.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/White-Label-Apps-for-your-Taxi-or-Limo-Company.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'products/get-quote.php', redirectTo: 'products', pathMatch: 'full' },
  {
    path: 'services/technologies.php',
    redirectTo: 'services',
    pathMatch: 'full',
  },
  {
    path: 'blog/e-learning-mobile-app-development.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'White-Label-Apps-for-your-Taxi-or-Limo-Company.php',
    redirectTo: 'taxi-dispatch-software',
    pathMatch: 'full',
  },
  {
    path: 'products/auto-connect.php',
    redirectTo: 'products',
    pathMatch: 'full',
  },
  {
    path: 'ticketbazar.php',
    redirectTo: 'movie-ticketing-soft',
    pathMatch: 'full',
  },
  { path: 'marketing.php', redirectTo: 'digital-marketing', pathMatch: 'full' },
  {
    path: 'lataxi.php',
    redirectTo: 'taxi-dispatch-softwaree',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/Digital-Signage-Software-for-Casinos-and-Gaming-Centres.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  {
    path: 'blog/benefits-offshore-outsourcing/beacon.php',
    redirectTo: 'blog',
    pathMatch: 'full',
  },
  { path: 'portfolio/index.php', redirectTo: 'portfolio', pathMatch: 'full' },
  {
    path: 'Digital-Signage-Solutions-for-Schools-and-Colleges.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'cityride.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'food-delivery-app.php',
    redirectTo: 'online-food-ordering-system',
    pathMatch: 'full',
  },
  {
    path: 'attract_and_retain_your_retail_store_customers_through_digital_signage.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'what-beacons-can-do-for-your-election-campaign-or-public-political-party-meetings.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'services/about.php', redirectTo: 'services', pathMatch: 'full' },
  { path: 'eoffice.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'products/domain/marketing.php',
    redirectTo: 'digital-marketing',
    pathMatch: 'full',
  },
  {
    path: 'realestatemobileapp.php',
    redirectTo: 'digital-marketing',
    pathMatch: 'full',
  },
  {
    path: 'products/domain/get-quote.php',
    redirectTo: 'products',
    pathMatch: 'full',
  },
  {
    path: 'Digital-Signage-Software-for-Advertising-Companies.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Ondemanddelivery-app-development.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },
  {
    path: 'chatbot-development.php',
    redirectTo: 'mobile-app-development',
    pathMatch: 'full',
  },
  {
    path: 'e-learning-mobile-app-development.php',
    redirectTo: 'benefits-chatbot-for-business',
    pathMatch: 'full',
  },
  {
    path: 'chatbotsforbusiness.php',
    redirectTo: 'benefits-chatbot-for-business',
    pathMatch: 'full',
  },
  {
    path: 'Role-of-mHealth-Apps-in-Healthcare-Evolution.php',
    redirectTo: 'doctor-appointment-app',
    pathMatch: 'full',
  },
  { path: 'clickkart.php', redirectTo: '', pathMatch: 'full' },
  { path: 'digital-signage.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'Beacons-and-Digital-Signage–What-the-future-has-in-retail-store.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'How_can_Museums_Enhance_the_Visitor_Experience_by_using_Beacons.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Digital-Signage-Software-Solution-for-Corporate-Communication.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'Everything-You-Need-to-Know-about-Mobile-Application-Testing.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'How-Much-Does-Digital-Signage-Cost.php',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: 'Mobile-App-Marketing-Plan.php', redirectTo: '', pathMatch: 'full' },
  {
    path: 'medical-consultation-app.php',
    redirectTo: 'doctor-appointment-app',
    pathMatch: 'full',
  },
  {
    path: 'products/bookmydoc.php',
    redirectTo: 'doctor-appointment-app',
    pathMatch: 'full',
  },

  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
