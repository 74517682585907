import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-story-section',
  templateUrl: './story-section.component.html',
  styleUrls: ['./story-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StorySectionComponent {
  @Input() title: string = '';
  @Input() description: string[] = [];
  @Input() test: string = '';
  @Input() buttonText: string = '';
  @Input() imageUrl: string = '';
  @Input() viewMoreContent: string[] = [];

  showMore: boolean = false;
  ViewMoreContent: SafeHtml[] = [];

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.ViewMoreContent = this.viewMoreContent.map(content =>
      this.sanitizer.bypassSecurityTrustHtml(content)
    );
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
    this.buttonText = this.showMore ? "VIEW LESS" : "VIEW MORE";
  }

}
