<section class="header-banner">
    <div class="container">
        <div class="text-content">
            <h1>Reach New Heights With <br> Proven Digital Marketing <br> Strategies <br></h1>  
            <h6>Be visible and sticky in the digital marketplace with fully customized digital marketing <br>strategies that fit your industry and its use cases.</h6>
            <a routerLink="/portfolio" target="_blank">View our latest projects</a>
        </div>
    </div>
</section>
<section class="techware-wrapper">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col-md-5 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/digital-marketing-img-1.png" class="img-fluid" alt="digital-marketing">
            </div>
            <div class="col-md-7">
                <div class="help-box mt-4 mt-md-0">
                    <h1 class="cmn-heading">
                        We’re Techware Lab—An All <br> -in-One Digital Marketing <br> Solution for Your Marketing <br> and Branding Campaigns 
                    </h1>
                    <p>
                        We take your brand story and business goals, and devise a digital marketing plan for ultimate customer engagement and retention. Our vast team will create an innovative and forward-thinking marketing plan to drive user acquisition to your business. Our team will maximize your revenue and make your business more visible to your target customers.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="techware-innovation-wrapper ">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Our Expertise </h1>
            </div>
        </div>
        <div class="row side">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="team-box text-center">
                    <img src="/assets/images/digital-marketing-inn-1.png" class="img-one" alt="Techware-team">
                    <h3>Content Marketing </h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="team-box text-center">
                    <img src="/assets/images/digital-marketing-inn-2.png" class="img-one" alt="Techware-team">
                    <h3>Paid Advertising </h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box text-center">
                    <img src="/assets/images/digital-marketing-inn-3.png" class="img-one" alt="Techware-team">
                    <h3>Search Engine Optimization</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box text-center">
                    <img src="/assets/images/digital-marketing-inn-4.png" class="img-one" alt="Techware-team">
                    <h3>Social Media Marketing </h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box text-center">
                    <img src="/assets/images/digital-marketing-inn-5.png" class="img-one" alt="Techware-team">
                    <h3>Email Marketing </h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box text-center">
                    <img src="/assets/images/digital-marketing-inn-6.png" class="img-one" alt="Techware-team">
                    <h3>PPC</h3>
                </div>
            </div>
        </div>
    </div>
</section> 

<!-- <section class="testimonial-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h1 class="cmn-heading">Who We Work With</h1>
          <p>See other companies who have partnered with Techware Lab for digital marketing services.</p>
        </div>
        <div class="col-md-4"></div>
      </div>
      <div id="demo" class="carousel slide mt-5" data-ride="carousel">
        <!-- The slideshow -->
         <!--<div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-img.png"
                      alt="Profile"
                    />
                  </div>
                  <h3>Donnette Copestake</h3>
                  <h4>IT Director, Wyelands Bank</h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “Techware Lab and I worked together on a number of exciting innovation and R&D projects. Nixon was my lead technical consultant. He is a knowledgeable IT consultant who is highly collaborative in his approach. He is always quick to respond to questions / issues offering solutions to complex problems and often thinks ‘out of the box’. Nixon is intelligent, diligent and thorough in his approach and maintains a sense of humour. He was a highly valued member of my team, often going above and beyond what is expected of him. I would welcome him back to my team and strongly recommend him to future employers.”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img src="/assets/images/AbdulSattar.jpg" alt="Profile" />
                  </div>
                  <h3>AbdulSattar Zahid</h3>
                  <h4>
                    Alibaba Product Growth,<br />
                    Dubai
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “In a nutshell, I consider them to be a member of our team,
                    who just don't sit with us. I have always found the Techware
                    team very compliant. I have never gotten the feeling that
                    getting to a goal is important to me and to them we are just
                    another client, but I have always gotten the feeling that we
                    are one team and I value that over all the disagreements and
                    what not. As a testament to this feedback, what started from
                    one project has transformed our partnership in a very long one
                    and we hope to continue that. ”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-image-3.jpg"
                      alt="Profile"
                    />
                  </div>
                  <h3>Danette Copestake</h3>
                  <h4>
                    IT Director,<br />
                    Wyelands Bank
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    "Techware Lab and I worked together on a number of exciting
                    innovation and R&D projects. Nixon was my lead technical
                    consultant. He is a knowledgeable IT consultant who is highly
                    collaborative in his approach. He is always quick to respond
                    to questions / issues offering solutions to complex problems
                    and often thinks ‘out of the box’. Nixon is intelligent,
                    diligent and thorough in his approach and maintains a sense of
                    humour. He was a highly valued member of my team, often going
                    above and beyond what is expected of him. I would welcome him
                    back to my team and strongly recommend him to future
                    employers."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <a class="carousel-control-prev" href="#demo" data-slide="prev"> </a>
          <a class="carousel-control-next" href="#demo" data-slide="next"> </a>
        </div>
      </div>
    </div>
</section> -->

<section class="brands-wrapper">
    <div class="container">
      <div class="row mt-5 mb-4 mt-md-0">
        <div class="col-md-12 text-center">
          <h1 class="cmn-heading">Who We Work With</h1>
          <h2 class="cmn-sub-heading">
            See other companies who have partnered with Techware Lab for website
            design<br />
            and development services.
          </h2>
        </div>
      </div>
  
      <owl-carousel-o [options]="customOptionsOne">
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-one.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-two.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-three.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-four.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-eigt.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-nine.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-logo-five.png"
              class="img-fluid brand-img-one"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-logo-four.png"
              class="img-fluid brand-img-two"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-three.png"
              class="img-fluid brand-img-three"
              alt="Images"
            />
          </div>
        </ng-template>
  
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-five.png"
              class="img-fluid brand-img-four"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-six.png"
              class="img-fluid brand-img-five"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-sev.png"
              class="img-fluid brand-img-six"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-ten.png"
              class="img-fluid brand-img-seven"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-elv.png"
              class="img-fluid brand-img-eigt"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-tw.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </section>







<section class="design-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Digital Marketing Services </h1>
                <h2 class="cmn-sub-heading ">We offer innovative and trending digital marketing insight to guide organizations of any size to ultimate market success. </h2>
           
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/digital-marketing-img-2.png" class="img-fluid side-img" alt="digital-marketing">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-5 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Search Engine Optimization
                                         </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        Our experienced SEO consultants will take the necessary steps to ensure that your business ranks high on Google with their unique optimization strategy fit for any industry focus. Boost your brand visibility and sales results every time.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Email Marketing
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        From lead generation campaigns to big e-shots, we use the best solutions to create killer campaigns. Our team produces beautifully designed email marketing campaigns built to engage and perform. Send consistent and targeted email campaigns out to your target customers.
                                       </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Paid Advertising
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            PPC (pay-per-click) advertising is fundamental to ensuring your brand reach, coverage, and impact is maximized. Techware Lab boasts many years of combined experience in paid advertising to attract the right customers and deliver maximum ROI for clients.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Internet Branding
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            Our team takes on the seemingly complex process of internet branding, with everything from brand strategy formation to execution to ensure your branding is aligned to attract the right audience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFive">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Press Release Preparation
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            Promote your product across the global market through press release campaigns. Our press release submissions and advertising packages include media planning, banner advertisement and much more.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingSix">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            Proximity Marketing
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            We use various technologies to create proximity marketing zones. This allows us to use users’ location to send custom push notifications and ad messages. Our team ensures you get the data you need to know about your target audience for the best results.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingSeven">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            Mobile App Marketing
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            Our team will guide you through our proven mobile app marketing strategies to broaden your customer reach. If you have an existing app, we will tune the overall user experience, then ensure that it is highly ranked in app stores.
                                        </p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>




<section class="approach-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Approach to Digital Marketing </h1>
                <h2 class="cmn-sub-heading ">We provide a streamlined digital marketing approach across all industries. </h2>
            </div>
        </div>
        <div class="row mb-md-5 mt-md-4">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box blue">
                    <img src="/assets/images/digital-marketing-icon-1.png" alt="digital-marketing Icon">
                    <h3> Competitive Analysis and Evaluation  </h3>
                    <p>A deep analysis of your competitors in the market is the first step. This will help you discover the holes in your processes and plan how to go above and beyond for your customers moving forward.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="approach-box red">
                    <img src="/assets/images/digital-marketing-icon-2.png" alt="digital-marketing Icon">
                    <h3>Digital Marketing Plan Development </h3>
                    <p>Next, we help you develop a killer online marketing strategy in light of your competitive analysis and organizational evaluation—one that speaks to your customers’ needs and draws them in for good— all with the intent to maximize your engagement and ROI.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box yellow">
                    <img src="/assets/images/digital-marketing-icon-3.png" alt="digital-marketing Icon">
                    <h3>Launch and Execute </h3>
                    <p>Our digital marketing service professionals will equip you with modern tools and market knowledge to ensure a confident campaign launch for your organization’s product or service debut, significantly improving your online presence and impact.</p>
                </div>
            </div>
        </div>
    </div>
</section>






<section class="mobile-app-wrapper">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Our Preferred Digital Marketing Resources</h1> 
            </div>
        </div>
        <div class="row side">
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/digital-marketing-icon-one-1.png" class="img-fluid img-ind-one" alt="Images">
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/digital-marketing-icon-one-2.png" class="img-fluid img-ind-two" alt="Images">  
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/digital-marketing-icon-one-3.png" class="img-fluid img-ind-three" alt="Images">  
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/digital-marketing-icon-one-4.png" class="img-fluid img-ind-four" alt="Images"> 
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/digital-marketing-icon-one-5.png" class="img-fluid img-ind-five" alt="Images"> 
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/digital-marketing-icon-one-6.png" class="img-fluid img-ind-six" alt="Images">
                </div>
            </div>
        </div>
    </div>
</section>





<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Establish Your Presence Online With a Winning <br> Digital Strategy</h1>
                <h5 class="cmn-sub-heading">Our team of digital marketing professionals will have your online engagement growing in no time. We offer results-driven digital marketing services that leverage the latest digital technologies and proven strategies. <br>
                Are you ready to take your digital marketing to the next level? Get in touch with our team today.
                </h5>
                <button routerLink="/contact">Get a quote now</button>
            </div>
        </div>
    </div>
</section>








