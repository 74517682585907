<section class="blog-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1>
          Explore what it takes to move<br />
          from ideation to delivery
        </h1>
        <h2 class="cmn-sub-heading">
          At Techware Lab, we create tailored digital experiences for clients
          across industries. Take a look at our blog to learn more.
        </h2>
      </div>
    </div>
  </div>
</section>

<section class="blog-content-wrapper">
  <div class="container">
    <div
      class="row"
      infinite-scroll
      [infiniteScrollDistance]="distance"
      [infiniteScrollThrottle]="throttle"
      (scrolled)="onScroll()"
    >
      <div class="col-md-8">
        <!-- Loop through blogList -->
        <div *ngFor="let blog of blogList" class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img
                [src]="blog?.thumbnail"
                class="img-fluid blog-image"
                alt="blog image"
              />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <!-- <h1 [routerLink]="blog.routerLink">{{ blog.title }}</h1> -->
                  <h1
                    [innerHTML]="blog?.title"
                    [routerLink]="'/' + blog.slug"
                  ></h1>
                  <p [innerHTML]="blog?.metaDescription"></p>
                </div>
                <div class="bottom">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <h3>{{ blog?.createdAt | date : "MMMM y" }}</h3>
                    </div>
                  </div>
                  <!-- <button [routerLink]="blog.routerLink"> -->
                  <button>
                    <img
                      src="/assets/images/right-arrow.png"
                      [routerLink]="'/' + blog.slug"
                      alt="Arrow Right"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End of loop -->

        <!-- <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/the-importance-of-inclusive-website-design--thumbnail.jpg"
                class="img-fluid blog-image"
                alt="Responsive and Accessible: The Importance of Inclusive Website Design" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/the-importance-of-inclusive-website-design">
                    Responsive and Accessible: The Importance of Inclusive
                    Website Design
                  </h1>
                  <p>
                    In this blog, we'll uncover the magic behind inclusive
                    website design and why it's not just a nice-to-have but an
                    absolute necessity.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>September 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/the-importance-of-inclusive-website-design">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/vue.js-vs-react-thumbnail.jpg" class="img-fluid blog-image"
                alt="Vue.js vs React: A Comprehensive Comparison" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/vuejs-vs-react-a-comprehensive-comparison">
                    Vue.js vs React: A Comprehensive Comparison
                  </h1>
                  <p>
                    Ultimately, the decision between Vue.js and React depends on
                    your project's complexity, team expertise, and long-term
                    goals.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>August 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/vuejs-vs-react-a-comprehensive-comparison">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/seo-trends-for-2023-thumbnail.jpg" class="img-fluid blog-image"
                alt="SEO Trends for 2023: What to Expect and How to Adapt" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/seo-trends-for-2023">
                    SEO Trends for 2023: What to Expect and How to Adapt?
                  </h1>
                  <p>
                    From the rise of voice search to the changing algorithms,
                    grab your digital compass and join us on this adventure to
                    discover the key SEO trends in 2023.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>August 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/seo-trends-for-2023">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/thumbnail-stay-ahead-of-the-curve-cutting-edge-trends-in-digital-marketing.jpg"
                class="img-fluid blog-image" alt="stay ahead of the curve cutting edge trends in digital marketing" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/stay-ahead-of-the-curve-cutting-edge-trends-in-digital-marketing">
                    Stay Ahead of the Curve: Cutting-Edge Trends in Digital
                    Marketing
                  </h1>
                  <p>
                    Understanding and leveraging these trends can supercharge
                    your digital marketing strategy, engage with your audience,
                    and drive business growth.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>August 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/stay-ahead-of-the-curve-cutting-edge-trends-in-digital-marketing">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/thumbnail-understanding-the-cost-of-e-commerce-website-development.jpg"
                class="img-fluid blog-image" alt="understanding the cost of e commerce website development" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/understanding-the-cost-of-e-commerce-website-development">
                    Understanding the Cost of E-commerce Website Development
                  </h1>
                  <p>
                    Developing an e-commerce website involves various components
                    contributing to its cost. Understanding these factors is
                    essential for planning your budget.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>August 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/understanding-the-cost-of-e-commerce-website-development">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/android-apps-best-practices-and-expert-insights-thumbail.jpg"
                class="img-fluid blog-image"
                alt="Building Innovative Android Apps: Best Practices and Expert Insights" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/android-apps-best-practices-and-expert-insights">
                    Building Innovative Android Apps: Best Practices and Expert
                    Insights
                  </h1>
                  <p>
                    Building innovative Android apps requires user-centric
                    design, performance optimization, security measures,
                    seamless feature integration, & continuous testing.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>July 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/android-apps-best-practices-and-expert-insights">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-building-for-success-thmbnail.jpg" class="img-fluid blog-image"
                alt="Strategies for Effective Website and App Development?" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/strategies-for-effective-website-app-Development">
                    Strategies for Effective Website and App Development
                  </h1>
                  <p>
                    By understanding your target audience, and implementing
                    practical design principles, you can create a digital
                    platform that engages users and drives success.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>July 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/strategies-for-effective-website-app-Development">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-eCommerce-thmbnail.jpg" class="img-fluid blog-image"
                alt="Creating an Engaging eCommerce Website that Converts" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/creating-an-engaging-ecommerce-website-that-converts">
                    Creating an Engaging eCommerce Website that Converts
                  </h1>
                  <p>
                    Creating an engaging eCommerce website that converts
                    visitors into customers is a continuous process.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>July 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/creating-an-engaging-ecommerce-website-that-converts">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="blog-box">
            <div class="row">
              <div class="col-md-4">
                <img src="/assets/images/it-staff-augmentation-fuel-your-growth-thumb.webp" class="img-fluid blog-image"
                  alt="How IT Staff Augmentation Can Fuel Your Growth" />
              </div>
              <div class="col-md-8">
                <div class="box-content">
                  <div class="head">
                    <h1 routerLink="/how-it-staff-augmentation-can-fuel-your-growth">
                      Scaling Up with Confidence: How IT Staff Augmentation Can
                      Fuel Your Growth
                    </h1>
                    <p>
                      Are you ready to take your business to new heights? In
                      today's rapidly evolving digital world, scaling up your
                      operations is crucial for staying competitive.
                    </p>
                  </div>
                  <div class="bottom">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h3>June 2023</h3>
                      </div>
                    </div>
                    <button routerLink="/how-it-staff-augmentation-can-fuel-your-growth">
                      <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="blog-box">
            <div class="row">
              <div class="col-md-4">
                <img src="/assets/images/Whitelabeling-thumbnail-blog.webp" class="img-fluid blog-image"
                  alt="What is White labeling and How does it help businesses?" />
              </div>
              <div class="col-md-8">
                <div class="box-content">
                  <div class="head">
                    <h1 routerLink="/what-is-white-labeling-how-does-it-help-businesses">
                      What is White labeling and How does it help businesses?
                    </h1>
                    <p>
                      Companies in today's changing business world are
                      continuously looking for new methods to acquire a
                      competitive advantage. White labeling is one such method
                      that has gained traction in recent years.
                    </p>
                  </div>
                  <div class="bottom">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h3>June 2023</h3>
                      </div>
                    </div>
                    <button routerLink="/what-is-white-labeling-how-does-it-help-businesses">
                      <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="blog-box">
            <div class="row">
              <div class="col-md-4">
                <img src="/assets/images/nodejs-for-large-scale-thumbnail.webp" class="img-fluid blog-image"
                  alt="The Pros and Cons of Using Node.js for Large-Scale Web Applications" />
              </div>
              <div class="col-md-8">
                <div class="box-content">
                  <div class="head">
                    <h1 routerLink="/pros-and-cons-of-using-nodejs">
                      The Pros and Cons of Using Node.js for Large-Scale Web
                      Applications
                    </h1>
                    <p>
                      Node.js has experienced significant growth in web
                      development in recent years, capturing the development
                      world's attention. Its emergence has been nothing short of
                      remarkable, and there are numerous factors contributing to
                      its popularity....
                    </p>
                  </div>
                  <div class="bottom">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h3>June 2023</h3>
                      </div>
                    </div>
                    <button routerLink="/pros-and-cons-of-using-nodejs">
                      <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="blog-box">
            <div class="row">
              <div class="col-md-4">
                <img src="/assets/images/chat-gpt-prompts-thumbnail.webp" class="img-fluid blog-image"
                  alt="Elevate Your ChatGPT Experience With These Impactful Prompts" />
              </div>
              <div class="col-md-8">
                <div class="box-content">
                  <div class="head">
                    <h1 routerLink="/elevate-your-chatgpt-experience-with-these-impactful-prompts">
                      Elevate Your ChatGPT Experience With These Impactful
                      Prompts
                    </h1>
                    <p>
                      AI tools, such as ChatGPT, have brought the power of
                      artificial intelligence to the general public. Today, we
                      can receive a range of responses on any topic, ranging
                      from...
                    </p>
                  </div>
                  <div class="bottom">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h3>June 2023</h3>
                      </div>
                    </div>
                    <button routerLink="/elevate-your-chatgpt-experience-with-these-impactful-prompts">
                      <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="blog-box">
            <div class="row">
              <div class="col-md-4">
                <img src="/assets/images/ecommerce-seo-Blog-thumbnail.webp" class="img-fluid blog-image" alt="A Guide To Improving eCommerce SEO And User Experience
                                    " />
              </div>
              <div class="col-md-8">
                <div class="box-content">
                  <div class="head">
                    <h1 routerLink="/a-guide-to-improving-ecommerce-seo-and-user-experience">
                      A Guide To Improving eCommerce SEO And User Experience
                    </h1>
                    <p>
                      Do you have an eCommerce website? If yes, it is vital to
                      ensure that it's running smoothly. While a
                      well-functioning website is great, it's essential to
                      acknowledge that two...
                    </p>
                  </div>
                  <div class="bottom">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h3>May 2023</h3>
                      </div>
                    </div>
                    <button routerLink="/a-guide-to-improving-ecommerce-seo-and-user-experience">
                      <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="blog-box">
            <div class="row">
              <div class="col-md-4">
                <img src="/assets/images/MongoDB-Blog-Icon.webp" class="img-fluid blog-image" alt="What exactly is MongoDB and why major tech companies are adopting it?
                                    " />
              </div>
              <div class="col-md-8">
                <div class="box-content">
                  <div class="head">
                    <h1 routerLink="/what-is-mongodb-and-why-major-tech-companies-are-adopting-it">
                      What exactly is MongoDB and why major tech companies are
                      adopting it?
                    </h1>
                    <p>
                      In today's world, data plays a crucial role in both our
                      personal and professional lives. To use the potential of
                      this data, it's essential to have well-organized and
                      easily accessible...
                    </p>
                  </div>
                  <div class="bottom">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h3>May 2023</h3>
                      </div>
                    </div>
                    <button routerLink="/what-is-mongodb-and-why-major-tech-companies-are-adopting-it">
                      <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/taxi-dispatch-software-blog-thumbnail.webp" class="img-fluid blog-image"
                alt="taxi-dispatch" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/why-your-taxi-business-needs-a-reliable-dispatch-software">
                    Why Your Taxi Business Needs a Reliable Dispatch Software
                  </h1>
                  <p>
                    What is crucial for every owner of a taxi business? It's
                    offering top-notch taxi services to customers. Every
                    customer who uses your service expects a safe and secure
                    ride to their desired destination.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>May 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/why-your-taxi-business-needs-a-reliable-dispatch-software">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/legal-scheduling-software-blogIcon.webp" class="img-fluid blog-image"
                alt="redesigning-BlogIcon" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/legal-appointment-scheduling-software-is-a-must-have-tool">
                    Why Legal Appointment Scheduling Software is a Must-Have
                    Tool for Law Firms
                  </h1>
                  <p>
                    Time is highly valued in the legal industry, and leading law
                    firms are constantly exploring ways to boost the
                    productivity and efficiency of their teams by optimizing ...
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>April 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/legal-appointment-scheduling-software-is-a-must-have-tool">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/redesigning-BlogIcon.jpg" class="img-fluid blog-image"
                alt="redesigning-BlogIcon" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/things-you-need-before-redesigning-your-website">
                    Things You Need Before Redesigning Your Website
                  </h1>
                  <p>
                    Your website is the first impression your business makes on
                    potential customers. By regularly updating and adding new
                    content, products...
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>April 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/things-you-need-before-redesigning-your-website">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/GraphicDesignImp-BlogIcon.svg" class="img-fluid blog-image"
                alt="GraphicDesignImp-BlogIcon" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/importance-of-graphic-design-in-marketing">
                    What Makes Graphic Design Important in Marketing?
                  </h1>
                  <p>
                    If you think about some of the most renowned brands
                    globally, such as Apple, Nike, and Coca-Cola, merely seeing
                    their names on a screen can trigger the..
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>March 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/importance-of-graphic-design-in-marketing">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/UnseenRisksIcon.svg" class="img-fluid blog-image" alt="UnseenRisksIcon" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/unseen-risks-in-mobile-app-development-industry">
                    8 Unseen Risks in Mobile App Development Industry
                  </h1>
                  <p>
                    In today's world, developing an app and having things done
                    through it has become a pretty regular practice. There is an
                    app for practically anything you can imagine.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>February 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/unseen-risks-in-mobile-app-development-industry">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/on-demand-delivery-blogIcon.jpg" class="img-fluid blog-image" alt="on-demand" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/benefits-of-on-demand-delivery-app-for-your-business">
                    What does an on-demand delivery app offer to your business?
                  </h1>
                  <p>
                    Can you imagine a world without technological advancements?
                    The rapid pace of change in the world is evident as
                    technology continues to advance and enhance our
                    lifestyles...
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>January 2023</h3>
                    </div>
                  </div>
                  <button routerLink="/benefits-of-on-demand-delivery-app-for-your-business">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/Healthcare-blog1-icon.jpg" class="img-fluid blog-image" alt="Healthcare" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/healthcare-app-development-trends-to-watch-out-for-in-2023">
                    Healthcare App Development Trends To Watch Out for in 2023
                  </h1>
                  <p>
                    The widespread use of smartphones in today's digital age has
                    made them a go-to solution for addressing many everyday
                    challenges. The healthcare industry is no exception,
                    particularly...
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>December 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/healthcare-app-development-trends-to-watch-out-for-in-2023">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/eCommerce-blog-img1.jpg" class="img-fluid blog-image" alt="ECommerce" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/ecommerce-trends-that-will-take-over-in-2023">
                    eCommerce Trends That Will Take Over in 2023 and Beyond
                  </h1>
                  <p>
                    Rapid growth in the e-commerce market has been observed,
                    resulting in a better customer experience, strong
                    competition, and stimulating growth. As a result, it is
                    crucial for firms to pay more attention...
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>November 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/ecommerce-trends-that-will-take-over-in-2023">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-23.jpg" class="img-fluid blog-image" alt="Mobile App Ideas" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/why-you-need-a-library-management-system">
                    10 Reasons Why You Need A Library Management System
                  </h1>
                  <p>
                    Without completely comprehensive libraries, academic
                    institutions are incomplete. It is packed to the brim with
                    educational and recreational things. As a result, these
                    overflowing materials imply masses of inventory
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>October 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/why-you-need-a-library-management-system">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-21-1.png" class="img-fluid blog-image" alt="Mobile App Ideas" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/how-to-create-a-successful-ui-design">
                    How to Create a Successful UI Design?
                  </h1>
                  <p>
                    Did you know that 88% of viewers will not return to an app
                    or website after just one negative experience? This shows
                    the significance of UI design, which extends well beyond
                    aesthetics and has a vital influence on user experience.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>September 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/how-to-create-a-successful-ui-design">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-4.png" class="img-fluid blog-image" alt="Mobile App Ideas" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/mobile-app-ideas">
                    10 Best Mobile App Ideas to Consider in 2022
                  </h1>
                  <p>
                    With the swift advancement of technology in today's
                    digitalized era, smartphones have become an indispensable
                    component of human lives. Even though the primary function
                    of smartphones is telecommunication, mobile applications
                    have...
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>August 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/mobile-app-ideas">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-19-icon.jpg" class="img-fluid blog-image"
                alt="importance-of-ecommerce" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/importance-of-ecommerce-during-covid-19">
                    The Importance Of E-Commerce During Covid-19
                  </h1>
                  <p>
                    The internet is a global market, and E-commerce is an
                    integral part of it that makes buying and selling on the
                    world wide web possible.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>July 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/importance-of-ecommerce-during-covid-19">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-18-icon.png" class="img-fluid blog-image"
                alt="Firebase for mobile app" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/benefits-of-using-firebase-for-mobile-app-development">
                    Key benefits of using Firebase for mobile app development
                  </h1>
                  <p>
                    Firebase is a Backend-as-a-Service (BaaS) platform that
                    provides developers with a wide range of tools and services
                    to help them build high-quality apps faster. It is a cloud
                    computing service paradigm that allows web and mobile app
                    developers ....
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>June 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/benefits-of-using-firebase-for-mobile-app-development">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-17-icon.png" class="img-fluid blog-image"
                alt="Ecommerce app development" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/ecommerce-app-development-cost-2022">
                    Ecommerce App Development Cost in 2022
                  </h1>
                  <p>
                    Mobile app industry is thriving. It has grown so ingrained
                    in our lives that the necessity for an e-commerce
                    application is more imminent than ever before. Further with
                    mobile apps, ordering online products and making secure
                    payments via different....
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>June 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/ecommerce-app-development-cost-2022">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-16-icon.jpg" class="img-fluid blog-image" alt="Mobile App Security" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/how-to-ensure-mobile-app-security">
                    Mobile App Security: How to ensure the safety of those using
                    your App
                  </h1>
                  <p>
                    Security has always been a major concern for companies, as
                    it should. Mobile app security isn't a bonus or a perk; it's
                    a necessity. Even a single data breach might lose your
                    business a lifetime of trust. As a result, security should
                    be a top consideration from ...
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>May 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/how-to-ensure-mobile-app-security">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-15-icon.jpg" class="img-fluid blog-image"
                alt="Mobile App UI/UX Design" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/ui-ux-design-trends-2022">
                    What are Mobile App UI/UX Design Trends in 2022?
                  </h1>
                  <p>
                    As new technologies are emerging, and new design approaches
                    are surfacing, mobile user interface design trends change
                    all the time. Thus, following new design trends is a
                    necessary for companies who wish to keep up with the times.
                    Obviously, ...
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>May 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/ui-ux-design-trends-2022">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-14-icon.jpg" class="img-fluid blog-image" alt="Free Apps Make Money" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/how-do-free-apps-make-money">
                    How Do Free Apps Make Money in 2022?
                  </h1>
                  <p>
                    Mobile apps are essential for any business, not only for
                    smooth business operations and functionality but rather as a
                    separate revenue generation channel. The free app market has
                    always outnumbered the paid app market, but have you ever
                    ...
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>April 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/how-do-free-apps-make-money">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-13-icon.jpg" class="img-fluid blog-image"
                alt="Top Mobile App Development" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/best-mobile-app-development-frameworks">
                    Top Mobile App Development Frameworks for App Developers
                  </h1>
                  <p>
                    Mobile apps are now an essential part of our daily lives
                    where we have everything at our fingertips. We simply find
                    the appropriate apps and meet our desired outcome, whether
                    it is to book tickets, ride, or order food on-demand.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>March 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/best-mobile-app-development-frameworks">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-12-icon.jpg" class="img-fluid blog-image" alt="Food Delivery App" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/food-delivery-app-like-uber-eats">
                    How to Build a Food Delivery App Like Uber Eats?
                  </h1>
                  <p>
                    Building a food delivery app like Uber Eats is a difficult
                    endeavour that requires the integration of several
                    components. To create a mark for yourself in the online food
                    sector, you'll need a clear plan for developing your food
                    delivery app. Using Uber Eats as an ...
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>February 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/food-delivery-app-like-uber-eats">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-11.png" class="img-fluid blog-image" alt="Partner for startup" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/benefits-of-it-staff-augmentation-services">
                    5 Benefits of IT Staff Augmentation Services
                  </h1>
                  <p>
                    Using staff augmentation services is an excellent solution
                    for companies looking to reduce operational costs while
                    increasing productivity and efficiency. Before implementing
                    these services, it is critical to understand what it means,
                    in order to be successful ...
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>January 2022</h3>
                    </div>
                  </div>
                  <button routerLink="/benefits-of-it-staff-augmentation-services">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-10.png" class="img-fluid blog-image" alt="Partner for startup" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/why-flutter-for-mobile-app-development">
                    Why Choose Flutter For Your Mobile App Development?
                  </h1>
                  <p>
                    Mobile app development companies, for the longest time, have
                    had to support multiple platforms in their production
                    cycles. To reach a broad audience, you’d have to develop an
                    iOS version as well as an Android app.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>December 2021</h3>
                    </div>
                  </div>
                  <button routerLink="/why-flutter-for-mobile-app-development">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-9.png" class="img-fluid blog-image" alt="Partner for startup" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/hiring-remote-developers-from-india">
                    Why Hire Remote Software Developers From India?
                  </h1>
                  <p>
                    There’s no denying how important software development is
                    today. After all, developers play an essential role in
                    creating the popular technology we use in our everyday
                    lives.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>November 2021</h3>
                    </div>
                  </div>
                  <button routerLink="/hiring-remote-developers-from-india">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-8.png" class="img-fluid blog-image" alt="Partner for startup" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/finding-best-partner-for-startup">
                    Why Are We the Best Partner for Your Startup?
                  </h1>
                  <p>
                    If you are looking to build a new startup company you need
                    to select a good software development company. Read on, to
                    know Why Are We the Best Partner for Your Startup?
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>August 2021</h3>
                    </div>
                  </div>
                  <button routerLink="/finding-best-partner-for-startup">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-6.png" class="img-fluid blog-image" alt="Mobile App Development" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/things-to-plan-mobile-app-development">
                    10 Things to Plan for Before Developing Your Mobile
                    Application
                  </h1>
                  <p>
                    Poor planning is the reason for the failure of any mobile
                    application. Here are 10 things to consider before
                    developing a mobile application.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>July 2021</h3>
                    </div>
                  </div>
                  <button routerLink="/things-to-plan-mobile-app-development">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-2.png" class="img-fluid blog-image" alt="Delivery App Development" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/how-much-cost-develop-ondemand-delivery-app">
                    How Much Does It Cost to Develop an On-Demand Delivery App?
                  </h1>
                  <p>
                    On-demand delivery mobile applications have transformed the
                    way we shop, making it easier than ever to discover new
                    restaurants, stores, and more.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>June 2021</h3>
                    </div>
                  </div>
                  <button routerLink="/how-much-cost-develop-ondemand-delivery-app">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-3.png" class="img-fluid blog-image" alt=" Chatbots For Business" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/benefits-chatbot-for-business">
                    Chatbots for Business: 4 Benefits You Need to Know
                  </h1>
                  <p>
                    Keeping your customers informed and satisfied is one of the
                    most important aspects of running a business. It’s key to
                    any company, but how can business owners optimize their own
                    customer service?
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>June 2021</h3>
                    </div>
                  </div>
                  <button routerLink="/benefits-chatbot-for-business">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-1.png" class="img-fluid blog-image"
                alt="Why Techware software solutions  rebranded to Techware Lab" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/we-have-rebranded">
                    Techware Lab: Why (and how) we rebranded
                  </h1>
                  <p>
                    We are proud to share that Techware Software Solution is now
                    Techware Lab. Apart from a new name, we have a new brand
                    identity as well. While this is a significant change, our
                    core...
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>April 2021</h3>
                    </div>
                  </div>
                  <button routerLink="/we-have-rebranded">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-box">
          <div class="row">
            <div class="col-md-4">
              <img src="/assets/images/blog-img-5.png" class="img-fluid blog-image" alt="Chatbots for Business" />
            </div>
            <div class="col-md-8">
              <div class="box-content">
                <div class="head">
                  <h1 routerLink="/why-business-needs-chatbot">
                    Top 6 reasons why your business needs a chatbot
                  </h1>
                  <p>
                    Nowadays, the world is rapidly drifting towards intelligent
                    business solutions that can make important decisions or
                    perform essential tasks to assist various businesses.
                  </p>
                </div>
                <div class="bottom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h3>March 2021</h3>
                    </div>
                  </div>
                  <button routerLink="/why-business-needs-chatbot">
                    <img src="/assets/images/right-arrow.png" alt="Arrow Right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!--  -->
      </div>
      <div class="col-md-4">
        <div class="right-set">
          <div class="form-set">
            <div class="subscribe-set">
              <h1>Get our latest updates and tips right in your inbox.</h1>
              <p>Subscribe to our newsletter</p>
              <form [formGroup]="subscribeForm" (ngSubmit)="subscribeBox()">
                <div class="form-group">
                  <input
                    type="email"
                    formControlName="email"
                    placeholder="Your email address"
                    class="form-control"
                  />
                </div>
                <div
                  class="s_error"
                  *ngIf="
                    !subscribeForm.controls['email'].valid &&
                    (subscribeForm.controls['email'].dirty ||
                      subscribeForm.controls['email'].touched ||
                      subscribeFormSubmit)
                  "
                >
                  <div
                    class="s_validation"
                    *ngIf="subscribeForm.controls['email'].hasError('required')"
                  >
                    Provide an Email
                  </div>
                  <div
                    class="s_validation"
                    *ngIf="subscribeForm.controls['email'].hasError('pattern')"
                  >
                    Provide a Valid Email Id
                  </div>
                </div>
                <button class="submit">Subscribe</button>
              </form>
            </div>
          </div>
          <div class="rest-set">
            <div class="recent-post-set">
              <h1>Popular Posts</h1>
              <!--  -->
              <div *ngFor="let blog of blogList">
                <div class="post-box" routerLink="/why-business-needs-chatbot">
                  <img
                    [src]="blog?.thumbnail"
                    class="img-fluid blog-image"
                    [alt]="blog.altTag"
                  />
                  <!-- <img src="/assets/images/blog-img-5.png" alt="Chatbots for Business" /> -->
                  <div>
                    <h2
                      [innerHTML]="blog?.title"
                      [routerLink]="'/' + blog.slug"
                    ></h2>
                    <h3>{{ blog?.createdAt | date : "MMMM y" }}</h3>
                  </div>
                </div>
              </div>
              <!--  -->
              <!-- <div class="post-box" routerLink="/why-business-needs-chatbot">
                <img src="/assets/images/blog-img-5.png" alt="Chatbots for Business" />
                <div>
                  <h2>Top 6 reasons why your business needs a chatbot</h2>
                  <h3>March 2021</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/we-have-rebranded">
                <img src="/assets/images/blog-img-1.png"
                  alt="Why Techware software solutions  rebranded to Techware Lab" />
                <div>
                  <h2>Techware Lab: Why (and how) we rebranded</h2>
                  <h3>April 2021</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/benefits-chatbot-for-business">
                <img src="/assets/images/blog-img-3.png" alt="" />
                <div>
                  <h2>Chatbots for Business: 4 Benefits You Need to Know</h2>
                  <h3>June 2021</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/how-much-cost-develop-ondemand-delivery-app">
                <img src="/assets/images/blog-img-2.png" alt="Delivery App Development" />
                <div>
                  <h2>
                    How Much Does It Cost to Develop an On-Demand Delivery App?
                  </h2>
                  <h3>June 2021</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/things-to-plan-mobile-app-development">
                <img src="/assets/images/blog-img-6.png" alt="Mobile App Development" />
                <div>
                  <h2>
                    10 Things to Plan for Before Developing Your Mobile
                    Application
                  </h2>
                  <h3>July 2021</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/finding-best-partner-for-startup">
                <img src="/assets/images/blog-img-8.png" alt="Partner for startup" />
                <div>
                  <h2>Why Are We the Best Partner for Your Startup?</h2>
                  <h3>August 2021</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/hiring-remote-developers-from-india">
                <img src="/assets/images/blog-img-9.png" alt="" />
                <div>
                  <h2>Why Hire Remote Software Developers From India?</h2>
                  <h3>November 2021</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/why-flutter-for-mobile-app-development">
                <img src="/assets/images/blog-img-10.png" alt="Delivery App Development" />
                <div>
                  <h2>Why Choose Flutter For Your Mobile App Development?</h2>
                  <h3>December 2021</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/benefits-of-it-staff-augmentation-services">
                <img src="/assets/images/blog-img-11.png" alt="Delivery App Development" />
                <div>
                  <h2>5 Benefits of IT Staff Augmentation Services</h2>
                  <h3>January 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/food-delivery-app-like-uber-eats">
                <img src="/assets/images/blog-img-12.jpg" alt="food-delivery-app" />
                <div>
                  <h2>How to Build a Food Delivery App Like Uber Eats?</h2>
                  <h3>February 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/best-mobile-app-development-frameworks">
                <img src="/assets/images/blog-img-13.jpg" alt="top-mobile-app-development" />
                <div>
                  <h2>
                    Top Mobile App Development Frameworks for App Developers
                  </h2>
                  <h3>March 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/how-do-free-apps-make-money">
                <img src="/assets/images/blog-img-14.jpg" alt="Free Apps Make Money" />
                <div>
                  <h2>How Do Free Apps Make Money in 2022?</h2>
                  <h3>April 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/ui-ux-design-trends-2022">
                <img src="/assets/images/blog-img-15.jpg" alt="Mobile App UI/UX Design" />
                <div>
                  <h2>What are Mobile App UI/UX Design Trends in 2022?</h2>
                  <h3>May 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/how-to-ensure-mobile-app-security">
                <img src="/assets/images/blog-img-16.jpg" alt="Mobile App Security" />
                <div>
                  <h2>
                    Mobile App Security: How to ensure the safety of those using
                    your App
                  </h2>
                  <h3>May 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/ecommerce-app-development-cost-2022">
                <img src="/assets/images/blog-img-17.png" alt="Ecommerce app development" />
                <div>
                  <h2>Ecommerce App Development Cost in 2022</h2>
                  <h3>June 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/benefits-of-using-firebase-for-mobile-app-development">
                <img src="/assets/images/blog-img-18.png" alt="Firebase for mobile app" />
                <div>
                  <h2>
                    Key benefits of using Firebase for mobile app development
                  </h2>
                  <h3>June 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/importance-of-ecommerce-during-covid-19">
                <img src="/assets/images/blog-img-19-icon.jpg" alt="importance-of-ecommerce" />
                <div>
                  <h2>The Importance Of E-Commerce During Covid-19</h2>
                  <h3>July 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/mobile-app-ideas">
                <img src="/assets/images/blog-img-4.png" alt="Mobile App Ideas" />
                <div>
                  <h2>10 Best Mobile App Ideas to Consider in 2022</h2>
                  <h3>August 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/how-to-create-a-successful-ui-design">
                <img src="/assets/images/blog-img-21-1.png" alt="Mobile App Ideas" />
                <div>
                  <h2>How to Create a Successful UI Design?</h2>
                  <h3>September 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/why-you-need-a-library-management-system">
                <img src="/assets/images/blog-img-23.jpg" alt="Mobile App Ideas" />
                <div>
                  <h2>10 Reasons Why You Need A Library Management System</h2>
                  <h3>October 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/ecommerce-trends-that-will-take-over-in-2023">
                <img src="/assets/images/eCommerce-blog-img1.jpg" alt="Mobile App Ideas" />
                <div>
                  <h2>
                    eCommerce Trends That Will Take Over in 2023 and Beyond
                  </h2>
                  <h3>November 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/healthcare-app-development-trends-to-watch-out-for-in-2023">
                <img src="/assets/images/Healthcare-blog1-icon.jpg" alt="Mobile App Ideas" />
                <div>
                  <h2>
                    Healthcare App Development Trends To Watch Out for in 2023
                  </h2>
                  <h3>December 2022</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/benefits-of-on-demand-delivery-app-for-your-business">
                <img src="/assets/images/on-demand-delivery-blogIcon.jpg" alt="Mobile App Ideas" />
                <div>
                  <h2>
                    What does an on-demand delivery app offer to your business?
                  </h2>
                  <h3>January 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/unseen-risks-in-mobile-app-development-industry">
                <img src="/assets/images/UnseenRisksIcon.svg" alt="UnseenRisksIcon" />
                <div>
                  <h2>8 Unseen Risks in Mobile App Development Industry</h2>
                  <h3>February 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/importance-of-graphic-design-in-marketing">
                <img src="/assets/images/GraphicDesignImp-BlogIcon.svg" alt="GraphicDesignImp" />
                <div>
                  <h2>What Makes Graphic Design Important in Marketing?</h2>
                  <h3>March 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/things-you-need-before-redesigning-your-website">
                <img src="/assets/images/redesigning-BlogIcon.jpg" alt="redesigning-BlogIcon" />
                <div>
                  <h2>What Makes Graphic Design Important in Marketing?</h2>
                  <h3>April 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/legal-appointment-scheduling-software-is-a-must-have-tool">
                <img src="/assets/images/legal-scheduling-software-blogIcon.webp"
                  alt="legal-scheduling-software-blogIcon" />
                <div>
                  <h2>
                    Why Legal Appointment Scheduling Software is a Must-Have
                    Tool for Law Firms
                  </h2>
                  <h3>April 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/why-your-taxi-business-needs-a-reliable-dispatch-software">
                <img src="/assets/images/taxi-dispatch-software-blog-thumbnail.webp"
                  alt="legal-scheduling-software-blogIcon" />
                <div>
                  <h2>
                    Why Your Taxi Business Needs a Reliable Dispatch Software
                  </h2>
                  <h3>May 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/what-is-mongodb-and-why-major-tech-companies-are-adopting-it">
                <img src="/assets/images/MongoDB-Blog-Icon.webp" alt="legal-scheduling-software-blogIcon" />
                <div>
                  <h2>
                    What exactly is MongoDB and why major tech companies are
                    adopting it?
                  </h2>
                  <h3>May 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/a-guide-to-improving-ecommerce-seo-and-user-experience">
                <img src="/assets/images/ecommerce-seo-Blog-thumbnail.webp" alt="legal-scheduling-software-blogIcon" />
                <div>
                  <h2>
                    A Guide To Improving eCommerce SEO And User Experience
                  </h2>
                  <h3>May 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/elevate-your-chatgpt-experience-with-these-impactful-prompts">
                <img src="/assets/images/chat-gpt-prompts-thumbnail.webp" alt="legal-scheduling-software-blogIcon" />
                <div>
                  <h2>
                    Elevate Your ChatGPT Experience With These Impactful Prompts
                  </h2>
                  <h3>June 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/pros-and-cons-of-using-nodejs">
                <img src="/assets/images/nodejs-for-large-scale-thumbnail.webp"
                  alt="legal-scheduling-software-blogIcon" />
                <div>
                  <h2>
                    The Pros and Cons of Using Node.js for Large-Scale Web
                    Applications
                  </h2>
                  <h3>June 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/what-is-white-labeling-how-does-it-help-businesses">
                <img src="/assets/images/Whitelabeling-thumbnail-blog.webp" alt="legal-scheduling-software-blogIcon" />
                <div>
                  <h2>
                    What is White labeling and How does it help businesses?
                  </h2>
                  <h3>June 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/how-it-staff-augmentation-can-fuel-your-growth">
                <img src="/assets/images/it-staff-augmentation-fuel-your-growth-thumb.webp"
                  alt="legal-scheduling-software-blogIcon" />
                <div>
                  <h2>
                    Scaling Up with Confidence: How IT Staff Augmentation Can
                    Fuel Your Growth
                  </h2>
                  <h3>June 2023</h3>
                </div>
              </div>
              
              <div class="post-box" routerLink="/strategies-for-effective-website-app-Development">
                <img src="/assets/images/blog-building-for-success-thmbnail.jpg"
                  alt="legal-scheduling-software-blogIcon" />
                <div>
                  <h2>
                    Building for Success: Strategies for Effective Website and
                    App Development
                  </h2>
                  <h3>July 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/creating-an-engaging-ecommerce-website-that-converts">
                <img src="/assets/images/blog-eCommerce-thmbnail.jpg" alt="legal-scheduling-software-blogIcon" />
                <div>
                  <h2>
                    From Clicks to Customers: Creating an Engaging eCommerce
                    Website that Converts
                  </h2>
                  <h3>July 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/android-apps-best-practices-and-expert-insights">
                <img src="/assets/images/android-apps-best-practices-and-expert-insights-thumbail.jpg"
                  alt="legal-scheduling-software-blogIcon" />
                <div>
                  <h2>
                    Building Innovative Android Apps: Best Practices and Expert
                    Insights
                  </h2>
                  <h3>July 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/understanding-the-cost-of-e-commerce-website-development">
                <img src="/assets/images/thumbnail-understanding-the-cost-of-e-commerce-website-development.jpg" />
                <div>
                  <h2>
                    Understanding the Cost of E-commerce Website Development
                  </h2>
                  <h3>August 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/stay-ahead-of-the-curve-cutting-edge-trends-in-digital-marketing">
                <img src="/assets/images/thumbnail-stay-ahead-of-the-curve-cutting-edge-trends-in-digital-marketing.jpg"
                  alt="stay ahead of the curve cutting edge trends in digital marketing" />
                <div>
                  <h2>
                    Stay Ahead of the Curve: Cutting-Edge Trends in Digital
                    Marketing
                  </h2>
                  <h3>August 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/seo-trends-for-2023">
                <img src="/assets/images/seo-trends-for-2023-thumbnail.jpg"
                  alt="SEO Trends for 2023: What to Expect and How to Adapt " />
                <div>
                  <h2>SEO Trends for 2023: What to Expect and How to Adapt?</h2>
                  <h3>August 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/vuejs-vs-react-a-comprehensive-comparison">
                <img src="/assets/images/vue.js-vs-react-thumbnail.jpg"
                  alt="Vue.js vs React: A Comprehensive Comparison" />
                <div>
                  <h2>Vue.js vs React: A Comprehensive Comparison</h2>
                  <h3>August 2023</h3>
                </div>
              </div>
              <div class="post-box" routerLink="/the-importance-of-inclusive-website-design">
                <img src="/assets/images/the-importance-of-inclusive-website-design--thumbnail.jpg"
                  alt="Responsive and Accessible: The Importance of Inclusive Website Design" />
                <div>
                  <h2>
                    Responsive and Accessible: The Importance of Inclusive
                    Website Design
                  </h2>
                  <h3>September 2023</h3>
                </div>
              </div> -->
            </div>

            <!-- <div class="categories-set">
                            <h1> Categories</h1>
                            <ul>
                                <li>Business</li>
                                <li>Design</li>
                                <li>Design</li>
                                <li>Graphics and Multimedia</li>
                                <li>Inbound Marketing</li>
                                <li>Java Development</li>
                                <li>Mobile Technology</li>
                                <li>Need For Website</li>
                            </ul>
                        </div>
                        <div class="tags-set">
                            <h1>Tags</h1>
                            <ul>
                                <li>Business Promotion</li>
                                <li>Card Layouts</li>
                                <li>Custom web Designing</li>
                                <li>Custom Web development</li>
                                <li>Flat Designs</li>
                                <li>Global Outsourcing Services</li>
                                <li>Hamburger Menus</li>
                                <li>Hero images</li>
                                <li>Large and small-scale animations</li>
                                <li>Light Fidelity</li>
                                <li>Long scroll Markup Scheme</li>
                                <li>Material Design</li>
                                <li>Mobile Technology</li>
                                <li>Need Website</li>
                                <li>Offshore Graphics Design</li>
                            </ul>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
