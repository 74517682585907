import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EducationPopupComponent } from '../education-popup/education-popup.component';
declare var $: any;
declare const window: any;

@Component({
  selector: 'app-education-software-development',
  templateUrl: 'education-software-development.component.html',
  styleUrls: ['./education-software-development.component.scss'],
})
export class EducationSoftwareDevelopmentComponent implements OnInit {
  constructor(private model: MatDialog) {}

  ngOnInit() {}
  @HostListener('window:scroll', [])
  onWindowScroll() {
    $(window).scroll(function () {
      const sticky = $('.gitex-navbar'),
        scroll = $(window).scrollTop();

      if (scroll > 10) sticky.addClass('scrolled_navbar');
      else sticky.removeClass('scrolled_navbar');
    });

    window.addEventListener('load', function () {
      const sticky = $('.gitex-navbar'),
        scroll = $(window).scrollTop();

      if (scroll > 10) sticky.addClass('scrolled_navbar');
      else sticky.removeClass('scrolled_navbar');
    });
  }
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  OpenPopup() {
    this.model.open(EducationPopupComponent);
  }
}
