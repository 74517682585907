import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-chat-gptexperience',
  templateUrl: './chat-gptexperience.component.html',
  styleUrls: ['./chat-gptexperience.component.scss'],
})
export class ChatGPTExperienceComponent implements OnInit {
  public fbLink: string = environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;
  constructor(
    private title: Title,
    private meta: Meta,
    private seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      'Elevate Your ChatGPT Experience With These Impactful Prompts'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'By using customized prompts, you can direct ChatGPT to generate responses with a particular structure and emphasis.',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'By using customized prompts, you can direct ChatGPT to generate responses with a particular structure and emphasis..',
    });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://techwarelab.com/assets/images/chat-gpt-prompts.webp',
    });
    this.meta.updateTag({
      property: 'og:title',
      content: 'Elevate Your ChatGPT Experience With These Impactful Prompts',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'By using customized prompts, you can direct ChatGPT to generate responses with a particular structure and emphasis..',
    });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://techwarelab.com/elevate-your-chatgpt-experience-with-these-impactful-prompts',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://techwarelab.com/assets/images/chat-gpt-prompts.webp',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Elevate Your ChatGPT Experience With These Impactful Prompts',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'By using customized prompts, you can direct ChatGPT to generate responses with a particular structure and emphasis..',
    });
    this.meta.updateTag({
      name: 'twitter:domain',
      content: 'Elevate Your ChatGPT Experience With These Impactful Prompts',
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `elevate-your-chatgpt-experience-with-these-impactful-prompts`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u', `https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }
}
