<section class="uiuxSectionWrapper">


    <div class="heroSection">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="heroContent">
              <div>
                <h1>Social Media Optimization</h1>
                <h5>Connect, Engage, Grow.</h5>
                <p>
                  Our Social Media Optimization (SMO) service are engineered to amplify your online prsence and engage with your audience on a deeper level.
                </p>
                <button (click)="requestQuote()">Get Started</button>
              </div>
  
              <div class="pinkBgDiv"></div>
              <div class="yellowBgDiv"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="heroImgWrapper">
              <img src="../../../assets/images/smoHeroImage.png" />
            </div>
            <div class="heroImageBgWrapper">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M48.4,-55.6C62.1,-46.3,71.9,-30.4,74.1,-13.7C76.3,3,70.9,20.4,62.6,37.4C54.3,54.3,43,70.8,28,75.8C13,80.8,-5.8,74.2,-21.1,65.4C-36.4,56.6,-48.1,45.6,-58.3,31.8C-68.4,18,-77.1,1.4,-74.7,-13.4C-72.3,-28.2,-59,-41.3,-44.7,-50.5C-30.5,-59.7,-15.2,-64.9,1.1,-66.2C17.4,-67.5,34.8,-64.9,48.4,-55.6Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
            <div class="heroImageWrapper2">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#E6FBFF"
                  d="M65.2,-21.4C72.9,2.3,59.4,32.7,36.8,48.7C14.2,64.6,-17.4,66,-40.1,50.6C-62.8,35.2,-76.5,2.9,-68.4,-21.6C-60.2,-46.1,-30.1,-62.8,-0.7,-62.5C28.8,-62.3,57.6,-45.2,65.2,-21.4Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="featureSection">
        <div class="blueBgDiv"></div>
        <div class="pinkBgDiv"></div>
        <div class="yellowBgDiv"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <img src="../../../assets/images/smoFeatureImage.png" />
            </div>
            <div class="col-lg-6">
              <h2>Why Choose Techware Lab for Social Media Optimization?</h2>
              <div>
                <h6>Customized Social Strategies</h6>
                <p>
                  We don’t believe in one-size-fits-all. Our social media experts craft personalized strategies that align with your brand’s identity, goals, and audience demographics.
                </p>
              </div>
              <div>
                <h6>Data-Driven Engagement</h6>
                <p>
                  Leveraging the latest tools and analytics, we monitor, analyze, and respond to social media trends and user behavior, transforming data into actionable growth strategies.
                </p>
              </div>
              <div>
                <h6>Content That Captivates</h6>
                <p>
                  From eye-catching graphics to compelling narratives, our content is designed to capture attention, provoke thought, and encourage shares and interactions.
                </p>
              </div>
              <div>
                <h6>Platform-Specific Optimization</h6>
                <p>
                  We optimize your content for each social media platform, ensuring your message hits home, whether on Facebook, Twitter, LinkedIn, Instagram, or emerging networks.
                </p>
              </div>
              <div>
                <h6>Comprehensive Reporting</h6>
                <p>
                  Receive detailed insights into your SMO campaign’s performance with our regular and comprehensible reports that help you track engagement and ROI.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


    <div class="serviceSection">
      <div class="container">
        <h2>Our SMO Service</h2>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../../assets/images/smoProfileImage.png" />
            <h3>Profile Optimization</h3>
            <p>
              to ensure your social media profiles are 100% complete and reflect your brand’s personality.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/smoContentImage.png" />
            <h3>Content Planning and Posting</h3>
            <p>
              that keeps your audience engaged and informed.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/smoCommunityImage.png" />
            <h3>Community Management</h3>
            <p>
              that builds and nurtures your online community, turning followers into brand advocates.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <img src="../../../assets/images/smoAdvertisingImage.png" />
            <h3>Social Media Advertising</h3>
            <p>
              to extend your reach and pinpoint your target demographic with precision.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/smoBrandImage.png" />
            <h3>Brand Reputation Management</h3>
            <p>
              to maintain a positive and professional image of your brand in the digital space.
            </p>
          </div>
          <div class="col-lg-4">
            <img src="../../../assets/images/smoAnalyticsImage.png" />
            <h3>Analytics and Adjustments</h3>
            <p>
              where we continually refine our strategy based on campaign data.
            </p>
          </div>
        </div>
      </div>
    </div>
    
    <div class="quoteSection">
      <div class="container">
        <div class="row">
            <div class="col-md-6">
              <img src="../../../assets/images/smoQuoteImage.png" />
            </div>
            <div class="col-md-6">
              <div class="quoteContent">
               <div>
                  <p>Real Results,</p>
                 <p>Real Engagement</p>
               </div>
               <p>Techware Lab's approach to SMO is not just about growing numbers</p>
               <p>- it's about fostering genuine connections with your audience that lead to lasting relationships and tangible business outcomes</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  
    <div class="ctaWrapper">
      <div class="container">
        <h1>Elevate Your Social Media Presence</h1>
        <p>
          Are you ready to elevate your social media game? Partner with Techware Lab and let us harness the power of social media to create a robust, dynamic online presence for your brand. Contact us today and take the first step towards social media excellence.
        </p>
  
        <button routerLink="/contact">
          Book A Free Consultation
          </button>
      </div>
    </div>
  </section>
  