<app-navbar></app-navbar>
<div *ngIf="isLoading | async" class="loading-indicator">
  <mat-progress-spinner mode="indeterminate" [diameter]="50" [strokeWidth]="5"></mat-progress-spinner>
</div>
<div class="main-sec">
  <router-outlet (activate)="onActivate($event)"> </router-outlet>
  <div *ngIf="shouldDisplayIcons()" class="floating-sec">
    <ul>
      <li><a href="https://web.whatsapp.com/send?phone=9195678 79002"><img class="green-social" src="/assets/images/watsap-icon.png" alt="Float Icon" /></a></li>
      <li ><a href="https://t.me/Techwarelab"><img class="blue-social" src="/assets/images/telegram-icon.png" alt="Float Icon" /></a></li>
      <li ><a href="skype:live:.cid.cd03bb8fb0a28f98?chat"><img class="skype-social" src="/assets/images/skype-icon-blue.png" alt="Float Icon" /></a></li>
      <li ><a href="mailto:neeta@techwarelab.com"><img class="red-social" src="/assets/images/mail-icon.png" alt="Float Icon" /></a></li>
      <li ><a href="tel:+91 9567879002"><img class="purple-social" src="/assets/images/call-icon-new.png" alt="Float Icon" /></a></li>
    </ul>
  </div>
</div>

<app-footer></app-footer>
