import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import * as AOS from 'aos';
import { Subject } from 'rxjs';
import { SpinnerService } from './shared/spinner.service';

/* declare var $: any;*/

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLoading: Subject<boolean>;
  title = 'techware';

  constructor(@Inject(PLATFORM_ID) private platform: Object, private metaTagService: Meta, private spinnerService: SpinnerService,private router:Router) {
    this.isLoading = this.spinnerService.visibility;
  }
  onActivate(event) {
    if (isPlatformBrowser(this.platform)) {
      window.scroll(0, 0);
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      AOS.init();
    }
    this.shouldDisplayIcons();
  }

  shouldDisplayIcons(): any{

    const url = this.router.url;
    return (
      !url.includes('/custom-app-development')
    )

  }
}
