import { Component, OnInit } from '@angular/core';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-marketing-automation',
  templateUrl: './marketing-automation.component.html',
  styleUrls: ['./marketing-automation.component.scss'],

  
  
})
export class MarketingAutomationComponent implements OnInit {

  
  constructor(private model: MatDialog) { 
   
  }

  ngOnInit(): void {
    
  }
  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }

}
