import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Meta, Title } from "@angular/platform-browser";;
import { SeoService } from '../seo.service';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-ios-development',
  templateUrl: './ios-development.component.html',
  styleUrls: ['./ios-development.component.scss']
})
export class IosDevelopmentComponent implements OnInit {

  constructor(private model: MatDialog,private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "IOS Mobile Application Development Company| TechwareLab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "We provide the best iOS app development solutions that work flawlessly on the platforms and devices of your choice, based on your specific requirements.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "We provide the best iOS app development solutions that work flawlessly on the platforms and devices of your choice, based on your specific requirements.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/ser-2.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "IOS Mobile Application Development Company| TechwareLab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "We provide the best iOS app development solutions that work flawlessly on the platforms and devices of your choice, based on your specific requirements.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/ios-development",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/ser-2.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "IOS Mobile Application Development Company| TechwareLab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "We provide the best iOS app development solutions that work flawlessly on the platforms and devices of your choice, based on your specific requirements.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "IOS Mobile Application Development Company| TechwareLab",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }
  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

  customOptionsOne: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

}
