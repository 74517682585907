<section class="header-banner">
  <div class="container">
    <div class="text-content">
      <h1>IOS App Development</h1>
      <h5>iOS App Development for Agile Businesses</h5>
      <h6>
        Work with an iOS development company that can take your app<br> from concept to launch with a seamless user experience, full-stack<br> support, and game changing features for all iOS devices.

      </h6>
      <button (click)="requestQuote()" target="_blank">Get estimation for your project</button>
    </div>
  </div>
</section>


<section class="techware-wrapper">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col-md-5 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/design-mon.webp" class="img-fluid" alt="Cutting edge technologies">
            </div>
            <div class="col-md-7">
                <div class="help-box mt-4 mt-md-0">
                    <h1 class="cmn-heading">
                        Meet Techware Lab — a Fast, <br>
                        Affordable and Efficient Way <br>
                        to Manage Your iOS App <br>
                        Development Needs</h1>
                    <p>
                        Our team of front-end, back-end, and full-stack developers work diligently to champion the next generation of high-performing iOS application development services. </p>
                        <p>We support businesses with ongoing app development and support at every stage of the process. From initial concepts to new features, our team does it all.</p>
               
                </div>
            </div>
        </div>
    </div>
</section>


<section class="brands-wrapper">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Who We Work With </h1>
                <h2 class="cmn-sub-heading">See other companies who have partnered with Techware Lab for website design<br/> and development services.</h2>
            </div>
        </div>

        <owl-carousel-o  [options]="customOptionsOne">
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-one.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-two.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-three.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-four.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-eigt.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-nine.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-logo-five.png" class="img-fluid brand-img-one" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-logo-four.png" class="img-fluid brand-img-two" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-three.png" class="img-fluid brand-img-three" alt="Images">
                    
                </div>
            </ng-template>
       
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-five.png" class="img-fluid brand-img-four" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-six.png" class="img-fluid brand-img-five" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/logo-sev.png" class="img-fluid brand-img-six" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-ten.png" class="img-fluid brand-img-seven" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-elv.png" class="img-fluid brand-img-eigt" alt="Images">
                    
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="brand-box">
                    <img src="/assets/images/brand-tw.png" class="img-fluid" alt="Images">
                    
                </div>
            </ng-template>
           
        </owl-carousel-o>
    </div>
</section>


<section class="design-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our iOS Development Services</h1>
                <h2 class="cmn-sub-heading ">Find out why other companies love partnering with Techware Lab when it comes to iOS app development.</h2>
           
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/ios-development-vector.svg" class="img-fluid side-img" alt="Mobile app development company">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-5 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Seamless iOS app development experience
                                         </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        We help you implement immersive mobile user interface and user experience design for all iOS devices. Create innovative and familiar iOS web and mobile solutions that meet the unique needs of your business and customers. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Front-end, back-end, and full-stack support                                      </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>Our multidisciplinary and flexible team of front-end, back-end, and full-stack developers provide scalable iOS solutions at any and every stage of your mobile app development project.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            iOS software performance testing
                                                                                </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>We prioritize digital cyber security and top performance in the iOS development process. Our AI automated testing process will ensure that your application is free from hiccups and impossible for hackers to intrude.</p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<section class="approach-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Our Approach</h1>
                <h2 class="cmn-sub-heading">We offer assistance at every stage of your mobile app development to ensure <br/>you have support from start to finish.</h2>
            </div>
        </div>
        <div class="row mb-md-5 mt-md-4">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box blue">
                    <img src="/assets/images/ios-approach-1.svg" alt="Service Icon">
                    <h3>Establishing a blueprint
                    </h3>
                    <p>We start off by building various wireframes for your app project that cater both to your user needs and business objectives. With the help of clients, mockups are shortlisted and a final prototype is determine</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="approach-box red">
                    <img src="/assets/images/ios-approach-2.svg" alt="Service Icon">
                    <h3>App development
                    </h3>
                    <p>The prototype comes alive in this stage. Our developers spend extensive time configuring the code, interface design, and internal hardware for the app. Then, the software programming is debugged several times before source code integration.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box yellow">
                    <img src="/assets/images/ios-approach-3.svg" alt="Service Icon">
                    <h3>User Testing, Go-to-Market, & 
                        Ongoing Support 
                    </h3>
                    <p>In the final stage, the app is internally tested for bugs and glitches. Once approved, its final iteration is presented to clients, all while our team provides ongoing technical support and reiteration opportunities. Once it’s go-to-market ready, we help you launch it on the App Store. </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="device-wrapper">
    <div class="container">
      <h1 class="cmn-heading">
        Adaptable Software Development for iOS Devices
      </h1>
      <h2 class="cmn-sub-heading">
        Our team develops the best browser-based experience compatible for all iOS devices, including iPads, iPhones, Apple TVs, and Apple Watches.

      </h2>
      <div class="row mt-5">
        <div class="col-md-3 text-center mb-5">
          <img
            src="/assets/images/ios-device-1.png"
            class="img-fluid"
            alt="Device"
          />
          <h3>iPads</h3>
        </div>
        <div class="col-md-3 text-center mb-5">
          <img
            src="/assets/images/ios-device-2.png"
            class="img-fluid"
            alt="Device"
          />
          <h3>iPhones</h3>
        </div>
        <div class="col-md-3 text-center mb-5">
          <img
            src="/assets/images/ios-device-3.png"
            class="img-fluid"
            alt="Device"
          />
          <h3>Apple TVs</h3>
        </div>
        <div class="col-md-3 text-center mb-5">
          <img
            src="/assets/images/ios-device-4.png"
            class="img-fluid"
            alt="Device"
          />
          <h3>Apple Watches</h3>
        </div>
      </div>
    </div>
  </section>
  
  <section class="cases-wrapper">
    <div class="container">
      <h1 class="cmn-heading">iOS App Development Use Cases</h1>
      <h2 class="cmn-sub-heading">Using our custom iOS app development services opens the door to new opportunities for your business with access to all of Apple’s ecosystem capabilities.</h2>
      <div class="row mt-5">
        <div class="col-md-4 text-center mb-5">
          <img src="/assets/images/cases-vector-1.svg" class="img-fluid" alt="Icon">
          <h3>No more silos with seamless <br>
            software integrations</h3>
        </div>
        <div class="col-md-4 text-center mb-5">
          <img src="/assets/images/cases-vector-2.svg" class="img-fluid" alt="Icon">
          <h3>Become a leader of innovation <br>
            in your market</h3>
        </div>
        <div class="col-md-4 text-center mb-5">
          <img src="/assets/images/cases-vector-3.svg" class="img-fluid" alt="Icon">
          <h3>Experience massive <br>
            revenue generation</h3>
        </div>
      </div>
    </div>
  </section>

<section class="mobile-app-wrapper">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-left">
                <h1 class="cmn-heading">Discover iOS Apps that Host Top Industry Verticals</h1>
                <h2 class="cmn-sub-heading">Our iOS app library is equipped with the most popular App Store features.</h2>
            </div>
        </div>
        <div class="row side">
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-one.png" class="img-fluid img-ind-one" alt="Images">
                    <p>Public Sector</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-two.png" class="img-fluid img-ind-two" alt="Images">
                    <p>Healthcare</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-three.png" class="img-fluid img-ind-three" alt="Images">
                    <p>Enterprise</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-four.png" class="img-fluid img-ind-four" alt="Images">
                    <p>Banking & Finance </p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-five.png" class="img-fluid img-ind-five" alt="Images">
                    <p>Hospitality </p>
                </div>
            </div>
            <div class="col-md-2 ">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-six.png" class="img-fluid img-ind-six" alt="Images">
                    <p>Retail & Ecommerce </p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-sev.png" class="img-fluid img-ind-sev" alt="Images">
                    <p>Travel & Tourism</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-eigt.png" class="img-fluid img-ind-eigt" alt="Images">
                    <p>Food & Beverage</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-nine.png" class="img-fluid img-ind-nine" alt="Images">
                    <p>Transport & Logistic</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-ten.png" class="img-fluid img-ind-ten" alt="Images">
                    <p>On-Demand Delivery</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-elv.png" class="img-fluid img-ind-elv" alt="Images">
                    <p>Social &  Communication</p>
                </div>
            </div>
            <div class="col-md-2 ">
                <div class="team-box text-center">
                    <img src="/assets/images/ind-tw.png" class="img-fluid img-ind-tw" alt="Images">
                    <p>Education & Elearning</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technologies-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Technology Stack</h1>
                <h2 class="cmn-sub-heading">Our team of diverse stakeholders are skilled to manage the following technologies that support iOS software.</h2>
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/ios-technology.png" class="img-fluid" alt="Mobile app development company">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-5 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Programming languages
                                         </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>Swift</li>
                                            <li>Objective C</li>
                                            <li>C#</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Technologies
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>SwiftUI</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Database
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>SQLite</li>
                                            <li>Realm</li>
                                            <li>Core Data</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            IDE
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>Xcode</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFive">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Libraries
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>Alamofire</li>
                                            <li>AFNetworking</li>
                                            <li>SDWebImage</li>
                                            <li>RxSwift</li>
                                            <li>Realm</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingSix">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            Architecture
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul>
                                            <li>MVC</li>
                                            <li>MVVM</li>
                                            <li>Clean architecture</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
</section>

<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Build Your Next iOS App With Our Proven Team</h1>
                <h2 class="cmn-sub-heading">Using iOS software development will keep your company ahead in the digital world—it’s familiar, intuitive, and offers a simple integration process. Our expert developers are equipped to take you through our three-step go-to-market approach, so you can position your enterprise for success.
                </h2>
                <button routerLink="/contact"> Let's get started</button>
            </div>
        </div>
    </div>
</section>
