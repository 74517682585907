import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { Meta, Title } from "@angular/platform-browser";;
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-web-analytics',
  templateUrl: './web-analytics.component.html',
  styleUrls: ['./web-analytics.component.scss']
})
export class WebAnalyticsComponent implements OnInit {

  customOptionsOne: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  constructor(private model: MatDialog ,private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Web Analytics Services | TechwareLab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Web analytics services will help turn insights into ROI for companies. We offer marketing, strategic, and operational guidance to curate successful analytic diagnostics for your website and digital marketing processes across UAE, USA & India.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Web analytics services will help turn insights into ROI for companies. We offer marketing, strategic, and operational guidance to curate successful analytic diagnostics for your website and digital marketing processes across UAE, USA & India.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/ser-2.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Web Analytics Services | TechwareLab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Web analytics services will help turn insights into ROI for companies. We offer marketing, strategic, and operational guidance to curate successful analytic diagnostics for your website and digital marketing processes across UAE, USA & India.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/web-analytics",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/ser-2.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Web Analytics Services | TechwareLab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Web analytics services will help turn insights into ROI for companies. We offer marketing, strategic, and operational guidance to curate successful analytic diagnostics for your website and digital marketing processes across UAE, USA & India.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Web Analytics Services | TechwareLab",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  requestQuote() {
    this.model.open(RequestQuoteComponent);
  }

}
