import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-e-commerce-seo',
  templateUrl: './e-commerce-seo.component.html',
  styleUrls: ['./e-commerce-seo.component.scss'],
})
export class ECommerceSEOComponent implements OnInit {
  public fbLink: string = environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;
  constructor(
    private title: Title,
    private meta: Meta,
    private seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      'A Guide To Improving eCommerce SEO And User Experience'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'Looking to enhance your eCommerce SEO and user experience? Implementing specific strategies is crucial to improve your site ranking and visibility',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Looking to enhance your eCommerce SEO and user experience? Implementing specific strategies is crucial to improve your site ranking and visibility.',
    });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://techwarelab.com/assets/images/ecommerce-seo-Blog.webp',
    });
    this.meta.updateTag({
      property: 'og:title',
      content: 'A Guide To Improving eCommerce SEO And User Experience',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Looking to enhance your eCommerce SEO and user experience? Implementing specific strategies is crucial to improve your site ranking and visibility.',
    });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://techwarelab.com/a-guide-to-improving-ecommerce-seo-and-user-experience',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://techwarelab.com/assets/images/ecommerce-seo-Blog.webp',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'A Guide To Improving eCommerce SEO And User Experience',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Looking to enhance your eCommerce SEO and user experience? Implementing specific strategies is crucial to improve your site ranking and visibility.',
    });
    this.meta.updateTag({
      name: 'twitter:domain',
      content: 'A Guide To Improving eCommerce SEO And User Experience',
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `a-guide-to-improving-ecommerce-seo-and-user-experience`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u', `https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }
}
