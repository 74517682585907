<section class="career-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="circle-wrapper">
                    <h1>TTaxi: Online Taxi <br>
                        Booking Software
                    </h1>
                    <h2 class="cmn-sub-heading">Expect a smooth ride with our feature-rich taxi booking software. Give your clients the most reliable taxi service the Internet can offer</h2>
                    <a (click)="requestQuote()" target="_blank">Request a demo</a>
                </div>
            </div>
            <div class="col-md-5">
                <img src="/assets/images/ttaxi-img-1.png" class="img-fluid" alt="ttaxi">
            </div>
        </div>
    </div>
</section>

<section class="whatistaxi-wrapper">
    <div class="container">
        <div class="row pb-4">
            <div class="col-md-5" data-aos="fade-right">
                <img src="/assets/images/ttaxi-img-2.png" class="img-fluid" alt="ttaxi">
            </div>
            <div class="col-md-7">
                <div class="content">
                    <h1 class="cmn-heading">What Is TTaxi?</h1>
                    <p>Set up your new taxi firm, private-hire, or airport transfer business with the tools and expertise that will put you at the top. TTaxi provides you with all the design capabilities and intuitive taxi booking solutions to make your taxi business an online success.</p>
                    <p>
                        Whether you’re looking for taxi booking software or a custom white label taxi booking app, TTaxi can help streamline your business.
                    </p>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="taxi-box">
                    <img src="/assets/images/ttaxi-icon-1.png" class="img-fluid" alt="TTaxi">
                    <h2>Steer Your Service in the <br>
                        Right Direction</h2>
                    <p>TTaxi gives you full control over every aspect of your taxi business, whether it’s managing users, drivers, taxis, and even promo codes. Handle payments with ease through multiple payment options, including PayPal integration and even a built-in wallet.
                    </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="taxi-box">
                    <img src="/assets/images/ttaxi-icon-2.png" class="img-fluid" alt="TTaxi">
                    <h2>Drive Your Clients <br>
                        Anywhere</h2>
                    <p>Mobile apps are no longer a nice-to-have—they’re a must-have. Make sure your app is worthy of your logo by offering users a seamless experience across devices. At Techware Lab, our award-winning team takes care of every aspect of development, from ideation to delivery. 
                    </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="taxi-box">
                    <img src="/assets/images/ttaxi-icon-3.png" class="img-fluid" alt="TTaxi">
                    <h2>Build Relationships, <br>
                        Not Just Bookings
                        </h2>
                    <p>Techware Lab empowers your taxi business by building customer loyalty and providing the best secure software solutions that will give your customers the confidence to choose your services above the rest. 
                        Deliver your customers with a user-friendly interface, a fully responsive taxi booking system, and personalized icons that reflect your brand image with TTaxi.
                        </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ttaxi-features-wrapper">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-4">
                <h1 class="cmn-heading">TTaxi Features</h1>
                <img src="/assets/images/ttaxi-img-3.png" class="img-fluid" alt="TTaxi">
            </div>
            <div class="col-md-8">
                <ul class="list-1">
                    <li><span>1</span> <h6>Clear and friendly customer experience</h6></li>
                    <li><span>2</span> <h6>User and driver management</h6></li>
                    <li><span>3</span> <h6>User and driver apps</h6></li>
                    <li><span>4</span> <h6>Phone number authentication via google firebase</h6></li>
                    <li><span>5</span> <h6>One step and easy booking process</h6></li>
                    <li><span>6</span> <h6>Option to set cab types with rates</h6></li>
                    <li><span>7</span> <h6>Push messages for all the status</h6></li>
                    <li><span>8</span> <h6>Safe and secure payment</h6></li>
                    <li><span>9</span> <h6>Promocode management</h6></li>
                    <li><span>10</span> <h6>Manage the business using admin panel</h6></li>
                </ul>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-6 order-md-0 order-1">
                <ul class="list-2">
                    <li><span>11</span> <h6>Cancellation facility</h6></li>
                    <li><span>12</span> <h6>Booking flexibility</h6></li>
                    <li><span>13</span> <h6>Tracking system</h6></li>
                    <li><span>14</span> <h6>Rating and review</h6></li>
                    <li><span>15</span> <h6>View earnings statistics</h6></li>
                    <li><span>16</span> <h6>RTL supports</h6></li>
                    <li><span>17</span> <h6>100% customizable</h6></li>
                    <li><span>18</span> <h6>Auto assigning of driver</h6></li>
                    <li><span>19</span> <h6>View booking history</h6></li>
                </ul>
            </div>
            <div class="col-md-6 order-md-1 order-0">
                <img src="/assets/images/ttaxi-img-4.png" class="img-fluid" alt="TTaxi">
            </div>
        </div>
    </div>
</section>

<section class="abouttaxi-wrapper">
    <div class="container">
        <div class="row pb-4">
            <div class="col-md-7 order-md-0 order-1">
                <div class="content">
                    <h1 class="cmn-heading">About Techware Lab</h1>
                    <p>Techware Lab provides top design capabilities and software solutions that help elevate your business through industry-specific apps. With unparalleled expertise in website design and mobile app development, our TTaxi team will craft advanced custom solutions or taxi app development that will empower your customers with the flexibility and convenience to book taxis or run a successful taxi business. 
                    </p>
                </div>
            </div>
            <div class="col-md-5 order-md-1 order-0" data-aos="fade-left">
                <img src="/assets/images/ttaxi-img-2.png" class="img-fluid" alt="ttaxi">
            </div>
        </div>
    </div>
</section>

<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Launch Your Online Taxi Business Today</h1>
                <h2 class="cmn-sub-heading">Get the custom white label taxi booking applications that will help grow your business. Try TTaxi, the ultimate online taxi solution for booking and rentals today.</h2>
                <button routerLink="/contact">Learn more today</button>
            </div>
        </div>
    </div>
</section>

<section class="faq-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Frequently Asked Questions</h1>
        <mat-accordion>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                    Can Techware Lab build a white label taxi app like Uber for me?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>Yes, of course. You can develop your own white-label taxi booking app solution that you can rebrand and resell under your name. With our taxi dispatch system, you can attract more customers to your business</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How customizable is the solution?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Our pre-built taxi app solution is ready to use out of the box. You can always add or remove features as per your requirements.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How much does a taxi dispatch system cost? 
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>The cost of a basic app with common features will start at $5,000. The final cost of the taxi dispatch system depends on the scale and complexity of the project and the hourly rate of the development team. Want to find out how much it costs to develop a taxi booking app with Techware Lab? Request a free quote.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Does TTaxi support additional languages other than English?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Yes. TTaxi offers taxi businesses worldwide and supports languages other than English. </p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Does TTaxi accept payments in local currency?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Yes. TTaxi accepts multiple credit cards and currencies. All funds are settled in your local currency, and come with built-in global support.</p>
              </mat-expansion-panel>
        </mat-accordion>
    </div>
</section>

<section class="relates-solutions-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Related Solutions</h1>
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/legal-case-management-software">
                    <img src="/assets/images/new-related-img1.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/doctor-appointment-app">
                    <img src="/assets/images/new-related-img2.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/taxi-management-system">
                    <img src="/assets/images/new-related-img3.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/grocery-app-development">
                    <img src="/assets/images/new-related-img5.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/online-food-ordering-system">
                    <img src="/assets/images/new-related-img6.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/car-rental-app-development">
                    <img src="/assets/images/new-related-img7.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/movie-ticketing-software">
                    <img src="/assets/images/new-related-img8.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/beauty-salon-software">
                    <img src="/assets/images/new-related-img9.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/automotive-appointment-software">
                    <img src="/assets/images/new-related-img10.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/matrimony-app-development">
                    <img src="/assets/images/new-related-img11.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/payroll-management-system">
                    <img src="/assets/images/new-related-img12.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <!-- <ng-template carouselSlide>
                <div class="related-box" routerLink="/legal-case-management-software">
                    <img src="/assets/images/related-img-1.png" class="img-fluid" alt="Images">
                    <div class="logo-container">
                        <img src="/assets/images/t-court.png" class="img-fluid" alt="Logo">
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/doctor-appointment-app">
                    <img src="/assets/images/related-img-2.png" class="img-fluid" alt="Images">
                    <div class="logo-container">
                        <img src="/assets/images/t-doc.png" class="img-fluid" alt="Logo">
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/taxi-management-system">
                    <img src="/assets/images/related-img-3.png" class="img-fluid" alt="Images">
                    <div class="logo-container">
                        <img src="/assets/images/t-cab.png" class="img-fluid" alt="Logo">
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/taxi-dispatch-software">
                    <img src="/assets/images/related-img-3.png" class="img-fluid" alt="Images">
                    <div class="logo-container">
                        <img src="/assets/images/t-taxi.png" class="img-fluid" alt="Logo">
                    </div>
                </div>
            </ng-template> -->
        </owl-carousel-o>
    </div>
</section>