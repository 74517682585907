import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-ecommerce-trends',
  templateUrl: './ecommerce-trends.component.html',
  styleUrls: ['./ecommerce-trends.component.scss'],
})
export class ECommerceTrendsComponent implements OnInit {
  public fbLink: string = environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;
  constructor(
    private title: Title,
    private meta: Meta,
    private seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      'Ecommerce Trends That Will Take Over in 2023 and Beyond.'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'To keep ahead of the competition and be successful in their eCommerce business, organizations must be aware of the latest trends in e-commerce. Here is a list of current and emerging e-commerce trends that will shape the industry in the coming years.',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'To keep ahead of the competition and be successful in their eCommerce business, organizations must be aware of the latest trends in e-commerce. Here is a list of current and emerging e-commerce trends that will shape the industry in the coming years.',
    });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://techwarelab.com/assets/images/eCommerce-banner-img1.jpg',
    });
    this.meta.updateTag({
      property: 'og:title',
      content: 'Ecommerce Trends That Will Take Over in 2023 and Beyond.',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'To keep ahead of the competition and be successful in their eCommerce business, organizations must be aware of the latest trends in e-commerce. Here is a list of current and emerging e-commerce trends that will shape the industry in the coming years.',
    });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://techwarelab.com/ecommerce-trends-that-will-take-over-in-2023',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://techwarelab.com/assets/images/eCommerce-banner-img1.jpg',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Ecommerce Trends That Will Take Over in 2023 and Beyond.',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'To keep ahead of the competition and be successful in their eCommerce business, organizations must be aware of the latest trends in e-commerce. Here is a list of current and emerging e-commerce trends that will shape the industry in the coming years.',
    });
    this.meta.updateTag({
      name: 'twitter:domain',
      content: 'Ecommerce Trends That Will Take Over in 2023 and Beyond.',
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `ecommerce-trends-that-will-take-over-in-2023`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u', `https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }
}
