<footer  *ngIf="shouldDisplayFooter()" class="techware-footer-wrapper">
    <div class="container container-footer">
        <div class="scroll-up-arrow" (click)="scrollToTop()">
            <img class="img-fluid" src="../../assets/images/footer-scrollup-arrow.png" alt="scroll-up-arrow">
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-set">
                    <div class="subscribe-set">
                        <h1>Subscribe to our newsletter for the latest news, tips and product updates</h1>
                        <form [formGroup]="subscribeForm" (ngSubmit)="subscribeBox()">
                            <div class="input-group mb-3">
                                <input type="email" class="form-control" formControlName="email" placeholder="Your email address">
                                <div class="input-group-append">
                                    <button class="btn" type="submit">Subscribe</button>
                                </div>
                            </div>
                            <div class="s_error" *ngIf="!subscribeForm.controls['email'].valid && (subscribeForm.controls['email'].dirty || subscribeForm.controls['email'].touched || subscribeFormSubmit)">
                                <div class="s_validation" *ngIf="subscribeForm.controls['email'].hasError('required')">Provide an Email</div>
                                <div class="s_validation" *ngIf="subscribeForm.controls['email'].hasError('pattern')">Provide a Valid Email Id</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 pr-5">
                <img src="/assets/images/logo-white.png" class="img-fluid footer-logo" alt="Logo">
                <p>Techware Lab is a highly professional software development team based out of Kochi, India with product and sales offices in Canada and UAE. We have delivered our web and mobile solutions and custom software to clients across the world
                    since 2012.</p>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-3">
                        <h1>COMPANY</h1>
                        <ul>
                            <li routerLink="/about">About Us</li>
                            <li routerLink="/services">Services</li>
                            <li routerLink="/products">Products</li>
                            <li routerLink="/portfolio">Portfolio</li>
                            <li routerLink="/become-our-partner">Become Our Partner</li>
                            <li routerLink="/careers">Careers</li>
                            <li routerLink="/blog"> Blog</li>
                            <li routerLink="/contact"> Contact Us</li>
                        </ul>
                    </div>
                    <!-- <div class="col-md-3">
                        <h1>SERVICES</h1>
                        <ul>
                            <li>Web App Development</li>
                            <li>Mobile Development</li>
                            <li>Digital Marketing</li>
                            <li>Business Consulting</li>
                            <li>Proof-of-Concept and Prototyping</li>

                        </ul>
                    </div> -->
                    <div class="col-md-4">
                        <h1>BLOG</h1>
                        <ul>
                            <li routerLink="/how-much-cost-develop-ondemand-delivery-app">How Much Does It Cost to Develop an On-Demand Delivery App?</li>
                            <li routerLink="/benefits-chatbot-for-business">Chatbots for Business: 4 Benefits You Need to Know</li>
                            <li routerLink="/mobile-app-ideas"> 7 Best Mobile App Ideas to Consider in 2022</li>
                            <li routerLink="/we-have-rebranded"> Techware Lab: Why (and how) we rebranded</li>
                            <li routerLink="/why-business-needs-chatbot"> Top 6 reasons why your business needs a chatbot </li>  
                        </ul>
                    </div>
                    <div class="col-md-5">
                        <h1>OUR OFFICES</h1>
                        <div class="address-set">
                            <h2>India</h2>
                            <h3>TransAsia Cyber Park<br>Infopark Phase - II<br>Tower 1, 8th Floor<br> Ambalamedu P.O, Kochi<br>Kerala, India<br>682303 </h3>
                            <!-- <a href="https://api.whatsapp.com/send/?phone=919567879002&text=Hello%21+Thanks+for+writing+to+Techware+Lab+-+the+leading+boutique+software+development+company.+We+have+delivered+our+web+and+mobile+solutions+and+custom+software+to+clients+across+the+world+since+2012.+How+can+we+help+you%3F&app_absent=0" target="_blank" class="whatsapp">+91 96 332 92433</a> -->
                            <strong>For Sales: </strong><a href="https://api.whatsapp.com/send/?phone=919567879002&text=Hello%21+Thanks+for+writing+to+Techware+Lab+-+the+leading+boutique+software+development+company.+We+have+delivered+our+web+and+mobile+solutions+and+custom+software+to+clients+across+the+world+since+2012.+How+can+we+help+you%3F&app_absent=0" target="_blank" class="whatsapp">+91 95 678 79002</a>
                        </div>
                        <div>
                            <!-- <strong>For Partnership: </strong><span> +91 98 954 97787</span><br/> -->
                            <span (click)="openDefaultEmailClient()">Job Enquiries: <span class="mailTo">careers&#64;techwarelab.com</span></span>
                        </div>
                        <div class="address-set">
                            <h2>UAE</h2>
                            <h3> 3103 Latifa Towers<br>Sh. Zayed Road<br>PO Box 282893<br>Dubai, United Arab Emirates</h3>
                            <strong>For Sales: </strong><a href="https://api.whatsapp.com/send/?phone=971505547523&text=Hello%21+I+want+more+info+about+Techware+Lab!&app_absent=0" target="_blank" class="whatsapp">+971 50 554 7523</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="footer-social">
                    <h2>FOLLOW US</h2>
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/TechwareLabPvtLtd" target="_blank"><img src="/assets/images/facebook.png" alt="Facebook"></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/techwarelab/" target="_blank"><img src="/assets/images/instagram.png" alt="Instagram"></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/techwarelab/" target="_blank"><img src="/assets/images/linked.png" alt="LinkedIn"></a>
                        </li>
                        <li>
                            <a href="https://dribbble.com/techwarelab" target="_blank"><img src="/assets/images/dribbble.png" alt="Dribbble"></a>
                        </li>
                        <li>
                            <a href="https://www.behance.net/techwarelab" target="_blank"><img src="/assets/images/behance.png" alt="Behance"></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/TechWareWeb" target="_blank"><img src="/assets/images/twitter.png" alt="Twitter"></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <h3 class="credit">© 2022 Techware Lab. All rights reserved.</h3>
            </div>
        </div>
    </div>
</footer>