<section class="form-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 form-padding-inline">
          <h3>Let us know how we can help you</h3>
          <form class="mt-4" [formGroup]="caseStudyForm" (ngSubmit)="caseStudyFormSubmit()">
            <div class="form-group">
              <div class="row">
                <div class="col-md-6 input-padding-botton-resp">
                  <input type="text" formControlName="name" class="form-control" placeholder="Name">
                  <div class="s_error" *ngIf="!caseStudyForm.controls['name'].valid && (caseStudyForm.controls['name'].dirty || caseStudyForm.controls['name'].touched || formSubmit)">
                    <div class="s_validation" *ngIf="caseStudyForm.controls['name'].hasError('required')">
                        Provide a Name
                    </div>
                    <div class="s_validation" *ngIf="caseStudyForm.controls['name'].hasError('pattern')">Provide a Name</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <input type="email" formControlName="email" class="form-control" placeholder="Business Email">
                  <div class="s_error" *ngIf="!caseStudyForm.controls['email'].valid && (caseStudyForm.controls['email'].dirty || caseStudyForm.controls['email'].touched || formSubmit)">
                    <div class="s_validation" *ngIf="caseStudyForm.controls['email'].hasError('required')">Provide an Email</div>
                    <div class="s_validation" *ngIf="caseStudyForm.controls['email'].hasError('pattern')">Provide a Valid Email Id</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6 input-padding-botton-resp">
                  <select class="form-control" formControlName="country">
                    <option disabled value="0" [selected]="true">Select Country</option>
                    <option *ngFor="let country of countryList.default">
                    {{country.name}}
                    </option>
                  </select>
                  <div class="s_error" *ngIf="!caseStudyForm.controls['country'].valid && (caseStudyForm.controls['country'].dirty || caseStudyForm.controls['country'].touched || formSubmit)">
                    <div class="s_validation" *ngIf="caseStudyForm.controls['country'].hasError('required')">
                        Choose Country
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <input type="number" formControlName="phone" class="form-control" placeholder="Phone Number">
                  <div class="s_error" *ngIf="!caseStudyForm.controls['phone'].valid && (caseStudyForm.controls['phone'].dirty || caseStudyForm.controls['phone'].touched || formSubmit)">
                    <div class="s_validation" *ngIf="caseStudyForm.controls['phone'].hasError('required')">
                        Provide a Phone number
                    </div>
                    <div class="s_validation" *ngIf="caseStudyForm.controls['phone'].hasError('pattern')">Provide a Phone number</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-12 input-padding-botton-resp">
                  <select class="form-control" formControlName="service">
                    <option disabled value="0" [selected]="true">Select the service</option>
                    <option value="App Development">App Development</option>
                    <option value="Enterprise Software Development">Enterprise Software Development</option>
                    <option value="Consulting">Consulting</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Dedicated Teams">Dedicated Teams</option>
                    <option value="Dedicated Teams">Other</option>
                </select>
                </div>
              </div>
              <div class="s_error" *ngIf="!caseStudyForm.controls['service'].valid && (caseStudyForm.controls['service'].dirty || caseStudyForm.controls['service'].touched || formSubmit)">
                <div class="s_validation" *ngIf="caseStudyForm.controls['service'].hasError('required')">
                    Choose an Option From Above
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-12">
                  <textarea class="form-control" formControlName="requirement" placeholder="Your Message"></textarea>
                </div>
              </div>
              <div class="s_error" *ngIf="!caseStudyForm.controls['requirement'].valid && (caseStudyForm.controls['requirement'].dirty || caseStudyForm.controls['requirement'].touched || formSubmit)">
                <div class="s_validation" *ngIf="caseStudyForm.controls['requirement'].hasError('required')">
                    Provide Your Message
                </div>
              </div>
            </div>
            <button type="submit">SUBMIT</button>
          </form>
        </div>
      </div>
    </div>
</section>
  