import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';

const GET_BLOG_DETAILS = gql`
query GetAllBlogsWeb($page: Int, $size: Int, $search: String) {
    getAllBlogsWeb(page: $page, size: $size, search: $search) {
        message
        status
        page {
        totalItems
        totalPages
        currentPage
        }
        data {
        id
        title
        excerpt
        description
        featuredImage
        slug
        altTag
        metaTitle
        metaDescription
        thumbnail
        status
        UserId
        User {
            id
            name
            email
            status
        }
        createdAt
        updatedAt
        }
    }
}
`;

const GET_BLOG_DETAILS_BY_SLUG = gql`
    query GetBlogBySlugWeb($slug: String!) {
    getBlogBySlugWeb(slug: $slug) {
        message
        status
        data {
        id
        title
        excerpt
        description
        featuredImage
        slug
        altTag
        metaTitle
        metaDescription
        thumbnail
        status
        UserId
        User {
            id
            name
            email
            status
        }
        createdAt
        updatedAt
        }
    }
}
`;

@Injectable({
    providedIn: 'root'
})
export class BlogDetailsService {

    constructor(private apollo: Apollo) { }

    getBlogs(page: any, size: any, search: any): Observable<any> {
        return this.apollo
            .watchQuery<any>(
                {
                    query: GET_BLOG_DETAILS, fetchPolicy: 'network-only',
                    variables: { page: page, size: size, search: search }
                }
            )
            .valueChanges.pipe(map((result) => result.data.getAllBlogsWeb))
    }
    getBlog(slug: any): Observable<any> {
        return this.apollo
            .watchQuery<any>(
                {
                    query: GET_BLOG_DETAILS_BY_SLUG, fetchPolicy: 'network-only',
                    variables: { slug: slug }
                }
            )
            .valueChanges.pipe(map((result) => result.data.getBlogBySlugWeb))
    }
}