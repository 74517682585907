<section class="header-banner">
    <div class="container">
        <div class="text-content">
            <h1>Create Data-Driven Solutions <br> Through User Research & <br> Discovery</h1>  
            <h6>Discover the key touchpoints of your typical users and what makes them interact with  <br> your products and services with data-driven insights and market research.</h6>
            <a (click)="requestQuote()" target="_blank">Request a demo</a>
        </div>
    </div>
</section>
<section class="techware-wrapper">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col-md-5 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/user-research-img-1.png" class="img-fluid" alt="user-research">
            </div>
            <div class="col-md-7">
                <div class="help-box mt-4 mt-md-0">
                    <h1 class="cmn-heading">
                        At Techware Lab, We Focus on <br> the Usability and Design of <br> Products, Services, and <br> Solutions
                    </h1>
                    <p>
                        Our team is dedicated to researching the full user experience, giving us insight on how to inform our clients of the most appropriate products and services to introduce the modern market.
                    </p>
                    <button routerLink="/contact">Get a quote</button>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="testimonial-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h1 class="cmn-heading">Who We Work With</h1>
          <p>See other companies who have partnered with Techware Lab for our user research and discovery solutions.</p>
        </div>
        <div class="col-md-4"></div>
      </div>
      <div id="demo" class="carousel slide mt-5" data-ride="carousel">
        <!-- The slideshow -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-img.png"
                      alt="Profile"
                    />
                  </div>
                  <h3>Josef Myers</h3>
                  <h4>That Level, CEO, USA</h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “ I’ve been outsourcing some tasks to offshore developers for over 10 years now, and I’ve had the best experience with Techware by far. They follow the development and design requirements perfectly, and most importantly they communicate any questions or concerns early in the project, which saves everyone a lot of time. For every phase of the project management, development, and deployment, they work quickly and effectively. So whenever I send them a project, I know there’s no need to worry about them completing correctly and on time.”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img src="/assets/images/AbdulSattar.jpg" alt="Profile" />
                  </div>
                  <h3>AbdulSattar Zahid</h3>
                  <h4>
                    Alibaba Product Growth,<br />
                    Dubai
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “In a nutshell, I consider them to be a member of our team,
                    who just don't sit with us. I have always found the Techware
                    team very compliant. I have never gotten the feeling that
                    getting to a goal is important to me and to them we are just
                    another client, but I have always gotten the feeling that we
                    are one team and I value that over all the disagreements and
                    what not. As a testament to this feedback, what started from
                    one project has transformed our partnership in a very long one
                    and we hope to continue that. ”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-image-3.jpg"
                      alt="Profile"
                    />
                  </div>
                  <h3>Danette Copestake</h3>
                  <h4>
                    IT Director,<br />
                    Wyelands Bank
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    "Techware Lab and I worked together on a number of exciting
                    innovation and R&D projects. Nixon was my lead technical
                    consultant. He is a knowledgeable IT consultant who is highly
                    collaborative in his approach. He is always quick to respond
                    to questions / issues offering solutions to complex problems
                    and often thinks ‘out of the box’. Nixon is intelligent,
                    diligent and thorough in his approach and maintains a sense of
                    humour. He was a highly valued member of my team, often going
                    above and beyond what is expected of him. I would welcome him
                    back to my team and strongly recommend him to future
                    employers."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <a class="carousel-control-prev" href="#demo" data-slide="prev"> </a>
          <a class="carousel-control-next" href="#demo" data-slide="next"> </a>
        </div>
      </div>
    </div>
</section>


<section class="software-wrapper">
    <div class="container">
        <div class="row mb-md-5 mt-5">
            <div class="col-md-7">
                <div class="help-box">
                    <h1 class="cmn-heading">
                        Why User Research Is Key
                    </h1>
                    <p>User research helps you make well-informed decisions about the solutions you intend to make for your users. Data from your research is accurate enough to support your design and strategy conclusions, which allows you to notice the type of early adopters who are likely to use your product. </p>
                </div>
            </div>
            <div class="col-md-5 mt-3 mt-md-0" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/user-research-img-2.png" class="img-fluid" alt="user-research">
            </div>
        </div>
    </div>
</section>






<section class="design-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading text-center">Our User Research & Discovery Solutions</h1>
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/user-research-img-3.png" class="img-fluid side-img" alt="user-research">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-5 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Design Auditing
                                         </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        Analyze all of the elements of your organization’s proposed product design and ensure cohesiveness across all channels of your user experience before investing your time and money.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Digital Product Development
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        Create your app, website, mobile and various other digital solutions with the help of our skilled developers. Gain a proper understanding of how your target customer likes to interact with your product or service for the best outcome.
                                       </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            User and Usability Testing
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            Get a full evaluation of your product’s effectiveness post construction. We test the overall design of your digital product with real users, observing their experience and interactions with the software, interface, and accessibility.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<section class="approach-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Our Approach</h1>
                <h2 class="cmn-sub-heading ">Get assistance at every stage of the user research for your product development with our proven five-step approach.</h2>
            </div>
        </div>
        <div class="row mb-md-5 mt-md-4">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box blue">
                    <img src="/assets/images/user-research-icon-1.png" alt="enterprise Icon">
                    <h3> Understanding Your User </h3>
                    <p> We orchestrate a user-centered vision based on your customer personas and key pain points. </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="approach-box red">
                    <img src="/assets/images/user-research-icon-2.png" alt="enterprise Icon">
                    <h3>Intent: Finding the Why</h3>
                    <p>Get a strategic plan for creative action with your customers’ perspective in mind.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box red">
                    <img src="/assets/images/user-research-icon-3.png" alt="enterprise Icon">
                    <h3>Defining the When  </h3>
                    <p>We determine the temperature of your current market and gauge a timeline to implement your strategic plan. We forecast any potential predictions for trending innovations on the rise.</p>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box blue">
                    <img src="/assets/images/user-research-icon-4.png" alt="enterprise Icon">
                    <h3> Configuring the How  </h3>
                    <p>Our team brainstorms customer perceptions based on our proposed solutions to better market your products and services.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box red">
                    <img src="/assets/images/user-research-icon-5.png" alt="enterprise Icon">
                    <h3>Finalizing the What </h3>
                    <p>Settle on the direction for product development and innovation based on insight from our extensive user research.</p>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="mobile-app-wrapper">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Features</h1>
            </div>
        </div>
        <div class="row side">
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/user-research-icon-one-1.png" class="img-fluid img-ind-one" alt="Images">
                    <h5 class="cmn-sub-heading">Heatmap Analysis </h5>
                    
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/user-research-icon-one-2.png" class="img-fluid img-ind-two" alt="Images">
                    <h5 class="cmn-sub-heading">Trend Analysis </h5>
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/user-research-icon-one-3.png" class="img-fluid img-ind-three" alt="Images">
                    <h5 class="cmn-sub-heading">Customer Journey Mapping </h5>
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/user-research-icon-one-4.png" class="img-fluid img-ind-four" alt="Images">
                    <h5 class="cmn-sub-heading">Document Research</h5>
                </div>
            </div>
            <div class="col-md-12 text-center">
                <div class="team-box text-center">
                    <img src="/assets/images/user-research-icon-one-5.png" class="img-fluid img-ind-five" alt="Images">
                    <h5 class="cmn-sub-heading">Audio and Video Analysis </h5>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Gather the Data-Driven Insights You Need to <br> Succeed</h1>
                <h5 class="cmn-sub-heading">Increase product relevance and effectiveness with the help of our proven team of designers, researchers, and developers. We know how to leverage the latest technology to keep your digital solution soaring in the market.</h5>
                <button routerLink="/contact">Get a quote now</button>
            </div>
        </div>
    </div>
</section>








