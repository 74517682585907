import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'building-for-success.component',
  templateUrl: './building-for-success.component.html',
  styleUrls: ['./building-for-success.component.scss']
})
export class BuildingForSuccessComponent implements OnInit {

  public fbLink: string = environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;
  constructor(
    private title: Title,
    private meta: Meta,
    private seoService: SeoService
  ) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      'Strategies for Effective Website and App Development'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'By understanding your target audience, and implementing practical design principles, you can create a digital platform that engages users and drives success',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'By understanding your target audience, and implementing practical design principles, you can create a digital platform that engages users and drives success',
    });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://techwarelab.com/assets/images/blog-building-for-success-main.jpg',
    });
    this.meta.updateTag({
      property: 'og:title',
      content:
        'Strategies for Effective Website and App Development',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'By understanding your target audience, and implementing practical design principles, you can create a digital platform that engages users and drives success',
    });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://techwarelab.com/strategies-for-effective-website-app-Development',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://techwarelab.com/assets/images/blog-building-for-success-main.jpg',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Strategies for Effective Website and App Development',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'By understanding your target audience, and implementing practical design principles, you can create a digital platform that engages users and drives success',
    });
    this.meta.updateTag({
      name: 'twitter:domain',
      content:
        'Strategies for Effective Website and App Development',
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `strategies-for-effective-website-app-Development`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u', `https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }

}
