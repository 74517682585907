import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-ecommerce-app-development',
  templateUrl: './ecommerce-app-development.component.html',
  styleUrls: ['./ecommerce-app-development.component.scss']
})
export class EcommerceAppDevelopmentComponent implements OnInit {

  public fbLink:string=environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;

  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }


  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Ecommerce App Development Cost in 2022 | Techware Lab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Several factors influence the cost of developing an e-commerce app. Each may differ in terms of features and design. This article discusses the cost of developing an eCommerce app in 2022.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Several factors influence the cost of developing an e-commerce app. Each may differ in terms of features and design. This article discusses the cost of developing an eCommerce app in 2022.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-17.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Ecommerce App Development Cost in 2022 | Techware Lab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Several factors influence the cost of developing an e-commerce app. Each may differ in terms of features and design. This article discusses the cost of developing an eCommerce app in 2022.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/ecommerce-app-development-cost-2022",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/blog-img-17.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Ecommerce App Development Cost in 2022 | Techware Lab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Several factors influence the cost of developing an e-commerce app. Each may differ in terms of features and design. This article discusses the cost of developing an eCommerce app in 2022.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Ecommerce App Development Cost in 2022 | Techware Lab",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `ecommerce-app-development-cost-2022`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u',`https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');
  
      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');
      
      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }
  

}
