import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor(private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit(): void {

    this.createLinkForCanonicalURL();

    this.title.setTitle(
      "Software and IOT Applications Development Services | Hire Skilled Developers - Techware Lab"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "We offer a diverse range of services all designed to find a new competitive edge for your business. Check out our custom software development services tailored to your exact business needs.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "We offer a diverse range of services all designed to find a new competitive edge for your business. Check out our custom software development services tailored to your exact business needs.",
    });
    this.meta.updateTag({
      property: "og:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      property: "og:title",
      content:
        "Software and IOT Applications Development Services | Hire Skilled Developers - Techware Lab",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "We offer a diverse range of services all designed to find a new competitive edge for your business. Check out our custom software development services tailored to your exact business needs.",
    });
    this.meta.updateTag({
      property: "og:url",
      content:
        "https://techwarelab.com/services",
    });
    this.meta.updateTag({
      name: "twitter:image",
      content:
        "https://techwarelab.com/assets/images/og_logo.png",
    });
    this.meta.updateTag({
      name: "twitter:title",
      content:
        "Software and IOT Applications Development Services | Hire Skilled Developers - Techware Lab",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "We offer a diverse range of services all designed to find a new competitive edge for your business. Check out our custom software development services tailored to your exact business needs.",
    });
    this.meta.updateTag({
      name: "twitter:domain",
      content:
        "Software and IOT Applications Development Services | Hire Skilled Developers - Techware Lab",
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
  


}
