<section class="story-section">
    <div class="container">
      <div class="row">
        <div class="col-md-6 story-content">
          <h3>{{ title }}</h3>
          <p *ngFor="let shortDesc of description">{{ shortDesc }}</p>
          <button *ngIf="!showMore" (click)="toggleShowMore()">{{ buttonText }}</button>
          <!-- Conditional rendering of extra content -->
          
        </div>
        <div class="col-md-6">
          <div class="story-img" data-aos="fade-left">
            <img [src]="imageUrl" alt="Story Image" class="img-fluid">
          </div>
        </div>
        <!-- <div *ngIf="showMore" class="extra-content col-md-12">
          <p [innerHTML] *ngFor="let paragraph of viewMoreContent" >{{ paragraph }}</p> -->
            <!-- Button to toggle extra content visibility when extra content is shown -->
            <!-- <button (click)="toggleShowMore()">{{ buttonText }}</button>
        </div> -->
        <div *ngIf="showMore" class="extra-content col-md-12">
          <ng-container *ngFor="let content of viewMoreContent" >
              <ng-container *ngTemplateOutlet="dynamicContent; context: { $implicit: content }"></ng-container>
          </ng-container>
          <button (click)="toggleShowMore()">{{ showMore ? 'VIEW LESS' : 'VIEW MORE' }}</button>
        </div>
        
      </div>  
    </div>
</section>

<ng-template #dynamicContent let-content>
  <div [innerHTML]="content"></div>
</ng-template>
  