<section class="header-banner">
    <div class="container">
        <div class="text-content">
            <h1>Enterprise Development for <br> the Modern Business</h1>  
            <h6>An enterprise mobility solution that enables your business to work smarter. Improve your <br> current operations and mitigate IT complications with a modern and integrated solution <br> that will benefit your internal team and customers. </h6>
            <a (click)="requestQuote()" target="_blank">Request a demo</a>
        </div>
    </div>
</section>
<section class="techware-wrapper">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col-md-5 text-right" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/enterprise-mon.png" class="img-fluid" alt="Enterprise Development">
            </div>
            <div class="col-md-7">
                <div class="help-box mt-4 mt-md-0">
                    <h1 class="cmn-heading">
                        Welcome to Techware Lab, the <br/>
                        Home of Outstanding Enterprise <br>
                        Development for Companies <br>
                        That Prioritize Innovation
                    </h1>
                    <p>
                        In the digital world, enterprise mobility is crucial to empowering any internal team, and the people they serve. You need the right strategy and technology to truly make a difference. We specialize in enterprise development to help you best engage with your customers, increase productivity amongst your employees, and improve the overall performance of your enterprise.
                    </p>
                    <button routerLink="/contact">Get a free quote</button>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <section class="testimonial-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h1 class="cmn-heading">Who We Work With</h1>
          <p>See other companies who have partnered with Techware Lab for enterprise development services.</p>
        </div>
        <div class="col-md-4"></div>
      </div>
      <div id="demo" class="carousel slide mt-5" data-ride="carousel">
        <!-- The slideshow -->
        <!-- <div class="carousel-inner"> 
          <div class="carousel-item active">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-img.png"
                      alt="Profile"
                    />
                  </div>
                  <h3>Donnette Copestake</h3>
                  <h4>IT Director, Wyelands Bank</h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “Techware Lab and I worked together on a number of exciting innovation and R&D projects. Nixon was my lead technical consultant. He is a knowledgeable IT consultant who is highly collaborative in his approach. He is always quick to respond to questions / issues offering solutions to complex problems and often thinks ‘out of the box’. Nixon is intelligent, diligent and thorough in his approach and maintains a sense of humour. He was a highly valued member of my team, often going above and beyond what is expected of him. I would welcome him back to my team and strongly recommend him to future employers.”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img src="/assets/images/AbdulSattar.jpg" alt="Profile" />
                  </div>
                  <h3>AbdulSattar Zahid</h3>
                  <h4>
                    Alibaba Product Growth,<br />
                    Dubai
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “In a nutshell, I consider them to be a member of our team,
                    who just don't sit with us. I have always found the Techware
                    team very compliant. I have never gotten the feeling that
                    getting to a goal is important to me and to them we are just
                    another client, but I have always gotten the feeling that we
                    are one team and I value that over all the disagreements and
                    what not. As a testament to this feedback, what started from
                    one project has transformed our partnership in a very long one
                    and we hope to continue that. ”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-image-3.jpg"
                      alt="Profile"
                    />
                  </div>
                  <h3>Danette Copestake</h3>
                  <h4>
                    IT Director,<br />
                    Wyelands Bank
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    "Techware Lab and I worked together on a number of exciting
                    innovation and R&D projects. Nixon was my lead technical
                    consultant. He is a knowledgeable IT consultant who is highly
                    collaborative in his approach. He is always quick to respond
                    to questions / issues offering solutions to complex problems
                    and often thinks ‘out of the box’. Nixon is intelligent,
                    diligent and thorough in his approach and maintains a sense of
                    humour. He was a highly valued member of my team, often going
                    above and beyond what is expected of him. I would welcome him
                    back to my team and strongly recommend him to future
                    employers."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <a class="carousel-control-prev" href="#demo" data-slide="prev"> </a>
          <a class="carousel-control-next" href="#demo" data-slide="next"> </a>
        </div>
      </div>
    </div>
</section> -->

<section class="brands-wrapper">
    <div class="container">
      <div class="row mt-5 mb-4 mt-md-0">
        <div class="col-md-12 text-center">
          <h1 class="cmn-heading">Who We Work With</h1>
          <h2 class="cmn-sub-heading">
            See other companies who have partnered with Techware Lab for website
            design<br />
            and development services.
          </h2>
        </div>
      </div>
  
      <owl-carousel-o [options]="customOptionsOne">
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-one.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-two.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-three.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-four.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-eigt.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-nine.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-logo-five.png"
              class="img-fluid brand-img-one"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-logo-four.png"
              class="img-fluid brand-img-two"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-three.png"
              class="img-fluid brand-img-three"
              alt="Images"
            />
          </div>
        </ng-template>
  
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-five.png"
              class="img-fluid brand-img-four"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-six.png"
              class="img-fluid brand-img-five"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/logo-sev.png"
              class="img-fluid brand-img-six"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-ten.png"
              class="img-fluid brand-img-seven"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-elv.png"
              class="img-fluid brand-img-eigt"
              alt="Images"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="brand-box">
            <img
              src="/assets/images/brand-tw.png"
              class="img-fluid"
              alt="Images"
            />
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </section>





<section class="design-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Enterprise Development Services </h1>
                <h2 class="cmn-sub-heading ">Our team provides a diverse range of services that help leverage digital, streamline your business’s workflows and help you make your mark in your industry.</h2>
           
                <div class="row mt-5">
                    <div class="col-md-5">
                        <img src="/assets/images/enterprise-img-2.png" class="img-fluid side-img" alt="enterprise development">
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6 mt-5 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Application Development
                                         </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        We build and onboard customizable enterprise mobile solutions in the form of applications, helping you finalize your preferred platform, and design and software decisions. Our ability to leverage trending technology stacks allows us to adequately help you reach and surpass your business goals.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Mobility Development Strategy
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                       <p>
                                        Techware Lab works with your team to build a feasible roadmap, outlining the timeline, milestones, and every step of your journey to active mobility, from inception to implementation. We capture data and analytics based on the performance of your app throughout its lifecycle, zeroing in on performance metrics, engagement, and revenue generation.
                                       </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            User Interface (UI) and User Experience (UX) Design
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            Our design team has recommendations that will help your enterprise achieve record-breaking customer engagement and retention, as well as improve team performance. It’s all about providing an innovative, memorable and enjoyable experience for all.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Back-End Integration
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>
                                            We offer integration services for your enterprise software, ensuring smooth functioning across multiple platforms and systems. This way, your applications can operate seamlessly with SaaS and other similar software.
                                        </p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<section class="approach-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Our Approach to Mobilizing Your Enterprise</h1>
                <h2 class="cmn-sub-heading">We take calculated and intentional steps throughout the mobile application development process.</h2>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-12">
                <img src="/assets/images/enterprise-timeline.png" alt="Enterprise timeline" width="100%">
            </div>
        </div>
    </div>
</section>


<section class="approach-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">How Techware Lab’s Holistic Approach Makes Your Business Better</h1>
            </div>
        </div>
        <div class="row mb-md-5 mt-md-4">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box blue">
                    <img src="/assets/images/enterprise-icon-1.png" alt="enterprise Icon">
                    <h3> Provide Seamless Customer Experiences </h3>
                    <p>Engage your customers anywhere, anytime, and on any device with the help of our enterprise mobility development system.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="approach-box red">
                    <img src="/assets/images/enterprise-icon-2.png" alt="enterprise Icon">
                    <h3>Revive Employee Productivity</h3>
                    <p>Leverage digital within the workplace. Enforce employees to adapt to a new way of working with the implementation of immersive mobile apps while keeping company data safe and secure.</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="approach-box yellow">
                    <img src="/assets/images/enterprise-icon-3.png" alt="enterprise Icon">
                    <h3>Lead the Tech and Market Force</h3>
                    <p>Digitize your business operations and become a competitive force in the marketplace and a fierce competitor.</p>
                </div>
            </div>
        </div>
    </div>
</section>






<section class="mobile-app-wrapper">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Leverage the Latest Technologies</h1>
                <p>We’re dedicated to leveraging digital technologies for your business’s ultimate digital transformation.</p>
            </div>
        </div>
        <div class="row side">
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/enterprise-ind-one.png" class="img-fluid img-ind-one" alt="Images">
                    <h5 class="cmn-sub-heading">Machine Learning and Artificial Intelligence</h5>
                    <p>Implement AI tools and SDKs within your application development.</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/enterprise-ind-two.png" class="img-fluid img-ind-two" alt="Images">
                    <h5 class="cmn-sub-heading">Internet of Things</h5>
                    <p>Give your business a competitive advantage by merging the physical and digital world.</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/enterprise-ind-three.png" class="img-fluid img-ind-three" alt="Images">
                    <h5 class="cmn-sub-heading">Cloud Software</h5>
                    <p>Maximize cloud computing to transform and accelerate your business’s digital transformation.</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="team-box text-center">
                    <img src="/assets/images/enterprise-ind-four.png" class="img-fluid img-ind-three" alt="Images">
                    <h5 class="cmn-sub-heading">Blockchain</h5>
                    <p>Blockchain app developments services suitable with your business goals.</p>
                </div>
            </div>
            
        </div>
    </div>
</section>


<section class="techware-innovation-wrapper ">
    <div class="container">
        <div class="row mt-5 mb-4 mt-md-0">
            <div class="col-md-12 text-center">
                <h1 class="cmn-heading">Enterprise Development Use Cases</h1>
            </div>
        </div>
        <div class="row side">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="team-box text-center">
                    <img src="/assets/images/enterprise-inn-1.png" class="img-one" alt="Techware-team">
                    <h3>No more silos with seamless software integrations</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="team-box text-center">
                    <img src="/assets/images/enterprise-inn-2.png" class="img-two" alt="Techware-team">
                    <h3>Be an innovation leader in your market</h3>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="team-box text-center">
                    <img src="/assets/images/enterprise-inn-3.png" class="img-three" alt="Techware-team">
                    <h3>Experience massive growth and customer retention</h3>
                </div>
            </div>
        </div>
    </div>
</section> 


<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Strengthen Your Enterprise App Development With a Proven Technology Partner</h1>
                <h5 class="cmn-sub-heading">Our team of enterprise app developers specialize in deploying high-quality and reliable enterprise mobile development solutions for the modern enterprise. We’ll tailor a unique digital strategy suitable for your business goals and equip you with the tools and technology needed to stand out from the competition.</h5>
                <button routerLink="/contact">Get a free quote now</button>
            </div>
        </div>
    </div>
</section>








