<section class="results-section">
    <div class="container">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-5">
          <div class="results-img" data-aos="fade-right">
            <img [src]="imageUrl" alt="Results Image" class="img-fluid">
          </div>
        </div>
        <div class="col-md-7 result-content">
          <h3>{{ title }}</h3>
          <p *ngFor="let shortDesc of description">{{ shortDesc }}</p>
        </div>
      </div>
    </div>
</section>
  