<section class="quote-wrapper">
    <span class="close_btn" (click)="closeChatDialog()"><img src="/assets/images/close-icon.png" alt="Close"></span>
    <!-- <form [formGroup]="contactForm" (ngSubmit)="contactFormSubmit()">
        <div class="form-group">
            <input type="text" formControlName="name" class="form-control" placeholder="Name">
            <div class="s_error" *ngIf="!contactForm.controls['name'].valid && (contactForm.controls['name'].dirty || contactForm.controls['name'].touched || contactSubmit)">
                <div class="s_validation" *ngIf="contactForm.controls['name'].hasError('required')">
                    Provide a Name
                </div>
            </div>
        </div>
        <div class="form-group">
            <input type="email" formControlName="email" class="form-control" placeholder="Email">
            
            <div class="s_error" *ngIf="!contactForm.controls['email'].valid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched || contactSubmit)">
                <div class="s_validation" *ngIf="contactForm.controls['email'].hasError('required')">Provide an Email</div>
                <div class="s_validation" *ngIf="contactForm.controls['email'].hasError('pattern')">Provide a Valid Email Id</div>
            </div>
        </div>
        <div class="form-group">
            <select class="form-control" formControlName="country">
                <option disabled value="0" [selected]="true">Select Country</option>
                <option *ngFor="let contry of countryList.default">
                {{contry.name}}
                </option>
            </select>
            
            <div class="s_error" *ngIf="!contactForm.controls['country'].valid && (contactForm.controls['country'].dirty || contactForm.controls['country'].touched || contactSubmit)">
                <div class="s_validation" *ngIf="contactForm.controls['country'].hasError('required')">
                    Choose Country
                </div>
            </div>
        </div>
        <div class="form-group">
            <input type="Number" formControlName="phone" class="form-control" placeholder="Phone">
            
            <div class="s_error" *ngIf="!contactForm.controls['phone'].valid && (contactForm.controls['phone'].dirty || contactForm.controls['phone'].touched || contactSubmit)">
                <div class="s_validation" *ngIf="contactForm.controls['phone'].hasError('required')">
                    Provide a Phone number
                </div>
            </div>
        </div>
        <div class="form-group">
            <select class="form-control" formControlName="reason">
                <option disabled value="0" [selected]="true">What are you looking for?</option>
                <option value="App Development">App Development</option>
                <option value="Enterprise Software Development">Enterprise Software Development</option>
                <option value="Consulting">Consulting</option>
                <option value="Marketing">Marketing</option>
                <option value="Dedicated Teams">Dedicated Teams</option>
                <option value="Dedicated Teams">Other</option>
            </select>
            <div class="s_error" *ngIf="!contactForm.controls['reason'].valid && (contactForm.controls['reason'].dirty || contactForm.controls['reason'].touched || contactSubmit)">
                <div class="s_validation" *ngIf="contactForm.controls['reason'].hasError('required')">
                    Choose an Option From Above
                </div>
            </div>
        </div>
        <div class="form-group">
            <textarea rows="5" placeholder="Please describe your requirements " formControlName="requirement" class="form-control"></textarea>
            
            <div class="s_error" *ngIf="!contactForm.controls['requirement'].valid && (contactForm.controls['requirement'].dirty || contactForm.controls['requirement'].touched || contactSubmit)">
                <div class="s_validation" *ngIf="contactForm.controls['requirement'].hasError('required')">
                    Provide Your Requirements
                </div>
            </div>
        </div>
        <div class="form-group">
            <button>Request a Quote</button>
        </div>
    </form> -->


    <iframe class="iframeClass" id="zohoIframeModal" src="about:blank" width="100%" height="520" #zohoIframe></iframe>




 
</section>