import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-your-taxi-business-needs',
  templateUrl: './your-taxi-business-needs.component.html',
  styleUrls: ['./your-taxi-business-needs.component.scss'],
})
export class YourTaxiBusinessNeedsComponent implements OnInit {
  public fbLink: string = environment.fbURL;
  public tLink: string = environment.linkedUrl;
  public gLink: string = environment.twitterURL;
  public wLink: string = environment.whatsappURL;

  navUrl: string;
  constructor(
    private title: Title,
    private meta: Meta,
    private seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.createLinkForCanonicalURL();

    this.title.setTitle(
      'Why Your Taxi Business Needs a Reliable Dispatch Software'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'No business is complete without incorporating technology, including taxi industry. Implementing taxi dispatch software in taxi businesses offers great benefits.',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'No business is complete without incorporating technology, including taxi industry. Implementing taxi dispatch software in taxi businesses offers great benefits.',
    });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://techwarelab.com/assets/images/taxi-dispatch-software-blog.webp',
    });
    this.meta.updateTag({
      property: 'og:title',
      content: 'Why Your Taxi Business Needs a Reliable Dispatch Software',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'No business is complete without incorporating technology, including taxi industry. Implementing taxi dispatch software in taxi businesses offers great benefits.',
    });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://techwarelab.com/why-your-taxi-business-needs-a-reliable-dispatch-software',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://techwarelab.com/assets/images/taxi-dispatch-software-blog.webp',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Why Your Taxi Business Needs a Reliable Dispatch Software',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'No business is complete without incorporating technology, including taxi industry. Implementing taxi dispatch software in taxi businesses offers great benefits.',
    });
    this.meta.updateTag({
      name: 'twitter:domain',
      content: 'Why Your Taxi Business Needs a Reliable Dispatch Software',
    });
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  public share(mediaType: string) {
    let searchParams = new URLSearchParams();
    let plink = `why-your-taxi-business-needs-a-reliable-dispatch-software`;
    switch (mediaType) {
      case 'facebook':
        searchParams.set('u', `https://techwarelab.com/${plink}`);
        this.navUrl = this.fbLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'linkedin':
        searchParams.set('url', `https://techwarelab.com/${plink}`);
        this.navUrl = this.tLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'twitter':
        searchParams.set('Click Here', `https://techwarelab.com/${plink}`);
        this.navUrl = this.gLink + searchParams;
        return window.open(this.navUrl, '_blank');

      case 'whatsapp':
        searchParams.set('', `https://techwarelab.com/${plink}`);
        this.navUrl = this.wLink + searchParams;
        return window.open(this.navUrl, '_blank');
    }
  }
}
