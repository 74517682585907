import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { WebService } from '../providers/web.service';
import { MatDialog } from '@angular/material/dialog';
import { HelperService } from '../../providers/helper.service';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as countries from './countries.json';
import { MessageSuccessComponent } from '../message-success/message-success.component';
@Component({
  selector: 'app-partner-landing-page',
  templateUrl: './partner-landing-page.component.html',
  styleUrls: ['./partner-landing-page.component.scss'],
})
export class PartnerLandingPageComponent implements OnInit {
  form!: FormGroup;
  submitted = false;
  countryList: any;
  loader = false;

  constructor(
    private title: Title,
    private meta: Meta,
    private fb: FormBuilder,
    public service: WebService,
    private model: MatDialog,
    public helper: HelperService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {

    this.loadZohoScript();

    this.countryList = Object.values(JSON.parse(JSON.stringify(countries)));
    this.contactFormInit();

    this.title.setTitle(' Become Our Partner | Techware Lab');
    this.meta.updateTag({
      name: 'description',
      content:
        'Explore partnership opportunities with Techware Lab, your gateway to collaborative software development excellence.',
    });
  }

  loadZohoScript(): void {


    setTimeout(() => {

      const iframe = document.getElementById('zohoIframePartner') as HTMLIFrameElement;

      const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
  
  
      const script = iframeDoc.createElement('script');
      // const script = this.renderer.createElement('script');
      script.src = 'https://crm.zoho.com/crm/WebFormServeServlet?rid=dd8e9bd6a58ef545664ba3b6af0e88fabc47f674ef55409c3c0e436d4206125aaa0c212ede1b24ea0a4d1116c0ea164bgid5d2cd02a92e38954fda2f318dd487414991c498255342bd8c1859d1f24fa759e&script=$sYG';
      script.id = 'formScript6239895000001543022';
      script.type = 'text/javascript';
      script.async = true;
  
  
      const style = iframeDoc.createElement('style');
  
      style.innerHTML = `
      
      #crmWebToEntityForm {
        
        background-color: var(--bgcolor-secondary);
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 12px;
        padding: 0px !important;
        position: absolute;
        background-color: transparent !important;
        
        
        #webform6239895000001543022 {
          #formsubmit {
            max-width: 300px;
            background-color: #ffffff;
            border: 1px solid #da2a80 !important;
            color: #da2a80 !important;
            padding: 8px 35px;
            border: 2px solid black;
            border-radius: 30px;
            font-weight: 700;
          }
      
          input {
            width: 100%;
            max-width: 750px;
            padding: 10px;
            border: 2px solid #d8d8d8;
            border-radius: 30px;
            @media screen and (max-width: 1200px) {
              padding: 20px;
            }
            @media screen and (max-width: 992px) {
              padding: 16px;
            }
            @media screen and (max-width: 768px) {
              padding: 12px;
            }
          }
          .zcwf_title {
            display: none;
          }

          
          .zcwf_row {
            margin:0 !important;

            width: 100%;
            align-items: center;
            display: flex;
            @media (max-width: 1200px) {
              flex-direction: column;
              align-items: flex-start;
            }
            // @media (max-width: 600px) {
            //   justify-content: space-between;
            // }
          }
          .zcwf_col_lab {
            @media (max-width: 768px) {
              width: 100% !important;
            }
            label {
              font-weight: 800;
              width: 100%;
              color:#fff;
              // @media screen and (max-width: 576px) {
              //   max-width: 140px;
              //   font-size: 12px;
              // }
            }
          }
          #reCaptchaField {
            font-weight: 800;
            @media (max-width: 600px) {
              font-size: 12px !important;
            }
          }
          .zcwf_col_fld {
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
            }
            #imgid6239895000000955096 {
              width: 35%;
              @media (max-width: 576px) {
                width: 80%;
              }
            }
            a {
              color: black;
            }
            select,
            textarea {
              width: 100%;
              padding: 10px;
              border-radius: 30px;
              @media (max-width: 600px) {
                padding: 12px;
              }
            }
            input[type="reset"] {
              display: none;
            }
          }
        }
      }
      
      `
  
      script.onload = () => {
        this.measureFormHeight();
        // const leadSourceField = document.querySelector('#Lead_Source') as HTMLSelectElement;
        // if (leadSourceField) {
        //   leadSourceField.value = 'techware';
        // }
      };
  
      iframeDoc.body.appendChild(script);
      iframeDoc.head.appendChild(style);
      // Append the script to the zohoFormContainer
      // this.renderer.appendChild(this.zohoFormContainer.nativeElement, script);

      
    }, 500);

   
    
  }

  measureFormHeight(): void {
    setTimeout(() => {
      const formElement = document.getElementById('webform6239895000000955096');
      if (formElement) {
        if (isPlatformBrowser(this.platformId)) {
          this.measureFormHeight();
          window.addEventListener('resize', this.measureFormHeight.bind(this));
        }
      } else {
        console.log('Form element not found');
      }
    }, 10);
  }

  contactFormInit() {
    this.form = this.fb.group({
      name: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z ]{2,30}$')],
      ],
      company: ['', [Validators.required]],
      // code: ['+91', [Validators.required]],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'
          ),
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      this.loader = true;
      let userObj = this.form.value;
      this.service.post_data('joinUsFormData', '', userObj).subscribe(
        (response) => {
          if (response && response.status && response.status == 200) {
            this.model.open(MessageSuccessComponent);
          } else {
            this.helper.showAlert(4, 'Something went wrong');
          }
          this.form.reset({
            name: '',
            email: '',
            company: '',
            phone: '',
            message: '',
          });
          this.submitted = false;
          this.loader = false;
        },
        (error) => {
          this.helper.showAlert(4, 'Something went wrong');
          this.submitted = false;
          this.loader = false;
        }
      );
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
