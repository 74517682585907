<section class="career-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="circle-wrapper">
                    <h1>TDoc: Find and Book <br>
                        Appointments with <br>
                        Doctors Online
                    </h1>
                    <h2 class="cmn-sub-heading">Say no to long wait times at the doctor’s office. Get the software solution that makes scheduling on-demand doctor’s appointments quick and easy. Our doctor booking software and app will allow you to focus on providing medical care instead of organizing your appointments. 
                    </h2>
                    <a (click)="requestQuote()" target="_blank">Request a demo</a>
                </div>
            </div>
            <div class="col-md-5">
                <img src="/assets/images/doc-img-1.png" class="img-fluid" alt="ttaxi">
            </div>
        </div>
    </div>
</section>

<section class="whatistaxi-wrapper">
    <div class="container">
        <div class="row pb-4">
            <div class="col-md-5" data-aos="fade-right">
                <img src="/assets/images/doc-img-2.png" class="img-fluid" alt="ttaxi">
            </div>
            <div class="col-md-7">
                <div class="content">
                    <h1 class="cmn-heading">What Is TDoc?</h1>
                    <p>Tired of navigating the complexities of scheduling doctor’s appointments? TDoc offers users a new tool for managing appointments and generating swift alerts so you never forget a doctor’s visit again. Strengthen the doctor-patient relationship with this easy-to-use and expertly designed app. 

                    </p>
                    <p>
                        Whether you’re a medical practitioner or a hospital owner, we have a medical appointment scheduling solution for you to create the best experiences for your patients. Think of it as the Uber for doctors.
                    </p>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="50">
                <div class="taxi-box">
                    <img src="/assets/images/doc-icon-1.png" class="img-fluid" alt="TTaxi">
                    <h2>Make Medical Appointment<br>
                        Scheduling Painless</h2>
                    <p>TDoc’s accessible interface makes timing appointments and staying connected with doctors through a single app seamless. Our patient-centric app or patient scheduling software also lets users search doctors by specialty, services, and qualifications and even check for reviews.
                    </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="150">
                <div class="taxi-box">
                    <img src="/assets/images/doc-icon-2.png" class="img-fluid" alt="TTaxi">
                    <h2>Let Doctors <br>
                        Take Control</h2>
                    <p>TDoc’s easy sign-in system of accounts allows individual physicians to handle scheduling, profile editing, and report generation through its value-added platform. Have a vacation coming up? Let everyone know in an instant.
                    </p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up-right" data-aos-delay="250">
                <div class="taxi-box">
                    <img src="/assets/images/doc-icon-3.png" class="img-fluid" alt="TTaxi">
                    <h2>Boost Healthcare Access <br>
                        For Everyone
                        </h2>
                    <p>Techware Labs’ first-ever advanced doctor appointment scheduling software works alongside best-in-class hospitals, doctors, and medical technology. TDoc assures that healthcare is affordable and accessible to everyone.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ttaxi-features-wrapper">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-4">
                <h1 class="cmn-heading">TDoc Features</h1>
                <img src="/assets/images/doc-img-3.png" class="img-fluid" alt="TTaxi">
            </div>
            <div class="col-md-8">
                <ul class="list-1">
                    <li><span>1</span> <h6>Find Nearby Doctors in your Network</h6></li>
                    <li><span>2</span> <h6>Book Appointment with a Tap</h6></li>
                    <li><span>3</span> <h6>View Appointment Slots</h6></li>
                    <li><span>4</span> <h6>View and Book at Multiple Locations</h6></li>
                    <li><span>5</span> <h6>Easily Handle Multiple Doctor Calendars</h6></li>
                    <li><span>6</span> <h6>Drag and Drop Functionality for Quick Appointment Changes</h6></li>
                    <li><span>7</span> <h6>Pre-Built Interfaces to Top Payment Gateways</h6></li>
                    <li><span>8</span> <h6>View Full Booking History</h6></li>
                    <li><span>9</span> <h6>View Previously Patient Rating</h6></li>
                    <li><span>10</span> <h6>Search for Doctors Based on Specialty, Location, Available date, and Particular Health Insurance</h6></li>
                </ul>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-6 order-md-0 order-1">
                <div class="content">
                    <h2 class="cmn-heading">About Techware Lab</h2>
                    <p>Techware Lab provides top design capabilities and software solutions that help elevate your business through industry-specific apps. Choose the experts in web design and web/mobile app development to bring the process of booking a medical practitioner to the technological age. TDoc, our doctor appointment app, makes finding doctors a painless process so patients get the healthcare they need when they need it. </p>
                </div>
            </div>
            <div class="col-md-6 order-md-1 order-0">
                <img src="/assets/images/doc-img-4.png" class="img-fluid" alt="TTaxi">
            </div>
        </div>
    </div>
</section>

<section class="footer-banner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="cmn-heading">Simplify Doctor Appointment 
                    Booking Today</h1>
                <h2 class="cmn-sub-heading">Don’t let the hassle of old-fashioned booking stop your clients from accessing medical services. Choose TDoc and start connecting patients with doctors today.
                </h2>
                <button routerLink="/contact">Learn more today</button>
            </div>
        </div>
    </div>
</section>

<section class="faq-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Frequently Asked Questions</h1>
        <mat-accordion>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                    What is TDoc medical appointment scheduling software?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>TDoc provides patients with online booking based on doctors’ real-time availability and offers a better experience for patients and their healthcare practitioners.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How can I build a doctor appointment app like Practo or Zocdoc?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>We would need to build a mobile application and a web-based admin panel that includes all the features of a doctor appointment application. Every application is unique, and the technology stack would vary according to the requirements and features of the project.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How much does it cost to build a doctor appointment app?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>It’s impossible to provide a direct quote without knowing the specifics of what your app needs. Cost is typically determined by what features are required, what functionality the platform needs, and turnaround time. A simple doctor appointment app may start at $5,000.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How do I make an appointment with a doctor on TDoc?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Patients can search doctors by specialty and book an appointment through the app. TDoc also provides scheduling service, providing free of charge medical care facilities for end users by integrating information about medical practices and doctor's individual schedules in a central location.
                </p>
                <p>The end user searchable database includes specialities, range of services, office locations, photographs, educational backgrounds, and more. For each doctor, users are able to review the free slots in the schedule and make appointments for those slots.</p>
              </mat-expansion-panel>
        </mat-accordion>
    </div>
</section>

<section class="relates-solutions-wrapper">
    <div class="container">
        <h1 class="cmn-heading">Related Solutions</h1>
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/legal-case-management-software">
                    <img src="/assets/images/new-related-img1.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/taxi-management-system">
                    <img src="/assets/images/new-related-img3.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/taxi-dispatch-software">
                    <img src="/assets/images/new-related-img4.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/grocery-app-development">
                    <img src="/assets/images/new-related-img5.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/online-food-ordering-system">
                    <img src="/assets/images/new-related-img6.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/car-rental-app-development">
                    <img src="/assets/images/new-related-img7.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/movie-ticketing-software">
                    <img src="/assets/images/new-related-img8.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/beauty-salon-software">
                    <img src="/assets/images/new-related-img9.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/automotive-appointment-software">
                    <img src="/assets/images/new-related-img10.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/matrimony-app-development">
                    <img src="/assets/images/new-related-img11.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="related-box" routerLink="/payroll-management-system">
                    <img src="/assets/images/new-related-img12.png" class="img-fluid" alt="Images">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>