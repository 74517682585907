<section class="contact-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-7">
        <div class="main-heading">
          <h1>Know someone who needs our services?</h1>
          <h5>
            Join Techware Lab Today and Take Your Business to the Next Level
          </h5>
        </div>
      </div>
      <div class="col-5">
        <div class="contactMain">
          <div class="contact">
            <div class="contactForm mb-5">
              <div class="contactHead">
                <h4>Fill Out The Form And Book A Free Consultation With Us.</h4>
              </div>
              <div class="form-wrapper">

                <!--
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                  <div class="row">
                    <div class="col-12">
                      <div class="contact-item w-100 my-2">
                        <input
                          type="text"
                          class="style-input w-100"
                          placeholder="Full Name"
                          formControlName="name"
                        />
                      </div>
                      <div
                        class="s_error"
                        *ngIf="
                          (!form.controls['name'].valid &&
                            (form.controls['name'].dirty ||
                              form.controls['name'].touched)) ||
                          submitted
                        "
                      >
                        <div
                          class="s_validation"
                          *ngIf="form.controls['name'].hasError('required')"
                        >
                          *
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="contact-item w-100 my-2">
                        <input
                          type="text"
                          class="style-input w-100"
                          placeholder="Company Name"
                          formControlName="company"
                        />
                      </div>
                      <div
                        class="s_error"
                        *ngIf="
                          (!form.controls['company'].valid &&
                            (form.controls['company'].dirty ||
                              form.controls['company'].touched)) ||
                          submitted
                        "
                      >
                        <div
                          class="s_validation"
                          *ngIf="form.controls['company'].hasError('required')"
                        >
                          *
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="contact-item w-100 my-2">
                        <input
                          type="email"
                          class="style-input w-100"
                          placeholder="Email"
                          formControlName="email"
                        />
                      </div>
                      <div
                        class="s_error"
                        *ngIf="
                          (!form.controls['email'].valid &&
                            (form.controls['email'].dirty ||
                              form.controls['email'].touched)) ||
                          submitted
                        "
                      >
                        <div
                          class="s_validation"
                          *ngIf="form.controls['email'].hasError('required')"
                        >
                          *
                        </div>
                        <div
                          class="s_validation"
                          *ngIf="form.controls['email'].hasError('email')"
                        >
                          Invalid
                        </div>
                      </div>
                    </div> -->
                    <!-- <div class="col-sm-3">
                      <div class="w-100 mb-2">
                        <mat-form-field
                          class="selectOption"
                          *ngIf="countryList"
                        >
                          <mat-select
                            formControlName="code"
                            class="selectOption style"
                          >
                            <mat-option
                              *ngFor="let item of countryList[0]"
                              [value]="item.code"
                              >{{ item.code }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div> -->
                    <!--
                    <div class="col-12">
                      <div class="contact-item">
                        <input
                          type="text"
                          class="style-input w-100"
                          placeholder="Mobile"
                          formControlName="phone"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div
                        class="s_error"
                        *ngIf="
                          (!form.controls['phone'].valid &&
                            (form.controls['phone'].dirty ||
                              form.controls['phone'].touched)) ||
                          submitted
                        "
                      >
                        <div
                          class="s_validation"
                          *ngIf="form.controls['phone'].hasError('required')"
                        >
                          *
                        </div>
                        <div
                          class="s_validation"
                          *ngIf="form.controls['phone'].hasError('pattern')"
                        >
                          Invalid
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="contact-item w-100 my-2">
                        <textarea
                          class="style-input w-100"
                          placeholder="What motivated your organization to join us?"
                          formControlName="message"
                        ></textarea>
                      </div>
                      <div
                        class="s_error"
                        *ngIf="
                          (!form.controls['message'].valid &&
                            (form.controls['message'].dirty ||
                              form.controls['message'].touched)) ||
                          submitted
                        "
                      >
                        <div
                          class="s_validation"
                          *ngIf="form.controls['message'].hasError('required')"
                        >
                          *
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <input
                          type="submit"
                          class="submit-button w-100 my-2"
                          value="Join Us"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              -->

              <iframe class="iframeClass" id="zohoIframePartner" src="about:blank" width="100%" height="430" #zohoIframe></iframe>

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="process">
      <div class="row">
        <div class="col-4">
          <div class="partner-img">
            <img
              src="../../assets/images/partner-process-img.png"
              alt=""
              class="w-100"
            />
          </div>
        </div>
        <div class="col-8">
          <div class="process-content">
            <p>
              As a trusted consultancy, individual consultant, or marketing
              agency, you may require software development services for your
              clients, in addition to the services you already offer. We realize
              that software development may not be your main focus, and we're
              here to handle that responsibility for you. We're prepared to
              create customized software that meets your clients'
              industry-specific and business requirements.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<br />
<section>
  <div class="process-content">
    <div class="background-img">
      <div class="container">
        <h2>Partnership Process</h2>
        <div class="process-set">
          <div class="para-one">
            <div class="listing">1</div>
            <p>
              We commit to protecting your sensitive data by signing an NDA and
              agreement that regulates our cooperation.
            </p>
          </div>
          <div class="para-two">
            <div class="listing">2</div>
            <p>
              We assist and communicate with your clients who require software
              solutions that include:
            </p>
            <ul>
              <li>Software design & development</li>
              <li>Custom web and mobile application development</li>
              <li>Website development</li>
              <li>Custom-made software development.</li>
              <li>Custom ERP development</li>
            </ul>
          </div>
          <div class="para-three">
            <div class="listing">3</div>
            <p>
              After closing a contract, we ensure you get the benefits from the
              efforts you put in.
            </p>
          </div>
        </div>
        <br />
        <p class="ml-0">
          We aim to establish lasting partnerships and offer end-to-end software
          development services to all your new customers. We hold your
          reputation in high esteem and ensure timely and cost-effective
          services to end-users.
        </p>
        <br />
        <p class="mb-5 ml-0">
          Over the years, we maintained a trusted partnership with our partners
          from various industries including banking, finance, logistics,
          eCommerce, manufacturing, and retail, in UAE, US, and Europe. We at
          Techware Lab believe that reaching partners is an effective way to get
          new customers and provide them with world-class services that can
          match their expectations.
        </p>
      </div>
    </div>
  </div>
</section>
<br />
<br />
<section>
  <div class="container">
    <div class="faq">
      <h2 class="mt-5">FAQ Partner Program</h2>
      <br />
      <mat-accordion>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              How Do I Become A Techware Lab Partner?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            You need to fill out the form first that will provide us with your
            contact information, and we'll reach out to you promptly. Once we
            have a clear understanding, you'll be onboarded into our program.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              What is included in our Partnership Program?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            We offer end-to-end software development services including software
            design & development, custom web and mobile application development,
            website development, custom-made software development, and custom
            ERP development.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              What kind of work can we do together?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            At Techware Lab, we offer a range of services that we can
            collaborate on, including web development, mobile app development,
            software development, digital marketing, branding and graphic
            designing services, and more.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              What are we looking for in a partner?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            At Techware Lab, we are looking for partners who are interested in
            expanding their earning potential by bringing in new contracts. We
            value partners who are dedicated to building long-term relationships
            with their clients and who share our commitment to delivering
            high-quality, customized solutions that meet our clients' needs.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Is there any partner program fee?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            No, it's free of cost. You need to complete the form, and we will
            review it. If you are eligible, you can become our partner and start
            earning.
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</section>
<br>


<section class="mb-5">
  <div class="container">
    <div class="testimonial">
      <div class="heading">
        <h1 class="cmn-heading">
          Here are the experiences of our business partners who had a great
          journey with us
        </h1>
      </div>
      <div id="demo" class="carousel slide mt-5" data-ride="carousel">
        <!-- The slideshow -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-image-1.jpg"
                      alt="Profile"
                    />
                  </div>
                  <h3>Josef Myers</h3>
                  <h4>That Level, CEO, USA</h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “I've been outsourcing some tasks to offshore developers for
                    over 10 years now, and I've had the best experience with
                    Techware by far. They follow the development and design
                    requirements perfectly, and most importantly they
                    communicate any questions or concerns early in the project,
                    which saves everyone a lot of time. For every phase of the
                    project management, development, and deployment, they work
                    quickly and effectively. So whenever I send them a project,
                    I know there's no need to worry about them completing
                    correctly and on time.”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img src="/assets/images/AbdulSattar.jpg" alt="Profile" />
                  </div>
                  <h3>AbdulSattar Zahid</h3>
                  <h4>
                    Alibaba Product Growth,<br />
                    Dubai
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    “In a nutshell, I consider them to be a member of our team,
                    who just don't sit with us. I have always found the Techware
                    team very compliant. I have never gotten the feeling that
                    getting to a goal is important to me and to them we are just
                    another client, but I have always gotten the feeling that we
                    are one team and I value that over all the disagreements and
                    what not. As a testament to this feedback, what started from
                    one project has transformed our partnership in a very long
                    one and we hope to continue that. ”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-md-3">
                <div class="test-circle">
                  <div class="test-profile">
                    <img
                      src="/assets/images/testimonial-image-3.jpg"
                      alt="Profile"
                    />
                  </div>
                  <h3>Danette Copestake</h3>
                  <h4>
                    IT Director,<br />
                    Wyelands Bank
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="test-content">
                  <p>
                    "Techware Lab and I worked together on a number of exciting
                    innovation and R&D projects. Nixon was my lead technical
                    consultant. He is a knowledgeable IT consultant who is
                    highly collaborative in his approach. He is always quick to
                    respond to questions / issues offering solutions to complex
                    problems and often thinks ‘out of the box’. Nixon is
                    intelligent, diligent and thorough in his approach and
                    maintains a sense of humour. He was a highly valued member
                    of my team, often going above and beyond what is expected of
                    him. I would welcome him back to my team and strongly
                    recommend him to future employers."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <a class="carousel-control-prev" href="#demo" data-slide="prev"> </a>
          <a class="carousel-control-next" href="#demo" data-slide="next"> </a>
        </div>
      </div>
    </div>
  </div>
</section>
<br>

<div class="to-top" (click)="scrollToTop()">
  <div class="container">
    <img src="../../assets/images/to-top.png" alt="" >
  </div>
</div>
