import { Component, OnInit } from '@angular/core';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-devops-consulting',
  templateUrl: './devops-consulting.component.html',
  styleUrls: ['./devops-consulting.component.scss']
})
export class DevopsConsultingComponent implements OnInit {


  constructor(private model: MatDialog) { }

  ngOnInit(): void {
  }
  requestQuote() {

    this.model.open(RequestQuoteComponent);

  }

}
