import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiConfig } from './../environments/server.config';


const httplive = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization'
  })
};

@Injectable({
  providedIn: 'root'
})

export class WebService {
  commonHeader = {'Auth': 'CODE_INFOTECH_AUTH'}

  constructor(private http: HttpClient) {}

  post_data(url: string, auth: string, data, serviceType: number = 1) {
    const authHeader = (auth != '') ? {'Authorization' : 'Bearer '+auth} : {} ;
    const httpOptions = { headers: new HttpHeaders({...this.commonHeader,...authHeader}) };

    const serviceUrl = this.getServiceUrl(serviceType);
    return this.http.post(`${serviceUrl + url}`, data, httpOptions).pipe(map((response: any) => response));
  }

  getServiceUrl(serviceType: number) {
    switch (serviceType) {
      case 1: return apiConfig;
    }
  }

}
